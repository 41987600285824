import React, { useContext, useEffect, useState } from 'react'
import cn from 'classnames'

import { TabsSelect } from '../../../../components/TabsSelect/TabsSelect'
import { ProgrammBlock } from './ProgrammBlock/ProgrammBlock'

import { createProgrammArray } from '../../functions'
import { createDate } from '../../../../functions/common'

import EventContext from '../../../../context/EventContext'
import { actions } from '../actions'

import './EventProgramm.scss'

export const EventProgramm = React.memo(({ past = false, deleteEvent = false }) => {
	const { state, localDispatch } = useContext(EventContext)

	const [tab, setTab] = useState(0)

	const dispatchHandler = (callback) => (value) => localDispatch(callback(value))

	const optionsDate = {
		weekday: 'short',
		day: 'numeric',
		month: 'short',
	}

	const tabs = Object.keys(state.programms).map((date) => ({
		title: createDate(date, 'ru', optionsDate),
	}))

	const programmBlocks = Object.values(state.programms)
	/* create tabs & programm array */

	/* functions */
	const addNewLecture = (index) => {
		const entry = Object.entries(state.programms)[index]

		const event = entry[0]
		const eventProgramm = entry[1]

		localDispatch(
			actions.setProgramms({
				...state.programms,
				[event]: createProgrammArray(eventProgramm),
			})
		)
	}

	const deleteLecture = (programmIndex, lectureIndex) => {
		const entry = Object.entries(state.programms)[programmIndex]

		const event = entry[0]
		const eventProgramm = entry[1]

		localDispatch(
			actions.setProgramms({
				...state.programms,
				[event]: eventProgramm.filter((lecture, index) => index !== lectureIndex),
			})
		)
	}
	/* functions */

	useEffect(() => {
		setTab(0)
	}, [state.dateStart, state.dateEnd])

	return (
		<div
			className={cn('event-programm-block', {
				'past-event': past,
				'delete-event': deleteEvent,
			})}
		>
			<h2>Программа</h2>
			<TabsSelect tabs={tabs} tab={tab} setTab={setTab} />
			{Object.keys(state.programms).map((item, index) => (
				<div
					className={cn('daily-programm-block', { visible: tab === index })}
					key={`daily-programm-${index}`}
				>
					{programmBlocks[index].map((programm, index) => (
						<ProgrammBlock
							key={index}
							index={index}
							timeStart={programm.time_start}
							timeEnd={programm.time_end}
							name={programm.name}
							description={programm.description}
							lectorName={programm.lecturer}
							deleteProgramm={() => deleteLecture(tab, index)}
							visible={tab === index}
							setProgrammsObject={dispatchHandler(actions.setProgramms)}
							programmDay={item}
							fullProgramm={state.programms}
							fullDayProgramm={programmBlocks[tab]}
							fullDayProgrammIndex={index}
							errors={programm.errors}
						/>
					))}
				</div>
			))}
			<div className="add-programm-block">
				<div className="add-programm-button">
					<button onClick={() => addNewLecture(tab)}>
						<span className="label">Добавить лекцию</span>
					</button>
				</div>
			</div>
		</div>
	)
})
