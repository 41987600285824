import React from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'

import { url } from '../../../api/api'

import { getPlugNumber, createDate } from '../../../functions/common'
import { Button } from '../../Buttons/Button/Button'
import { DeleteReason } from '../../DeleteReason/DeleteReason'
import { avatarPlug } from '../../Avatar/avatar-functions'

import { setCommentId, changeCommentStatus } from '../../../store/reducers/comments/comments'

import closeIcon from '../../../assets/media/svg/status-icons/close-white.svg'

import './CommentBlock.scss'

export const CommentBlock = React.memo(
	({ image, id, name, time, msg, status, showModal, reason, comId }) => {
		const dispatch = useDispatch()

		const plugNumber = getPlugNumber(id)

		const imageUrl = image ? `${url}/static/${image}` : avatarPlug(plugNumber)

		const setViewedComment = () => {
			dispatch(setCommentId({ commentId: comId }))
			dispatch(changeCommentStatus())
		}

		const openModalWindow = () => {
			dispatch(setCommentId({ commentId: comId }))
			showModal()
		}

		const renderButtons = (type) => {
			const switchObj = {
				1: (
					<>
						<Button gray type={'ss'} label={'Одобрить'} onClick={setViewedComment} />
						<Button icon={closeIcon} red type={'ss'} label={'Удалить'} onClick={openModalWindow} />
					</>
				),
				2: <Button icon={closeIcon} red type={'ss'} label={'Удалить'} onClick={openModalWindow} />,
				3: <DeleteReason reason={reason} />,
			}

			return switchObj[type]
		}

		const optionsDate = {
			day: 'numeric',
			month: 'numeric',
			year: 'numeric',
		}

		return (
			<div className="comment-block">
				<div
					className={cn('comment-block__avatar avatar__image', {
						[`plug plug-${plugNumber}`]: !image,
					})}
				>
					<img src={imageUrl} alt="" />
				</div>
				<div className="comment-block__body">
					<div className="comment-block__header">
						<div className="comment-block__header__name">
							<p>{name}</p>
						</div>
						<div className="comment-block__header__time">
							<p>{`${createDate(time, 'ru', optionsDate)} в ${createDate(
								time,
								'ru',
								optionsDate
							)}`}</p>
						</div>
					</div>
					<div className="comment-block__text">
						<p className="text">{msg}</p>
					</div>
				</div>
				<div className="comment-block__buttons">{renderButtons(status)}</div>
			</div>
		)
	}
)
