import React from 'react'
import cn from 'classnames'
import { useSelector } from 'react-redux'

import { SearchInput } from '../Inputs/SearchInput/SearchInput'
import { IconButton } from '../IconButton/IconButton'
import { TabsSelect } from '../TabsSelect/TabsSelect'

import { getDevice } from '../../store/reducers/app/app-selectors'

import './TableHeader.scss'

export const TableHeader = ({
	title,
	stanalone,
	searchValue,
	onSearchChange,
	onRefresh,
	placeholder,
	tabs,
	tab,
	setTab,
}) => {
	const device = useSelector(getDevice)

	return (
		<div className={cn('table-header-component', { stanalone })}>
			<p className="title">{title}</p>
			<div className="header-tools">
				{device === 'mobile' ? (
					<>
						<div className="header-input-mobile">
							<SearchInput
								placeholder={placeholder}
								value={searchValue}
								onChange={onSearchChange}
							/>
							<IconButton
								className="round-icon-button"
								iconName="refresh"
								size={32}
								onClick={onRefresh}
							/>
						</div>
						{!!tabs?.length && <TabsSelect tabs={tabs} tab={tab} setTab={setTab} />}
					</>
				) : (
					<>
						{!!tabs?.length && <TabsSelect tabs={tabs} tab={tab} setTab={setTab} />}
						<SearchInput placeholder={placeholder} value={searchValue} onChange={onSearchChange} />
						<IconButton
							className="round-icon-button"
							iconName="refresh"
							size={32}
							onClick={onRefresh}
						/>
					</>
				)}
			</div>
		</div>
	)
}
