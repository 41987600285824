import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Filters } from '../../../../components/Filters/Filters'
import { FiltersHeader } from '../../../../components/FiltersHeader/FiltersHeader'

import { setFilters } from '../../../../store/reducers/knowledge-base/knowledge-base'

import { getFilters } from '../../../../store/reducers/knowledge-base/knowledge-base-selectors'

export const KnowledgeBaseFilters = React.memo(() => {
	const dispatch = useDispatch()

	const { type, status } = useSelector(getFilters)

	const handleTypeFilter = (value) => () => {
		dispatch(setFilters({ type: type === value ? 0 : value, offset: 0 }))
	}

	const handleStatusFilter = (value) => () => {
		dispatch(setFilters({ status: status === value ? 0 : value, offset: 0 }))
	}

	const resetFilter = () => {
		dispatch(setFilters({ type: 0, status: 0, offset: 0 }))
	}

	const FILTER = [
		{
			title: 'Тип',
			items: [
				{ title: 'Статьи', handler: handleTypeFilter(1), active: type === 1 },
				{ title: 'Видео', handler: handleTypeFilter(2), active: type === 2 },
			],
		},
		{
			title: 'Статус',
			items: [
				{
					title: 'Неопубликованные',
					handler: handleStatusFilter(1),
					active: status === 1,
				},
				{
					title: 'Опубликованные',
					handler: handleStatusFilter(2),
					active: status === 2,
				},
				{
					title: 'Удаленные',
					handler: handleStatusFilter(3),
					active: status === 3,
				},
			],
		},
	]

	return (
		<div className="knowledge-base-filters">
			<FiltersHeader reset={resetFilter} />
			<Filters filter={FILTER} />
		</div>
	)
})
