import React from 'react'
import cn from 'classnames'

import './Button.scss'

export const Button = ({
	className,
	label,
	nType = 'button',
	type = 'lg', // md1, md2, lg, s
	white,
	green,
	blue,
	red,
	gray,
	disabled,
	onClick = () => {},
	loading,
	icon,
	size = 24,
}) => (
	<button
		type={nType}
		className={cn('button-main', type, className, {
			white,
			red,
			blue,
			gray,
			loading,
			green,
		})}
		onClick={onClick}
		disabled={disabled}
	>
		<span className="bg" />
		<span className="label">
			{icon && <i style={{ backgroundImage: `url(${icon})`, width: size, height: size }} />}
			{label}
		</span>
	</button>
)
