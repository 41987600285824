import React from 'react'
import { Page, Text, View, Image } from '@react-pdf/renderer'

import { checkUserSkillLevel } from '../../functions'

export const SkillsPage = ({ styles, user, pagesLogo }) => {
	const {
		user: {
			first_page: { name, surname },
			third_page: { specializations },
			fourth_page: { skills, other },
		},
		local: { techs, specs },
	} = user

	const {
		page,
		pageHeader,
		pageName,
		section,
		pagesLogo: pagesLogoStyle,
		pageWrapper,
		title,
		row,
		skillName,
		skillTag,
		skillsWrapper,
		junior,
		middle,
		senior,
	} = styles

	return (
		<Page style={page}>
			<View style={pageHeader}>
				<Text style={pageName}>
					Резюме {surname} {name}
				</Text>
				<Image style={pagesLogoStyle} source={pagesLogo}></Image>
			</View>

			<View style={pageWrapper}>
				{/* навыки */}
				<View style={section}>
					<Text style={title}>Специализации</Text>
					<View style={skillsWrapper}>
						{!!specs.length &&
							specs.map((entry, index) => (
								<View key={index} style={row}>
									{specializations[entry.key] === 1 && (
										<View style={junior}>
											<Text style={skillName}>{entry.value}</Text>
											<Text style={skillTag}>
												{checkUserSkillLevel(specializations[entry.key])}
											</Text>
										</View>
									)}
									{specializations[entry.key] === 2 && (
										<View style={middle}>
											<Text style={skillName}>{entry.value}</Text>
											<Text style={skillTag}>
												{checkUserSkillLevel(specializations[entry.key])}
											</Text>
										</View>
									)}
									{specializations[entry.key] === 3 && (
										<View style={senior}>
											<Text style={skillName}>{entry.value}</Text>
											<Text style={skillTag}>
												{checkUserSkillLevel(specializations[entry.key])}
											</Text>
										</View>
									)}
								</View>
							))}
					</View>
				</View>

				{/* Технологии */}
				<View style={section}>
					<Text style={title}>Технологии</Text>
					<View style={skillsWrapper}>
						{!!techs.length &&
							techs.map((entry) =>
								entry.tech_skills.map((item, index) => (
									<View key={index} style={row}>
										{skills[item.key] === 1 && (
											<View style={junior}>
												<Text style={skillName}>{item.value}</Text>
												<Text style={skillTag}>{checkUserSkillLevel(skills[item.key])}</Text>
											</View>
										)}
										{skills[item.key] === 2 && (
											<View style={middle}>
												<Text style={skillName}>{item.value}</Text>
												<Text style={skillTag}>{checkUserSkillLevel(skills[item.key])}</Text>
											</View>
										)}
										{skills[item.key] === 3 && (
											<View style={senior}>
												<Text style={skillName}>{item.value}</Text>
												<Text style={skillTag}>{checkUserSkillLevel(skills[item.key])}</Text>
											</View>
										)}
									</View>
								))
							)}
					</View>

					{/* Другое */}
					<View style={skillsWrapper}>
						{other &&
							Object.entries(other).map((entry) => {
								const [tech, level] = entry
								return (
									<View key={tech} style={row}>
										{level === 1 && (
											<View style={junior}>
												<Text style={skillName}>{tech}</Text>
												<Text style={skillTag}>{checkUserSkillLevel(level)}</Text>
											</View>
										)}
										{level === 2 && (
											<View style={middle}>
												<Text style={skillName}>{tech}</Text>
												<Text style={skillTag}>{checkUserSkillLevel(level)}</Text>
											</View>
										)}
										{level === 3 && (
											<View style={senior}>
												<Text style={skillName}>{tech}</Text>
												<Text style={skillTag}>{checkUserSkillLevel(level)}</Text>
											</View>
										)}
									</View>
								)
							})}
					</View>
				</View>
			</View>
		</Page>
	)
}
