import { getAdminToken } from '../functions/localStorage'
import instance from './api'

const notificationsApi = {
	async requestNotifications({ limit, offset }) {
		const response = await instance.post(
			`user/get/notif?limit=${limit}&offset=${offset}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async checkNots() {
		const response = await instance.post(
			'user/set/notif/check',
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},
}

export default notificationsApi
