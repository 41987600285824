import React from 'react'

import './FormHeader.scss'

export const FormHeader = ({ text, subText }) => (
	<header className="form-header">
		<h1>{text}</h1>
		{subText && <p>{subText}</p>}
	</header>
)
