import { createSlice } from '@reduxjs/toolkit'

import transactionsApi from '../../../api/transactions-api'

import { onError } from '../functions'
import { getUserInfo } from '../user/user'

const initialState = {
	transactions: [],
	transactionsById: [],
	filters: {
		limit: 10,
		offset: 0,
		pat: '',
		type: 0,
	},
	count: 0,
	searchResult: [],
	isLoading: false,
}

const transactions = createSlice({
	name: 'transactions',
	initialState,
	reducers: {
		setTransactions: (state, action) => {
			state.transactions = action.payload.transactions
		},
		setTransactionsById: (state, action) => {
			state.transactionsById = action.payload.transactionsById
		},
		setCount: (state, action) => {
			state.count = action.payload.count
		},
		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},
		setSearchResult: (state, action) => {
			state.searchResult = action.payload.result
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
	},
})

const { reducer, actions } = transactions

export const {
	setTransactions,
	setCount,
	setFilters,
	setSearchResult,
	setIsLoading,
	setTransactionsById,
} = actions

// Получение списка транзакций
export const requestTransactions = () => async (dispatch, getState) => {
	const {
		transactions: { filters },
	} = getState()

	dispatch(setIsLoading({ isLoading: true }))

	const res = await transactionsApi.requestTransactions(filters)

	if (res.success) {
		dispatch(setTransactions({ transactions: res.result.trans }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Поиск группы или пользователя
export const searchForTransaction = (pat) => async (dispatch) => {
	const res = await transactionsApi.searchForTransaction(pat)

	if (res.success) {
		dispatch(setSearchResult({ result: res.result }))
	} else {
		onError(dispatch, res.result)
	}
}

// Проведение транзакции
export const createTransaction =
	({ to_id, is_user, msg, value }, userAdminTable) =>
	async (dispatch) => {
		const res = await transactionsApi.createTransaction({
			to_id,
			is_user,
			msg,
			value,
		})

		if (res.success) {
			dispatch(requestTransactions())
			userAdminTable && dispatch(requestUserTransactions(to_id))
			userAdminTable && dispatch(getUserInfo(to_id))
		} else {
			onError(dispatch, res.result)
		}
	}

// Получение списка транзакций конкретного пользователя

export const requestUserTransactions = (id) => async (dispatch, getState) => {
	const {
		transactions: {
			filters: { offset, limit },
		},
	} = getState()

	dispatch(setIsLoading({ isLoading: true }))

	const res = await transactionsApi.requestUserTransactions(limit, offset, id)

	if (res.success) {
		dispatch(setTransactionsById({ transactionsById: res.result }))
		dispatch(setCount({ count: res.result.count }))
	} else onError(dispatch, res.result)

	dispatch(setIsLoading({ isLoading: false }))
}

export default reducer
