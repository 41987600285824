import React, { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { Header } from '../Header/Header'

import './AppLayout.scss'

export const AppLayout = ({ children }) => {
	const { pathname } = useLocation()

	const pageContainer = useRef()

	useEffect(() => {
		if (!!pathname.search('events/')) return
		if (pageContainer.current.scrollTop) pageContainer.current.scrollTo(0, 0)
	}, [pathname])

	return (
		<div className="app">
			<Header />
			<div className="container">
				<main className="page-container" ref={pageContainer}>
					{children}
				</main>
			</div>
		</div>
	)
}
