import { createSlice } from '@reduxjs/toolkit'

import commonApi from '../../../api/common-api'
import newsApi from '../../../api/news-api'

import { onError } from '../functions'

const initialState = {
	isLoading: false,
	news: [],
	count: 0,
	filters: {
		type: 0,
		status: 0,
		limit: 10,
		offset: 0,
		name: '',
	},
	newData: {},
	newDataRequested: false,
}

const news = createSlice({
	name: 'news',
	initialState,
	reducers: {
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
		setNews: (state, action) => {
			state.news = action.payload.news
		},
		setCount: (state, action) => {
			state.count = action.payload.count
		},
		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},
		setNewData: (state, action) => {
			state.newData = action.payload.data
		},
		setNewDataRequested: (state, action) => {
			state.newDataRequested = action.payload.requested
		},
	},
})

const { reducer, actions } = news

export const {
	setIsLoading,
	setNews,
	setCount,
	setFilters,
	setNewData,
	setNewDataRequested,
} = actions

// Получение списка новостей
export const requestNews = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		news: { filters },
	} = getState()

	const res = await newsApi.requestNews(filters)

	if (res.success) {
		dispatch(setNews({ news: res.result.news }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Получение новости по id
export const requestNewById = (id) => async (dispatch) => {
	const res = await newsApi.requestNewById(id)

	if (res.success) {
		dispatch(setNewData({ data: res.result }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setNewDataRequested({ requested: true }))
}

// Создание новости
export const createNew = (data, gallery) => async (dispatch) => {
	const res = await newsApi.createNew(data)

	if (res.success) {
		if (gallery.getAll('new_photo').length) {
			dispatch(uploadGallery(res.result.id, gallery))
		}
		dispatch(requestNews())
	} else {
		onError(dispatch, res.result)
	}
}

// Редактирование новости
export const updateNew = (id, data, gallery) => async (dispatch) => {
	const res = await newsApi.updateNew(data)

	if (res.success) {
		if (gallery.getAll('new_photo').length) {
			dispatch(uploadGallery(id, gallery))
		}
		dispatch(requestNews())
	} else {
		onError(dispatch, res.result)
	}
}

// Загрузка галереи
export const uploadGallery = (id, data) => async (dispatch) => {
	const res = await newsApi.uploadGallery(id, data)

	if (!res.success) {
		onError(dispatch, res.result)
	}
}

// Опубликовать новость
export const publishNew = (id) => async (dispatch) => {
	const res = await commonApi.approveTarget(id, 4)

	if (res.success) {
		dispatch(requestNews())
	} else {
		onError(dispatch, res.result)
	}
}

// Снять новость с публикации
export const unpublishNew = (id) => async (dispatch) => {
	const res = await commonApi.rejectTarget(id, 4)

	if (res.success) {
		dispatch(requestNews())
	} else {
		onError(dispatch, res.result)
	}
}

// Удалить новость
export const deleteNew = (id) => async (dispatch) => {
	const res = await commonApi.deleteTarget(id, 4)

	if (res.success) {
		dispatch(requestNews())
	} else {
		onError(dispatch, res.result)
	}
}

export default reducer
