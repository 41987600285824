import React from 'react'

import { Dropdown } from '../Dropdown/Dropdown'
import { Input } from '../Inputs/Input/Input'
import { Checkbox } from '../Checkbox/Checkbox'
import { Button } from '../Buttons/Button/Button'

import './SelectCategory.scss'

export const SelectCategory = React.memo(
	({
		type,
		inputLabel = 'Категория',
		onClose,
		onSubmit = () => {},
		title,
		categoryValue,
		productValue,
		changeProductValue,
		selectCategory,
		selectProduct,
		productOptions,
		categoryOptions,
		labelValue,
		bgValue,
		isHide,
		changeLabelValue,
		changeBgValue,
		changeIsHideValue,
	}) => {
		const checkSubmit = () => {
			if (type === 'Актуальное') return !categoryValue || !productValue
			return !productValue
		}

		return (
			<div className="select-category-block">
				<h2>{title}</h2>

				{type === 'Актуальное' && (
					<Dropdown
						label="Категория"
						inputText={categoryValue}
						options={categoryOptions}
						onChange={selectCategory}
					/>
				)}

				<Dropdown
					label={inputLabel || ''}
					disabled={type === 'Актуальное' && !categoryValue}
					inputText={productValue}
					onInput={changeProductValue}
					onChange={selectProduct}
					options={productOptions}
				/>

				{(type === 'Актуальное' || type === 'Товар') && (
					<Input
						label="Текст лейбла"
						value={labelValue}
						onChange={changeLabelValue}
						disabled={type === 'Актуальное' && !categoryValue && !productValue}
					/>
				)}

				{type === 'Актуальное' && (
					<Input
						label="Цвет подложки и тени (#000000)"
						value={bgValue}
						onChange={changeBgValue}
						disabled={type === 'Актуальное' && (!categoryValue || !productValue)}
					/>
				)}

				{type === 'Актуальное' && (
					<div className="hide-title">
						<Checkbox onChange={changeIsHideValue} checked={isHide} />
						Спрятать заголовок
					</div>
				)}

				<div className="select-category-block__buttons">
					<Button white label="Отменить" type="md2" onClick={onClose} />
					<Button blue label="Сохранить" type="md2" onClick={onSubmit} disabled={checkSubmit()} />
				</div>
			</div>
		)
	}
)
