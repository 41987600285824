import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ModalContainer } from '../../../components/ModalContainer/ModalContainer'
import { SelectCategory } from '../../../components/SelectCategory/SelectCategory'
import { getActual } from '../../../store/reducers/content/content-selector'
import { EmptyItem } from '../CommonComponents/EmptyItem/EmptyItem'
import { Tile } from '../CommonComponents/Tiles/Tile/Tile'
import { TileSubtitle } from '../CommonComponents/Tiles/Tile/TileSubtitle/TileSubtitle'
import { TileTitle } from '../CommonComponents/Tiles/Tile/TileTitle/TileTitle'
import { Tiles } from '../CommonComponents/Tiles/Tiles'
import { PointsCounter } from '../CommonComponents/PointsCounter/PointsCounter'

import { getOffices, getOfficesFilters } from '../../../store/reducers/offices/offices-selectors'
import { getFilters as getNewsFilters, getNews } from '../../../store/reducers/news/news-selectors'
import {
	getProducts,
	productsFilters as getProductsFilters,
} from '../../../store/reducers/products/products-selectors'
import {
	getEventsList,
	getFilters as getEventFilters,
} from '../../../store/reducers/events/events-selectors'

import { requestOffices, setOfficesFilters } from '../../../store/reducers/offices/offices'
import { requestNews, setFilters as setNewsFilters } from '../../../store/reducers/news/news'
import { requestProducts, setProductsFilters } from '../../../store/reducers/products/products'
import { requestEvents, setFilters as setEventFilters } from '../../../store/reducers/events/events'

import { setActual } from '../../../store/reducers/content/content'

import './ActualInfoBlock.scss'
import { url } from '../../../api/api'

export const ActualInfoBlock = React.memo(() => {
	const dispatch = useDispatch()
	const actualArray = useSelector(getActual)

	const officeFilters = useSelector(getOfficesFilters)
	const offices = useSelector(getOffices)
	const officeName = officeFilters.name

	const officesOptions = offices.filter((item) => item.status === 2)

	const newsFilters = useSelector(getNewsFilters)
	const news = useSelector(getNews)
	const newsName = newsFilters.name

	const eventFilters = useSelector(getEventFilters)
	const events = useSelector(getEventsList)
	const eventName = eventFilters.name

	const eventsOptions = events.filter((item) => item.status !== 4 && item.status !== 1)

	const productFilters = useSelector(getProductsFilters)
	const products = useSelector(getProducts)
	const productName = productFilters.name

	const productsOptions = products.filter((item) => item.status === 2)

	const [showModal, toggleModal] = useState(false)
	const [categoryValue, setCategoryValue] = useState({})
	const [selectedItem, setSelectedItem] = useState({})
	const [labelText, setLabelText] = useState('')
	const [bgColor, setBgColor] = useState('')
	const [isHide, setIsHide] = useState(false)

	const [index, setIndex] = useState(null)

	const options = [
		{ name: 'Офисы', id: 1 },
		{ name: 'События', id: 2 },
		{ name: 'Товары', id: 3 },
		{ name: 'Новости', id: 4 },
	]

	const changeFiltersName = (value) => {
		const switchObj = {
			События: dispatch(setEventFilters({ name: value })),
			Товары: dispatch(setProductsFilters({ name: value })),
			Офисы: dispatch(setOfficesFilters({ name: value })),
			Новости: dispatch(setNewsFilters({ name: value })),
		}

		return switchObj[categoryValue?.name]
	}

	const innerChildrens = (type, item) => {
		const switchObj = {
			0: <TileTitle large text={item.name} />,
			1: <TileTitle large text={item.name} />,
			2: (
				<>
					<TileTitle large text={item.name} />
					<TileSubtitle large white text={item.type === 2 ? 'Оффлайн' : 'Онлайн'} />
				</>
			),
			3: (
				<>
					<PointsCounter white count={item.price} /> {/* цена */}
					<TileSubtitle medium text={item.name} /> {/* название */}
				</>
			),
			4: <TileTitle large text={item.name} />,
		}

		return switchObj[type]
	}
	/* TODO сделать филтрацию новостей */
	const currentOptions = () => {
		const switchObj = {
			События: eventsOptions,
			Товары: productsOptions,
			Офисы: officesOptions,
			Новости: news,
		}

		return switchObj[categoryValue?.name]
	}

	const changeProductValue =
		() =>
		({ target: { value } }) => {
			changeFiltersName(value)
		}

	const color = (index) => {
		return index === 0 ? '#5D83E4' : index === 1 ? '#BB6BD9' : '#34A26D'
	}

	const setItemType = () => {
		const switchObj = {
			События: 2,
			Товары: 3,
			Офисы: 1,
			Новости: 4,
		}

		return switchObj[categoryValue?.name]
	}

	const selectProduct = (item) => {
		const _item = { ...item, type_actual: setItemType() }
		setSelectedItem(_item)
		changeFiltersName(_item.name)
	}

	const currentProductValue = () => {
		const switchObj = {
			События: eventName,
			Товары: productName,
			Офисы: officeName,
			Новости: newsName,
		}

		return switchObj[categoryValue?.name]
	}

	const resetFilters = () => {
		dispatch(setEventFilters({ name: '' }))
		dispatch(setProductsFilters({ name: '' }))
		dispatch(setOfficesFilters({ name: '' }))
		dispatch(setNewsFilters({ name: '' }))
	}

	const acceptSelectedItem = () => {
		const _itemArray = [...actualArray]

		const _selectedItem = {
			...selectedItem,
			label: labelText,
			back_color: bgColor,
			is_hide_title: isHide,
		}

		_itemArray[index] = _selectedItem

		setSelectedItem({})
		resetFilters()
		setLabelText('')
		setBgColor('')

		dispatch(setActual({ actual: _itemArray }))

		toggleModal(false)
	}

	const handleModal = (value, index) => () => {
		setIndex(index)
		toggleModal(value)
		if (value) {
			const targetCard = options.find((item) => item.id === actualArray[index]?.type_actual)
			const selectProduct = (source) => {
				const item = source.find((item) => item.id === actualArray[index]?.id)
				const _item = { ...item, type_actual: targetCard.id }
				setSelectedItem(_item)
				changeFiltersName(_item.name)
			}

			setCategoryValue(targetCard)
			setLabelText(actualArray[index]?.label)
			setBgColor(actualArray[index]?.back_color)
			setIsHide(actualArray[index]?.is_hide_title)

			if (targetCard.id === 1) {
				selectProduct(officesOptions)
			}
			if (targetCard.id === 2) {
				selectProduct(eventName)
			}
			if (targetCard.id === 3) {
				selectProduct(productsOptions)
			}
			if (targetCard.id === 4) {
				selectProduct(news)
			}
		} else {
			setTimeout(() => {
				selectProduct([])
				setCategoryValue({})
				setLabelText('')
				setBgColor('')
				setIsHide(false)
			}, 500)
		}
	}

	useEffect(() => {
		dispatch(requestEvents())
	}, [dispatch, eventName])

	useEffect(() => {
		dispatch(requestOffices())
	}, [dispatch, officeName])

	useEffect(() => {
		dispatch(requestNews())
	}, [dispatch, newsName])

	useEffect(() => {
		dispatch(requestProducts())
	}, [dispatch, productName])

	return (
		<div className="actual-info-block main-block">
			<div className="main-block__header">
				<h3>
					Актуальное <span className="main-block__header-positions">(3 позиции)</span>
				</h3>
			</div>
			<Tiles>
				{!!actualArray.length &&
					actualArray.map((item, index) =>
						item ? (
							<Tile
								label={item.label}
								image={`${url}/static/${item.main_image}`}
								color={color(index)}
								style={{
									padding: '18px 20px',
									backgroundColor: item.back_color,
								}}
								hideTitle={item.is_hide_title}
								classes={`tile-${index} ${item.type_actual === 3 ? 'change-image' : ''}`}
								toggleModal={handleModal(true, index)}
								key={`tile-${index}`}
							>
								{innerChildrens(item.type_actual, item)}
							</Tile>
						) : (
							<EmptyItem
								toggleModal={handleModal(true, index)}
								key={index}
								index={index}
								classes={`tile-${index}`}
							/>
						)
					)}
			</Tiles>

			<ModalContainer show={showModal} onClose={handleModal(false, null)}>
				<SelectCategory
					title="Актуальное"
					onClose={handleModal(false, null)}
					inputLabel={`Выберите ${categoryValue?.name ? categoryValue?.name : 'категорию'}`}
					type="Актуальное"
					categoryValue={categoryValue?.name}
					productValue={currentProductValue()}
					selectCategory={setCategoryValue}
					selectProduct={selectProduct}
					changeProductValue={changeProductValue()}
					categoryOptions={options}
					productOptions={currentOptions()}
					onSubmit={acceptSelectedItem}
					labelValue={labelText}
					bgValue={bgColor}
					isHide={isHide}
					changeLabelValue={({ target: { value } }) => setLabelText(value)}
					changeBgValue={({ target: { value } }) => setBgColor(value)}
					changeIsHideValue={() => setIsHide(!isHide)}
				/>
			</ModalContainer>
		</div>
	)
})
