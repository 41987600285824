import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { TableBody } from '../../../../components/TableBody/TableBody'
import { TableHeader } from '../../../../components/TableHeader/TableHeader'
import { TableRow } from '../../../../components/TableBody/TableRows/TableRow/TableRow'

import { requestNews, setFilters } from '../../../../store/reducers/news/news'

import {
	getFilters,
	getIsLoading,
	getNews,
	getCount,
} from '../../../../store/reducers/news/news-selectors'

import { changeOffset, refreshTableItems, searchItem } from '../../../../functions/common'

import './NewsTable.scss'

export const NewsTable = React.memo(() => {
	const dispatch = useDispatch()

	const isLoading = useSelector(getIsLoading)
	const news = useSelector(getNews)
	const count = useSelector(getCount)
	const { limit, offset, name } = useSelector(getFilters)

	return (
		<div className="news-table">
			<TableHeader
				title="Список новостей"
				placeholder="Наименование"
				searchValue={name}
				onSearchChange={searchItem(dispatch, setFilters, 'name')}
				onRefresh={refreshTableItems(dispatch, requestNews)}
			/>
			<TableBody
				contentTitlesArray={[
					{ title: '' },
					{ title: 'Дата' },
					{ title: 'Наименование' },
					{ title: 'Описание' },
					{ title: 'Тип' },
				]}
				additionalClasses={['news']}
				offset={offset}
				limit={limit}
				count={count}
				setOffset={changeOffset(dispatch, setFilters)}
				isLoading={isLoading}
			>
				{news.map((news, index) => (
					<Link to={`news/${news.id}`} key={`table-link-${index}`}>
						<div
							className="table-row"
							style={{ animationDelay: `${index * 40}ms` }}
							key={`table-block-${news.id}`}
						>
							<TableRow
								row={{
									image: news.prev_image,
									time_create: news.time_create,
									name: news.name,
									news_descr: news.description,
									news_type: news.type,
								}}
								status={news.status}
								key={`table-row-${index}`}
							/>
						</div>
					</Link>
				))}
			</TableBody>
		</div>
	)
})
