import React from 'react'

import { BlockHeaderButtons } from './BlockHeaderButtons/BlockHeaderButtons'
import { BlockHeaderTitle } from './BlockHeaderTitle/BlockHeaderTitle'

import './InfoPageItemBlockHeader.scss'

export const InfoPageItemBlockHeader = React.memo(
	({ index, title, moveBack, moveForw, deleteBlock, length, type }) => {
		return (
			<div className="info-page-block-header">
				<BlockHeaderTitle title={title} type={type} />
				<BlockHeaderButtons
					index={index}
					moveBack={moveBack}
					moveForw={moveForw}
					deleteBlock={deleteBlock}
					length={length}
				/>
			</div>
		)
	}
)
