import { createSlice } from '@reduxjs/toolkit'

import usersApi from '../../../api/users-api'

import { onError } from '../functions'

const initialState = {
	isLoading: false,
	data: {},
	messagesList: [],
	isMessagesLoading: false,
}

const user = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
		setIsMessagesLoading: (state, action) => {
			state.isMessagesLoading = action.payload.isMessagesLoading
		},
		setData: (state, action) => {
			state.data = action.payload.data
		},
		setMessages: (state, action) => {
			state.messagesList = action.payload.messagesList
		},
	},
})

const { reducer, actions } = user

export const { setIsLoading, setData, setMessages, setIsMessagesLoading } = actions

export const getUserInfo = (id) => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await usersApi.getInfo(id)

	if (res.success) {
		dispatch(setData({ data: res.result }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

export const getMessages = (id) => async (dispatch) => {
	dispatch(setIsMessagesLoading({ isMessagesLoading: true }))

	const res = await usersApi.getComments(id)

	if (res.success) {
		dispatch(setMessages({ messagesList: res.result }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsMessagesLoading({ isMessagesLoading: false }))
}

export const addComment = (opt) => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await usersApi.addComment(opt)

	if (res.success) {
		dispatch(getMessages(opt.user_id))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

export const deleteComment = (msg_id, user_id) => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await usersApi.deleteComment(msg_id)

	if (res.success) {
		dispatch(getMessages(user_id))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

export default reducer
