import React from 'react'

import { PartnerInfo } from './PartnerInfo/PartnerInfo'
import { UserInfo } from './UserInfo/UserInfo'

import './UserItemInfo.scss'

export const UserItemInfo = React.memo(
	({ id, image, name, recruterImage, recruterName, setViewer }) => (
		<div className="user-item__user-info">
			<UserInfo id={id} image={image} name={name} setViewer={setViewer} />
			<PartnerInfo
				id={id}
				recruterImage={recruterImage}
				recruterName={recruterName}
			/>
		</div>
	)
)
