import { getAdminToken } from '../functions/localStorage'
import instance from './api'

const productsApi = {
	async requestProducts(filters) { //список товаров
		const response = await instance.post(
			'/admin/search/product',
			{...filters},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async createProduct(data) { //создание продукта
		const response = await instance.post(
			'/admin/create/product',
			{...data},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async updateProduct(data) { //редактирование продукта
		const response = await instance.post(
			'/admin/update/product',
			{...data},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async approveProduct(id) { // публикация продукта
		const response = await instance.post(
			`/admin/set/approve/product?product_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async rejectProduct(id) { // удалить продукт
		const response = await instance.post(
			`/admin/set/reject/product?product_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async requestProduct(id) { // страница продукта
		const response = await instance.post(
			`/product/get?product_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	}
}

export default productsApi
