import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { EventPage } from './EventPage/EventPage'
import { EventsList } from './EventsList/EventsList'

import {
	requestEvent,
	setEvent,
	setFilters,
	setEventsList,
} from '../../store/reducers/events/events'

import { setCount } from '../../store/reducers/applications/applications'
import { getSelectedEvent } from '../../store/reducers/events/events-selectors'

import './EventsPage.scss'

export const EventsPage = React.memo(() => {
	const match = useRouteMatch()
	const history = useHistory()
	const id = match.params.id

	const event = useSelector(getSelectedEvent)

	const dispatch = useDispatch()

	const buttonRef = useRef()

	const [create, setCreate] = useState(id === 'create')
	const [showButtons, setShowButtons] = useState(false)

	const changeCreate = (state) => () => {
		if (state) {
			history.push('/events/create')
		} else {
			dispatch(setEvent({ event: null }))
			history.goBack()
		}

		setCreate(state)
	}

	const handleClickOutside = useCallback(({ target }) => {
		if (!buttonRef.current || !buttonRef?.current?.contains(target)) {
			setShowButtons(false)
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		if (id && id !== 'create' && !create) dispatch(requestEvent(id))
	}, [dispatch, id, create])

	useEffect(
		() => () => {
			dispatch(
				setFilters({
					limit: 15,
					offset: 0,
					data_mode: '',
					date_mode: '',
					type: 0,
					status: 0,
					is_proposal: 0,
					name: '',
					city: '',
				})
			)
			dispatch(setEventsList({ eventsList: [] }))
		},
		[dispatch]
	)

	useEffect(
		() => () => {
			dispatch(setCount({ count: 0 }))
		},
		[dispatch, id]
	)

	return (
		<div className="events-main-page">
			{id || create ? (
				<EventPage
					id={id}
					edit={id && !create}
					cancel={changeCreate(false)}
					setCreate={setCreate}
					event={event}
					clickOutside={handleClickOutside}
					ref={buttonRef}
					setShowButtons={setShowButtons}
					showButtons={showButtons}
				/>
			) : (
				<EventsList createNewEvent={changeCreate(true)} />
			)}
		</div>
	)
})
