import React from 'react'

import './CancelButton.scss'

export const CancelButton = React.memo(({ closeIt = () => {} }) => (
	<button
		className="cancel__button"
		onClick={closeIt}
		type="button"
	>
		Отменить
	</button>
))