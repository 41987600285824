import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DragAndDrop } from '../../../../../components/DragAndDrop/DragAndDrop'

import { deleteMedia, uploadImage } from '../../../../../store/reducers/events/events'

import { getEventPhotos } from '../../../../../store/reducers/events/events-selectors'

export const AddPhoto = React.memo(() => {
	const dispatch = useDispatch()

	const photos = useSelector(getEventPhotos)

	const handleUpload = (id, formData) => {
		dispatch(uploadImage(id, formData))
	}

	const handleDelete = (id, imageId) => {
		dispatch(deleteMedia(id, 0, imageId))
	}

	return (
		<DragAndDrop photos={photos} uploadPhotos={handleUpload} deletePhoto={handleDelete} fData />
	)
})
