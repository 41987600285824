import React from 'react'
import { Page, Text, View, Image } from '@react-pdf/renderer'

import { getLangName, phoneSpaces, createDate } from '../../../../functions/common'

import { url } from '../../../../api/api'

import mainLogo from '../../../../assets/media/img/pdf-resume/main-logo.png'

export const UserPage = ({ styles, user }) => {
	const {
		user: {
			first_page: {
				name,
				surname,
				email,
				country,
				city,
				birthday,
				exp_it,
				langs,
				social,
				about,
				image,
			},
			tel,
		},
		local,
	} = user

	const avatar = `${url}/static/${image}`

	const {
		page,
		pageHeader,
		section,
		avatar: avatarStyle,
		mainText,
		mainLogo: mainLogoStyle,
		pageWrapper,
		title,
		row,
		rowTitle,
	} = styles

	const optionsDate = {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
	}

	return (
		<Page size="A4" style={page}>
			<View style={pageHeader}>
				<View style={section}>
					<Image style={avatarStyle} source={avatar} />
					<Text style={mainText}>
						{surname} {name}
					</Text>
				</View>
				<Image style={mainLogoStyle} source={mainLogo} />
			</View>
			<View style={pageWrapper}>
				{/* Личные данные */}
				<View style={section}>
					<Text style={title}>Личные данные</Text>
					<View style={row}>
						<Text style={rowTitle}>Телефон</Text>
						<Text>{phoneSpaces(`${tel}`) ? phoneSpaces(`${tel}`) : 'не указано'}</Text>
					</View>
					<View style={row}>
						<Text style={rowTitle}>Email</Text>
						<Text>{email ? email : 'не указано'}</Text>
					</View>
					<View style={row}>
						<Text style={rowTitle}>Страна</Text>
						<Text>{local.countries[country] ? local.countries[country] : 'не указано'}</Text>
					</View>
					<View style={row}>
						<Text style={rowTitle}>Город</Text>
						<Text>{local.cities[city] ? local.cities[city] : 'не указано'}</Text>
					</View>
					<View style={row}>
						<Text style={rowTitle}>Дата рождения</Text>
						<Text>
							{createDate(birthday, 'ru', optionsDate)
								? createDate(birthday, 'ru', optionsDate)
								: 'не указано'}
						</Text>
					</View>
				</View>

				{/* Опыт работы */}
				<View style={section}>
					<Text style={title}>Опыт работы</Text>
					<View style={row}>
						<Text style={rowTitle}>Опыт работы в IT</Text>
						<Text>{exp_it ? exp_it : 'не указано'}</Text>
					</View>
				</View>

				{/* языки */}
				<View style={section}>
					<Text style={title}>Иностранные языки</Text>
					{langs && !!langs.length ? (
						langs.map((item) => (
							<View key={item.id} style={row}>
								<Text style={rowTitle}>{local.language[item.id]}</Text>
								<Text>{getLangName(item.skill)}</Text>
							</View>
						))
					) : (
						<Text>Языки отсутствуют</Text>
					)}
				</View>

				{/* Соцсети */}
				<View style={section}>
					<Text style={title}>Соцсети</Text>
					{!!social.length ? (
						social.map((item, index) => (
							<View key={index}>
								<Text>{item.link}</Text>
							</View>
						))
					) : (
						<Text>Соцсети отсутствуют</Text>
					)}
				</View>

				{/* Обо мне */}
				<View style={section}>
					<Text style={title}>Обо мне</Text>
					{about !== '' ? (
						<View>
							<Text>{about}</Text>
						</View>
					) : (
						<Text>Описание не указано</Text>
					)}
				</View>
			</View>
		</Page>
	)
}
