import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import cn from 'classnames'

import { TableBody } from '../../../../components/TableBody/TableBody'
import { TableRow } from '../../../../components/TableBody/TableRows/TableRow/TableRow'
import { TableHeader } from '../../../../components/TableHeader/TableHeader'
import { requestProducts, setProductsFilters } from '../../../../store/reducers/products/products'

import { changeOffset, refreshTableItems, searchItem } from '../../../../functions/common'

import './ProductsTable.scss'

export const ProductsTable = React.memo(
	({ productsList, limit, offset, count, name, isLoading }) => {
		const dispatch = useDispatch()

		return (
			<div className="products-table">
				<TableHeader
					title={'Список товаров и услуг'}
					placeholder="Наименование"
					searchValue={name}
					onSearchChange={searchItem(dispatch, setProductsFilters, 'name')}
					onRefresh={refreshTableItems(dispatch, requestProducts)}
				/>
				<TableBody
					contentTitlesArray={[
						{ title: 'Наименование' },
						{ title: 'Описание' },
						{ title: 'Местоположение' },
						{ title: 'Вид' },
						{ title: 'Квота' },
						{ title: 'Остаток' },
						{ title: 'Стоимость' },
						{ title: 'Заявки' },
					]}
					additionalClasses={['products-table']}
					limit={limit}
					count={count}
					offset={offset}
					setOffset={changeOffset(dispatch, setProductsFilters)}
					isLoading={isLoading}
				>
					{productsList.map((row, index) => (
						<Link to={`products/${row.id}`} key={`table-link-${index}`}>
							<div
								className={cn('table-row', {
									before: row.date_mode === 'before',
									delete: row.status === 3,
								})}
								style={{ animationDelay: `${index * 40}ms` }}
							>
								<TableRow
									row={{
										image: row.image,
										name: row.name,
										prod_descr: row.prod_descr,
										loc_addr: row.city ? row.city : row.country,
										prod_type: row.prod_type,
										all_count: row.all_count,
										balance: row.balance,
										price: row.price,
										count_props: row.count_props,
									}}
									textTooltip={row.status === 1 ? 'Неопубликованный продукт' : 'Удалено'}
									status={row.status}
									deleteClass={row.status === 3}
								/>
							</div>
						</Link>
					))}
				</TableBody>
			</div>
		)
	}
)
