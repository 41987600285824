import React from 'react'
import cn from 'classnames'

import './Icon.scss'

export const Icon = ({
  name = '',
  size = 24,
  contain
}) => {
  return (
    <div
      className="icon-container"
      style={{ height: size, width: size }}
    >
      <i className={cn( 'icon', name, { contain } )}/>
    </div>
  )
}