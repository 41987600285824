import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Formik, Form } from 'formik'

import { Button } from '../../../components/Buttons/Button/Button'
import { Input } from '../../../components/Inputs/Input/Input'
import { TextAreaField } from '../../../components/TextAreaField/TextAreaField'
import { ModalSearch } from '../../../components/ModalContainer/ModalSearch/ModalSearch'

import {
	createTransaction,
	searchForTransaction,
	setSearchResult,
} from '../../../store/reducers/transactions/transactions'

import { getSearchResult } from '../../../store/reducers/transactions/transactions-selectors'

import './PointsModal.scss'

export const PointsModal = React.memo(({ close }) => {
	const dispatch = useDispatch()

	const [searchValue, setSearchValue] = useState('')
	const [selectedValue, setSelectedValue] = useState('')
	const [selected, setSelected] = useState(null)

	const searchResult = useSelector(getSearchResult)

	const handleSelect = (item) => {
		setSelectedValue(item.name)
		setSelected(item)
		dispatch(setSearchResult({ result: [] }))
	}

	const searchRequest = useCallback(
		(value) => dispatch(searchForTransaction(value)),
		[dispatch]
	)

	return (
		<Formik
			initialValues={{ points: '', msg: '' }}
			onSubmit={(values, { setValues }) => {
				const { points, msg } = values
				const { id, is_user } = selected

				dispatch(createTransaction({ to_id: id, is_user, value: points, msg }))

				setSelected(null)
				setSearchValue('')
				setSelectedValue('')
				setValues({ points: '', msg: '' })

				close()
			}}
		>
			{({ values }) => {
				const { points, msg } = values

				return (
					<div className="points-modal">
						<div className="points-modal__title">
							<span>Изменить баллы</span>
						</div>
						<Form>
							<ModalSearch
								inputPlaceholder="Пользователь/Группа"
								handleSelect={handleSelect}
								searchValue={searchValue}
								setSearchValue={setSearchValue}
								setSelectedValue={setSelectedValue}
								selectedValue={selectedValue}
								searchResult={searchResult}
								setResult={setSearchResult}
								searchRequest={searchRequest}
							/>
							<Field
								name="points"
								type="number"
								label={'Баллы(+/-)'}
								as={Input}
							/>
							<Field name="msg" label={'Текст сообщения'} as={TextAreaField} />
							<div className="points-modal__buttons">
								<Button label={'Отменить'} type="md1" onClick={close} white />
								<Button
									nType="submit"
									label={'Подтвердить'}
									type="md2"
									disabled={!points || !msg || !selected}
								/>
							</div>
						</Form>
					</div>
				)
			}}
		</Formik>
	)
})
