import React from 'react'
import cn from 'classnames'

import { BotIcon } from '../BotIcon/BotIcon'

import './BotIntegrations.scss'

export const BotIntegrations = React.memo(({ horizontal = false, bots = [] }) => (
	<div className={cn('bot-integration', { horizontal })}>
		{!horizontal && <h3>Интеграции</h3>}
		{bots.map((bot) => (
			<BotIcon
				icon={bot.icon}
				title={!horizontal && bot.title}
				link={bot.link}
				key={`bot-${bot.title}`}
			/>
		))}
	</div>
))
