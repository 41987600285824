import React from 'react'

import { NavLinkItem } from './NavLinkItem/NavLinkItem'

export const NavLinks = React.memo(() => {
	const linksArray = [
		{ text: 'Пользователи', value: '/users' },
		{ text: 'События', value: '/events' },
		{ text: 'Товары', value: '/products' },
		{ text: 'Офисы', value: '/offices' },
		{ text: 'Группы', value: '/groups' },
		{ text: 'Квоты', value: '/quotas' },
		{ text: 'Новости', value: '/news' },
		{ text: 'Транзакции', value: '/transactions' },
		{ text: 'Наполнение', value: '/content' },
		{ text: 'Партнерство', value: '/partners' },
		{ text: 'База знаний', value: '/knowledge-base' },
		{ text: 'Комментарии', value: '/comments' },
		{ text: 'Аналитика', value: '/analitics' },
	]

	return (
		<nav className="nav-block">
			{linksArray.map((link, index) => (
				<NavLinkItem text={link.text} linkValue={link.value} key={`links-${index}`} />
			))}
		</nav>
	)
})
