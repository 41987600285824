import { getAdminToken } from '../functions/localStorage'
import instance from './api'

const getTypeString = (type) => {
	switch (type) {
		case 1:
			return 'office'

		case 2:
			return 'product'

		case 3:
		default:
			return 'group'
	}
}

const quotasApi = {
	async requestQuotas(filters) {
		const response = await instance.post(`admin/search/quota`, filters, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async requestQuotasById({ limit, offset, id, type }) {
		const typeString = getTypeString(type)

		const response = await instance.post(
			`admin/get/quota/${typeString}?${typeString}_id=${id}&limit=${limit}&offset=${offset}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async createQuota(data) {
		const response = await instance.post(`admin/create/quota`, data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async updateQuota(data) {
		const response = await instance.post(`admin/update/quota`, data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async deleteQuota(id) {
		const response = await instance.post(
			`admin/delete/quota?quota_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},
}

export default quotasApi
