import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'

import { Notifications } from './Notifications/Notifications'
import { Avatar } from '../Avatar/Avatar'
import { NavLinks } from './NavLinks/NavLinks'

import { getPhoto, getId } from '../../store/reducers/profile/profile-selectors'

import { getIsAuth } from '../../store/reducers/auth/auth-selectors'

import './Header.scss'

export const Header = () => {
	const location = useLocation()
	const empty = !useSelector(getIsAuth)
	const photo = useSelector(getPhoto)
	const id = useSelector(getId)
	const isAuth = useSelector(getIsAuth)

	const burgerRef = useRef()

	const [showMobileMenu, setShowMobileMenu] = useState(false)

	const handleClickOutside = useCallback(({ target }) => {
		if (!burgerRef.current.contains(target)) setShowMobileMenu(false)
	}, [])

	useEffect(() => {
		if (showMobileMenu) {
			document.addEventListener('click', handleClickOutside)
		} else {
			document.removeEventListener('click', handleClickOutside)
		}
	}, [handleClickOutside, showMobileMenu])

	useEffect(() => {
		setShowMobileMenu(false)
	}, [location])

	return (
		<header className={cn('app-header', { hide: !isAuth })}>
			<>
				<div className="mobile-menu-wrapper" onClick={() => setShowMobileMenu(!showMobileMenu)}>
					<i></i>
				</div>
				<div className="app-header__logo">
					<Link to="/">
						<div className="x-icon"></div>
						<div className="x-name admin "></div>
					</Link>
				</div>
				{!empty && (
					<div className="info-container">
						<Notifications />
						<Avatar inHeader image={photo} id={id} />
					</div>
				)}

				<div className={cn('burger-menu', { show: showMobileMenu })} ref={burgerRef}>
					<NavLinks />
				</div>
			</>
		</header>
	)
}
