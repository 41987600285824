import React, { useRef, useEffect } from 'react'
import cn from 'classnames'

import './Tooltip.scss'

export const Tooltip = React.memo(
	({ show, right = false, left = !right, children }) => {
		const tooltip = useRef()
		const angle = useRef()
		const reverseAngle = useRef()

		useEffect(() => {
			const parent = tooltip.current.parentNode
			// координаты родителя
			const coords = parent.getBoundingClientRect()

			const _tooltip = tooltip.current
			const _angle = angle.current
			const _reverseAngle = reverseAngle.current

			// половина ширины родителя
			const parentHalfWidth = parent.offsetWidth / 2

			const tooltipWidth = _tooltip.offsetWidth
			// четверть ширины подсказки для рассчета положения подсказки и уголка при левостороннем расположении
			const leftTooltipOffset = tooltipWidth * 0.25
			// три четверти ширины подсказки для рассчета положения подсказки и уголка при правостороннем расположении
			const rightTooltipOffset = tooltipWidth * 0.75

			// рассчет расположения подсказки и уголка по горизонтали
			if (left) {
				_tooltip.style.left = `${
					coords.left + parentHalfWidth - leftTooltipOffset
				}px`

				_angle.style.left = `${leftTooltipOffset - 10}px`
				_reverseAngle.style.left = _angle.style.left
			} else if (right) {
				_tooltip.style.left = `${
					coords.left + parentHalfWidth - rightTooltipOffset
				}px`

				_angle.style.left = `${rightTooltipOffset - 10}px`
				_reverseAngle.style.left = _angle.style.left
			}

			// рассчет расположения подсказки по вертикали
			if (
				coords.top + _tooltip.offsetHeight + 40 >
				document.documentElement.clientHeight
			) {
				_tooltip.style.top = `${coords.top - _tooltip.offsetHeight - 5}px`
				_angle.style.display = 'none'
				_reverseAngle.style.display = 'block'
			} else {
				_tooltip.style.top = `${coords.bottom + 5}px`
				_angle.style.display = 'block'
				_reverseAngle.style.display = 'none'
			}
		})

		return (
			<div ref={tooltip} className={cn('tooltip', { show })}>
				<span ref={angle} className="tooltip__angle">
					▲
				</span>
				<div
					ref={reverseAngle}
					className="tooltip__angle tooltip__reverse-angle"
				>
					▼
				</div>
				{children}
			</div>
		)
	}
)
