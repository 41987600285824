import React from 'react'
import ru from 'date-fns/locale/ru'
import DatePicker from 'react-datepicker'

import { Input } from '../Inputs/Input/Input'

import 'react-datepicker/dist/react-datepicker.css'

import './DatePeriod.scss'

export const DatePeriod = React.memo(
	({ dateStart, setDateStart, dateEnd, setDateEnd, users, handler = () => {} }) => {
		const dateFilterStart = (date) => {
			const today = new Date()
			const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
			const dateEndNew = new Date(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate())

			if (
				newDate <= dateEndNew &&
				newDate >= new Date(today.getFullYear(), today.getMonth(), today.getDate())
			)
				return date
		}

		const dateFilterEnd = (date) => {
			const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate())
			const dateStartNew = new Date(
				dateStart.getFullYear(),
				dateStart.getMonth(),
				dateStart.getDate()
			)

			return newDate >= dateStartNew
		}

		return (
			<div className="date-period__block">
				<div className="date-picker-block">
					<DatePicker
						dateFormat="dd.MM.yyyy"
						selected={dateStart}
						onChange={(date) => setDateStart(date)}
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						filterDate={!users && dateFilterStart}
						locale={ru}
						onSelect={(date) => handler(date, 'start')}
						customInput={<Input label="Начало" />}
						popperPlacement="top-left"
					/>
				</div>
				<div className="date-picker-block">
					<DatePicker
						dateFormat="dd.MM.yyyy"
						selected={dateEnd}
						onChange={(date) => setDateEnd(date)}
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						locale={ru}
						onSelect={(date) => handler(date, 'end')}
						filterDate={!users && dateFilterEnd}
						customInput={<Input label="Конец" />}
						popperPlacement="top-left"
					/>
				</div>
			</div>
		)
	}
)
