import React from 'react'
import { Icon } from '../../../../components/Icon/Icon'

import './EmptyItem.scss'

export const EmptyItem = React.memo(({ toggleModal, deleteItem, check, classes = '' }) => (
	<div className={`empty-element-block ${classes}`}>
		<div className="empty-element-block__container" onClick={toggleModal}>
			<Icon name="plus" size={24} />
			<span>Добавить позицию</span>
		</div>
		{check && (
			<div className="delete-button" onClick={deleteItem}>
				<Icon name="close" />
			</div>
		)}
	</div>
))
