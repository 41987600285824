import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Filters } from '../../../components/Filters/Filters'

import { getFilters } from '../../../store/reducers/transactions/transactions-selectors'

import { setFilters } from '../../../store/reducers/transactions/transactions'
import { FiltersHeader } from '../../../components/FiltersHeader/FiltersHeader'

export const TransactionsFilters = React.memo(() => {
	const dispatch = useDispatch()

	const { type } = useSelector(getFilters)

	const handleTypeFilter = (value) => () => {
		dispatch(setFilters({ type: value, offset: 0 }))
	}

	const reset = () => {
		dispatch(setFilters({ type: 0, offset: 0 }))
	}

	const FILTER = [
		{
			title: 'Тип',
			items: [
				{ title: 'Все', handler: handleTypeFilter(0), active: type === 0 },
				{
					title: 'Начисление',
					handler: handleTypeFilter(1),
					active: type === 1,
				},
				{ title: 'Списание', handler: handleTypeFilter(2), active: type === 2 },
			],
		},
	]

	return (
		<div className="transactions-filters">
			<FiltersHeader reset={reset} />
			<Filters filter={FILTER} />
		</div>
	)
})
