import React, { useState } from 'react'

import { QuestionnaireTabsItem as Tab } from './QuestionnaireTabsItem/QuestionnaireTabsItem'
import { DataTabContent } from './DataTabContent/DataTabContent'
import { EmploymentTabContent } from './EmploymentTabContent/EmploymentTabContent'
import { SkillsTabContent } from './SkillsTabContent/SkillsTabContent'

import './QuestionnaireTabs.scss'

export const QuestionnaireTabs = React.memo(() => {
	const [dataTab, setDataTab] = useState(true)
	const [employmentTab, setEmploymentTab] = useState(false)
	const [skillsTab, setSkillsTab] = useState(false)

	const handleTabClick = (setActiveTab) => () => {
		setDataTab(false)
		setEmploymentTab(false)
		setSkillsTab(false)
		setActiveTab(true)
	}

	return (
		<div className="questionnaire-tabs">
			<div className="questionnaire-tabs__header">
				<Tab
					label="Данные"
					active={dataTab}
					onClick={handleTabClick(setDataTab)}
				/>
				<Tab
					label="Занятость"
					active={employmentTab}
					onClick={handleTabClick(setEmploymentTab)}
				/>
				<Tab
					label="Навыки"
					active={skillsTab}
					onClick={handleTabClick(setSkillsTab)}
				/>
			</div>
			<div className="questionnaire-tabs__content">
				{dataTab && <DataTabContent />}
				{employmentTab && <EmploymentTabContent />}
				{skillsTab && <SkillsTabContent />}
			</div>
		</div>
	)
})
