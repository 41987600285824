import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TableHeader } from '../../../components/TableHeader/TableHeader'
import { TableBody } from '../../../components/TableBody/TableBody'
import { TableRows } from '../../../components/TableBody/TableRows/TableRows'
import { TableRow } from '../../../components/TableBody/TableRows/TableRow/TableRow'

import {
	getCount,
	getFilters,
	getIsLoading,
	getSkills,
} from '../../../store/reducers/partners/partners-selectors'

import { deleteSkill, requestSkills, setFilters } from '../../../store/reducers/partners/partners'

import {
	getSkillName,
	refreshTableItems,
	searchItem,
	uppercaseFirstChar,
} from '../../../functions/common'

import './PartnersTable.scss'

export const PartnersTable = React.memo(({ handleModal }) => {
	const dispatch = useDispatch()

	const skills = useSelector(getSkills)
	const count = useSelector(getCount)
	const filters = useSelector(getFilters)
	const isLoading = useSelector(getIsLoading)
	const { pat, limit, offset } = filters

	const handleRowClick =
		(id) =>
		({ target }) => {
			if (target.closest('.delete-button')) return

			handleModal(true, id)()
		}

	return (
		<div className="partners-table">
			<TableHeader
				title="Коэффициенты"
				placeholder="Поиск"
				searchValue={pat}
				onSearchChange={searchItem(dispatch, setFilters, 'pat')}
				onRefresh={refreshTableItems(dispatch, requestSkills)}
			/>
			<TableBody
				contentTitlesArray={[
					{ title: 'Специализация/Технология' },
					{ title: 'Уровень владения' },
					{ title: 'Коэффициент партнера' },
					{ title: 'Стоимость просмотра' },
				]}
				limit={limit}
				count={count}
				offset={offset}
				isLoading={isLoading}
			>
				<TableRows>
					{skills.map((item) => (
						<div key={item.id} className="table-row" onClick={handleRowClick(item.id)}>
							<TableRow
								row={{
									name: item.title,
									skill_value: uppercaseFirstChar(getSkillName(item.skill)),
									point_sell: item.point_sell,
									point_buy: item.point_buy,
									delete_button: {
										deleteItem: () => dispatch(deleteSkill(item.id)),
									},
								}}
							/>
						</div>
					))}
				</TableRows>
			</TableBody>
		</div>
	)
})
