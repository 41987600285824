import React from 'react'
import cn from 'classnames'

import { getSocial } from '../../../../../functions/common'

import './ContentItemSocialLine.scss'

export const ContentItemSocialLine = React.memo(({ value }) => {
	const { link, type } = value

	return (
		<div className="content-item-line content-item-social-line">
			<div className={cn('content-item-social-line__icon', getSocial(link))}>
				<i></i>
			</div>
			<div className="content-item-social-line__value">
				<a href={link} target="_blank" rel="noopener noreferrer">
					{type === 'telegram' && 'Telegram'}
					{type === 'habr' && 'Habr'}
					{type === 'github' && 'GitHub'}
					{type === 'behance' && 'Behance'}
					{type === 'dribble' && 'Dribbble'}
					{type === 'linkedin' && 'LinkedIn'}
					{type === 'facebook' && 'Facebook'}
					{type === 'instagram' && 'Instagram'}
					{type === 'yt' && 'YouTube'}
					{type === 'vk' && 'VK'}
				</a>
			</div>
		</div>
	)
})
