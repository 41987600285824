import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'

import { ModalContainer } from '../../../../../../../../components/ModalContainer/ModalContainer'
import { YesNoModal } from '../../../YesNoModal/YesNoModal'
import { AddButton } from './AddButton/AddButton'
import { DropdownItem } from './DropdownItem/DropdownItem'

import { addUsersToGroup } from '../../../../../../../../store/reducers/groups/groups'

import './GroupsDropdown.scss'

export const GroupsDropdown = React.memo(
	React.forwardRef(
		(
			{
				isGroupListShow,
				setisGroupListShow,
				isLoading,
				notUserGroups,
				name,
				id,
				handleClickOutside,
			},
			ref
		) => {
			const [isPopupShow, setIsPopupShow] = useState(false)
			const [groupObj, setGroupObj] = useState({})

			const dispatch = useDispatch()

			//скрыть выпадающий список с группами
			const handleCloseGroupList = () => {
				setisGroupListShow(false)
			}

			//клик на группу из выпадающего списка
			const handleChooseGroup = (item) => () => {
				setIsPopupShow(true)
				setGroupObj(item)
			}

			//добавление юзера в группу("добавить" в модалке)
			const handleAddUserToGroup = (groupId) => {
				setisGroupListShow(false)
				setIsPopupShow(false)
				setGroupObj({})
				dispatch(addUsersToGroup({ group_id: groupId, users: [id] }))
			}

			// "отмена" в модалке добавления в группу
			const handleClosePopup = () => {
				setisGroupListShow(false)
				setIsPopupShow(false)
				setGroupObj({})
			}

			//отслеживание клика вне выпадающего списка
			useEffect(() => {
				if (isGroupListShow) {
					document.addEventListener('mousedown', handleClickOutside)
				} else {
					document.removeEventListener('mousedown', handleClickOutside)
				}

				return () => {
					document.removeEventListener('mousedown', handleClickOutside)
				}
			}, [isGroupListShow, handleClickOutside])

			return (
				<ul
					ref={ref}
					className={cn('add-groups-list', {
						show: isGroupListShow && !isLoading,
						'without-scroll': notUserGroups.length <= 5,
					})}
				>
					<AddButton handleCloseGroupList={handleCloseGroupList} />
					{!!notUserGroups?.length ? (
						notUserGroups.map((item, index) => (
							<DropdownItem
								key={item.id}
								handleChooseGroup={handleChooseGroup(item)}
								item={item}
							/>
						))
					) : (
						<li className="add-groups-list-item empty">
							<span>Нет подходящих групп</span>
						</li>
					)}
					<ModalContainer show={isPopupShow} onClose={handleClosePopup}>
						<YesNoModal
							name={name}
							groupObj={groupObj}
							onClose={handleClosePopup}
							onClick={handleAddUserToGroup}
						/>
					</ModalContainer>
				</ul>
			)
		}
	)
)
