import React from 'react'
import cn from 'classnames'

import { FilterItem } from './FilterItem/FilterItem'
import { FilterRange } from './FilterRange/FilterRange'
import { CheckBox } from './CheckBox/CheckBox'

import './Filter.scss'

export const Filter = React.memo(
	({ title, items = [], range, checkbox, price, setPrice, maxPrice, style, handler, active }) => (
		<div className={cn('filter', { hide: style })}>
			<div className="filter__title">{title}</div>
			<div className={cn('filter__items', { checkbox: checkbox })}>
				{!!items.length &&
					items.map((item, i) =>
						range ? (
							<FilterRange
								key={`${item.title}-${i}`}
								text={item}
								price={price}
								setPrice={setPrice}
								maxPrice={maxPrice}
							/>
						) : checkbox ? (
							<CheckBox
								active={active}
								key={`${item[0]}-${i}`}
								index={i}
								item={item}
								handler={handler}
							/>
						) : (
							<FilterItem key={`${item.title}-${i}`} {...item} filter={item} />
						)
					)}
			</div>
		</div>
	)
)
