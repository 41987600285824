export const getSkills = ({ partners: { skills } }) => skills

export const getFilters = ({ partners: { filters } }) => filters

export const getCount = ({ partners: { count } }) => count

export const getMainValues = ({ partners: { mainValues } }) => mainValues

export const getSearchResult = ({ partners: { searchResult } }) =>
	searchResult.map(({ key, value }) => ({ key, name: value }))

export const getIsLoading = ({ partners: { isLoading } }) => isLoading

export const getSkillById =
	(id) =>
	({ partners: { skills } }) =>
		skills.find((item) => item.id === id)
