import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'

import { NewsItem } from './NewsItem/NewsItem'
import { NewsList } from './NewsList/NewsList'

import { requestNewById, requestNews, setFilters, setNews } from '../../store/reducers/news/news'

import {
	getFilters,
	getNewData,
	getNewDataRequested,
} from '../../store/reducers/news/news-selectors'

import './NewsPage.scss'

export const NewsPage = React.memo(() => {
	const dispatch = useDispatch()

	const match = useRouteMatch()
	const history = useHistory()

	const { offset, name, type, status } = useSelector(getFilters)
	const newData = useSelector(getNewData)
	const requested = useSelector(getNewDataRequested)

	const id = match.params.id

	const [create, setCreate] = useState(id === 'create')

	const changeCreate = (state) => () => {
		if (state) {
			history.push('/news/create')
		} else {
			history.goBack()
		}
		setCreate(state)
	}

	useEffect(() => {
		if (id && !create && id !== 'create') {
			dispatch(requestNewById(id))
		}
	}, [dispatch, create, id])

	useEffect(() => {
		dispatch(requestNews())
	}, [dispatch, offset, name, type, status])

	useEffect(
		() => () => {
			dispatch(
				setFilters({
					type: 0,
					status: 0,
					limit: 10,
					offset: 0,
					name: '',
				})
			)
			dispatch(setNews({ news: [] }))
		},
		[dispatch]
	)

	return (
		<div className="news-page">
			{id || create ? (
				<NewsItem
					id={id !== 'create' ? id : ''}
					edit={id && !create}
					cancel={changeCreate(false)}
					setCreate={setCreate}
					data={newData}
					requested={requested}
					history={history}
				/>
			) : (
				<NewsList createNewNews={changeCreate(true)} />
			)}
		</div>
	)
})
