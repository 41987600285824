import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'
import ru from 'date-fns/locale/ru'

import { BreadCrumbs } from '../../../components/BreadCrumbs/BreadCrumbs'
import { EditCard } from '../../../components/EditCard/EditCard'
import { Input } from '../../../components/Inputs/Input/Input'
import { Preview } from '../../../components/Preview/Preview'
import { LoadImage } from '../../../components/LoadImage/LoadImage'
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { InfoPageItemBlock } from '../../../components/InfoPage/InfoPageItemBlock/InfoPageItemBlock'
import { InfoPageTypesBtns } from './../../../components/InfoPage/InfoPageTypesBtns/InfoPageTypesBtns'
import { InfoPageAddGallery } from '../../../components/InfoPage/InfoPageAddGallery/InfoPageAddGallery'
import { InfoPageAddBlocks } from '../../../components/InfoPage/InfoPageAddBlocks/InfoPageAddBlocks'
import { Preloader } from '../../../components/Preloader/Preloader'
import { PublishButton } from '../../../components/Buttons/PublishButton/PublishButton'
import { PanelTitle } from '../../../components/Panels/Panel/PanelTitle/PanelTitle'

import { onError } from '../../../store/reducers/functions'

import {
	createNew,
	publishNew,
	unpublishNew,
	deleteNew,
	setNewData,
	setNewDataRequested,
	updateNew,
} from '../../../store/reducers/news/news'

import { url } from '../../../api/api'

import './NewsItem.scss'

export const NewsItem = React.memo(({ id, edit, cancel, setCreate, data, requested, history }) => {
	if (!Object.keys(data).length && edit) {
		if (requested) {
			history.push('/news')
		} else {
			return <Preloader />
		}
	}

	const dispatch = useDispatch()

	const [preview, setPreview] = useState(data?.prev_image ? `${url}/static/${data.prev_image}` : '')

	const [image, setImage] = useState(data?.image || '')
	const [blocks, setBlocks] = useState(data?.blocks || [])
	const [newsName, setNewsName] = useState(data?.name || '')
	const [newsDescription, setNewsDescription] = useState(data?.description || '')
	const [time, setTime] = useState(data?.time_create ? new Date(data.time_create) : new Date())
	const [newType, setNewType] = useState(data?.type || 1)

	const [newGallery, setNewGallery] = useState([])
	const [delFromGallery, setDelFromGallery] = useState([])

	const [errors, setErrors] = useState({})

	const gallery = data?.gallery || []

	const crumbs = [{ link: '/news', text: 'Новости' }, { text: edit ? newsName : 'Создать новость' }]

	const published = data?.status === 2

	const addBlock = (type, content) => {
		setBlocks([...blocks, { type, content }])
	}

	// Сохранение новости
	const handleSave = async () => {
		const formData = new FormData()

		if (!newsName || !newsDescription || !preview) {
			if (!newsName || !newsDescription) {
				onError(dispatch, {
					code: 0,
					msg: 'Не заполнено имя или описание новости',
				})
				setErrors((errors) => ({
					...errors,
					name: !newsName.length,
					description: !newsDescription.length,
				}))
			}

			if (!preview) {
				onError(dispatch, {
					code: 0,
					msg: 'Установите миниатюру',
				})
				setErrors((errors) => ({
					...errors,
					preview: true,
				}))
			}

			return false
		}

		newGallery.forEach((item) => {
			const [name, value] = item
			formData.append(name, value)
		})

		const data = {
			name: newsName,
			prev_image: preview,
			image,
			description: newsDescription,
			time_create: time,
			type: newType,
			blocks,
			gallery,
			deleted: delFromGallery,
		}

		if (edit) {
			data.id = id
			dispatch(updateNew(id, data, formData))
		} else {
			dispatch(createNew(data, formData))
		}

		history.push('/news')
	}

	// Добавление фото в галерею
	const handleAddGalleryPhotos = (photos) => {
		setNewGallery((g) => [...g, ...photos])
	}

	// Удаление фото из галереи
	const handleDeleteGalleryPhotos = (index, file) => {
		if (typeof file === 'string') {
			setDelFromGallery((d) => [...d, index])
		} else {
			setNewGallery((g) => g.filter((item) => item[1].localId !== file.localId))
		}
	}

	// Публикация, снятие с публикации, удаление
	const changeNewStatus = (id, callback) => () => {
		dispatch(callback(id))
		history.push('/news')
	}

	const publish = async () => {
		await handleSave()
		changeNewStatus(id, publishNew)()
	}

	// отключение режима создания новости при размонтировании
	useEffect(
		() => () => {
			if (!edit) {
				setCreate(false)
			}
		},
		[edit, setCreate]
	)

	// Отчистка стейта при размонтировании
	useEffect(
		() => () => {
			dispatch(setNewData({ data: {} }))
			dispatch(setNewDataRequested({ requested: false }))
		},
		[dispatch]
	)

	return (
		<div className="news-item">
			<div className="news-item__header">
				<BreadCrumbs items={crumbs} />
				<div className="button-block">
					<EditCard
						id={id}
						edit={edit && data?.status !== 3}
						deleteIt={'Удалить новость'}
						cancel={'Отменить'}
						save={'Сохранить'}
						savedFunc={handleSave}
						cancelFunc={cancel}
						deleteFunc={changeNewStatus(id, deleteNew)}
					/>
					<PublishButton
						show={edit && data?.status !== 3}
						isPublished={published}
						unpublishFunc={changeNewStatus(id, unpublishNew)}
						publishFunc={publish}
					/>
				</div>
			</div>
			<PageWrapper>
				<Panel>
					<div className="news-item__types-block">
						<PanelTitle text="Общее" />
						<InfoPageTypesBtns news type={newType} setType={setNewType} />
					</div>
					<Preview
						image={preview}
						setImage={setPreview}
						accept={'.jpg, .png, .jpeg, .gif'}
						error={errors.preview}
						aspect={7 / 4}
					/>
					<LoadImage image={image} setImage={setImage} accept={'.jpg, .png, .jpeg, .gif'} />
					<Input
						label={'Название'}
						value={newsName}
						name={'info-name'}
						onChange={({ target: { value } }) => setNewsName(value)}
						className={errors.name && 'error'}
					/>
					<Input
						label={'Краткое описание'}
						value={newsDescription}
						name={'info-description'}
						onChange={({ target: { value } }) => setNewsDescription(value)}
						className={errors.description && 'error'}
					/>
					<DatePicker
						dateFormat="dd.MM.yyyy"
						selected={time}
						onChange={setTime}
						showMonthDropdown
						showYearDropdown
						peekNextMonth
						dropdownMode="select"
						locale={ru}
						customInput={<Input label="Дата" placeholder="" />}
					/>
				</Panel>

				{blocks?.map((item, index) => (
					<Panel key={index}>
						<InfoPageItemBlock
							block={item}
							index={index}
							blocks={blocks}
							setBlocks={setBlocks}
							blocksLength={blocks.length}
						/>
					</Panel>
				))}

				<InfoPageAddBlocks addBlock={addBlock} />

				<InfoPageAddGallery
					gallery={gallery}
					addPhotos={handleAddGalleryPhotos}
					deletePhoto={handleDeleteGalleryPhotos}
				/>
			</PageWrapper>
		</div>
	)
})
