import React from 'react'

import { Button } from '../../../components/Buttons/Button/Button'
import { ColumnsContainer } from '../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { RightColumn } from '../../../components/PageWrapper/ColumnsContainer/RightColumn/RightColumn'
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { KnowledgeBaseTable } from './KnowledgeBaseTable/KnowledgeBaseTable'
import { KnowledgeBaseFilters } from './KnowledgeBaseFilters/KnowledgeBaseFilters'

import './KnowledgeBaseList.scss'

export const KnowledgeBaseList = React.memo(({ createNewArticle }) => (
	<div className="knowledge-base-list">
		<PageWrapper>
			<ColumnsContainer>
				<LeftColumn>
					<KnowledgeBaseTable />
				</LeftColumn>
				<RightColumn>
					<Button label={'Создать статью'} onClick={createNewArticle} />
					<Panel>
						<KnowledgeBaseFilters />
					</Panel>
				</RightColumn>
			</ColumnsContainer>
		</PageWrapper>
	</div>
))
