import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TextAreaField } from '../../../components/TextAreaField/TextAreaField'
import { SwitchInput } from '../../../components/Inputs/SwitchInput/SwitchInput'
import { Input } from '../../../components/Inputs/Input/Input'

import { setMainNews } from '../../../store/reducers/content/content'
import { getMainNews } from '../../../store/reducers/content/content-selector'

import './MainNewsBlock.scss'

export const MainNewsBlock = React.memo(() => {
	const dispatch = useDispatch()

	const { active, text, url } = useSelector(getMainNews)

	const changeSwitchValue = () =>
		dispatch(
			setMainNews({
				active: !active,
			})
		)

	const changeLinkText = ({ target: { value } }) =>
		dispatch(
			setMainNews({
				text: value,
			})
		)

	return (
		<div className="main-news-block">
			<div className="main-news-block__wrapper">
				<div className="main-news-block__header">
					<h3>Главная новость</h3>
					<div className="main-news-block__header-switch">
						<SwitchInput
							textActive="Включена"
							textUnactive="Отключена"
							value={active}
							onChange={changeSwitchValue}
							textStyle={{ color: '#fff', fontSize: '16px', fontWeight: '600' }}
							customColor="#A5BEFF"
							custom
						/>
					</div>
				</div>
				<div className="main-news-block__body">
					<div className="main-news-block__body-input">
						<TextAreaField value={text} onChange={changeLinkText} minRows={2} label="Текст" />
					</div>
					<div className="main-news-block__body-input">
						<Input
							label="+ Добавить ссылку"
							value={url}
							onChange={({ target: { value } }) =>
								dispatch(
									setMainNews({
										url: value,
									})
								)
							}
						/>
					</div>
				</div>
			</div>
		</div>
	)
})
