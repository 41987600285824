import { getAdminToken } from '../functions/localStorage'
import instance from './api'

const getTypeString = (type) => {
	switch (type) {
		case 1:
			return 'user'

		case 2:
		default:
			return 'product'
	}
}

const certificateApi = {
	async requestCertificates({ limit, offset }) {
		const response = await instance.post(
			`admin/get/certs?limit=${limit}&offset=${offset}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async requestCertificatesById({ limit, offset, id, type }) {
		const typeString = getTypeString(type)

		const response = await instance.post(
			`admin/get/certs/${typeString}?${typeString}_id=${id}&limit=${limit}&offset=${offset}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async approveCertificate(id) {
		const response = await instance.post(
			`admin/approve/cert?cert_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async rejectCertificate(id, message) {
		const response = await instance.post(
			`admin/set/reject/cert?cert_id=${id}&msg=${message} `,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},
}

export default certificateApi
