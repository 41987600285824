import React from 'react'
import cn from 'classnames'

import DatePicker, { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/es'

import { Input } from '../../../../../components/Inputs/Input/Input'
import { TextAreaField } from '../../../../../components/TextAreaField/TextAreaField'

import './ProgrammBlock.scss'

export const ProgrammBlock = React.memo(
	({
		timeStart,
		timeEnd,
		name,
		description,
		lectorName,
		deleteProgramm,
		setProgrammsObject,
		fullProgramm,
		programmDay,
		fullDayProgramm,
		fullDayProgrammIndex,
		errors,
		index,
	}) => {
		registerLocale('ru', ru)

		const setProggramInformation = (information, value) => {
			const newProgrammArray = [...fullDayProgramm]

			const newProgrammObj = {
				...fullDayProgramm[fullDayProgrammIndex],
				[information]: value,
			}

			newProgrammArray.splice(fullDayProgrammIndex, 1, newProgrammObj)

			setProgrammsObject({ ...fullProgramm, [programmDay]: newProgrammArray })
		}

		return (
			<div className={cn('programm-block', { errors })}>
				<div className="programm-block__inputs-block">
					<div className="time-block">
						<DatePicker
							selected={new Date(timeStart)}
							onChange={(value) => setProggramInformation('time_start', value)}
							showTimeSelect
							showTimeSelectOnly
							timeIntervals={15}
							locale="ru"
							timeCaption="Time"
							timeFormat="p"
							dateFormat="p"
							customInput={<Input label="Начало" placeholder="" />}
						/>
						<DatePicker
							selected={new Date(timeEnd)}
							onChange={(value) => setProggramInformation('time_end', value)}
							showTimeSelect
							showTimeSelectOnly
							timeIntervals={15}
							locale="ru"
							timeCaption="Time"
							dateFormat="p"
							timeFormat="p"
							customInput={<Input label="Конец" placeholder="" />}
						/>
						{index !== 0 && <button className="delete-button" onClick={deleteProgramm} />}
					</div>
					<Input
						label={'Название'}
						value={name}
						onChange={({ target: { value } }) => setProggramInformation('name', value)}
					/>

					<TextAreaField
						label={'Описание'}
						value={description}
						onChange={({ target: { value } }) => setProggramInformation('description', value)}
					/>
					<Input
						label={'Лектор'}
						value={lectorName}
						onChange={({ target: { value } }) => setProggramInformation('lecturer', value)}
					/>
				</div>
			</div>
		)
	}
)
