import React from 'react'

import './PageWrapper.scss'

// Принимает объект style для настройки отступов
export const PageWrapper = React.memo(({ style, children }) => (
	<div className="page-wrapper" style={style}>
		{children}
	</div>
))
