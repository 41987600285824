import React from 'react'
import cn from 'classnames'

import './ContentItemSkillLine.scss'

export const ContentItemSkillLine = React.memo(
	({ label, value }) => {
		
		const checkUserSkillLevel = (level) => level === 1 ? 'Junior' : level === 2 ? 'Middle' : 'Senior'

		return (
			<div className="content-item-line content-item-skill-line">
				<div className="content-item-skill-line__label">{label}</div>
				<div
					className={cn('content-item-skill-line__value', checkUserSkillLevel(value))}
				>
					{checkUserSkillLevel(value)}
				</div>
			</div>
		)
	}
)
