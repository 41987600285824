import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { TableBody } from '../../../components/TableBody/TableBody'
import { ModalContainer } from '../../../components/ModalContainer/ModalContainer'
import { QuestionnaireContent } from '../../../components/QuestionnaireContent/QuestionnaireContent'
import { TableCertificatesRow } from '../../../components/TableBody/TableRows/TableCertificatesRow/TableCertificatesRow'
import { RejectCertModal } from './RejectCertModal/RejectCertModal'

import {
	getCertificatesCount,
	getCertificatesFilters,
	getCertificatesList,
	getCertificatesType,
	getIsLoading,
} from '../../../store/reducers/certificates/certificates-selectors'
import { getUserData } from '../../../store/reducers/user/user-selectors'

import {
	approveCertificate,
	rejectCertificate,
	requestCertificatesById,
	setCertificates,
	setCount,
	setFilters,
} from '../../../store/reducers/certificates/certificates'
import { getUserInfo, setData } from '../../../store/reducers/user/user'

import { changeOffset } from '../../../functions/common'

export const Certificates = React.memo(({ userPage }) => {
	const match = useRouteMatch()
	const id = match.params.id

	const dispatch = useDispatch()

	const certList = useSelector(getCertificatesList)
	const count = useSelector(getCertificatesCount)
	const type = useSelector(getCertificatesType)
	const { limit, offset } = useSelector(getCertificatesFilters)
	const user = useSelector(getUserData)
	const isLoading = useSelector(getIsLoading)

	const buttonRef = useRef()

	const [questionnaireModal, openQuestionnaireModal] = useState(false)
	const [isCertModalShow, setIsCertModalShow] = useState(false)
	const [isCertRejectShow, setIsRejectShow] = useState(false)
	const [rejectMessage, setRejectMessage] = useState('')
	const [certificateItem, setCertificateItem] = useState({})
	const [userId, setUserId] = useState(null)

	const [approveModal, setApproveModal] = useState(false)

	const openModal = (id) => {
		openQuestionnaireModal(true)
		setUserId(id)
	}

	const handleRejectCertificate = (cert_id, msg) => () => {
		dispatch(rejectCertificate(cert_id, msg))
		dispatch(requestCertificatesById(id))
		setIsRejectShow(false)
		setRejectMessage('')
		setIsCertModalShow(false)
	}

	const showSertificate = (item) => ({ target }) => {
		if (buttonRef?.current?.contains(target)) return
		setIsCertModalShow(true)
		setCertificateItem(item)
	}

	/*
		Открыть/закрыть модальное окно одобрения сертификата
	*/
	const handleApproveModal = (state) => () => {
		setApproveModal(state)
	}

	/*
		Подтвердить сертификат
	*/
	const handleApproveCertificate = (cert_id) => () => {
		dispatch(approveCertificate(cert_id))
		dispatch(requestCertificatesById(id))
		setApproveModal(false)
		setIsCertModalShow(false)
	}

	useEffect(() => {
		if (id !== 'create') dispatch(requestCertificatesById(id))
	}, [dispatch, id, type, offset])

	useEffect(() => {
		if (userId) dispatch(getUserInfo(userId))
	}, [dispatch, userId])

	useEffect(
		() => () => {
			dispatch(setCertificates({ certs: [] }))
			dispatch(
				setFilters({
					limit: 10,
					offset: 0,
				})
			)
			dispatch(setCount({ count: 0 }))
			!userPage && dispatch(setData({ data: {} }))
		},
		[dispatch, userPage]
	)

	return (
		<>
			<TableBody
				limit={limit}
				count={count}
				offset={offset}
				setOffset={changeOffset(dispatch, setFilters)}
				isLoading={isLoading}
			>
				{!!certList.length ? (
					certList.map((item, index) => (
						<TableCertificatesRow
							key={`cert-${index}`}
							name={item.name}
							openModal={() => openModal(item.user_id)}
							showSertificate={showSertificate(item)}
							image={item.image}
							code={item.code}
							status={item.status}
							classList={userPage && ['user-page-certificates']}
							userPage={userPage}
							ref={buttonRef}
						/>
					))
				) : (
					<span>Список сертификатов пуст</span>
				)}
			</TableBody>
			<ModalContainer show={isCertModalShow} onClose={() => setIsCertModalShow(false)}>
				<RejectCertModal
					item={certificateItem}
					isCertRejectShow={isCertRejectShow}
					setIsRejectShow={setIsRejectShow}
					setRejectMessage={setRejectMessage}
					rejectMessage={rejectMessage}
					handleRejectCertificate={handleRejectCertificate}
					approveModal={approveModal}
					handleApproveModal={handleApproveModal}
					handleApproveCertificate={handleApproveCertificate}
				/>
			</ModalContainer>

			<ModalContainer
				profile
				show={questionnaireModal}
				onClose={() => openQuestionnaireModal(false)}
			>
				{!!Object.values(user).length && <QuestionnaireContent info={user} />}
			</ModalContainer>
		</>
	)
})
