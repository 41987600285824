import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableBody } from '../../../../../components/TableBody/TableBody'
import { TableRequestRow } from '../../../../../components/TableBody/TableRows/TableRequestRow/TableRequestRow'
import { ModalContainer } from '../../../../../components/ModalContainer/ModalContainer'
import { QuestionnaireContent } from '../../../../../components/QuestionnaireContent/QuestionnaireContent'

import { requestUsersInGroup } from '../../../../../store/reducers/groups/groups'
import {
	getUsers,
	getUsersCount,
	getUsersFilters,
	getIsLoading,
} from '../../../../../store/reducers/groups/groups-selectors'
import { getUserData } from '../../../../../store/reducers/user/user-selectors'

import { setUsersFilters, deleteUserFromGroup } from '../../../../../store/reducers/groups/groups'
import { getUserInfo, setData } from '../../../../../store/reducers/user/user'

import { changeOffset } from '../../../../../functions/common'

import icon from '../../../../../assets/media/svg/delete-black.svg'

import './TableItem.scss'

export const TableItem = React.memo(({ questionnaireModal, openQuestionnaireModal, id }) => {
	const dispatch = useDispatch()

	const [userId, setUserId] = useState(null)

	const users = useSelector(getUsers)
	const count = useSelector(getUsersCount)
	const filters = useSelector(getUsersFilters)
	const isLoading = useSelector(getIsLoading)
	const userInfo = useSelector(getUserData)

	const { pat, offset, limit } = filters

	const selectUserQuestionnaire = (id) => () => {
		openQuestionnaireModal(true)
		setUserId(id)
	}

	const removeItem = (itemId) => () => dispatch(deleteUserFromGroup(id, itemId))

	const closeModal = () => {
		openQuestionnaireModal(false)
		setTimeout(() => {
			dispatch(setData({ data: {} }))
			setUserId(null)
		}, 300)
	}

	useEffect(() => {
		dispatch(requestUsersInGroup(id))
	}, [dispatch, offset, pat, id])

	useEffect(() => {
		if (userId) {
			dispatch(getUserInfo(userId))
		}
	}, [dispatch, userId])

	return (
		<div className="group__row">
			<TableBody
				limit={limit}
				count={count}
				offset={offset}
				setOffset={changeOffset(dispatch, setUsersFilters)}
				isLoading={isLoading}
			>
				{users.map((item, index) => (
					<div key={`group-${index}`} className="group__row-item">
						<TableRequestRow
							name={item.name}
							image={item.image}
							phone={item.tel}
							classList={['group']}
							openModal={selectUserQuestionnaire(item.id)}
							icon={icon}
							deleteIt
							removeItem={removeItem(item.id)}
						/>
					</div>
				))}
			</TableBody>
			<ModalContainer profile show={questionnaireModal} onClose={closeModal}>
				<QuestionnaireContent info={userInfo} />
			</ModalContainer>
		</div>
	)
})
