import { createSlice } from '@reduxjs/toolkit'
import { getUserInfo } from '../user/user'

import usersApi from '../../../api/users-api'
import { onError } from '../functions'

const initialState = {
	isLoading: false,
	filters: {
		limit: 15,
		offset: 0,
		name: '',
		role: 0,
		status: 0,
		sort: '',
		sort_money: false,
		skills: [],
		specializations: [],
		group_id: '',
		is_banned: 0,
		is_work_open: 0,
		time_register_start: null,
		time_register_end: null,
		is_group: 0,
	},
	count: 0,
	users: [],
}

const users = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setUsers: (state, action) => {
			state.users = action.payload.users
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},
	},
})

const { reducer, actions } = users

export const { setIsLoading, setUsers, setFilters, setCount } = actions

// Получение данных пользователей
export const getUsersList = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		users: { filters },
	} = getState()

	const res = await usersApi.getUsersList(filters)

	if (res.success) {
		dispatch(setUsers({ users: res.result.users }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Подтверждение анкеты
export const approve = (id) => async (dispatch) => {
	const res = await usersApi.approveForm(id)

	if (res.success) {
		dispatch(getUsersList())
	} else {
		onError(dispatch, res.result)
	}
}

// Отклонение анкеты
export const reject = (id, msg) => async (dispatch) => {
	const res = await usersApi.rejectForm(id, msg)

	if (res.success) {
		dispatch(getUsersList())
	} else {
		onError(dispatch, res.result)
	}
}

// Бан пользователя
export const ban = (id, state) => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await usersApi.setBan(id, state)

	if (res.success) {
		// dispatch(getUsersList())
		dispatch(getUserInfo(id))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

export default reducer
