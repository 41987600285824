import React from 'react'
import cn from 'classnames'

import { IconButton } from '../IconButton/IconButton'

import './ModalContainer.scss'

export const ModalContainer = ({ profile, show = false, onClose, children, map }) => (
	<div
		className={cn('modal-wrapper', { show }, { profile }, { map })}
		onMouseDown={({ target }) => {
			if (!target.closest('.modal-container')) onClose()
		}}
	>
		<div className="modal-container">
			<IconButton
				className={cn('btn-close', { outside: profile })}
				iconName="close"
				size={32}
				onClick={onClose}
			/>
			{children}
		</div>
	</div>
)
