import React from 'react'
import { Icon } from '../../../../../../../components/Icon/Icon'

import './UserAdminTransactionsHeader.scss'

export const UserAdminTransactionsHeader = React.memo(({ userMoney, setQuestionnaireModal }) => {
	return (
		<div className="user-transactions-header">
			<div className="user-money">
				<span>{userMoney}</span>
				<div className="icon-wrapper">
					<i></i>
				</div>
			</div>
			<div className="add-money-button">
				<button className="add-button" onClick={() => setQuestionnaireModal(true)}>
					<Icon name="plus" size={24} />
					<div className="add-text">
						<span>Оперировать баллами</span>
					</div>
				</button>
			</div>
		</div>
	)
})
