import React from 'react'
import cn from 'classnames'

import { Preloader } from '../Preloader/Preloader'
import { TableRows } from './TableRows/TableRows'
import { TableTitle } from './TableTitles/TableTitle/TableTitle'
import { TableTitles } from './TableTitles/TableTitles'
import { TablePagination } from '../TablePagination/TablePagination'

import './TableBody.scss'

export const TableBody = React.memo(
	({
		contentTitlesArray = [],
		additionalClasses = [],
		limit,
		count,
		offset,
		setOffset,
		children,
		isLoading,
	}) => (
		<div className={cn('table', ...additionalClasses)}>
			<div className="table-inner-block">
				{!!contentTitlesArray.length && (
					<TableTitles>
						{contentTitlesArray.map(({ title, handler, withSort }, index) => (
							<TableTitle
								sort={withSort}
								title={title}
								onClick={handler}
								key={`table-title-${index}`}
							/>
						))}
					</TableTitles>
				)}
				<TableRows>{children}</TableRows>
				{isLoading && (
					<div className="preloader-container">
						<Preloader />
					</div>
				)}
			</div>

			<TablePagination
				limit={limit}
				count={count}
				offset={offset}
				changePage={setOffset}
			/>
		</div>
	)
)
