import React from 'react'

import './DeleteButton.scss'

export const DeleteButton = React.memo(({ icon, onClick, type }) => {
  return (
    <button
      className="delete__button"
      style={{ backgroundImage: `url(${icon})` }}
      type={type}
      onClick={onClick}
    ></button>
  )
})