import React from 'react'

import { Icon } from '../../../../../../../../../components/Icon/Icon'

import './AddButton.scss'

export const AddButton = React.memo(({ handleCloseGroupList }) => {
	return (
		<li className="first-list-option">
			<button className="close-list" onClick={handleCloseGroupList}>
				<Icon name="close" size={24} />
				<div className="group-name add-text">
					<span>Добавить в группу</span>
				</div>
			</button>
		</li>
	)
})
