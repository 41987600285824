import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { QuestionnaireTabs as Tabs } from './QuestionnaireTabs/QuestionnaireTabs'
import { PanelTitle } from '../../components/Panels/Panel/PanelTitle/PanelTitle'
import { Icon } from '../Icon/Icon'
import { Preloader } from '../Preloader/Preloader'
import { Avatar } from '../Avatar/Avatar'
import { BotIntegrations } from '../BotIntegrations/BotIntegrations'
import { AnaliticsInfo } from '../AnaliticsInfo/AnaliticsInfo'
import { ExportPdf } from '../ExportPdf/ExportPdf'

import { getStatusSpecs } from '../../functions/common'

import { getIsLoading } from '../../store/reducers/user/user-selectors'

import './QuestionnaireContent.scss'

export const QuestionnaireContent = React.memo(({ info, userPage, withAvatar, setViewer }) => {
	if (!Object.keys(info).length)
		return (
			<div className="preloader-container">
				<Preloader />
			</div>
		)

	const isLoading = useSelector(getIsLoading)

	const { user, referal } = info
	const {
		id,
		bots,
		first_page: { image },
	} = user

	const status = getStatusSpecs(user ? user.status : -1)

	const ref = useRef()

	const ok = !isLoading

	return (
		<>
			<div
				ref={ref}
				className={cn('questionnaire-content', {
					buttons: status === 'moderate',
				})}
			>
				{ok ? (
					<>
						<PanelTitle
							text={
								userPage
									? 'Анкета'
									: user
									? `${user.first_page.surname} ${user.first_page.name}`
									: 'Загрузка...'
							}
						>
							{userPage && (
								<div className={cn('user-form-status', status.color)}>
									<div className="icon-container">
										<i className="icon" />
									</div>
									<span className="text">{status.text}</span>
								</div>
							)}
							{status && user.status < 4 && (
								<div className={cn('form-status', status.color)}>
									<Icon name={status.icon} />
								</div>
							)}
						</PanelTitle>
						{withAvatar && <Avatar id={id} image={image} large withViewer setViewer={setViewer} />}
						<Tabs />
						<BotIntegrations bots={bots} />
						<AnaliticsInfo fromLink={referal.from_link} refLink={referal.refer_link} />

						<div className="pdf-button-wrapper">
							<ExportPdf user={info} />
						</div>
					</>
				) : (
					<div className="preloader-container">
						<Preloader />
					</div>
				)}
			</div>
		</>
	)
})
