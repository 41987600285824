import React from 'react'
import { usePDF, Font } from '@react-pdf/renderer'

import { TemplatePdf } from './TemplatePdf/TemplatePdf.jsx'
import { Preloader } from '../Preloader/Preloader.jsx'

import './ExportPdf.scss'

Font.register({
	family: 'Roboto',
	src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})

export const ExportPdf = React.memo(({ user }) => {
	if (!user?.user) return null

	const [instance] = usePDF({ document: TemplatePdf({ user }) })

	const {
		user: {
			first_page: { name, surname },
		},
	} = user

	return (
		<div className="pdf-wrapper">
			<a className="pdf__downdload-link" href={instance.url} download={`${surname}${name}Cv.pdf`}>
				{
					instance.loading ? (
						<div className="preloader-container">
							<Preloader />
						</div>
						) : (
						<span className="label">Скачать анкету в pdf</span>
						)
					}
			</a>
		</div>
	)
})
