import React, { useState } from 'react'
import { url } from '../../../../api/api'

import cn from 'classnames'

import { Tooltip } from '../../../Tooltip/Tooltip'
import { Icon } from '../../../Icon/Icon'
import { BotIntegrations } from '../../../BotIntegrations/BotIntegrations'

import { createDateRange, getStatusSpecs, createDate } from '../../../../functions/common'

import wifi from '../../../../assets/media/svg/wifi.svg'
import coffee from '../../../../assets/media/svg/coffee.svg'

import './TableRow.scss'

export const TableRow = React.memo(
	({
		row = {},
		status,
		mode,
		textTooltip,
		deleteClass,
		plug,
		plugNumber,
		adminPlugNumber,
		adminPlug,
	}) => {
		const [showTooltip, setShowTooltip] = useState(false)

		const dateRangeOptions = {
			month: 'long',
			day: 'numeric',
		}

		const dateOptions = {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
		}

		const timeOptions = {
			hour: 'numeric',
			minute: 'numeric',
		}

		const checkTransaktionValue = (data) => (data > 0 ? `+${data}` : data)

		const setClassforTransaktionValue = (data) => (data > 0 ? 'positive' : 'negative')

		const changeEquipmentStyle = (num, index) => {
			const numSwitchObj = {
				1: (
					<p
						className="equipment"
						key={`equipment-${index}`}
						style={{ backgroundImage: `url(${wifi})` }}
					>
						Wi-Fi
					</p>
				),
				2: (
					<p
						className="equipment"
						key={`equipment-${index}`}
						style={{ backgroundImage: `url(${coffee})` }}
					>
						Кофемашина
					</p>
				),
			}

			return numSwitchObj[num]
		}

		const rowItemConstructor = (type, data) => {
			switch (type) {
				/* users page */
				case 'userStatus':
					const statusOpt = getStatusSpecs(data)
					return (
						<div className="user-status">
							<Icon name={statusOpt.icon} />
							<span className={cn('text', statusOpt.color)}>{statusOpt.text}</span>
						</div>
					)

				case 'userGroup':
					return <div className="user-group">{data.join(', ')}</div>

				// Добавление бота в строку для таблицы пользователей
				case 'bots':
					return <BotIntegrations bots={data} horizontal />

				case 'ban':
					return <>{data.isBanned && <Icon name="lock-red" size={24} />}</>

				/* users page */

				case 'image':
					return !!data ? (
						<img src={`${url}/static/${data}`} alt="img" />
					) : (
						<div className="image-plug" />
					)

				case 'time':
					return (
						data.time_start &&
						data.time_end && (
							<div>{createDateRange(data.time_start, data.time_end, 'ru', dateRangeOptions)}</div>
						)
					)

				case 'from':
					return <div className="overflowText">{data}</div>

				case 'target':
					return <div className="overflowText">{data}</div>

				case 'count_props':
					return status !== 2 ? (
						<div
							className={cn('unpublic', {
								delete: deleteClass,
							})}
							onMouseEnter={() => setShowTooltip(true)}
							onMouseLeave={() => setShowTooltip(false)}
						>
							<p>!</p> <Tooltip show={showTooltip}>{textTooltip}</Tooltip>
						</div>
					) : (
						<div className="request-count">
							<p className={cn({ gray: status !== 2 || data === 0 })}>{data}</p>
						</div>
					)

				case 'office_time':
					return (
						data.time_start &&
						data.time_end && (
							<div>{createDateRange(data.time_start, data.time_end, 'ru', timeOptions)}</div>
						)
					)

				case 'equipment':
					return data.map((item, i) => changeEquipmentStyle(item, i))

				case 'all_count':
					return mode === 2 ? <span>{`${data} в мес`}</span> : <span>{data}</span>

				case 'type':
					const typeSwitchObj = {
						1: 'Офис',
						2: 'Событие',
						3: 'Товар',
					}

					return typeSwitchObj[data]

				case 'mode':
					const modeSwitchObj = {
						1: 'Одноразовая',
						2: 'Месячная',
						3: 'Индивидуальная',
					}

					return modeSwitchObj[data]

				// товары

				case 'prod_type':
					const prodTypeSwitchObj = {
						1: 'Товар',
						2: 'Услуга',
					}

					return prodTypeSwitchObj[data]

				case 'prod_descr':
					return <p className="text">{data}</p>
				// транзакции

				case 'msg':
					if (data.length > 42) {
						return (
							<div className="transactions-description">
								{`${data.slice(0, 43)}...`}
								<div className="description-popup">
									<p>{data}</p>
								</div>
							</div>
						)
					} else {
						return <p>{data}</p>
					}

				case 'user_image':
					const imageUrl = data ? `${url}/static/${data}` : plug

					return (
						<img
							src={`${imageUrl}`}
							alt=""
							className={cn('avatar', { [`plug plug-${plugNumber}`]: !data })}
						/>
					)

				case 'admin_image':
					return data ? (
						<img src={`${url}/static/${data}`} alt="" className="avatar" />
					) : (
						<img
							src={`${adminPlug}`}
							alt="event-img"
							className={cn('avatar', {
								[`plug plug-${adminPlugNumber}`]: !data,
							})}
						/>
					)

				case 'trans_time':
					return <p>{createDate(data, 'ru', dateOptions)}</p>

				case 'trans_value':
					return (
						<div className={cn('points-value', setClassforTransaktionValue(data))}>
							<div className="icon-wrapper">
								<i></i>
							</div>
							{checkTransaktionValue(data)}
						</div>
					)

				case 'users_value':
					return (
						<div className={cn('points-value', setClassforTransaktionValue(data))}>
							<div className="icon-wrapper">
								<i></i>
							</div>
							{data}
						</div>
					)

				case 'commentStatus':
					const switchObj = {
						1: <div className="comment-label new">Новый</div>,
						2: <div className="comment-label viewed">Просмотрен</div>,
						3: <div className="comment-label rejected">Удален</div>,
					}
					return switchObj[data]

				//новости

				case 'time_create':
					return <p>{createDate(data, 'ru', dateOptions)}</p>

				case 'news_type':
					const newsTypeSwitchObj = {
						1: <div>События</div>,
						2: <div>Обучение</div>,
						3: <div>Интервью</div>,
						4: <div>Маркет</div>,
						5: <div>Офисы</div>,
					}

					return newsTypeSwitchObj[data]

				case 'news_descr':
					return <p className="text">{data}</p>

				case 'delete_button':
					return (
						<button type="button" className="delete-button" onClick={data.deleteItem}>
							<i></i>
						</button>
					)

				default:
					return <p>{data}</p>
			}
		}

		return Object.values(row).map((item, index) => (
			<div className="row-item" key={index}>
				{rowItemConstructor(Object.keys(row)[index], item)}
			</div>
		))
	}
)
