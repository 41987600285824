import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Panel } from '../../../../../components/Panels/Panel/Panel'
import { TabsSelect } from '../../../../../components/TabsSelect/TabsSelect'
import { Requests } from '../../../../../components/AdminTable/Requests/Requests'
import { Certificates } from '../../../../../components/AdminTable/Certificates/Certificates'
import { UserAdminGroups } from './UserAdminGroups/UserAdminGroups'
import { UserAdminNotes } from './UserAdminNotes/UserAdminNotes'
import { UserAdminTransactions } from './UserAdminTransactions/UserAdminTransactions'

import { getApplicationsCount } from '../../../../../store/reducers/applications/applications-selector'

import { filterTabs } from '../../../users-constants'

import './UserAdminTable.scss'

export const UserAdminTable = React.memo(({ id, name }) => {
	const [tab, setTab] = useState(0)

	const tabsCount = useSelector(getApplicationsCount)

	return (
		<div className="requests-list">
			<TabsSelect tabs={filterTabs(tabsCount)} tab={tab} setTab={setTab} />
			<Panel>
				{tab === 0 && <Requests userPage />}
				{tab === 1 && <UserAdminGroups name={name} />}
				{tab === 2 && <Certificates userPage />}
				{tab === 3 && <UserAdminTransactions id={id} name={name} />}
				{tab === 4 && <UserAdminNotes id={id} />}
			</Panel>
		</div>
	)
})
