import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
	page: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: '#fff',
		fontFamily: 'Roboto',
		fontSize: 12,
	},
	pageWrapper: {
		paddingHorizontal: 20,
		paddingVertical: 10,
	},
	section: {
		marginBottom: 10,
		display: 'flex',
		flexDirection: 'column',
	},
	mainLogo: {
		height: 140,
	},
	pagesLogo: {
		width: 100,
	},
	avatar: {
		width: 100,
		height: 100,
	},
	mainText: {
		display: 'block',
		width: 300,
		marginBottom: 10,
		fontSize: 24,
		color: '#4d5063',
	},
	pageHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingVertical: 10,
		paddingHorizontal: 20,
		backgroundColor: '#5d83e41a',
	},
	pageName: {
		display: 'flex',
		flexDirection: 'row',
		fontSize: 12,
		color: '',
	},
	title: {
		display: 'block',
		fontSize: 16,
		marginBottom: 5,
		color: '#4d5063',
	},
	rowTitle: {
		width: 165,
		marginRight: 22,
		fontSize: 12,
		opacity: 0.7,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: 5,
	},
	icon: {
		width: 16,
		height: 16,
		marginRight: 15,
	},
	skillsWrapper: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'center',
		marginBottom: 10,
	},
	skillName: {
		display: 'flex',
		borderRadius: 5,
		paddingHorizontal: 4,
		backgroundColor: '#fff',
		color: '#000',
		fontSize: 12,
	},
	skillTag: {
		color: '#fff',
		paddingLeft: 4,
		paddingRight: 4,
		fontSize: 12,
	},
	junior: {
		display: 'flex',
		flexDirection: 'row',
		paddingLeft: 2,
		paddingVertical: 2,
		marginRight: 5,
		alignItems: 'center',
		borderRadius: 5,
		backgroundColor: '#f2994a',
	},
	middle: {
		display: 'flex',
		flexDirection: 'row',
		paddingLeft: 2,
		paddingVertical: 2,
		marginRight: 5,
		alignItems: 'center',
		borderRadius: 5,
		backgroundColor: '#eb5757',
	},
	senior: {
		display: 'flex',
		flexDirection: 'row',
		paddingLeft: 2,
		paddingVertical: 2,
		marginRight: 5,
		alignItems: 'center',
		borderRadius: 5,
		backgroundColor: '#4d5162',
	},
})
