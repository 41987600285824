import React from 'react'
import cn from 'classnames'

import { Icon } from '../../../../components/Icon/Icon'

import './PointsCounter.scss'

export const PointsCounter = React.memo(
	({ increase = false, decrease = false, white = false, count = 0, withIcon = true }) => {
		const formatedCount = count.toLocaleString('ru')
		const _count = increase ? `+${formatedCount}` : decrease ? `-${formatedCount}` : formatedCount

		return (
			<div className={cn('points-counter', { increase, decrease, white })}>
				<div className={'points-counter__count'}>{_count}</div>
				{withIcon && (
					<div className="points-counter__icon">
						<Icon name="point" size={16} />
					</div>
				)}
			</div>
		)
	}
)
