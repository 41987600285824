import React from 'react'
import { Avatar } from '../../../../../../components/Avatar/Avatar'

import './UserInfo.scss'

export const UserInfo = React.memo(({ id, image, name, setViewer }) => {
	return (
		<div className="user-info-block">
			<Avatar id={id} image={image} large withViewer setViewer={setViewer} />
			<div className="user-name">
				<span>{name}</span>
			</div>
		</div>
	)
})
