import React from 'react'

import { Button } from '../../../components/Buttons/Button/Button'

import { articlesTypes } from '../../../pages/KnowledgeBasePage/articles-types'
import { newsTypes } from '../../../pages/NewsPage/news-types'

import './InfoPageTypesBtns.scss'

export const InfoPageTypesBtns = React.memo(
	({ news = false, knowledgeBase = false, type, setType }) => {
		const handleClick = (value) => () => {
			setType(value)
		}

		const createBtns = (arr) =>
			arr.map((item, i) => (
				<Button
					key={`${item}-${i}`}
					type="s"
					gray
					disabled={type === i + 1}
					label={item}
					onClick={handleClick(i + 1)}
				/>
			))

		return (
			<div className="info-page-types-btns">
				{news && createBtns(newsTypes)}
				{knowledgeBase && createBtns(articlesTypes)}
			</div>
		)
	}
)
