export const getProducts = ({ products: { products } }) => products

export const getProductsCount = ({ products: { productsCount } }) => productsCount

export const productsFilters = ({ products: { productsFilters } }) => productsFilters

export const getProductInfo = ({ products: { productInfo } }) => productInfo

export const getCities = ({ products: { productSearchCity } }) => productSearchCity

export const getCountries = ({ products: { productSearchCountry } }) => productSearchCountry

export const getIsLoading = ({ products: { isLoading } }) => isLoading

export const getCitiesForFilters = ({ products: { cities } }) => cities

export const getCountriesForFilters = ({ products: { countries } }) => countries

export const getMaxPrice = ({ products: { maxPrice } }) => maxPrice
