import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { TableBody } from '../../../components/TableBody/TableBody'
import { TableRequestRow } from '../../../components/TableBody/TableRows/TableRequestRow/TableRequestRow'
import { ModalContainer } from '../../../components/ModalContainer/ModalContainer'
import { QuestionnaireContent } from '../../../components/QuestionnaireContent/QuestionnaireContent'

import { getUserData } from '../../../store/reducers/user/user-selectors'
import { getUserInfo, setData } from '../../../store/reducers/user/user'
import {
	requestApplicationsById,
	setApplications,
	setFilters,
} from '../../../store/reducers/applications/applications'

import {
	getApplications,
	getApplicationsCount,
	getFilters,
	getIsLoading,
} from '../../../store/reducers/applications/applications-selector'

import { changeOffset } from '../../../functions/common'

export const Requests = React.memo(({ userPage }) => {
	const dispatch = useDispatch()

	const match = useRouteMatch()
	const id = match.params.id

	const [questionnaireModal, openQuestionnaireModal] = useState(false)
	const [userId, setUserId] = useState(null)

	const requestList = useSelector(getApplications)
	const count = useSelector(getApplicationsCount)
	const user = useSelector(getUserData)
	const { limit, offset } = useSelector(getFilters)
	const isLoading = useSelector(getIsLoading)

	const openModal = (id) => {
		openQuestionnaireModal(true)
		setUserId(id)
	}

	const closeModal = () => {
		openQuestionnaireModal(false)
	}

	useEffect(() => {
		dispatch(requestApplicationsById(id))
	}, [dispatch, id, offset])

	useEffect(() => {
		if (userId) dispatch(getUserInfo(userId))
	}, [dispatch, userId])

	useEffect(
		() => () => {
			dispatch(setApplications({ applications: [] }))
			dispatch(
				setFilters({
					limit: 10,
					offset: 0,
				})
			)
			!userPage && dispatch(setData({ data: {} }))
		},
		[dispatch, userPage]
	)
	return (
		<>
			<TableBody
				limit={limit}
				count={count}
				offset={offset}
				setOffset={changeOffset(dispatch, setFilters)}
				isLoading={isLoading}
			>
				{!!requestList.length ? (
					requestList.map((user, index) => (
						<TableRequestRow
							image={user.image}
							phone={user.tel}
							name={user.name}
							openModal={() => openModal(user.user_id)}
							key={`request-${index}`}
							appId={user.id}
							productId={user.product_id}
							id={id}
							status={user.status}
							type={2}
							classList={userPage && ['user-page-requests']}
							userPage={userPage}
						/>
					))
				) : (
					<span>Список заявок пуст</span>
				)}
			</TableBody>
			<ModalContainer profile show={questionnaireModal} onClose={closeModal}>
				<QuestionnaireContent info={user} />
			</ModalContainer>
		</>
	)
})
