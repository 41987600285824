import React, { useContext } from 'react'

import { Input } from '../../../../../components/Inputs/Input/Input'
import { TextAreaField } from '../../../../../components/TextAreaField/TextAreaField'

import EventContext from '../../../../../context/EventContext'

import { actions } from '../../actions'

import './EventDescription.scss'

export const EventDescription = React.memo(({ fillPassword }) => {
	const { state, localDispatch } = useContext(EventContext)

	return (
		<div className="event-description-block">
			<Input
				label={'Наименование'}
				value={state.name}
				onChange={({ target: { value } }) => localDispatch(actions.setName(value))}
				className={state.errors.name && 'error'}
			/>
			<Input
				label={'Пароль'}
				value={state.password}
				onChange={({ target: { value } }) => localDispatch(actions.setPassword(value))}
				className={state.errors.name && 'error'}
				disabled={!!fillPassword}
			/>
			<TextAreaField
				label="Описание"
				value={state.description}
				onChange={({ target: { value } }) => localDispatch(actions.setDescription(value))}
				error={state.errors.description}
			/>
		</div>
	)
})
