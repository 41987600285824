import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ModalContainer } from '../../../components/ModalContainer/ModalContainer'
import { SelectCategory } from '../../../components/SelectCategory/SelectCategory'
import { BlockHeader } from '../CommonComponents/BlockHeader/BlockHeader'
import { EmptyItem } from '../CommonComponents/EmptyItem/EmptyItem'
import { NewsItem } from './NewsItem/NewsItem'

import { getNews } from '../../../store/reducers/content/content-selector'

import { requestNews, setFilters } from '../../../store/reducers/news/news'
import { setNews } from '../../../store/reducers/content/content'

import { getFilters, getNews as getNewsList } from '../../../store/reducers/news/news-selectors'

import './NewsBlock.scss'

export const NewsBlock = React.memo(() => {
	const dispatch = useDispatch()

	const newsArray = useSelector(getNews)
	const news = useSelector(getNewsList)
	const { name } = useSelector(getFilters)

	const newsOptions = news.filter((item) => item.status !== 3)

	const [showModal, toggleModal] = useState(false)
	const [selectedNews, setSelectedNews] = useState({})
	const [index, setIndex] = useState(0)

	const handleModal = (value, index) => () => {
		toggleModal(value)
		setIndex(index)
	}

	const addNewsBlock = () => dispatch(setNews({ news: [null, ...newsArray] }))

	const selectProduct = (item) => {
		setSelectedNews(item)
		dispatch(setFilters({ name: item.name }))
	}

	const changeValue = () => ({ target: { value } }) => dispatch(setFilters({ name: value }))

	const deleteNewsBlock = (newsIndex) => () =>
		dispatch(setNews({ news: newsArray.filter((item, index) => index !== newsIndex) }))

	const acceptSelectedNews = () => {
		setSelectedNews({})
		dispatch(setFilters({ name: '' }))

		const _newsArray = [...newsArray]

		_newsArray[index] = selectedNews

		dispatch(setNews({ news: _newsArray }))

		toggleModal(false)
	}

	useEffect(() => {
		dispatch(requestNews())
	}, [dispatch, name])

	return (
		<div className="news-block-container main-block">
			<BlockHeader
				title="Новости кластера"
				showButton={newsArray.length < 6}
				positionText="(Не менее 4 позиций)"
				buttonText="Добавить новость"
				addElement={addNewsBlock}
			/>

			<div className="news-block-container__news">
				{!!newsArray.length &&
					newsArray.map((news, index) =>
						news ? (
							<NewsItem
								item={news}
								key={index}
								deleteItem={deleteNewsBlock(index)}
								toggleModal={handleModal(true, index)}
								showDeleteButton={newsArray.length > 4}
							/>
						) : (
							<EmptyItem
								toggleModal={handleModal(true, index)}
								deleteItem={deleteNewsBlock(index)}
								check={newsArray.length > 4}
								key={index}
							/>
						)
					)}
			</div>

			<ModalContainer show={showModal} onClose={handleModal(false, 0)}>
				<SelectCategory
					title="Добавить новость"
					inputLabel="Новость"
					type="Новость"
					productValue={name}
					productOptions={newsOptions}
					selectProduct={selectProduct}
					changeProductValue={changeValue()}
					onClose={handleModal(false, 0)}
					onSubmit={acceptSelectedNews}
				/>
			</ModalContainer>
		</div>
	)
})
