import React from 'react'
import cn from 'classnames'

import './FilterItem.scss'

export const FilterItem = React.memo(({ title, filter, active = false, handler = () => {} }) => (
	<div className={cn('filter-item', { active })} onClick={handler}>
		<i
			className={cn('filter__icon', { icon: filter.icon })}
			style={filter.icon ? { backgroundImage: `url(${filter.icon})` } : { backgroundImage: '' }}
		></i>
		{title}
	</div>
))
