import React from 'react'

import { Icon } from '../Icon/Icon'

import './DeleteReason.scss'

export const DeleteReason = React.memo(({ reason }) => (
	<div>
		<div className="already-deleted">
			<Icon name="denied" /> <p>Комментарий удален</p>
		</div>
		{reason && (
			<p className="delete-reason">
				Причина удаления: <span>{reason}</span>
			</p>
		)}
	</div>
))
