import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { ColumnsContainer } from '../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { RightColumn } from '../../../components/PageWrapper/ColumnsContainer/RightColumn/RightColumn'
import { Button } from '../../../components/Buttons/Button/Button'
import { TableHeader } from '../../../components/TableHeader/TableHeader'
import { TableBody } from '../../../components/TableBody/TableBody'
import { TableRow } from '../../../components/TableBody/TableRows/TableRow/TableRow'

import { requestGroups } from '../../../store/reducers/groups/groups'
import {
	getGroups,
	getGroupsCount,
	getFilters,
	getIsLoading,
} from '../../../store/reducers/groups/groups-selectors'

import { setGroupsFilters } from '../../../store/reducers/groups/groups'

import './GroupsList.scss'
import { changeOffset, refreshTableItems, searchItem } from '../../../functions/common'

export const GroupsList = React.memo(({ createNewGroup }) => {
	const dispatch = useDispatch()

	const groups = useSelector(getGroups)
	const count = useSelector(getGroupsCount)
	const filters = useSelector(getFilters)
	const isLoading = useSelector(getIsLoading)

	const { name, limit, offset } = filters

	useEffect(() => {
		dispatch(requestGroups())
	}, [dispatch, offset, name])

	return (
		<div className="groups__list">
			<PageWrapper>
				<ColumnsContainer>
					<LeftColumn>
						<div className="groups__list-wrapper">
							<TableHeader
								title={'Список групп'}
								placeholder={'Наименование'}
								searchValue={name}
								onSearchChange={searchItem(dispatch, setGroupsFilters, 'name')}
								onRefresh={refreshTableItems(dispatch, requestGroups)}
							/>
							<TableBody
								contentTitlesArray={[
									{ title: 'Наименование' },
									{ title: 'Описание' },
									{ title: 'Кол-во пользователей' },
								]}
								additionalClasses={['groups']}
								limit={limit}
								count={count}
								offset={offset}
								setOffset={changeOffset(dispatch, setGroupsFilters)}
								isLoading={isLoading}
							>
								{groups.map((row, index) => (
									<Link to={`groups/${row.id}`} key={`table-link-${index}`}>
										<div className="table-row" style={{ animationDelay: `${index * 40}ms` }}>
											<TableRow
												row={{
													name: row.name,
													prod_descr: row.prod_descr,
													count: row.count,
												}}
												exceptions={[
													'id',
													'type',
													'status',
													'status_props',
													'time',
													'image',
													'loc_city',
													'loc_addr',
												]}
												status={row.status}
											/>
										</div>
									</Link>
								))}
							</TableBody>
						</div>
					</LeftColumn>
					<RightColumn>
						<Button label={'Создать группу'} onClick={createNewGroup} />
					</RightColumn>
				</ColumnsContainer>
			</PageWrapper>
		</div>
	)
})
