import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { Icon } from '../../../../components/Icon/Icon'
import { url } from '../../../../api/api'

import { getLocal } from '../../../../store/reducers/app/app-selectors'

import { getStatusSpecs, getSkillName, getPlugNumber } from '../../../../functions/common'

import { avatarPlug } from '../../../../components/Avatar/avatar-functions'

import './FormCard.scss'

export const FormCard = ({
	id,
	avatar = '',
	name,
	status,
	isBanned,
	phone,
	mail,
	country,
	exp,
	skills,
	userId,
	onClick = () => {},
}) => {
	const local = useSelector(getLocal)

	const plugNumber = getPlugNumber(userId)

	const techLibraryArrays = !!Object.keys(local).length
		? local.techs.map((item) => item.tech_skills)
		: []
	const techLibrary = techLibraryArrays.flat()

	const checkTechInLocalization = (tech) => techLibrary.find((item) => item.key === tech)?.value

	const statusOpt = getStatusSpecs(status)
	// const _skills = sortSkills(skills)

	return (
		<div
			className={cn('form-card-component', { banned: isBanned })}
			style={{ animationDelay: `${id * 50}ms` }}
			onClick={onClick}
		>
			<div className="header">
				<div className="profile">
					<div className="avatar-container">
						{avatar === '' ? (
							<div
								className={`avatar__image plug plug-${plugNumber}`}
								style={{ backgroundImage: `url(${avatarPlug(plugNumber)})` }}
							></div>
						) : (
							<img src={`${url}/static/${avatar}`} alt="" />
						)}
					</div>
					<span className="name">{name}</span>
				</div>
				{status !== 4 && (
					<div className={cn('form-status', statusOpt.color)}>
						<Icon name={statusOpt.icon} />
					</div>
				)}
			</div>
			<div className="body">
				<div className="phone-block">
					<span>Телефон</span>
					<span>{phone}</span>
				</div>
				<div className="country-block">
					<span>Страна</span>
					{country ? <span>{country}</span> : <span className="placeholder">Отсутствует</span>}
				</div>
				<div className="mail-block">
					<span>Email</span>
					{mail ? <span>{mail}</span> : <span className="placeholder">Отсутствует</span>}
				</div>
				<div className="exp-block">
					<span>Опыт в IT</span>
					{exp ? <span>{exp}</span> : <span className="placeholder">Отсутствует</span>}
				</div>
			</div>
			<div className="divider"></div>
			<div className="skills-container">
				<span>Навыки</span>
				<div className="skills">
					{!!skills.length ? (
						skills.map((item, index) => (
							<div key={index} className={cn('skill-item', getSkillName(item.value))}>
								<span>{checkTechInLocalization(item.key)}</span>
							</div>
						))
					) : (
						<span className="placeholder">Не указаны</span>
					)}
				</div>
			</div>
		</div>
	)
}
