import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TableBody } from '../../../components/TableBody/TableBody'
import { TableRow } from '../../../components/TableBody/TableRows/TableRow/TableRow'
import { TableHeader } from '../../../components/TableHeader/TableHeader'
import { changeOffset } from '../../../functions/common'
import {
	requestAnaliticsData,
	setData,
	setFilters,
} from '../../../store/reducers/analitics/analitics'

import {
	getAnaliticsCount,
	getAnaliticsData,
	getAnaliticsFilters,
	getIsLoading,
} from '../../../store/reducers/analitics/analitics-selectors'

import './AnaliticsTable.scss'

export const AnaliticsTable = React.memo(() => {
	const dispatch = useDispatch()
	const analiticsData = useSelector(getAnaliticsData)
	const count = useSelector(getAnaliticsCount)
	const isLoading = useSelector(getIsLoading)
	const filters = useSelector(getAnaliticsFilters)
	const { offset, limit, pat } = filters

	useEffect(() => {
		dispatch(requestAnaliticsData())
	}, [dispatch, filters])

	useEffect(
		() => () => {
			dispatch(setData({ data: [] }))
			dispatch(
				setFilters({
					limit: 10,
					offset: 0,
					pat: '',
				})
			)
		},
		[dispatch]
	)

	return (
		<div className="analitics-table">
			<TableHeader
				title="Аналитика"
				placeholder="откуда/метка"
				searchValue={pat}
				onSearchChange={({ target: { value } }) => {
					dispatch(setFilters({ pat: value, offset: 0 }))
				}}
				onRefresh={() => dispatch(setFilters({ pat: '', offset: 0 }))}
			/>

			<TableBody
				contentTitlesArray={[
					{ title: 'Откуда' },
					{ title: 'Метка' },
					{ title: 'Переходы' },
					{ title: 'Регистрации' },
				]}
				additionalClasses={['analitics']}
				offset={offset}
				limit={limit}
				count={count}
				setOffset={changeOffset(dispatch, setFilters)}
				isLoading={isLoading}
			>
				{!!analiticsData.length ? (
					analiticsData.map((target, index) => {
						return (
							<div
								className="table-row"
								style={{ animationDelay: `${index * 40}ms` }}
								key={`table-block-${target.from_link}-${target.refer_link}-${target.count}-${target.reg_count}`}
							>
								<TableRow
									row={{
										from: target.from_link,
										target: target.refer_link,
										conversion: target.count,
										reg: target.reg_count,
									}}
									key={`table-row-${index}`}
								/>
							</div>
						)
					})
				) : (
					<div className="empty-data">Данные отсутствуют</div>
				)}
			</TableBody>
		</div>
	)
})
