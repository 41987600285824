import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { Panel } from '../../../../components/Panels/Panel/Panel'
import { Quotas } from '../../../../components/AdminTable/Quotas/Quotas'
import { TabsSelect } from '../../../../components/TabsSelect/TabsSelect'
import { Requests } from '../../../../components/AdminTable/Requests/Requests'
import { Certificates } from '../../../../components/AdminTable/Certificates/Certificates'
import { AddPhoto } from './AddPhoto/AddPhoto'
import { AddVideo } from './AddVideo/AddVideo'
import { Comments } from '../../../../components/Comments/Comments'

import { getApplicationsCount } from '../../../../store/reducers/applications/applications-selector'
import { getSelectedEvent } from '../../../../store/reducers/events/events-selectors'

import { tabsCreator } from './functions'

import './EventTable.scss'

export const EventTable = React.memo(({ past, status }) => {
	const [tab, setTab] = useState(0)

	const match = useRouteMatch()
	const id = match.params.id

	const propsCount = useSelector(getApplicationsCount)
	const event = useSelector(getSelectedEvent)

	return (
		<div className="events-table">
			<TabsSelect tabs={tabsCreator(past, propsCount)} tab={tab} setTab={setTab} />
			{past ? (
				<Panel>
					{tab === 0 && <AddPhoto />}
					{tab === 1 && <AddVideo />}
					{tab === 2 && <Certificates />}
					{tab === 3 && <Quotas id={id} status={status} type={2} />}
					{tab === 4 && <Comments name={event.name} />}
				</Panel>
			) : (
				<Panel>
					{tab === 0 && <Requests />}
					{tab === 1 && <Certificates />}
					{tab === 2 && <Quotas id={id} status={status} type={2} />}
				</Panel>
			)}
		</div>
	)
})
