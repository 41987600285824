import React from 'react'

export const VideoItem = React.memo(({ item, deleteFile }) => {
    return (
        <div 
            className="prewiew-wrapper"
        >
            <div className="btn-wrapper">
                <button
                    className='preimage__delete-btn'
                    onClick={deleteFile}
                ><span></span></button>
            </div>
            <iframe 
                title='video-prewiew__item'
                className='video-prewiew__item'
                width="160"
                height="160"
                src={item}
                frameBorder="0"
            ></iframe>
        </div>
    )
})