import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ModalContainer } from '../../../components/ModalContainer/ModalContainer'
import { SelectCategory } from '../../../components/SelectCategory/SelectCategory'
import { BlockHeader } from '../CommonComponents/BlockHeader/BlockHeader'
import { EmptyItem } from '../CommonComponents/EmptyItem/EmptyItem'

import { getOffices } from '../../../store/reducers/content/content-selector'
import { setOffices } from '../../../store/reducers/content/content'
import { OfficeAddress } from './OfficeAddress/OfficeAddress'

import {
	getOffices as officesArray,
	getOfficesFilters,
} from '../../../store/reducers/offices/offices-selectors'
import { requestOffices, setOfficesFilters } from '../../../store/reducers/offices/offices'

import './OfficeBlock.scss'

export const OfficeBlock = React.memo(() => {
	const dispatch = useDispatch()

	const officeArray = useSelector(getOffices)
	const offices = useSelector(officesArray)

	const officesOptions = offices.filter(
		(item) =>
			item.status !== 3 &&
			item.status !== 1 &&
			item.id !== officeArray[0].id &&
			item.id !== officeArray[1].id
	)

	const { name } = useSelector(getOfficesFilters)

	const [showModal, toggleModal] = useState(false)
	const [selectedOffice, setSelectedOffice] = useState({})
	const [index, setIndex] = useState(0)

	const handleModal = (value, index) => () => {
		toggleModal(value)
		setIndex(index)
	}

	const changeValue =
		() =>
		({ target: { value } }) =>
			dispatch(setOfficesFilters({ name: value }))

	const selectOffice = (item) => {
		setSelectedOffice(item)
		dispatch(setOfficesFilters({ name: item.name }))
	}

	const acceptSelectedOffice = () => {
		setSelectedOffice({})
		dispatch(setOfficesFilters({ name: '' }))

		const _officeArray = [...officeArray]

		_officeArray[index] = selectedOffice

		dispatch(setOffices({ offices: _officeArray }))

		toggleModal(false)
	}

	useEffect(() => {
		dispatch(requestOffices())
	}, [dispatch, name])

	return (
		<div className="office-block-container main-block">
			<BlockHeader title="Офисы" positionText="(2 позиции)" />
			<div className="office-block-container__list">
				{!!officeArray.length &&
					officeArray.map((item, index) =>
						item ? (
							<OfficeAddress
								image={item.image}
								title={item.name}
								address={item.loc_addr}
								changeItemFunc={handleModal(true, index)}
								key={index}
							/>
						) : (
							<EmptyItem toggleModal={handleModal(true, index)} key={index} />
						)
					)}
			</div>
			<ModalContainer show={showModal} onClose={handleModal(false)}>
				<SelectCategory
					title="Добавить офис"
					inputLabel="Офис"
					type="Офис"
					productOptions={officesOptions}
					productValue={name}
					changeProductValue={changeValue()}
					selectProduct={selectOffice}
					onClose={handleModal(false)}
					onSubmit={acceptSelectedOffice}
				/>
			</ModalContainer>
		</div>
	)
})
