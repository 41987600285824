import React from 'react'

import { Button } from '../../Buttons/Button/Button'
import { Panel } from '../../Panels/Panel/Panel'
import { PanelTitle } from '../../Panels/Panel/PanelTitle/PanelTitle'

import textIcon from '../../../assets/media/svg/text-editor-icons/text.svg'
import keyTextIcon from '../../../assets/media/svg/text-editor-icons/key-text.svg'
import imgIcon from '../../../assets/media/svg/text-editor-icons/images.svg'
import videoIcon from '../../../assets/media/svg/text-editor-icons/video.svg'

import './InfoPageAddBlocks.scss'

export const InfoPageAddBlocks = React.memo(({ addBlock }) => {
	const blocksConfig = [
		{ title: 'Текст', icon: textIcon, type: 'text' },
		{ title: 'Ключевой текст', icon: keyTextIcon, type: 'key' },
		{ title: 'Изображения', icon: imgIcon, type: 'photo' },
		{ title: 'Видео', icon: videoIcon, type: 'video' },
	]

	const handleClick = (type) => () => {
		addBlock(type, '')
	}

	return (
		<div className="info-page-add-blocks">
			<Panel>
				<PanelTitle text="Добавить блок" />
				<div className="info-page-add-blocks_buttons">
					{blocksConfig.map(({ title, icon, type }, i) => (
						<Button
							key={`${title}-${i}`}
							type="s"
							white
							label={title}
							icon={icon}
							onClick={handleClick(type)}
						/>
					))}
				</div>
			</Panel>
		</div>
	)
})
