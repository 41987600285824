import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'

import { logout } from '../../store/reducers/auth/auth'

import { url } from '../../api/api'

import { avatarPlug } from './avatar-functions'
import { getPlugNumber } from '../../functions/common'

import './Avatar.scss'

export const Avatar = React.memo(
	({ inHeader = false, image, id, large, small, withViewer, setViewer }) => {
		const dispatch = useDispatch()

		const [dropdown, setDropdown] = useState(false)

		const plugNumber = id && getPlugNumber(id)

		const imageUrl = image ? `${url}/static/${image}` : avatarPlug(plugNumber)

		const handleAvatarHover = (value) => () => {
			setDropdown(value)
		}

		const handleLogoutClick = () => {
			dispatch(logout())
		}

		return (
			<>
				<div
					className={'avatar'}
					onMouseEnter={inHeader ? handleAvatarHover(true) : () => {}}
					onMouseLeave={inHeader ? handleAvatarHover(false) : () => {}}
				>
					<div
						className={cn('avatar__image', {
							[`plug plug-${plugNumber}`]: !image,
							'with-viewer': image && withViewer,
							large,
							small,
						})}
						onClick={withViewer && image ? setViewer : () => {}}
						style={{ backgroundImage: `url(${imageUrl})` }}
					></div>

					{inHeader && (
						<div className={cn('avatar__dropdown', { show: dropdown })}>
							<ul className="dropdown__list">
								<li className="dropdown__list-item" onClick={handleLogoutClick}>
									<i></i>
									Выйти
								</li>
							</ul>
						</div>
					)}
				</div>
			</>
		)
	}
)
