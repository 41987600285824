import React from 'react'

import { url } from '../../../../api/api'

import { ItemOptions } from '../../CommonComponents/ItemOptions/ItemOptions'

import './NewsItem.scss'

export const NewsItem = React.memo(({ item, toggleModal, deleteItem, showDeleteButton }) => (
	<div className="added-news-item">
		<div className="added-news-item__image">
			<img src={`${url}/static/${item.prev_image}`} alt="" />
		</div>
		<div className="added-news-item__text">
			<span>{item.name}</span>
			<ItemOptions
				showDeleteButton={showDeleteButton}
				toggleModal={toggleModal}
				deleteItem={deleteItem}
			/>
		</div>
	</div>
))
