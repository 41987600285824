import React from 'react'

import './InfoPageTag.scss'

export const InfoPageTag = React.memo(({ text, deleteTag }) => (
	<div className="info-page-tag" onClick={deleteTag}>
		{text}
		<div className="info-page-tag__overlay">
			<i></i>
		</div>
	</div>
))
