import React from 'react'
import { useSelector } from 'react-redux'

import { ContentItemLine as Line } from '../QuestionnaireContentItem/ContentItemLine/ContentItemLine'
import { QuestionnaireContentItem as ContentItem } from '../QuestionnaireContentItem/QuestionnaireContentItem'

import { workType } from '../../../../functions/common'

import { getUserData } from '../../../../store/reducers/user/user-selectors'

export const EmploymentTabContent = React.memo(() => {
	const { user, local } = useSelector(getUserData)

	return (
		<div>
			<ContentItem title="Работа">
				<Line label="Организация" value={user.second_page.comp} />
				<Line label="Должность" value={user.second_page.post} />
				<Line label="Файл резюме" value={user.first_page.resume_file} link />
				<Line label="Ссылка на резюме" value={user.first_page.resume_link} />
				<Line label="Ссылка на Git" value={user.first_page.git_link} />

				<Line
					label="Предложения о работе"
					value={user.second_page.work_open ? 'Принимает' : 'Отклоняет'}
				/>
				<Line label="Мотивация" value={user.second_page.motivation} />
				<Line label="Город" value={local.cities[user.first_page.city]} />
				<Line label="Желаемый режим занятости" value={workType(user.second_page.work_type)} />
			</ContentItem>
			<ContentItem title="Вознаграждение">
				<Line label="Ожидаемая ЗП" value={user.second_page.month_cost} />
				<Line label="Стоимость часа" value={user.second_page.hour_cost} />
			</ContentItem>
			<ContentItem title="Релокация">
				<Line
					label="Страны, в которые готов переехать"
					value={
						user.second_page.countries
							? user.second_page.countries.map((item) => local.countries[item]).join(', ')
							: ''
					}
				/>
				<Line
					label="Города, в которые готов переехать"
					value={
						user.second_page.cities
							? user.second_page.cities.map((item) => local.cities[item]).join(', ')
							: ''
					}
				/>
			</ContentItem>
		</div>
	)
})
