import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import AppContext from '../../context/AppContext'

import { ContentPage } from '../../pages/ContentPage/ContentPage'
import { ProductsPage } from '../../pages/ProductsPage/ProductsPage'
import { OfficesPage } from '../../pages/OfficesPage/OfficesPage'
import { UsersPage } from '../../pages/UsersPage/UsersPage'
import { EventsPage } from '../../pages/EventsPage/EventsPage'
import { QuotasPage } from '../../pages/QuotasPage/QuotasPage'
import { GroupsPage } from '../../pages/GroupsPage/GroupsPage'
import { TransactionsPage } from '../../pages/TransactionsPage/TransactionsPage'
import { NewsPage } from '../../pages/NewsPage/NewsPage'
import { ModalContainer } from '../ModalContainer/ModalContainer'
import { PartnersPage } from '../../pages/PartnersPage/PartnersPage'
import { KnowledgeBasePage } from '../../pages/KnowledgeBasePage/KnowledgeBasePage'
import { CommentsPage } from '../../pages/CommentsPage/CommentsPage'
import { AnaliticsPage } from '../../pages/AnaliticsPage/AnaliticsPage'

export const PagesRouter = () => {
	const [confirmModal, setConfirmModal] = useState(false)
	const [confirmModalContent, setConfirmModalContent] = useState(null)

	const handleConfirmModal = (state, content = null) => () => {
		setConfirmModal(state)
		if (state) {
			setConfirmModalContent(content)
		} else {
			setTimeout(() => {
				setConfirmModalContent(null)
			}, 300)
		}
	}

	return (
		<>
			<AppContext.Provider value={{ setConfirmConfig: handleConfirmModal }}>
				<Switch>
					<Route path="/users/:id?" render={() => <UsersPage />} />
					<Route path="/events/:id?" render={() => <EventsPage />} />
					<Route path="/products/:id?" render={() => <ProductsPage />} />
					<Route path="/offices/:id?" render={() => <OfficesPage />} />
					<Route path="/groups/:id?" render={() => <GroupsPage />} />
					<Route path="/quotas/:id?" render={() => <QuotasPage />} />
					<Route path="/news/:id?" render={() => <NewsPage />} />
					<Route path="/transactions" render={() => <TransactionsPage />} />
					<Route path="/content" render={() => <ContentPage />} />
					<Route path="/partners" render={() => <PartnersPage />} />
					<Route path="/knowledge-base/:id?" render={() => <KnowledgeBasePage />} />
					<Route path="/comments" render={() => <CommentsPage />} />
					<Route path="/analitics" render={() => <AnaliticsPage />} />
					<Redirect from="*" to="/users" />
				</Switch>
			</AppContext.Provider>
			<ModalContainer show={confirmModal} onClose={handleConfirmModal(false)}>
				{confirmModalContent}
			</ModalContainer>
		</>
	)
}
