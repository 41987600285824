import React from 'react'
import { Document } from '@react-pdf/renderer'

import { UserPage } from './UserPage/UserPage.jsx'
import { JobPage } from './JobPage/JobPage.jsx'
import { SkillsPage } from './SkillsPage/SkillsPage.jsx'

import { styles } from '../styles'

import pagesLogo from '../../../assets/media/img/pdf-resume/xcluster.png'

export const TemplatePdf = ({ user }) => {
	return user?.user ? (
		<Document>
			<UserPage styles={styles} user={user} />
			<JobPage styles={styles} user={user} pagesLogo={pagesLogo} />
			<SkillsPage styles={styles} user={user} pagesLogo={pagesLogo} />
		</Document>
	) : (
		<Document>Что-то пошло не так и pdf не сгенерировался, пожалуйста извините...</Document>
	)
}
