import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { useDispatch } from 'react-redux'
import cn from 'classnames'

import { DnDInputFace } from './DnDInputFace/DnDInputFace'
import { PreimageGallery } from './PreimageGallery/PreimageGallery'

import { addNot } from '../../store/reducers/global-nots/global-nots'

import './DragAndDrop.scss'

export const DragAndDrop = React.memo(
	({ photos = [], uploadPhotos = () => {}, deletePhoto = () => {}, fData }) => {
		const dispatch = useDispatch()
		const match = useRouteMatch()
		const id = match.params.id

		const [files, setFiles] = useState([])

		const formData = new FormData()
		const { getRootProps, getInputProps } = useDropzone({
			accept: '.jpeg, .png, .jpg',
			maxSize: 2097152,
			onDropRejected: () =>
				dispatch(addNot({ not: 'Cлишком большой размер фотографии', type: 'error' })),
			onDrop: (acceptedFiles) => {
				setFiles([
					...files,
					...acceptedFiles.map((file) => {
						Object.assign(file, {
							preview: URL.createObjectURL(file),
							localId: Math.random() * Math.random(),
						})
						formData.append('new_photo', file)
						return file
					}),
				])

				if (fData) {
					uploadPhotos(id, formData)
				} else {
					uploadPhotos(formData)
				}
			},
		})

		const deleteFile = (imageId, file) => {
			setFiles(files.filter((file, index) => index !== imageId))

			if (fData) {
				deletePhoto(id, imageId)
			} else {
				deletePhoto(imageId, file)
			}
		}

		useEffect(() => {
			if (photos.length) {
				setFiles(photos)
			}
		}, [photos])

		return (
			<section className={cn('dropzone-container', { empty: !files.length })}>
				<aside className="dropzone__preview">
					<div {...getRootProps({ className: 'dropzone' })}>
						<input {...getInputProps()} />
						{!files.length && <DnDInputFace />}
						{files.length > 19 && (
							<div className="fake-button fake-button--pagination">
								<div className="icon-wrapper">
									<i></i>
								</div>
								<span>Загрузить</span>
							</div>
						)}
					</div>
					<PreimageGallery files={files} deleteFile={deleteFile} id={id} />
				</aside>
			</section>
		)
	}
)
