import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'

import { ColumnsContainer } from '../../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { Column } from '../../../../components/PageWrapper/ColumnsContainer/Column/Column'
import { Panel } from '../../../../components/Panels/Panel/Panel'
import { UserAdminTable } from './UserAdminTable/UserAdminTable'
import { UserItemHeader } from './UserItemHeader/UserItemHeader'
import { QuestionnaireContent } from '../../../../components/QuestionnaireContent/QuestionnaireContent'
import { UserItemInfo } from './UserItemInfo/UserItemInfo'
import { ViewAvatarModal } from '../../../../components/Avatar/ViewAvatarModal/ViewAvatarModal'

import { getUserInfo, setData } from '../../../../store/reducers/user/user'
import { setType as setCertificatesType } from '../../../../store/reducers/certificates/certificates'
import { setType as setAplicationsType } from '../../../../store/reducers/applications/applications'
import { Preloader } from '../../../../components/Preloader/Preloader'

import {
	getUserData,
	getIsLoading,
} from '../../../../store/reducers/user/user-selectors'

import { url } from '../../../../api/api'

import './UserItem.scss'

export const UserItem = React.memo(({ id }) => {
	const dispatch = useDispatch()

	const user = useSelector(getUserData)
	const isLoading = useSelector(getIsLoading)

	// адрес аватара для просмотра в модалке
	const src = !!Object.keys(user).length
		? `${url}/static/${user.user.first_page.image}`
		: ''

	const [showModal, setShowModal] = useState(false)

	const handleModal = (state) => () => {
		setShowModal(state)
	}
	const buttonRef = useRef()

	const [showButtons, setShowButtons] = useState(false)

	const handleClickOutside = useCallback(({ target }) => {
		if (!buttonRef.current || !buttonRef?.current?.contains(target)) {
			setShowButtons(false)
			document.removeEventListener('click', handleClickOutside)
		}
	}, [])

	useEffect(() => {
		dispatch(getUserInfo(id))
		dispatch(setCertificatesType({ type: 1 }))
		dispatch(setAplicationsType({ type: 1 }))
	}, [dispatch, id])

	useEffect(
		() => () => {
			dispatch(setData({ data: {} }))
			dispatch(setCertificatesType({ type: null }))
			dispatch(setAplicationsType({ type: null }))
		},
		[dispatch]
	)

	return (
		<>
			{!!Object.keys(user).length && (
				<div className={cn('user-item', { banned: user.user.isbanned })}>
					<UserItemHeader
						ref={buttonRef}
						id={id}
						name={`${user.user.first_page.name} ${user.user.first_page.surname}`}
						isBanned={user.user.isbanned}
						photo={user.user.first_page.image}
						showButtons={showButtons}
						setShowButtons={setShowButtons}
						clickOutside={handleClickOutside}
					/>
					<ColumnsContainer>
						<Column>
							<UserItemInfo
								id={id}
								image={user.user.first_page.image}
								name={`${user.user.first_page.name} ${user.user.first_page.surname}`}
								recruterImage={user.recruter.image}
								recruterName={`${user.recruter.name} ${user.recruter.surname}`}
								setViewer={handleModal(true)}
							/>
							<Panel>
								<div className="user-item-questionnaire">
									<QuestionnaireContent
										info={user}
										userPage
										isLoading={isLoading}
									/>
								</div>
							</Panel>
						</Column>
						<Column>
							<UserAdminTable
								id={id}
								name={`${user.user.first_page.name} ${user.user.first_page.surname}`}
							/>
						</Column>
					</ColumnsContainer>
					{isLoading && (
						<div className="preloader-container">
							<Preloader />
						</div>
					)}
				</div>
			)}
			{
				// модальное окно для просмотра аватара
			}
			<ViewAvatarModal src={src} show={showModal} close={handleModal(false)} />
		</>
	)
})
