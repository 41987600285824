import React from 'react'

import './ProductItemChars.scss'

export const ProductItemChars = React.memo(({ characteristics, setCharacteristics }) => {
	
	const addChar = () => {
		setCharacteristics([...characteristics, { key: '', value: '' }])
	}

	const editChar = (i, field) => ({ target: { value } }) => {
		const _charsList = characteristics.map((item, index) => {
			if (i === index) {
				return {...item, [field]: value}
			} else {
				return item
			}
		})

		setCharacteristics(_charsList)
	}

	return (
		<div className="product-item__chars">
			<div className="product-item__title">
				<span>Характеристики</span>
			</div>
			<div className="chars__subtitle">
				<span>Название</span>
			</div>
			<div className="chars__subtitle">
				<span>Значение</span>
			</div>
			{characteristics.map((item, i) => {
				return (
					<div key={i} className="product-item__chars-row">
						<input
							className="input-text"
							type="text"
							value={item.key}
							onChange={editChar(i, 'key')}
						/>
						<input
							className="input-text"
							type="text"
							value={item.value}
							onChange={editChar(i, 'value')}
						/>
					</div>
				)
			})}
			<button className="product-item__add-char input-text " onClick={addChar}>
				<span>+ Новая характеристика</span>
			</button>
		</div>
	)
})
