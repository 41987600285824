export const getOffices = ({ offices: { offices } }) => offices

export const getOfficesCount = ({ offices: { officesCount } }) => officesCount

export const getOfficesFilters = ({ offices: { officesFilters } }) => 
officesFilters

export const getIsLoading = ({ offices: { isLoading } }) => isLoading

export const getCities = ({ offices: { cities } }) => cities

export const getOfficeInfo = ({ offices: { officeInfo } }) => officeInfo 