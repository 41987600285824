import React from 'react'

import { Button } from '../../../../components/Buttons/Button/Button'

import deleteIcon from '../../../../assets/media/svg/delete.svg'
import editIcon from '../../../../assets/media/svg/edit.svg'

import './ItemOptions.scss'

export const ItemOptions = React.memo(({ showDeleteButton, toggleModal, deleteItem, absolute }) =>
	absolute ? (
		<div className="item-options-absolute">
			<div className="item-options-absolute__change">
				<Button label="Изменить" white type="md1" onClick={toggleModal} icon={editIcon} />
			</div>
			{showDeleteButton && (
				<div className="item-options-absolute__delete">
					<Button label="Удалить" white type="md1" onClick={deleteItem} icon={deleteIcon} />
				</div>
			)}
		</div>
	) : (
		<div className="item-options">
			<div className="item-options__change" onClick={toggleModal}>
				Изменить
			</div>
			{showDeleteButton && (
				<div className="item-options__delete" onClick={deleteItem}>
					Удалить
				</div>
			)}
		</div>
	)
)
