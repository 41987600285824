import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CommentBlock } from './CommentBlock/CommentBlock'
import { CommentTabs } from './CommentTabs/CommentTabs'
import { ModalContainer } from '../../components/ModalContainer/ModalContainer'
import { RejectComment } from '../../pages/CommentsPage/RejectComment/RejectComment'
import { Preloader } from '../Preloader/Preloader'

import {
	getCommentId,
	getComments,
	getIsLoading,
} from '../../store/reducers/comments/comments-selector'
import {
	requestComments,
	setCommentId,
	setComments,
	setFilters,
} from '../../store/reducers/comments/comments'

import './Comments.scss'

export const Comments = React.memo(({ name }) => {
	const dispatch = useDispatch()

	const [tabs, setTabs] = useState(0)
	const [showModal, setShowModal] = useState(false)

	const commentID = useSelector(getCommentId)
	const allComments = useSelector(getComments)
	const isLoading = useSelector(getIsLoading)

	const commentTabsArray = [{ name: 'Новые' }, { name: 'Просмотренные' }]

	const commentsByName = allComments.filter((comment) => comment.target_name === name)
	const newComemnts = commentsByName.filter((comment) => comment.status === 1)
	const viewedComemnts = commentsByName.filter((comment) => comment.status !== 1)

	const modalHandler = (value) => () => setShowModal(value)

	const setTabsHandler = (value) => () => setTabs(value)

	useEffect(
		() => () => {
			dispatch(setComments({ comments: [] }))
			dispatch(setCommentId({ commentId: '' }))
			dispatch(setFilters({ offset: 0, limit: 10 }))
		},
		[dispatch]
	)

	useEffect(() => {
		dispatch(setFilters({ offset: 0, limit: 0 }))
		dispatch(requestComments())
	}, [dispatch])

	const renderComments = () => {
		const switchObj = {
			0: newComemnts,
			1: viewedComemnts,
		}

		return !!switchObj[tabs].length ? (
			switchObj[tabs].map((comment) => {
				return (
					<CommentBlock
						image={comment.from_image}
						id={comment.from_id}
						comId={comment.id}
						name={comment.from_name}
						time={comment.time_create}
						msg={comment.msg}
						status={comment.status}
						reason={comment.status_msg}
						key={`comment-${comment.id}`}
						showModal={modalHandler(true)}
					/>
				)
			})
		) : (
			<div>Нет комментариев</div>
		)
	}

	return (
		<div className="comments-block">
			{isLoading ? (
				<div className="preloader-container">
					<Preloader />
				</div>
			) : (
				<>
					<div className="comments-block__tabs-button">
						{commentTabsArray.map((tab, index) => (
							<CommentTabs
								name={tab.name}
								active={tabs === index}
								setTabs={setTabsHandler(index)}
								key={`tab-${tab.name}`}
							/>
						))}
					</div>
					<div className="comments-block__body">{renderComments()}</div>
					<ModalContainer show={showModal} onClose={modalHandler(false)}>
						{!!commentID ? (
							<RejectComment
								comments={allComments}
								closeModal={modalHandler(false)}
								commentID={commentID}
							/>
						) : (
							<></>
						)}
					</ModalContainer>
				</>
			)}
		</div>
	)
})
