import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { TableBody } from '../../../../components/TableBody/TableBody'
import { TableHeader } from '../../../../components/TableHeader/TableHeader'
import { TableRow } from '../../../../components/TableBody/TableRows/TableRow/TableRow'

import {
	requestKnowledgeBase,
	setFilters,
} from '../../../../store/reducers/knowledge-base/knowledge-base'

import {
	getFilters,
	getIsLoading,
	getKnowledgeBase,
	getCount,
} from '../../../../store/reducers/knowledge-base/knowledge-base-selectors'

import { changeOffset, refreshTableItems, searchItem } from '../../../../functions/common'

import './KnowledgeBaseTable.scss'

export const KnowledgeBaseTable = React.memo(() => {
	const dispatch = useDispatch()

	const isLoading = useSelector(getIsLoading)
	const knowledgeBase = useSelector(getKnowledgeBase)
	const count = useSelector(getCount)
	const { limit, offset, name } = useSelector(getFilters)

	return (
		<div className="knowledge-base-table">
			<TableHeader
				title="База знаний"
				placeholder="Наименование"
				searchValue={name}
				onSearchChange={searchItem(dispatch, setFilters, 'name')}
				onRefresh={refreshTableItems(dispatch, requestKnowledgeBase)}
			/>

			<TableBody
				contentTitlesArray={[
					{ title: '' },
					{ title: 'Дата' },
					{ title: 'Наименование' },
					{ title: 'Описание' },
					{ title: 'Тип' },
				]}
				additionalClasses={['news']}
				offset={offset}
				limit={limit}
				count={count}
				setOffset={changeOffset(dispatch, setFilters)}
				isLoading={isLoading}
			>
				{knowledgeBase.map((article, index) => (
					<Link to={`knowledge-base/${article.id}`} key={`table-link-${index}`}>
						<div
							className="table-row"
							style={{ animationDelay: `${index * 40}ms` }}
							key={`table-block-${article.id}`}
						>
							<TableRow
								row={{
									image: article.prev_image,
									time_create: article.time_create,
									name: article.name,
									article_descr: article.description,
									article_type: article.type,
								}}
								status={article.status}
								key={`table-row-${index}`}
							/>
						</div>
					</Link>
				))}
			</TableBody>
		</div>
	)
})
