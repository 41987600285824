import React from 'react'

import { Button } from '../../../components/Buttons/Button/Button'
import { ColumnsContainer } from '../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { RightColumn } from '../../../components/PageWrapper/ColumnsContainer/RightColumn/RightColumn'
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { NewsFilters } from './NewsFilters/NewsFilters'
import { NewsTable } from './NewsTable/NewsTable'

import './NewsList.scss'

export const NewsList = React.memo(({ createNewNews }) => (
	<div className="news-list">
		<PageWrapper>
			<ColumnsContainer>
				<LeftColumn>
					<NewsTable />
				</LeftColumn>
				<RightColumn>
					<Button label={'Создать новость'} onClick={createNewNews} />
					<Panel>
						<NewsFilters />
					</Panel>
				</RightColumn>
			</ColumnsContainer>
		</PageWrapper>
	</div>
))
