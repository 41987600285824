import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import cn from 'classnames'

import { addNot } from '../../store/reducers/global-nots/global-nots'

import { url } from '../../api/api'

import noImage from '../../assets/media/svg/nooffice.svg'

import './LoadImage.scss'

export const LoadImage = React.memo(({ image, setImage, accept, market }) => {
	const dispatch = useDispatch()

	const [baseImage, setBaseImage] = useState(null)

	const openFile = useRef()
	const showImage = useRef()
	const match = useRouteMatch()
	const newEvent = match.params.id === 'create'

	const imageUrl = !baseImage && !newEvent ? `${url}/static/${image}` : baseImage

	const loadImage = (e) => {
		const file = e.currentTarget.files[0]

		if (market && file.type !== 'image/png') return false

		if (file.size / 1024 / 1024 > 1.5) {
			dispatch(addNot({ not: 'Cлишком большой размер фотографии', type: 'error' }))
		} else {
			const reader = new FileReader()

			reader.onload = () => {
				showImage.current.style.backgroundImage = `url(${reader.result})`
				setBaseImage(reader.result)
				setImage(reader.result)
			}

			if (file) {
				reader.readAsDataURL(file)
			} else {
				showImage.current.style.backgroundImage = ''
			}
		}
	}

	return (
		<div className="load__image">
			<div
				className={cn('load__image-upload', { image })}
				style={{ backgroundImage: `url(${imageUrl})` }}
				ref={showImage}
			>
				<input
					type="file"
					accept={accept}
					className="hidden__input"
					ref={openFile}
					onChange={(e) => loadImage(e)}
				/>
				<button className="load__image-load" onClick={() => openFile.current.click()}>
					<img src={noImage} alt="load-pic" />
					<span>Загрузить</span>
				</button>
			</div>
		</div>
	)
})
