import instance from './api'

import { getAdminToken } from '../functions/localStorage'

const knowledgeBaseApi = {
	async requestKnowledgeBase(params) {
		const response = await instance.post('admin/search/know', params, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async requestArticleById(id) {
		const response = await instance.post(
			`know/get?know_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async createArticle(data) {
		const response = await instance.post('admin/create/know', data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async updateArticle(data) {
		const response = await instance.post('admin/update/know', data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async uploadGallery(id, data) {
		const response = await instance.post(`know/upload/image?know_id=${id}`, data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
				'Content-Type': 'multipart/form-data',
			},
		})

		return response.data
	},
}

export default knowledgeBaseApi
