export const createProgrammArray = (array = []) => [
	...array,
	{
		time_start: new Date().toISOString(),
		time_end: new Date().toISOString(),
		name: '',
		description: '',
		lecturer: '',
	},
]
