import avatar1 from '../../assets/media/svg/smile-plugs/avatar1.svg'
import avatar2 from '../../assets/media/svg/smile-plugs/avatar2.svg'
import avatar3 from '../../assets/media/svg/smile-plugs/avatar3.svg'
import avatar4 from '../../assets/media/svg/smile-plugs/avatar4.svg'
import avatar5 from '../../assets/media/svg/smile-plugs/avatar5.svg'
import avatar6 from '../../assets/media/svg/smile-plugs/avatar6.svg'
import avatar7 from '../../assets/media/svg/smile-plugs/avatar7.svg'
import avatar8 from '../../assets/media/svg/smile-plugs/avatar8.svg'
import avatar9 from '../../assets/media/svg/smile-plugs/avatar9.svg'
import avatar10 from '../../assets/media/svg/smile-plugs/avatar10.svg'

export const avatarPlug = (id) => {
	const switchObj = {
		0: avatar1,
		1: avatar10,
		2: avatar2,
		3: avatar3,
		4: avatar4,
		5: avatar5,
		6: avatar6,
		7: avatar7,
		8: avatar8,
		9: avatar9,
	}

	if (!!switchObj[id]) return switchObj[id]

	return avatar1
}
