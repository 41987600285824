import React from 'react'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/BreadCrumbs'
import { EditCard } from '../../../../components/EditCard/EditCard'
import { PublishButton } from '../../../../components/Buttons/PublishButton/PublishButton'

import './ProductItemHeader.scss'

export const ProductItemHeader = React.memo(
	({
		id,
		name,
		edit,
		cancel,
		createProduct,
		status,
		approveProduct,
		rejectProduct,
		deleteProduct,
	}) => {
		const crumbs = [
			{ link: '/products', text: 'Товары' },
			{ link: `/products/${id}`, text: `${edit ? name : 'Новый товар'}` },
		]

		return (
			<div className="product-item__page-header">
				<BreadCrumbs items={crumbs} />
				<div className="product-header__buttoms">
					<EditCard
						edit={edit}
						saveType={'submit'}
						deleteIt={'Удалить товар'}
						cancel={'Отменить'}
						save={edit ? 'Сохранить' : 'Добавить'}
						cancelFunc={cancel}
						savedFunc={() => createProduct()}
						deleteFunc={() => deleteProduct()}
					/>
					<PublishButton
						show={edit && status !== 3}
						isPublished={edit && status === 2}
						unpublishFunc={rejectProduct}
						publishFunc={approveProduct}
					/>
				</div>
			</div>
		)
	}
)
