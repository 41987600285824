import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ModalContainer } from '../../../../../../components/ModalContainer/ModalContainer'
import { Preloader } from '../../../../../../components/Preloader/Preloader'
import { YesNoModal } from '../YesNoModal/YesNoModal'

import { addComment, deleteComment, getMessages } from '../../../../../../store/reducers/user/user'

import {
	getIsMessagesLoading,
	getUserMessages,
} from '../../../../../../store/reducers/user/user-selectors'
import { getId } from '../../../../../../store/reducers/profile/profile-selectors'

import './UserAdminNotes.scss'
import { NotesListItem } from './NotesListItem/NotesListItem'
import { NotesAdminInput } from './NotesAdminInput.jsx/NotesAdminInput'

export const UserAdminNotes = React.memo(({ id }) => {
	const [comment, setComment] = useState('')
	const [isModalActive, setIsModalActive] = useState(false)
	const [msgId, setMsgId] = useState('')

	const dispatch = useDispatch()

	const messagesList = useSelector(getUserMessages)
	const adminId = useSelector(getId)
	const isLoading = useSelector(getIsMessagesLoading)

	const handleCommentSend = () => {
		dispatch(addComment({ user_id: id, msg: comment }))
		setComment('')
	}

	const handleDeleteModalOpen = (msg_id) => {
		setMsgId(msg_id)
		setIsModalActive(true)
	}

	const handleCommentDelete = () => {
		dispatch(deleteComment(msgId, id))
		setIsModalActive(false)
		setMsgId('')
	}

	const handleDeleteModalClose = () => {
		setIsModalActive(false)
		setMsgId('')
	}

	useEffect(() => {
		dispatch(getMessages(id))
	}, [dispatch, id])

	return (
		<div className="user-admin-notes">
			<div className="notes-list">
				{messagesList && !!messagesList.length ? (
					messagesList.map((msg, index) => (
						<NotesListItem
							key={index}
							index={index}
							msg={msg}
							handleDeleteModalOpen={handleDeleteModalOpen}
							adminId={adminId}
						/>
					))
				) : (
					<span>Комментарии отсутствуют</span>
				)}
			</div>
			<NotesAdminInput
				comment={comment}
				setComment={setComment}
				handleCommentSend={handleCommentSend}
			/>
			<ModalContainer show={isModalActive} onClose={handleDeleteModalClose}>
				<YesNoModal onClose={handleDeleteModalClose} onClick={handleCommentDelete} />
			</ModalContainer>
			{isLoading && (
				<div className="preloader-container">
					<Preloader />
				</div>
			)}
		</div>
	)
})
