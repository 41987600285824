import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Formik, Form } from 'formik'

import { Button } from '../../../components/Buttons/Button/Button'
import { Input } from '../../../components/Inputs/Input/Input'
import { ModalSearch } from '../../../components/ModalContainer/ModalSearch/ModalSearch'
import { SelectInput } from '../../../components/Inputs/SelectInput/SelectInput'

import {
	addSkillPrice,
	searchSkills,
	setResult,
} from '../../../store/reducers/partners/partners'

import {
	getSearchResult,
	getSkillById,
} from '../../../store/reducers/partners/partners-selectors'

import './PartnersModal.scss'

export const PartnersModal = React.memo(({ id, close }) => {
	const dispatch = useDispatch()

	const searchResult = useSelector(getSearchResult)
	const skillById = useSelector(getSkillById(id))

	const [searchValue, setSearchValue] = useState('')
	const [selectedValue, setSelectedValue] = useState(
		skillById ? skillById.title : ''
	)
	const [selected, setSelected] = useState(
		skillById ? { key: skillById.id_key, name: skillById.title } : null
	)

	const [skillValue, setSkillValue] = useState(skillById ? skillById.skill : 0)

	const handleSelect = (item) => {
		setSelectedValue(item.name)
		setSelected(item)
		dispatch(setResult({ result: [] }))
	}

	const searchRequest = useCallback(
		(value) => {
			dispatch(searchSkills(value))
		},
		[dispatch]
	)

	const selectInputItems = [
		{ title: 'Junior', value: 1 },
		{ title: 'Middle', value: 2 },
		{ title: 'Senior', value: 3 },
	]

	return (
		<Formik
			enableReinitialize
			initialValues={{
				coefficient: skillById ? skillById.point_sell : '',
				cost: skillById ? skillById.point_buy : '',
			}}
			onSubmit={(values, { setValues }) => {
				const { coefficient, cost } = values

				dispatch(
					addSkillPrice({
						id_key: selected.key,
						skill: Number(skillValue),
						point_sell: Number(coefficient),
						point_buy: Number(cost),
					})
				)

				close()
				setSearchValue('')
				setSelectedValue('')
				setSelected(null)
				setSkillValue(0)
				setValues({ coefficient: '', cost: '' })
			}}
		>
			{({ values }) => {
				const { coefficient, cost } = values

				return (
					<div className="partners-modal">
						<div className="partners-modal__title">
							<span>Изменить баллы</span>
						</div>
						<Form>
							<ModalSearch
								inputPlaceholder="Специализация/технология"
								handleSelect={handleSelect}
								searchValue={searchValue}
								setSearchValue={setSearchValue}
								setSelectedValue={setSelectedValue}
								selectedValue={selectedValue}
								searchResult={searchResult}
								setResult={setResult}
								searchRequest={searchRequest}
								disabled={!!skillById}
							/>
							<SelectInput
								label="Уровень владения"
								items={selectInputItems}
								onChange={setSkillValue}
								style={{ marginBottom: '10px' }}
								initialValue={
									selectInputItems.find((item) => item.value === skillValue)
										?.title
								}
							/>
							<Field
								name="coefficient"
								type="number"
								label={'Коэффициент партнера'}
								as={Input}
							/>
							<Field
								name="cost"
								type="number"
								label={'Стоимость просмотра'}
								as={Input}
							/>
							<div className="partners-modal__buttons">
								<Button label={'Отменить'} type="md1" onClick={close} white />
								<Button
									nType="submit"
									label={'Подтвердить'}
									type="md2"
									disabled={!skillValue || !coefficient || !cost || !selected}
								/>
							</div>
						</Form>
					</div>
				)
			}}
		</Formik>
	)
})
