import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DatePeriod } from '../../../components/DatePeriod/DatePeriod'
import { Filters } from '../../../components/Filters/Filters'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { FiltersHeader } from '../../../components/FiltersHeader/FiltersHeader'

import { getFilters } from '../../../store/reducers/users/users-selectors'
import { getLocal } from '../../../store/reducers/app/app-selectors'
import { getGroups } from '../../../store/reducers/groups/groups-selectors'

import { setFilters } from '../../../store/reducers/users/users'
import { requestGroups } from '../../../store/reducers/groups/groups'

import 'react-datepicker/dist/react-datepicker.css'
import './UsersFilters.scss'

export const UsersFilters = React.memo(() => {
	const dispatch = useDispatch()

	const local = useSelector(getLocal)
	const filters = useSelector(getFilters)
	const groups = useSelector(getGroups)

	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)

	const {
		specializations,
		skills,
		status,
		group_id,
		role,
		is_banned,
		is_work_open,
		is_group,
	} = filters

	//фильтр технологий
	const skillsList = local.techs ? local.techs : []

	const handleSkillsFilter = (value) => () => {
		dispatch(
			setFilters({
				skills: skills.includes(value)
					? skills.filter((item) => item !== value)
					: [...skills, value],
				offset: 0,
			})
		)
	}

	const activeSkills = (value) => {
		return skills.includes(value) ? true : false
	}

	//фильтр специализаций
	const specList = local.specializations
		? local.specializations.map((item) => [item.key, item.value])
		: []

	const handleSpecFilter = (value) => () => {
		dispatch(
			setFilters({
				specializations: specializations.includes(value)
					? specializations.filter((item) => item !== value)
					: [...specializations, value],
				offset: 0,
			})
		)
	}

	const specFilter = [
		{
			title: 'Специализации',
			items: specList,
			handler: handleSpecFilter,
		},
	]

	const activeSpec = (value) => {
		return specializations.includes(value) ? true : false
	}

	//фильтр статуса анкеты
	const handlerStatusFilter = (value) => () => {
		dispatch(setFilters({ status: status === value ? 0 : value, offset: 0 }))
	}

	//Фильтр "открыт к предложениям по работе"\
	const handleJobFilter = (value) => () => {
		dispatch(setFilters({ is_work_open: is_work_open === value ? 0 : value, offset: 0 }))
	}

	//фильтр групп
	const handleGroupFilter = (value, isGroup) => () => {
		dispatch(
			setFilters({
				group_id: group_id === value ? '' : value,
				is_group: is_group === isGroup && group_id === value ? 0 : isGroup,
				offset: 0,
			})
		)
	}

	const groupList = groups.map((item) => ({
		title: item.name,
		handler: handleGroupFilter(item.id, 1),
		active: group_id === item.id,
	}))

	//фильтр роли
	const handlerRoleFilter = (value) => () => {
		dispatch(setFilters({ role: role === value ? 0 : value, offset: 0 }))
	}

	//фильтр блокировки
	const handlerBannedFilter = (value) => () => {
		dispatch(setFilters({ is_banned: is_banned === value ? 0 : value, offset: 0 }))
	}

	// фильтр даты регистрации

	const handleDatePeriod = (date, period) => {
		if (period === 'start') {
			return dispatch(setFilters({ time_register_start: date, offset: 0 }))
		} else if (period === 'end') {
			return dispatch(setFilters({ time_register_end: date, offset: 0 }))
		}
	}

	//сброс фильтров
	const handleResetFilters = () => {
		dispatch(
			setFilters({
				name: '',
				role: 0,
				status: 0,
				sort: '',
				skills: [],
				specializations: [],
				is_work_open: 0,
				group_id: '',
				is_group: 0,
				is_banned: 0,
				offset: 0,
				time_register_start: null,
				time_register_end: null,
			})
		)
	}

	const FILTER = {
		filters: [
			{
				title: 'Статус анкеты',
				items: [
					{
						title: 'Без анкеты',
						active: status === 4,
						handler: handlerStatusFilter(4),
					},
					{
						title: 'Модерация',
						active: status === 1,
						handler: handlerStatusFilter(1),
					},
					{
						title: 'Одобрена',
						active: status === 2,
						handler: handlerStatusFilter(2),
					},
					{
						title: 'Отклонена',
						active: status === 3,
						handler: handlerStatusFilter(3),
					},
				],
			},
			{
				title: 'Группы',
				items: groupList.concat([
					{
						title: 'Без группы',
						active: is_group === 2,
						handler: handleGroupFilter('', 2),
					},
				]),
			},
			{
				title: 'Роль',
				items: [
					{
						title: 'Администратор',
						handler: handlerRoleFilter(2),
						active: role === 2,
					},
					{
						title: 'Партнёр',
						handler: handlerRoleFilter(3),
						active: role === 3,
					},
					{
						title: 'Рекрутер',
						handler: handlerRoleFilter(4),
						active: role === 4,
					},
					{
						title: 'Пользователь',
						handler: handlerRoleFilter(5),
						active: role === 5,
					},
				],
			},
			{
				title: 'Блокировка',
				items: [
					{
						title: 'Активный',
						handler: handlerBannedFilter(1),
						active: is_banned === 1,
					},
					{
						title: 'Заблокированный',
						handler: handlerBannedFilter(2),
						active: is_banned === 2,
					},
				],
			},
			{
				title: 'Открыт к предложениям по работе',
				items: [
					{
						title: 'Да',
						handler: handleJobFilter(1),
						active: is_work_open === 1,
					},
					{
						title: 'Нет',
						handler: handleJobFilter(2),
						active: is_work_open === 2,
					},
				],
			},
		],
	}

	useEffect(() => {
		dispatch(requestGroups())
	}, [dispatch])

	return (
		<div className="users-filters">
			<Panel>
				<FiltersHeader reset={handleResetFilters} />
				<div className="users-filters-wrapper">
					<Filters filter={FILTER.filters} />
					<div className="users-period">
						<div className="filter__title">Дата регистрации</div>
						<DatePeriod
							dateStart={!startDate ? new Date() : startDate}
							setDateStart={setStartDate}
							dateEnd={!endDate ? new Date() : endDate}
							setDateEnd={setEndDate}
							handler={handleDatePeriod}
							users
						/>
					</div>
					<Filters checkbox filter={specFilter} isActive={activeSpec} />
					<Filters
						subtitles
						filter={skillsList}
						title="Технологии"
						handler={handleSkillsFilter}
						isActive={activeSkills}
					/>
				</div>
			</Panel>
		</div>
	)
})
