import React, { useState } from 'react'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { url } from '../../../../api/api'

import { Button } from '../../../Buttons/Button/Button'
import { LightButton } from '../../../Buttons/LightButton/LightButton'
import { DeleteButton } from '../../../Buttons/DeleteButton/DeleteButton'
import { RejectModal } from './RejectModal/RejectModal'
import { ModalContainer } from '../../../ModalContainer/ModalContainer'

import { approveApplication } from '../../../../store/reducers/applications/applications'

import './TableRequestRow.scss'

export const TableRequestRow = React.memo(
	({
		name,
		image,
		phone,
		classList = [],
		openModal,
		deleteIt,
		icon,
		removeItem,
		appId,
		id,
		status,
		userPage,
		type,
		productId,
	}) => {
		const dispatch = useDispatch()

		const [openRejectModal, setOpenRejectModal] = useState(false)
		const [rejectMessage, setRejectMessage] = useState('')

		const closeModal = () => setOpenRejectModal(false)

		return (
			<>
				<div className={cn('table-request-row', ...classList)}>
					<div className="table-request-row__image">
						{image ? <img src={`${url}/static/${image}`} alt="" /> : <div className="image-plug" />}
					</div>
					{userPage ? (
						<div className="table-request-row__product-info">
							<div className="table-request-row__name">{name}</div>
							{type === 1 && <Link to={`/offices/${productId}`}>На страницу офиса</Link>}
							{type === 2 && <Link to={`/events/${productId}`}>На страницу события</Link>}
							{type === 3 && <Link to={`/products/${productId}`}>На страницу товара</Link>}
						</div>
					) : (
						<div className="table-request-row__name">{name}</div>
					)}
					<div className="table-request-row__phone">{phone}</div>
					<div className="table-request-row__questionnaire-button">
						<LightButton label="Анкета" onClick={openModal} />
					</div>
					{status === 1 && (
						<>
							<div className="table-request-row__reject-button">
								<Button red type="md1" label="Отклонить" onClick={() => setOpenRejectModal(true)} />
							</div>
							<div className="table-request-row__accept-button">
								<Button
									green
									type="md1"
									label="Одобрить"
									onClick={() => dispatch(approveApplication(id, appId))}
								/>
							</div>
						</>
					)}

					{status === 2 && <span className="approve">Заявка подтверждена</span>}
					{status === 3 && <span className="reject">Заявка отклонена</span>}
				</div>
				{deleteIt && <DeleteButton icon={icon} onClick={removeItem} type="button" />}
				<ModalContainer show={openRejectModal} onClose={closeModal}>
					<RejectModal
						message={rejectMessage}
						setMessage={setRejectMessage}
						closeModal={closeModal}
						appId={appId}
						id={id}
					/>
				</ModalContainer>
			</>
		)
	}
)
