import React from 'react'
import { useDispatch } from 'react-redux'
import { rejectApplication } from '../../../../../store/reducers/applications/applications'
import { Button } from '../../../../Buttons/Button/Button'

import { TextAreaField } from '../../../../TextAreaField/TextAreaField'

import './RejectModal.scss'

export const RejectModal = React.memo(({ message, setMessage, closeModal, appId, id }) => {
	const dispatch = useDispatch()

	const rejectFunc = () => {
		dispatch(rejectApplication(id, appId, message))
		closeModal()
	}

	return (
		<div className="reject-modal-container">
			<h2>Причина отказа</h2>
			<TextAreaField
				label="Причина отказа"
				value={message}
				onChange={({ target: { value } }) => setMessage(value)}
			/>
			<div className="buttons-block">
				<Button white type="md1" label="Закрыть" onClick={closeModal} />
				<Button red type="md1" label="Отклонить" onClick={rejectFunc} />
			</div>
		</div>
	)
})
