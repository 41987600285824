export const getIsLoading = ({ comments: { isLoading } }) => isLoading

export const getComments = ({ comments: { comments } }) => comments

export const getCount = ({ comments: { count } }) => count

export const getFilters = ({ comments: { filters } }) => filters

export const getisLoading = ({ comments: { isLoading } }) => isLoading

export const getCommentId = ({ comments: { commentId } }) => commentId
