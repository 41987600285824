import React, { useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { url } from '../../../api/api'

import { BreadCrumbs } from '../../../components/BreadCrumbs/BreadCrumbs'
import { EditCard } from '../../../components/EditCard/EditCard'
import { Column } from '../../../components/PageWrapper/ColumnsContainer/Column/Column'
import { LeftColumn } from '../../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { EventTable } from './EventTable/EventTable'
import { EventProgramm } from './EventProgramm/EventProgramm'
import { ColumnsContainer } from '../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { EventInfo } from './EventInfo/EventInfo'
import { PublishButton } from '../../../components/Buttons/PublishButton/PublishButton'
import { BackToList } from '../../../components/Buttons/BackToList/BackToList'
import { ShowButtonsBlock } from '../../../components/Buttons/ShowButtonsBlock/ShowButtonsBlock'
import { ButtonsPanelBlock } from '../../../components/Buttons/ShowButtonsBlock/ButtonsPanelBlock/ButtonsPanelBlock'

import {
	approveEvent,
	createEvent,
	deleteEvent,
	rejectEvent,
	setEvent,
	updateEvent,
} from '../../../store/reducers/events/events'

import { onError } from '../../../store/reducers/functions'

import { setType as setCertificatesType } from '../../../store/reducers/certificates/certificates'
import { setType as setAplicationsType } from '../../../store/reducers/applications/applications'
import { setType as setQuotasType } from '../../../store/reducers/quotas/quotas'
import { getDevice } from '../../../store/reducers/app/app-selectors'

import { createProgrammArray } from '../functions'
import EventContext from '../../../context/EventContext'

import { actions } from './actions'

import './EventPage.scss'

export const EventPage = React.memo(
	React.forwardRef(
		({ id, edit, cancel, setCreate, event, clickOutside, setShowButtons, showButtons }, ref) => {
			if (!event && edit) return null

			const { setErrors, setProgramms } = actions

			const history = useHistory()
			const dispatch = useDispatch()

			const device = useSelector(getDevice)

			const formatDate = (date) => {
				const newDate = new Date(date)

				return new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 0, 0, 0)
			}

			const formatStreamDate = (startDate, streamDate) =>
				new Date(
					startDate.getFullYear(),
					startDate.getMonth(),
					startDate.getDate(),
					streamDate.getHours(),
					streamDate.getMinutes()
				)

			/* local state */

			const initialState = {
				image: !!event ? event.image : null,
				dateStart: !!event ? new Date(event.time_start) : new Date(),
				dateEnd: !!event ? new Date(event.time_end) : new Date(),
				type: !!event ? event.type : 1,
				programms: !!event ? event.programms : {},
				city: !!event ? event.city : '',
				address: !!event ? event.loc_addr : '',
				addressInfo: !!event ? event.loc_desc : '',
				name: !!event ? event.name : '',
				description: !!event ? event.description : '',
				isStream: !!event ? event.is_stream : false,
				streamLink: !!event ? event.stream_link : '',
				streamStartTime: !!event ? new Date(event.stream_start) : new Date(),
				streamEndTime: !!event ? new Date(event.stream_end) : new Date(),
				xCoord: !!event ? event.loc_x : 0,
				yCoord: !!event ? event.loc_y : 0,
				errors: {},
				password: !!event ? event.password : '',
				preview: !!event ? `${url}/static/${event.prev_image}` : '',
			}

			/* local state */

			const reducers = (state, action) => {
				const switchObj = {
					image: { ...state, image: action.payload },
					'date-start': { ...state, dateStart: action.payload },
					'date-end': { ...state, dateEnd: action.payload },
					type: { ...state, type: action.payload },
					programms: { ...state, programms: action.payload },
					city: { ...state, city: action.payload },
					address: { ...state, address: action.payload },
					'address-info': { ...state, addressInfo: action.payload },
					name: { ...state, name: action.payload },
					description: { ...state, description: action.payload },
					'is-stream': { ...state, isStream: action.payload },
					'stream-link': { ...state, streamLink: action.payload },
					'stream-start-time': { ...state, streamStartTime: action.payload },
					'stream-end-time': { ...state, streamEndTime: action.payload },
					'x-coord': { ...state, xCoord: action.payload },
					'y-coord': { ...state, yCoord: action.payload },
					errors: { ...state, errors: action.payload },
					password: { ...state, password: action.payload },
					preview: { ...state, preview: action.payload },
				}

				return switchObj[action.type]
			}

			const [state, localDispatch] = useReducer(reducers, initialState)

			/* functions */

			const checkEmptyBlockInProgramms = () => {
				let flag = false

				// Получаем список всех программ по дням
				const allProgrammsBlock = Object.values(state.programms)

				allProgrammsBlock.map((dayBlock) => {
					// Получаем список программ в конкретном дне
					const dayProgrammValues = Object.values(dayBlock)

					dayProgrammValues.map((programmBlock) => {
						// Копируем весь объект и удаляем поля, которое не обязательно для заполнения
						const objWithImportField = { ...programmBlock }

						delete objWithImportField.lecturer
						delete objWithImportField.description

						// Получаем список полей
						const programmBlockValues = Object.values(objWithImportField)

						// Проверка поля на пустоту
						programmBlockValues.map((values) => {
							if (!values) {
								flag = true
								programmBlock.errors = true
							} else {
								programmBlock.errors && delete programmBlock.errors
							}

							return ''
						})

						return ''
					})
					return ''
				})

				return flag
			}

			// Создание или обновление события

			const handleEvent = async () => {
				const fullEvent = {
					name: state.name,
					image: state.image,
					type: state.type,
					description: state.description,
					time_start: formatDate(state.dateStart),
					time_end: formatDate(state.dateEnd),
					loc_addr: state.address,
					loc_desc: state.addressInfo,
					loc_x: state.xCoord,
					loc_y: state.yCoord,
					city: state.city,
					programms: state.programms,
					password: state.password,
					prev_image: state.preview,
					is_stream: state.isStream,
				}

				if (state.isStream) {
					fullEvent.stream_start = formatStreamDate(state.dateStart, state.streamStartTime)

					fullEvent.stream_end = formatStreamDate(state.dateStart, state.streamEndTime)

					fullEvent.stream_link = state.streamLink
				}

				if (!state.name || !state.description || !state.password || !state.preview) {
					onError(dispatch, { code: 0, msg: 'Не заполнены обязательные поля' })
					localDispatch(
						setErrors({
							...state.errors,
							name: state.name.length ? false : true,
							description: state.description.length ? false : true,
							password: state.password.length ? false : true,
							preview: state.preview ? false : true,
						})
					)

					return
				} else if (checkEmptyBlockInProgramms()) {
					onError(dispatch, {
						code: 0,
						msg: 'Заполните обязательные поля в программах',
					})
					localDispatch(
						setErrors({
							...state.errors,
							programms: Object.keys(state.programms).length ? false : true,
						})
					)
					return
				} else {
					if (edit) {
						fullEvent.id = id
						dispatch(updateEvent(fullEvent))
					} else {
						dispatch(createEvent(fullEvent))
					}
				}

				history.push('/events')
			}

			const changeStatusEvent = (id, func) => () => {
				dispatch(func(id))

				history.push('/events')
			}

			const publishEvent = async () => {
				await handleEvent()
				changeStatusEvent(id, approveEvent)()
			}

			const handleItemClick = () => setShowButtons(true)

			// Создание первичного блока и списка табуляции для программ события
			useEffect(() => {
				if (!event) {
					const start = formatDate(+state.dateStart).toISOString()

					let dateObject = {
						[start]: createProgrammArray(),
					}

					if (+state.dateStart === +state.dateEnd) {
						localDispatch(setProgramms(dateObject))
					} else {
						let newDate = +state.dateStart

						while (newDate < +state.dateEnd) {
							newDate += 86400000

							const date = formatDate(newDate).toISOString()

							dateObject[date] = createProgrammArray()
						}

						localDispatch(setProgramms(dateObject))
					}
				} else {
					// Получаем последнюю метку времени в массиве программ
					const programmsDay = Object.keys(event.programms)

					const start = new Date(programmsDay[0])
					const end = new Date(programmsDay[programmsDay.length - 1])

					let dateObject = {}

					if (
						+new Date(start) !== +new Date(state.dateStart) ||
						+new Date(end) !== +new Date(state.dateEnd)
					) {
						let newDate = +state.dateStart

						while (newDate <= +state.dateEnd) {
							const date = formatDate(newDate).toISOString()

							if (event.programms[date]) {
								dateObject[date] = event.programms[date]
							} else {
								dateObject[date] = createProgrammArray()
							}

							newDate += 86400000
						}

						localDispatch(setProgramms(dateObject))
					} else {
						localDispatch(setProgramms(event.programms))
					}
				}
			}, [state.dateStart, state.dateEnd, event, localDispatch, setProgramms])

			useEffect(() => {
				const ProgrammsDay = Object.keys(state.programms)

				const start = new Date(ProgrammsDay[ProgrammsDay.length - 1])

				if (+start > +state.dateEnd) {
					// Получаем массив в котором будут лежать программы: [день: [программы]]
					const rebuildProgrammObjectToArray = Object.entries(state.programms)

					// Фильтруем, исключая все элементы у которых день больше чем день конца
					const sortedArray = rebuildProgrammObjectToArray.filter(
						(item) => +(new Date(item[0]) <= +state.dateEnd)
					)

					// Подготавливаем новый объект с программами
					let newProgrammObject = {}

					// Добавляем значения
					sortedArray.forEach((item) => (newProgrammObject[item[0]] = [...item[1]]))

					localDispatch(setProgramms(newProgrammObject))

					return
				}
			}, [state.programms, state.dateEnd, setProgramms])

			useEffect(() => {
				dispatch(setCertificatesType({ type: 2 }))
				dispatch(setAplicationsType({ type: 2 }))
				dispatch(setQuotasType({ type: 2 }))
			}, [dispatch, id])

			useEffect(() => {
				if (showButtons) {
					document.addEventListener('click', clickOutside)
				} else {
					document.removeEventListener('click', clickOutside)
				}
			}, [clickOutside, showButtons, setShowButtons])

			useEffect(
				() => () => {
					if (!edit) {
						setCreate(false)
					}
				},
				[edit, setCreate]
			)

			useEffect(
				() => () => {
					document.removeEventListener('click', clickOutside)
				},
				[clickOutside]
			)

			useEffect(
				() => () => {
					dispatch(setEvent({ event: null }))
					dispatch(setCertificatesType({ type: null }))
					dispatch(setAplicationsType({ type: null }))
					dispatch(setQuotasType({ type: null }))
				},
				[dispatch]
			)
			/* functions */

			const crumbs = [
				{ link: '/events', text: 'События' },
				{ text: edit ? event.name : 'Новое событие' },
			]

			return (
				<div className="event-page page">
					<PageWrapper>
						<div className="event-page__header">
							{device === 'mobile' || device === 'notepad' ? (
								<>
									<BackToList text="К событиям" onClick={cancel} />
									<ShowButtonsBlock
										text="Управление событием"
										ref={ref}
										onClick={handleItemClick}
									/>
									<ButtonsPanelBlock show={showButtons}>
										<EditCard
											edit={edit}
											status={edit && event.status}
											deleteIt={'Удалить событие'}
											cancel={'Отменить'}
											save={'Сохранить'}
											deleteFunc={changeStatusEvent(id, deleteEvent)}
											cancelFunc={cancel}
											savedFunc={handleEvent}
										/>
										<PublishButton
											show={edit && event.status !== 4}
											isPublished={edit && event.status === 2}
											unpublishFunc={changeStatusEvent(id, rejectEvent)}
											publishFunc={publishEvent}
										/>
									</ButtonsPanelBlock>
								</>
							) : (
								<>
									<BreadCrumbs items={crumbs} />

									<div className="button-block">
										<EditCard
											edit={edit}
											status={edit && event.status}
											deleteIt={'Удалить событие'}
											cancel={'Отменить'}
											save={'Сохранить'}
											deleteFunc={changeStatusEvent(id, deleteEvent)}
											cancelFunc={cancel}
											savedFunc={handleEvent}
										/>
										<PublishButton
											show={edit && event.status !== 4}
											isPublished={edit && event.status === 2}
											unpublishFunc={changeStatusEvent(id, rejectEvent)}
											publishFunc={publishEvent}
										/>
									</div>
								</>
							)}
						</div>
					</PageWrapper>
					<PageWrapper>
						<ColumnsContainer>
							<div className="event-page__body">
								<EventContext.Provider value={{ state: state, localDispatch: localDispatch }}>
									<LeftColumn>
										<Column>
											<Panel>
												<EventInfo
													past={edit && event.status === 3}
													deleteEvent={edit && event.status === 4}
													fillPassword={edit && event.password}
												/>
											</Panel>
										</Column>
										<Column>
											<Panel>
												<EventProgramm
													past={edit && event.status === 3}
													deleteEvent={edit && event.status === 4}
												/>
											</Panel>
										</Column>
									</LeftColumn>
								</EventContext.Provider>
								<Column>
									{edit && (
										<>
											<EventTable past={edit && event.status === 3} status={edit && event.status} />
										</>
									)}
								</Column>
							</div>
						</ColumnsContainer>
					</PageWrapper>
				</div>
			)
		}
	)
)
