import React, { useState, useEffect, useRef } from 'react'
import { GoogleMap, useJsApiLoader, Marker, StandaloneSearchBox } from '@react-google-maps/api'

import { PopupAddress } from './PopupAddress/PopupAddress'
import { Button } from '../../../../../../components/Buttons/Button/Button'
import { CancelButton } from '../../../../../../components/Buttons/CancelButton/CancelButton'

import mapicon from '../../../../../../assets/media/svg/mapicon.svg'
import './PopupMap.scss'

// стили для карты
const containerStyle = {
	width: '100%',
	height: '100%',
	maxHeight: '650px',
}

const placeholderStyle = {
	boxSizing: `border-box`,
	border: `1px solid transparent`,
	width: `300px`,
	height: `40px`,
	padding: `0 12px`,
	borderRadius: `3px`,
	boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
	fontSize: `14px`,
	outline: `none`,
	textOverflow: `ellipses`,
	position: 'absolute',
	left: '50%',
	transform: 'translateX(-50%)',
	marginTop: '10px',
}

export const PopupMap = React.memo(
	({
		marker,
		setMarker,
		setOpenMap,
		setAddress,
		xCoord,
		setXCoord = () => {},
		yCoord,
		setYCoord = () => {},
		address,
		setCity = () => {},
		city,
	}) => {
		const searchBox = useRef()
		const searchInput = useRef()

		// внешние данные
		const [coordinates, setCoordinates] = useState(marker)
		const [location, setLocation] = useState(city)
		const [locationAddress, setLocationAddress] = useState(address.split(','))
		const [libraries] = useState(['places'])

		// загрузка карты
		const { isLoaded, loadError } = useJsApiLoader({
			id: 'google-map-script',
			googleMapsApiKey: 'AIzaSyCz4vIOvF0QQjrI3IVQ8p1nJMDvF0XVFOE',
			libraries,
			language: 'ru',
			region: 'RU',
		})
		// получение координат с карты
		// const addNewCoord = (lat, lng) => {
		// 	setCoordinates({ lat: lat, lng: lng })
		// }

		const onLoad = (ref) => (searchBox.current = ref)

		const onPlacesChanged = () => {
			return searchBox.current.getPlaces().map((item) => {
				const locationItem = item.formatted_address.split(',')
				setLocation(locationItem[2])
				setLocationAddress(`${locationItem[0]}, ${locationItem[1]}`)
				setCoordinates({ lat: item.geometry.location.lat(), lng: item.geometry.location.lng() })
				return null
			})
		}

		useEffect(() => {
			setMarker(coordinates)
		}, [coordinates, setMarker])

		// отменить изменения
		const cancelMap = () => {
			setMarker({ lat: xCoord, lng: yCoord })
			setXCoord(xCoord)
			setYCoord(yCoord)
			setAddress(address)
			setCoordinates({ lat: xCoord, lng: yCoord })
			setLocation(`${address.split(',')[0]}`)
			setLocationAddress(
				`${address.split(',')[1] ? address.split(',')[1] : ''}, ${
					address.split(',')[2] ? address.split(',')[2] : ''
				}`
			)
			setCity(city)
			setOpenMap(false)
			searchInput.current.value = ''
		}

		// сохранить изменения
		const approveMap = () => {
			setMarker(coordinates)
			setXCoord(coordinates.lat)
			setYCoord(coordinates.lng)
			if (location || locationAddress) setAddress(`${location}, ${locationAddress}`)
			setCity(location)
			setOpenMap(false)
			searchInput.current.value = ''
		}

		return (
			<div className="popup__map">
				<div className="popup__map-item">
					{isLoaded ? (
						<GoogleMap
							mapContainerStyle={containerStyle}
							center={coordinates}
							zoom={16}
							// options={{
							// 	disableDefaultUI: true,
							// }}
							// onRightClick={({ latLng: { lat, lng } }) => addNewCoord(lat(), lng())}
						>
							<StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
								<input type="text" placeholder="Поиск" style={placeholderStyle} ref={searchInput} />
							</StandaloneSearchBox>
							<Marker
								position={coordinates}
								icon={{
									url: mapicon,
									scaledSize: new window.google.maps.Size(35, 55),
								}}
								// draggable
								// onDragEnd={({ latLng: { lat, lng } }) => addNewCoord(lat(), lng())}
							/>
						</GoogleMap>
					) : (
						loadError && console.log(loadError)
					)}
				</div>
				<div className="popup__map-description">
					<div className="popup__map-box">
						<PopupAddress name={'Город'} text={location} />
						<PopupAddress
							name={'Адрес'}
							text={
								locationAddress[0] !== location
									? locationAddress
									: locationAddress.length > 2
									? `${locationAddress[1]}, ${locationAddress[2]}`
									: locationAddress[1]
							}
						/>
					</div>
					<div className="popup__map-buttons">
						<CancelButton closeIt={() => cancelMap()} />
						<Button label={'Сохранить'} onClick={() => approveMap()} type={'md1'} />
					</div>
				</div>
			</div>
		)
	}
)
