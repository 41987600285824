import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'

import { Input } from '../../../components/Inputs/Input/Input'
import { Button } from '../../../components/Buttons/Button/Button'

import { setPartnersInfo } from '../../../store/reducers/partners/partners'

import { getMainValues } from '../../../store/reducers/partners/partners-selectors'

import './MainValues.scss'

export const MainValues = React.memo(() => {
	const dispatch = useDispatch()

	const mainValues = useSelector(getMainValues)
	const { def_sell: defSell, def_buy: defBuy, material } = mainValues

	return (
		<div className="partners-main-values">
			<Formik
				enableReinitialize
				initialValues={{
					coefficient: defSell || '',
					cost: defBuy || '',
					materials: material || '',
				}}
				onSubmit={(values, { setSubmitting }) => {
					setSubmitting(true)

					const { coefficient, cost, materials } = values

					dispatch(
						setPartnersInfo({
							def_sell: Number(coefficient),
							def_buy: Number(cost),
							material: materials,
						})
					)

					setSubmitting(false)
				}}
			>
				{({ values, isSubmitting }) => {
					const { coefficient, cost, materials } = values

					return (
						<Form>
							<div className="main-values__form-wrapper">
								<Field
									name="coefficient"
									label="Базовый коэффициент партнера"
									type="number"
									as={Input}
								/>
								<Field
									name="cost"
									label="Базовая стоимость просмотра профилей"
									type="number"
									as={Input}
								/>
								<Field name="materials" label="Материалы" as={Input} />
								<Button
									nType="submit"
									label="Сохранить"
									type="md2"
									disabled={isSubmitting || !coefficient || !cost || !materials}
								/>
							</div>
						</Form>
					)
				}}
			</Formik>
		</div>
	)
})
