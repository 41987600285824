import React from 'react'

export const AnaliticsInfo = ({ fromLink, refLink }) => (
	<div className="analitics">
		<h3>Информация для аналитики</h3>
		<div className="analitics__ref">
			Откуда пользователь пришел: <b>{fromLink || 'Нет данных'}</b>
		</div>
		<div className="analitics__points">
			Метки: <b>{refLink || 'Нет данных'}</b>
		</div>
	</div>
)
