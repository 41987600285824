import { createSlice } from '@reduxjs/toolkit'

import contentApi from '../../../api/content-api'

import { onError } from '../functions'
import { addNot } from '../global-nots/global-nots'

const initialState = {
	mainNews: {
		text: '',
		url: '',
		active: false,
	},
	searchList: [],
	filters: {
		limit: 10,
		offset: 0,
	},
	actual: [null, null, null],
	news: [null, null, null, null],
	popular: [null, null, null],
	offices: [null, null],
	links: {
		vk: '',
		fb: '',
		yt: '',
		ig: '',
		ld: '',
	},

	isLoading: false,
}

const content = createSlice({
	name: 'content',
	initialState,
	reducers: {
		setMainNews: (state, action) => {
			state.mainNews = { ...state.mainNews, ...action.payload }
		},

		setSearchList: (state, action) => {
			state.searchList = action.payload.searchList
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setActual: (state, action) => {
			state.actual = !!action.payload.actual.length ? action.payload.actual : [null, null, null]
		},

		setNews: (state, action) => {
			state.news = !!action.payload.news.length ? action.payload.news : [null, null, null, null]
		},

		setPopular: (state, action) => {
			state.popular = !!action.payload.popular.length ? action.payload.popular : [null, null, null]
		},

		setOffices: (state, action) => {
			state.offices = !!action.payload.offices.length ? action.payload.offices : [null, null]
		},

		setLinks: (state, action) => {
			state.links = { ...state.links, ...action.payload }
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
	},
})

const { reducer, actions } = content

export const {
	setMainNews,
	setFilters,
	setSearchList,
	setActual,
	setNews,
	setPopular,
	setOffices,
	setLinks,
	setIsLoading,
} = actions

export const searchNewsItem = (value) => async (dispatch, getState) => {
	const {
		content: { filters },
	} = getState()

	const res = await contentApi.searchNewsValue({ value, ...filters })

	if (res.success) {
		dispatch(setSearchList({ searchList: res.result }))
	}
}

export const requestContentPage = () => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await contentApi.requestContentPage()

	if (res.success) {
		dispatch(
			setMainNews({
				text: res.result.text,
				url: res.result.header_url,
				active: res.result.active_header,
			})
		)

		dispatch(
			setLinks({
				vk: res.result.vk,
				fb: res.result.fb,
				yt: res.result.yt,
				ig: res.result.ig,
				ld: res.result.ld,
			})
		)

		dispatch(setOffices({ offices: res.result.actual_offices }))

		dispatch(setPopular({ popular: res.result.actual_products }))

		dispatch(setNews({ news: res.result.actual_news }))

		dispatch(setActual({ actual: res.result.actual_board }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

export const updateContentPage = () => async (dispatch, getState) => {
	const {
		content: { mainNews, actual, news, popular, offices, links },
	} = getState()

	const saveObj = {
		active_header: mainNews.active,
		actual_board: actual.map((item) =>
			item
				? {
						id_req: item.id,
						label: item.label,
						type: item.type_actual,
						back_color: item.back_color,
						is_hide_title: item.is_hide_title,
				  }
				: {}
		),
		actual_news: news.map((item) => (item ? item.id : '')),
		actual_offices: offices.map((item) => (item ? item.id : '')),
		actual_products: popular.map((item) => (item ? { id_req: item.id, label: item.label } : {})),

		header_url: mainNews.url,

		text: mainNews.text,
		...links,
	}

	const res = await contentApi.updateContentPage(saveObj)

	if (res.success) {
		dispatch(addNot({ not: 'Главная страница успешно сохранена', type: 'success' }))
	} else {
		onError(dispatch, res.result)
	}
}

export default reducer
