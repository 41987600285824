import { createSlice } from '@reduxjs/toolkit'

import productsApi from '../../../api/products-api'
import commonApi from '../../../api/common-api'

import { onError } from '../functions'

const initialState = {
	products: [],
	productsCount: 0,
	productsFilters: {
		prod_type: 0,
		limit: 10,
		offset: 0,
		city: '',
		country: '',
		name: '',
		is_proposal: 0,
		min_price: 0,
		max_price: 0,
	},
	cities: [],
	countries: [],
	productSearchCity: [],
	productSearchCountry: [],
	productInfo: {},
	isLoading: false,
	maxPrice: 0,
}

const products = createSlice({
	name: 'products',
	initialState,
	reducers: {
		setProducts: (state, action) => {
			state.products = action.payload.products
		},
		setProductsCount: (state, action) => {
			state.productsCount = action.payload.count
		},
		setProductsFilters: (state, action) => {
			state.productsFilters = { ...state.productsFilters, ...action.payload }
		},
		setProductInfo: (state, action) => {
			state.productInfo = action.payload.productInfo
		},
		setCityForFilters: (state, action) => {
			state.cities = action.payload.cities
		},
		setCountryForFilters: (state, action) => {
			state.countries = action.payload.countries
		},
		setSearchCity: (state, action) => {
			state.productSearchCity = action.payload.productSearchCity
		},
		setSearchCountry: (state, action) => {
			state.productSearchCountry = action.payload.productSearchCountry
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
		setMaxPrice: (state, action) => {
			state.maxPrice = action.payload.maxPrice
		},
	},
})

export const { reducer, actions } = products

export const {
	setProducts,
	setProductsCount,
	setProductsFilters,
	setProductInfo,
	setCityForFilters,
	setCountryForFilters,
	setSearchCity,
	setSearchCountry,
	setIsLoading,
	setMaxPrice,
} = actions

// список товаров
export const requestProducts = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		products: { productsFilters },
	} = getState()

	const res = await productsApi.requestProducts(productsFilters)

	if (res.success) {
		dispatch(setProducts({ products: res.result.product }))
		dispatch(setProductsCount({ count: res.result.count }))
		dispatch(setMaxPrice({ maxPrice: res.result.max_price }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// информация о товаре
export const requestProduct = (id) => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await productsApi.requestProduct(id)

	if (res.success) {
		dispatch(setProductInfo({ productInfo: res.result }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// создание нового товара
export const createProduct = (data) => async (dispatch) => {
	const res = await productsApi.createProduct(data)

	if (res.success) {
		dispatch(requestProducts())
	} else {
		onError(dispatch, res.result)
	}
}

// редактирование продукта
export const updateProduct = (data) => async (dispatch) => {
	const res = await productsApi.updateProduct(data)

	if (res.success) {
		dispatch(requestProducts())
	} else {
		onError(dispatch, res.result)
	}
}

// публикация продукта
export const approveProduct = (id) => async (dispatch) => {
	const res = await commonApi.approveTarget(id, 3)

	if (res.success) {
		dispatch(requestProducts())
	} else {
		onError(dispatch, res.result)
	}
}

// удаление публикации продукта
export const rejectProduct = (id) => async (dispatch) => {
	const res = await commonApi.rejectTarget(id, 3)

	if (res.success) {
		dispatch(requestProducts())
	} else {
		onError(dispatch, res.result)
	}
}

// Удалить продукт
export const deleteProduct = (id) => async (dispatch) => {
	const res = await commonApi.deleteTarget(id, 3)

	if (res.success) {
		dispatch(requestProducts())
	} else {
		onError(dispatch, res.result)
	}
}

// Список городов для фильтров
export const requestEventsCity = () => async (dispatch) => {
	const res = await commonApi.getCityForFilters(3)

	if (res.success) {
		dispatch(setCityForFilters({ cities: res.result }))
	} else {
		onError(dispatch, res.result)
	}
}

// Список стран для фильтров
export const requestProductCountry = () => async (dispatch) => {
	const res = await commonApi.getCountryForFilters(3)

	if (res.success) {
		dispatch(setCountryForFilters({ countries: res.result }))
	} else {
		onError(dispatch, res.result)
	}
}

// список городов для выпадающего списка в продукте

export const requestListCities = (pat, city) => async (dispatch) => {
	const res = await commonApi.requestListCities(pat, city)

	if (res.success) {
		dispatch(setSearchCity({ productSearchCity: res.result }))
	} else {
		onError(dispatch, res.result)
	}
}

// список стран для выпадающего списка в продукте

export const requestListCountry = (pat) => async (dispatch) => {
	const res = await commonApi.searchCountry(pat)

	if (res.success) {
		dispatch(setSearchCountry({ productSearchCountry: res.result }))
	} else {
		onError(dispatch, res.result)
	}
}

export default reducer
