import instance from './api'

import { getAdminToken } from '../functions/localStorage'

const usersApi = {
	async getUsersList(opt) {
		const response = await instance.post(
			'admin/search/user',
			{ ...opt },
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async getInfo(id) {
		const response = await instance.get(`admin/get/info?user_id=${id}`, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async setBan(id, status) {
		const response = await instance.get(
			`admin/toggleban?user_id=${id}&isbanned=${Number(status)}`,
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async rejectForm(id, msg) {
		const response = await instance.post(
			`admin/set/reject/info?user_id=${id}&msg=${msg}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async approveForm(id) {
		const response = await instance.get(
			`admin/set/approve/info?user_id=${id}`,
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	//получение списка заметок о пользователе
	async getComments(id) {
		const responce = await instance.post(
			`admin/get/comments/user?user_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return responce.data
	},

	//создание заметки
	async addComment(opt) {
		const response = await instance.post(
			'admin/create/comment/user',
			{ ...opt },
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	//удаление заметки
	async deleteComment(comm_id) {
		const response = await instance.post(
			`admin/delete/comment/user?comm_id=${comm_id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},
}

export default usersApi
