import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TableBody } from '../../../components/TableBody/TableBody'
import { TableHeader } from '../../../components/TableHeader/TableHeader'
import { TableRow } from '../../../components/TableBody/TableRows/TableRow/TableRow'

import {
	getCount,
	getFilters,
	getIsLoading,
	getTransactions,
} from '../../../store/reducers/transactions/transactions-selectors'

import { requestTransactions, setFilters } from '../../../store/reducers/transactions/transactions'

import { avatarPlug } from '../../../components/Avatar/avatar-functions'

import './TransactionsTable.scss'
import {
	changeOffset,
	getPlugNumber,
	refreshTableItems,
	searchItem,
} from '../../../functions/common'

export const TransactionsTable = React.memo(() => {
	const dispatch = useDispatch()

	const transactions = useSelector(getTransactions)
	const count = useSelector(getCount)
	const isLoading = useSelector(getIsLoading)

	const { offset, limit, pat, type } = useSelector(getFilters)

	useEffect(() => {
		dispatch(requestTransactions())
	}, [dispatch, offset, limit, pat, type])

	return (
		<div className="transactions-table">
			<TableHeader
				title="Список транзакций"
				placeholder="Поиск"
				searchValue={pat}
				onSearchChange={searchItem(dispatch, setFilters, 'pat')}
				onRefresh={refreshTableItems(dispatch, requestTransactions)}
			/>
			<TableBody
				contentTitlesArray={[
					{ title: 'Дата' },
					{ title: '' },
					{ title: 'Получатель' },
					{ title: 'Телефон' },
					{ title: 'Описание' },
					{ title: '' },
					{ title: 'Администратор' },
					{ title: 'Баллы' },
				]}
				offset={offset}
				limit={limit}
				count={count}
				setOffset={changeOffset(dispatch, setFilters)}
				isLoading={isLoading}
			>
				{transactions.map((transaction, index) => {
					const plugNumber =
						(transaction.user_id.charCodeAt(transaction.user_id.length - 1) +
							transaction.user_id.charCodeAt(transaction.user_id.length - 2)) %
						10

					const adminPlugNumber = getPlugNumber(transaction.admin_id)

					return (
						<div
							className="table-row"
							style={{ animationDelay: `${index * 40}ms` }}
							key={`table-block-${transaction.id}`}
						>
							<TableRow
								row={{
									trans_time: transaction.trans_time,
									user_image: transaction.user_image,
									user_name: transaction.user_name,
									user_tel: transaction.user_tel,
									msg: transaction.msg,
									admin_image: transaction.admin_image,
									admin_name: transaction.admin_name,
									trans_value: transaction.value,
								}}
								status={transaction.status}
								key={`table-row-${index}`}
								plug={avatarPlug(plugNumber)}
								plugNumber={plugNumber}
								adminPlug={avatarPlug(adminPlugNumber)}
								adminPlugNumber={adminPlugNumber}
							/>
						</div>
					)
				})}
			</TableBody>
		</div>
	)
})
