import instance from './api'

import { getAdminToken } from '../functions/localStorage'

const profileApi = {
	async getCurrentUser() {
		const response = await instance.post(
			'user/get/shortinfo',
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async uploadPhoto(data) {
		const response = await instance.post('user/upload/photo', data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
				'Content-Type': 'multipart/form-data',
			},
		})

		return response.data
	},
}

export default profileApi
