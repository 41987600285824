import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { TabsSelect } from '../../../../components/TabsSelect/TabsSelect'
import { Panel } from '../../../../components/Panels/Panel/Panel'
import { Requests } from '../../../../components/AdminTable/Requests/Requests'
import { Certificates } from '../../../../components/AdminTable/Certificates/Certificates'
import { Quotas } from '../../../../components/AdminTable/Quotas/Quotas'

import { getApplicationsCount } from '../../../../store/reducers/applications/applications-selector'

import { filterTabs } from '../../product-constants'

import './ProductAdminTable.scss'

export const ProductAdminTable = React.memo(({ id, status }) => {
	const [tab, setTab] = useState(0)

	const tabsCount = useSelector(getApplicationsCount)

	return (
		<div className="requests-list">
			<TabsSelect tabs={filterTabs(tabsCount)} tab={tab} setTab={setTab} />
			<Panel>
				{tab === 0 && <Requests />}
				{tab === 1 && <Certificates />}
				{tab === 2 && <Quotas id={id} status={status} type={3} />}
			</Panel>
		</div>
	)
})
