import React from 'react'
import { Avatar } from '../../../../../../components/Avatar/Avatar'

import './PartnerInfo.scss'

export const PartnerInfo = React.memo(({ id, recruterImage, recruterName }) => {
	return (
		<div className="partner-info-block">
			<span>От партнера:</span>
			<div className="partner-info">
				<Avatar id={id} image={recruterImage} small />
				<div className="partner-name">
					<span>{recruterName}</span>
				</div>
			</div>
		</div>
	)
})
