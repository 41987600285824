import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { url } from '../../../api/api'

import { ModalContainer } from '../../../components/ModalContainer/ModalContainer'
import { SelectCategory } from '../../../components/SelectCategory/SelectCategory'
import { Tile } from '../CommonComponents/Tiles/Tile/Tile'
import { TileSubtitle } from '../CommonComponents/Tiles/Tile/TileSubtitle/TileSubtitle'
import { BlockHeader } from '../CommonComponents/BlockHeader/BlockHeader'
import { EmptyItem } from '../CommonComponents/EmptyItem/EmptyItem'
import { PointsCounter } from '../CommonComponents/PointsCounter/PointsCounter'

import { setPopular } from '../../../store/reducers/content/content'
import { requestProducts, setProductsFilters } from '../../../store/reducers/products/products'

import { getPopular } from '../../../store/reducers/content/content-selector'
import { getProducts, productsFilters } from '../../../store/reducers/products/products-selectors'

import './ProductsBlock.scss'

export const ProductsBlock = React.memo(() => {
	const dispatch = useDispatch()

	const productsArray = useSelector(getPopular)

	const products = useSelector(getProducts)
	const { name } = useSelector(productsFilters)

	const productsOptions = products.filter((item) => item.status !== 3)

	const [showModal, toggleModal] = useState(false)
	const [selectedProduct, setSelectedProduct] = useState({})
	const [labelText, setLabelText] = useState('')
	const [index, setIndex] = useState(0)

	const productListRef = useRef()

	const handleModal = (value, index) => () => {
		toggleModal(value)
		setIndex(index)
	}

	const addProductBlock = () => dispatch(setPopular({ popular: [...productsArray, null] }))

	const deleteProductBlock = (productIndex) =>
		dispatch(setPopular({ popular: productsArray.filter((item, index) => index !== productIndex) }))

	//добавление подложки на карточки товаров
	const randomColor = (index) => {
		const switchObj = {
			0: 'purple',
			1: 'red',
			2: 'blue',
			3: 'blue',
			4: 'purple',
			5: 'red',
		}

		return switchObj[index]
	}

	const changeValue = () => ({ target: { value } }) => dispatch(setProductsFilters({ name: value }))

	const selectProduct = (item) => {
		setSelectedProduct(item)
		dispatch(setProductsFilters({ name: item.name }))
	}

	//Подтверждение добавления товара в карточку и зачистка
	const acceptSelectedProduct = () => {
		const _productArray = [...productsArray]

		const _selectedProduct = { ...selectedProduct, label: labelText }

		_productArray[index] = _selectedProduct

		setSelectedProduct({})
		dispatch(setProductsFilters({ name: '' }))
		setLabelText('')

		dispatch(setPopular({ popular: _productArray }))

		toggleModal(false)
	}

	useEffect(() => {
		productListRef.current.style.setProperty('--count', productsArray.length - 3)
	}, [productListRef, productsArray])

	useEffect(() => {
		dispatch(requestProducts())
	}, [dispatch, name])

	return (
		<div className="products-block-container main-block">
			<BlockHeader
				title="Популярные предложения"
				positionText={`(Не менее 3 позиций)`}
				buttonText="Добавить товар в ленту"
				addElement={addProductBlock}
				showButton={productsArray.length < 6}
			/>

			<div className="products-block-container__list" ref={productListRef}>
				{!!productsArray.length &&
					productsArray.map((tile, index) =>
						tile ? (
							<Tile
								key={`tile-${index}`}
								small
								label={tile.label}
								color="#30323BE6"
								image={`${url}/static/${tile.image}`}
								access={!!tile.balance || (!!tile.balance && !!tile.count)}
								colorClass={randomColor(index)}
								classes={index > 2 ? 'second-row' : ''}
								check={productsArray.length > 3}
								deleteItem={() => deleteProductBlock(index)}
								toggleModal={handleModal(true, index)}
							>
								<div className="tile__item">
									<PointsCounter white count={tile.price} /> {/* цена */}
									<TileSubtitle medium text={tile.name} /> {/* название */}
								</div>
							</Tile>
						) : (
							<EmptyItem
								toggleModal={handleModal(true, index)}
								deleteItem={() => deleteProductBlock(index)}
								check={productsArray.length > 3}
								classes={index > 2 ? 'second-row' : ''}
								key={index}
							/>
						)
					)}
			</div>
			<ModalContainer show={showModal} onClose={handleModal(false, 0)}>
				<SelectCategory
					title="Добавить товар"
					inputLabel="Товар"
					type="Товар"
					productValue={name}
					productOptions={productsOptions}
					selectProduct={selectProduct}
					changeProductValue={changeValue()}
					onClose={handleModal(false, 0)}
					onSubmit={acceptSelectedProduct}
					labelValue={labelText}
					changeLabelValue={({ target: { value } }) => setLabelText(value)}
				/>
			</ModalContainer>
		</div>
	)
})
