import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { FormCard } from './FormCard/FormCard'
import { ModalContainer } from '../../../components/ModalContainer/ModalContainer'
import { QuestionnaireContent } from '../../../components/QuestionnaireContent/QuestionnaireContent'

import { Preloader } from '../../../components/Preloader/Preloader'
import { setFilters } from '../../../store/reducers/users/users'
import { TablePagination } from '../../../components/TablePagination/TablePagination'
import { ViewAvatarModal } from '../../../components/Avatar/ViewAvatarModal/ViewAvatarModal'

import { ChoiceBlock } from '../../../components/ChoiceBlock/ChoiceBlock'

import { phoneSpaces } from '../../../functions/common'

import { getUserInfo, setData } from '../../../store/reducers/user/user'

import {
	getCount,
	getFilters,
	getIsLoading,
	getUsers,
} from '../../../store/reducers/users/users-selectors'
import { getUserData } from '../../../store/reducers/user/user-selectors'

import { url } from '../../../api/api'

import './FormsTable.scss'

export const FormsTable = React.memo(() => {
	const dispatch = useDispatch()

	const [userId, setUserId] = useState(null)

	const users = useSelector(getUsers)
	const filters = useSelector(getFilters)
	const user = useSelector(getUserData)
	const count = useSelector(getCount)
	const isLoading = useSelector(getIsLoading)

	const { offset, limit } = filters

	const [modal, setModal] = useState(false)
	const [avatarModal, setAvatarModal] = useState(false)

	const src = !!Object.keys(user).length ? `${url}/static/${user.user.first_page.image}` : ''

	const handleModalToggle =
		(modalState, id = null) =>
		() => {
			setModal(modalState)
			if (id) {
				setUserId(id)
			} else {
				setTimeout(() => {
					setUserId(id)
				}, 500)
			}
		}

	const handleAvatarModal = (state) => () => {
		setAvatarModal(state)
	}

	useEffect(() => {
		dispatch(setFilters({ status: 1 }))
	}, [dispatch])

	useEffect(() => {
		if (userId) {
			dispatch(getUserInfo(userId))
		} else {
			dispatch(setData({ data: {} }))
		}
	}, [dispatch, userId])

	return (
		<div className="forms-table">
			<div className="forms-table-body">
				{!!users.length &&
					users.map((item, index) => (
						<FormCard
							key={item.id}
							id={index}
							userId={item.id}
							name={`${item.surname} ${item.name}`}
							mail={item.email}
							phone={phoneSpaces(`${item.tel}`)}
							status={item.status}
							isBanned={item.isbanned}
							country={item.country}
							exp={item.exp_it}
							skills={item.skills}
							avatar={item.image}
							onClick={handleModalToggle(true, item.id)}
						/>
					))}
				{isLoading && (
					<div className="preloader-container">
						<Preloader />
					</div>
				)}
			</div>
			<TablePagination
				limit={limit}
				count={count}
				offset={offset}
				changePage={(value) => dispatch(setFilters({ offset: value }))}
			/>
			<ModalContainer profile show={modal} onClose={handleModalToggle(false)}>
				<>
					<QuestionnaireContent info={user} withAvatar setViewer={handleAvatarModal(true)} />
					<ChoiceBlock closeModal={handleModalToggle(false)} user={user.user} currentId={userId} />
				</>
			</ModalContainer>
			<ViewAvatarModal src={src} show={avatarModal} close={handleAvatarModal(false)} />
		</div>
	)
})
