import React from 'react'

import cn from 'classnames'

import { Button } from '../../../Buttons/Button/Button'
import { TextAreaField } from '../../../TextAreaField/TextAreaField'

import { createDateRange } from '../../../../functions/common'

import './RejectCertModal.scss'
import { Confirm } from '../../../ModalContainer/Confirm/Confirm'

export const RejectCertModal = React.memo(
	({
		item,
		isCertRejectShow,
		setIsRejectShow,
		setRejectMessage,
		rejectMessage,
		handleRejectCertificate,
		approveModal = false,
		handleApproveModal = () => {},
		handleApproveCertificate = () => {},
	}) => {
		const dateOptions = { day: 'numeric', month: 'short', weekday: 'short' }

		return (
			<div className="reject-cert-modal">
				<div className="certificate-title">
					<span>{item.name}</span>
				</div>
				<div className="certificate-dates">
					<p>Сертификат активен до: </p>
					<span>{createDateRange(item.time_start, item.time_end, 'ru', dateOptions)}</span>
				</div>
				<div className="certificate-address">
					<p>Адрес проведения:</p>
					<span>{item.addr}</span>
				</div>
				<div className="certificate-code">
					<span>код</span>
					<p>{item.code}</p>
				</div>
				{(item.status === 1 || true) && (
					<div className="certificate-buttons">
						<Button
							label="Подтвердить сертификат"
							green
							type="s"
							onClick={handleApproveModal(true)}
						/>

						<Button
							label="Завершить действие сертификата"
							red
							type="s"
							onClick={() => setIsRejectShow(true)}
						/>
					</div>
				)}
				{item.status === 3 && <div className="certificate-status">Сертификат отменён</div>}
				{item.status === 4 && (
					<div className="certificate-status">Время действия сертификата истекло</div>
				)}
				<div className={cn('rejecet-certificate', { show: isCertRejectShow })}>
					<TextAreaField
						label="Причина завершения"
						onChange={({ target: { value } }) => setRejectMessage(value)}
						value={rejectMessage}
					/>
					<div className="buttons-block">
						<Button
							label="Отменить"
							white
							type="s"
							onClick={() => {
								setIsRejectShow(false)
								setRejectMessage('')
							}}
						/>

						<Button
							label="Отправить"
							red
							disabled={!rejectMessage}
							type="s"
							onClick={handleRejectCertificate(item.id, rejectMessage)}
						/>
					</div>
				</div>

				<div className={cn('approve-certificate', { show: approveModal })}>
					<Confirm
						green
						type="s"
						text="Вы уверены"
						cancel={handleApproveModal(false)}
						confirm={handleApproveCertificate(item.id)}
					/>
				</div>
			</div>
		)
	}
)
