//вкладки для панели с таблицами в UserAdminTable

export const filterTabs = (props) => [
	{
		title: 'Заявки',
		count: props,
	},
	{
		title: 'Группы',
	},
	{
		title: 'Сертификаты',
	},
	{
		title: 'Баллы',
	},
	{
		title: 'Заметки',
	},
]
