import React from 'react'
import cn from 'classnames'

import './TileTitle.scss'

export const TileTitle = React.memo(({ large = false, text, style }) => (
	<h3 className={cn('tile-title', { large })} style={style}>
		{text}
	</h3>
))
