import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PagesRouter } from './components/PagesRouter/PagesRouter'
import { AppLayout } from './components/AppLayout/AppLayout'

import { getInitialized } from './store/reducers/app/app-selectors'
import { getIsAuth } from './store/reducers/auth/auth-selectors'
import { EnterPage } from './pages/EnterPage/EnterPage'

import { GlobalNots } from './components/GlobalNots/GlobalNots'

import {
	requestLocal,
	connectWs,
	initialize,
	setDevice,
} from './store/reducers/app/app'

import './assets/styles/index.scss'

export const App = () => {
	const dispatch = useDispatch()

	const initialized = useSelector(getInitialized)
	const isAuth = useSelector(getIsAuth)

	const [size, setSize] = useState(window.innerWidth)

	const updateWidth = () => {
		setSize(window.innerWidth)
	}

	useEffect(() => {
		window.addEventListener('resize', updateWidth)

		const setDeviceValue = (screenValue) => {
			if (screenValue >= 1200) {
				dispatch(setDevice({ device: 'desktop' }))
			} else if (screenValue < 1200 && screenValue >= 992) {
				dispatch(setDevice({ device: 'small-desktop' }))
			} else if (screenValue < 992 && screenValue >= 768) {
				dispatch(setDevice({ device: 'notepad' }))
			} else {
				dispatch(setDevice({ device: 'mobile' }))
			}
		}

		setDeviceValue(size)

		return () => window.removeEventListener('resize', updateWidth)
	}, [dispatch, size])

	useEffect(() => {
		dispatch(initialize())
	}, [dispatch])

	useEffect(() => {
		if (initialized && isAuth) {
			dispatch(connectWs())
			dispatch(requestLocal())
		}
	}, [dispatch, initialized, isAuth])

	if (!initialized) return null

	return (
		<>
			<AppLayout>{isAuth ? <PagesRouter /> : <EnterPage />}</AppLayout>
			<GlobalNots />
		</>
	)
}
