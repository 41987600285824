import { createSlice } from '@reduxjs/toolkit'

import partnersApi from '../../../api/partners-api'

import { onError } from '../functions'

const initialState = {
	skills: [],
	filters: {
		pat: '',
		limit: 10,
		offset: 0,
	},
	count: 0,
	mainValues: {},
	searchResult: [],
	isLoading: false,
}

const partners = createSlice({
	name: 'partners',
	initialState,
	reducers: {
		setSkills: (state, action) => {
			state.skills = action.payload.skills
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setMainValues: (state, action) => {
			state.mainValues = action.payload.mainValues
		},

		setResult: (state, action) => {
			state.searchResult = action.payload.result
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
	},
})

const { actions, reducer } = partners

export const {
	setSkills,
	setFilters,
	setCount,
	setMainValues,
	setResult,
	setIsLoading,
} = actions

// Получение информации для таблицы
export const requestSkills = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		partners: { filters },
	} = getState()

	const res = await partnersApi.requestSkills(filters)

	if (res.success) {
		dispatch(setSkills({ skills: res.result.skills }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Поиск по списку скиллов для установки коэффициентов
export const searchSkills = (text) => async (dispatch) => {
	const res = await partnersApi.searchSkills(text)

	if (res.success) {
		dispatch(setResult({ result: res.result }))
	} else {
		onError(dispatch, res.result)
	}
}

// Добавление скилла в таблицу и установка стоимости рекрутинга и просмотра
export const addSkillPrice = (data) => async (dispatch) => {
	const res = await partnersApi.addSkillPrice(data)

	if (res.success) {
		dispatch(requestSkills())
	} else {
		onError(dispatch, res.result)
	}
}

// Удаление скилла из таблицы
export const deleteSkill = (id) => async (dispatch) => {
	const res = await partnersApi.deleteSkill(id)

	if (res.success) {
		dispatch(requestSkills())
	} else {
		onError(dispatch, res.result)
	}
}

// Получение базового коэффициента, стоимости просмотра и ссылки на архив
export const requestPartnersInfo = () => async (dispatch) => {
	const res = await partnersApi.requestPartnersInfo()

	if (res.success) {
		dispatch(setMainValues({ mainValues: res.result }))
	} else {
		onError(dispatch, res.result)
	}
}

// Установка базового коэффициента, стоимости просмотра и ссылки на архив
export const setPartnersInfo = (data) => async (dispatch) => {
	const res = await partnersApi.setPartnersInfo(data)

	if (res.success) {
		dispatch(requestPartnersInfo())
	} else {
		onError(dispatch, res.result)
	}
}

export default reducer
