import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { url } from '../../../api/api'

import { TextAreaField } from '../../../components/TextAreaField/TextAreaField'
import { Button } from '../../../components/Buttons/Button/Button'

import { rejectComment } from '../../../store/reducers/comments/comments'

import { getPlugNumber } from '../../../functions/common'
import { avatarPlug } from '../../../components/Avatar/avatar-functions'

import './RejectComment.scss'

export const RejectComment = React.memo(({ comments = [], commentID, closeModal }) => {
	const dispatch = useDispatch()

	const commentInfo = comments.find((item) => item.id === commentID)

	const { from_image, from_id, target_name, from_name } = commentInfo

	const plugNumber = getPlugNumber(from_id)

	const imageUrl = from_image ? `${url}/static/${from_image}` : avatarPlug(plugNumber)

	const [reason, setReason] = useState('')

	const rejectCommentFunc = () => {
		dispatch(rejectComment(reason))
		setReason('')
		closeModal()
	}

	const reasonHandler = ({ target: { value } }) => setReason(value)

	return (
		<div className="delete-comment-block">
			<div className="delete-comment-block__header">
				<div
					className={cn('sender-avatar avatar__image', {
						[`plug plug-${plugNumber}`]: !from_image,
					})}
				>
					<img src={imageUrl} alt="" />
				</div>
				<div className="attention-text">
					<p>
						Вы действительно хотите удалить комментарий пользователя <span>{from_name}</span> к
						событию <span>{target_name}</span>
					</p>
				</div>
			</div>
			<TextAreaField
				label={'Причина удаления'}
				onChange={reasonHandler}
				value={reason}
				minRows={3}
			/>
			<div className="button-block">
				<Button white label={'Отменить'} type={'md1'} onClick={closeModal} />
				<Button red label={'Удалить'} type={'md1'} onClick={rejectCommentFunc} />
			</div>
		</div>
	)
})
