import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	nots: [],
}

const globalNots = createSlice({
	name: 'globalNots',
	initialState,
	reducers: {
		addNot: (state, action) => {
			const { nots } = state

			state.nots = [
				...(nots.length < 3 ? nots : nots.slice(1, 3)),
				{
					id: nots.length ? nots[nots.length - 1].id + 1 : 0,
					text: action.payload.not,
					type: action.payload.type,
				},
			]
		},
		delNot: (state, action) => {
			state.nots = state.nots.filter((note) => note.id !== action.payload.id)
		},
	},
})

const { reducer, actions } = globalNots

export const { addNot, delNot } = actions

export default reducer
