import React from 'react'
import cn from 'classnames'

import './Panel.scss'

export const Panel = React.memo(({ disabled, children, style, classes }) => (
	<div className={cn('panel', { disabled }, classes)} style={style}>
		{children}
	</div>
))
