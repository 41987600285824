import React from 'react'
import cn from 'classnames'

import { Icon } from '../../Icon/Icon'

import { generateID } from '../../../functions/common'

import './SearchInput.scss'

export const SearchInput = ({
	placeholder,
	value,
	onChange = () => {},
	disabled,
	onFocus = () => {},
	onBlur = () => {},
	modal = false,
}) => {
	const id = generateID('sch-')

	return (
		<label className={cn('search-input-container', { modal })} htmlFor={id}>
			<Icon name="search" size={18} contain />
			<input
				type="text"
				id={id}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				disabled={disabled}
			/>
		</label>
	)
}
