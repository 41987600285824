import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import ru from 'date-fns/locale/es'

import { Input } from '../../../../components/Inputs/Input/Input'
import { OfficeFacilities } from './OfficeFacilities/OfficeFacilities'
import { LoadImage } from '../../../../components/LoadImage/LoadImage'
import { Preview } from '../../../../components/Preview/Preview'

import coffee from '../../../../assets/media/svg/coffee.svg'
import wifi from '../../../../assets/media/svg/wifi.svg'

import './OfficeInfo.scss'

export const OfficeInfo = React.memo(
	({
		name,
		setName,
		image,
		setImage,
		workStart,
		setWorkStart,
		workEnd,
		setWorkEnd,
		balance,
		setBalance,
		equipment,
		setEquipment,
		officeError,
		password,
		setPassword,
		fillPassword,
		preview,
		setPreview,
	}) => {
		const handleOfficeEquipment = (value) => () => {
			if (equipment.includes(value)) {
				setEquipment(equipment.filter((item) => item !== value))
			} else {
				setEquipment([...equipment, value])
			}
		}

		const DATA = [
			{
				title: 'Wi-Fi',
				icon: wifi,
				handler: handleOfficeEquipment(1),
				active: !!equipment.find((item) => item === 1),
			},
			{
				title: 'Кофемашина',
				icon: coffee,
				handler: handleOfficeEquipment(2),
				active: !!equipment.find((item) => item === 2),
			},
		]

		registerLocale('ru', ru)

		return (
			<div className="office__info">
				<div className="office__title">Общее</div>
				<Preview
					image={preview}
					setImage={setPreview}
					accept={'.jpg, .png, .jpeg, .gif'}
					error={!officeError.preview}
					aspect={1 / 1}
				/>
				<LoadImage image={image} setImage={setImage} accept={'.jpg, .png, .jpeg, .gif'} />
				<div className="office__info-wrapper">
					<Input
						label={'Наименование'}
						value={name}
						onChange={({ target: { value } }) => setName(value)}
						className={officeError.name ? '' : 'error'}
					/>
					<Input
						label={'Пароль'}
						value={password}
						onChange={({ target: { value } }) => setPassword(value)}
						className={officeError.password ? '' : 'error'}
						disabled={!!fillPassword}
					/>
					<div className="office__info-box">
						<div className="office__time-wrapper">
							<div className="office__time-item">
								<DatePicker
									selected={workStart}
									onChange={setWorkStart}
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={30}
									locale="ru"
									timeCaption="Time"
									timeFormat="p"
									dateFormat="p"
									customInput={
										<Input label="с" placeholder="" className={officeError.name ? '' : 'error'} />
									}
								/>
							</div>
							<div className="office__time-item">
								<DatePicker
									selected={workEnd}
									onChange={setWorkEnd}
									showTimeSelect
									showTimeSelectOnly
									timeIntervals={30}
									locale="ru"
									timeCaption="Time"
									dateFormat="p"
									timeFormat="p"
									customInput={
										<Input label="по" placeholder="" className={officeError.name ? '' : 'error'} />
									}
								/>
							</div>
						</div>
						<Input
							label={'Кол-во мест'}
							value={balance}
							type={'number'}
							onFocus={() => !balance && setBalance('')}
							onBlur={() => (!balance ? setBalance(0) : balance)}
							onChange={({ target: { value } }) => setBalance(value)}
							className={`info-num ${officeError.balance ? '' : 'error'}`}
						/>
					</div>
				</div>
				<div className="office__info-facilities">
					<div className="office__title">Оснащенность</div>
					<div className="office__info-buttons">
						{DATA.map((item, i) => (
							<OfficeFacilities
								key={`office-${i}`}
								image={item.icon}
								text={item.title}
								classname={item.active}
								onClick={item.handler}
							/>
						))}
					</div>
				</div>
			</div>
		)
	}
)
