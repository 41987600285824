import React from 'react'
import { Icon } from '../../../../components/Icon/Icon'

export const BlockHeader = React.memo(
	({ title, positionText, buttonText, addElement, showButton }) => (
		<div className="main-block__header">
			<h3>
				{title} <span> {positionText} </span>
			</h3>
			{buttonText && showButton && (
				<div className="main-block__header-button" onClick={addElement}>
					<Icon name="plus" size={14} />
					<p>{buttonText}</p>
				</div>
			)}
		</div>
	)
)
