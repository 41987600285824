import instance from './api'

import { getAdminToken } from '../functions/localStorage'

const transactionsApi = {
	async requestTransactions(filters) {
		const response = await instance.post(
			'/admin/search/ts',
			{ ...filters },
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async requestUserTransactions(limit, offset, id) {
		const responce = await instance.post(
			`/admin/get/ts/user?user_id=${id}&limit=${limit}&offset=${offset}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return responce.data
	},

	async createTransaction(params) {
		const response = await instance.post(
			'admin/update/money',
			{ ...params },
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async searchForTransaction(pat) {
		const response = await instance.post(
			`admin/get/forts?pat=${pat}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},
}

export default transactionsApi
