import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { BreadCrumbs } from '../../../components/BreadCrumbs/BreadCrumbs'
import { EditCard } from '../../../components/EditCard/EditCard'
import { ColumnsContainer } from '../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { Column } from '../../../components/PageWrapper/ColumnsContainer/Column/Column'
import { LeftColumn } from '../../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { OfficeInfo } from './OfficeInfo/OfficeInfo'
import { OfficeLocation } from './OfficeLocation/OfficeLocation'
import { OfficeTable } from './OfficeTable/OfficeTable'
import { PublishButton } from '../../../components/Buttons/PublishButton/PublishButton'

import { setType as setCertificatesType } from '../../../store/reducers/certificates/certificates'
import { setType as setAplicationsType } from '../../../store/reducers/applications/applications'
import { setType as setQuotasType } from '../../../store/reducers/quotas/quotas'

import {
	setOffice,
	createOffice,
	updateOffice,
	approveOffice,
	rejectOffice,
	deleteOffice,
} from '../../../store/reducers/offices/offices'
import { onError } from '../../../store/reducers/functions'
import { url } from '../../../api/api'

import './OfficePage.scss'

export const OfficePage = React.memo(({ id, office, setCreate, cancel, edit }) => {
	if (id && edit && !Object.keys(office).length) return null

	const dispatch = useDispatch()
	const history = useHistory()

	const [name, setName] = useState(edit ? office.name : '')
	const [image, setImage] = useState(edit ? office.image : '')
	const [workStart, setWorkStart] = useState(edit ? new Date(office.work_start) : new Date())
	const [workEnd, setWorkEnd] = useState(edit ? new Date(office.work_end) : new Date())
	const [equipment, setEquipment] = useState(edit ? office.equipment : [])
	const [balance, setBalance] = useState(edit ? office.balance : 0)
	const [address, setAddress] = useState(edit ? office.loc_addr : '')
	const [city, setCity] = useState(edit ? office.city : '')
	const [description, setDescription] = useState(edit ? office.loc_desc : '')
	const [xCoord, setXCoord] = useState(edit ? office.loc_x : 54.70572)
	const [yCoord, setYCoord] = useState(edit ? office.loc_y : 20.50662)
	const [password, setPassword] = useState(edit ? office.password : '')
	const [preview, setPreview] = useState(edit ? `${url}/static/${office.prev_image}` : '')

	// проверка на заполнение
	const [officeError, setOfficeError] = useState({
		name: true,
		workStart: true,
		workEnd: true,
		balance: true,
		address: true,
		city: true,
		description: true,
		xCoord: true,
		yCoord: true,
		password: true,
		preview: true,
	})

	const errorFunc = () => {
		onError(dispatch, { code: 0, msg: 'Заполните все необходимые поля' })

		setOfficeError({
			name: name ? true : false,
			workStart: workStart ? true : false,
			workEnd: workEnd ? true : false,
			balance: balance ? true : false,
			address: address ? true : false,
			city: city ? true : false,
			description: description ? true : false,
			xCoord: xCoord ? true : false,
			yCoord: yCoord ? true : false,
			password: password ? true : false,
			preview: preview ? true : false,
		})
	}

	const handleOffice = async () => {
		const data = {
			name: name,
			image: image,
			work_start: workStart,
			work_end: workEnd,
			equipment: equipment,
			balance: +balance,
			loc_addr: address,
			city: city,
			loc_desc: description,
			loc_x: xCoord,
			loc_y: yCoord,
			password: password,
			prev_image: preview,
		}

		if (
			!name ||
			!workStart ||
			!workEnd ||
			!balance ||
			!address ||
			!city ||
			!xCoord ||
			!yCoord ||
			!password ||
			!preview
		) {
			errorFunc()

			return
		}

		if (edit) {
			data.id = id
			dispatch(updateOffice(data))
		} else {
			dispatch(createOffice(data))
		}

		history.push('/offices')
	}

	const changeStatusOffice = (id, func) => () => {
		dispatch(func(id))

		history.push('/offices')
	}

	// публикация офиса

	const publishOffice = async () => {
		await handleOffice()

		changeStatusOffice(id, approveOffice)()
	}

	useEffect(() => {
		dispatch(setCertificatesType({ type: 2 }))
		dispatch(setAplicationsType({ type: 2 }))
		dispatch(setQuotasType({ type: 2 }))
	}, [dispatch, id])

	useEffect(
		() => () => {
			if (!edit) {
				setCreate(false)
			}
		},
		[edit, setCreate]
	)

	useEffect(
		() => () => {
			dispatch(setOffice({ officeInfo: {} }))
		},
		[dispatch]
	)

	const crumbs = [
		{ link: '/offices', text: 'Офисы' },
		{ link: `/offices/${id}`, text: `${edit ? office.name : 'Новый офис'}` },
	]

	return (
		<div className="office__page">
			<PageWrapper>
				<div className="office__header">
					<BreadCrumbs items={crumbs} />
					<div className="office__header-buttoms">
						<EditCard
							edit={edit}
							saveType={'submit'}
							deleteIt={'Удалить офис'}
							cancel={'Отменить'}
							save={edit ? 'Сохранить' : 'Добавить'}
							cancelFunc={cancel}
							savedFunc={handleOffice}
							deleteFunc={changeStatusOffice(id, deleteOffice)}
						/>
						<PublishButton
							show={edit && office.status !== 3}
							isPublished={edit && office.status === 2}
							publishFunc={publishOffice}
							unpublishFunc={changeStatusOffice(id, rejectOffice)}
						/>
					</div>
				</div>
			</PageWrapper>
			<PageWrapper>
				<ColumnsContainer>
					<LeftColumn>
						<Column>
							<Panel>
								<OfficeInfo
									name={name}
									setName={setName}
									image={image}
									setImage={setImage}
									workStart={workStart}
									setWorkStart={setWorkStart}
									workEnd={workEnd}
									setWorkEnd={setWorkEnd}
									balance={balance}
									setBalance={setBalance}
									equipment={equipment}
									setEquipment={setEquipment}
									officeError={officeError}
									password={password}
									setPassword={setPassword}
									fillPassword={edit && office.password}
									preview={preview}
									setPreview={setPreview}
								/>
							</Panel>
						</Column>
						<Column>
							<Panel>
								<OfficeLocation
									address={address}
									setAddress={setAddress}
									city={city}
									setCity={setCity}
									description={description}
									setDescription={setDescription}
									xCoord={xCoord}
									setXCoord={setXCoord}
									yCoord={yCoord}
									setYCoord={setYCoord}
									officeError={officeError}
								/>
							</Panel>
						</Column>
					</LeftColumn>
					{id !== 'create' && id && (
						<Column>
							<OfficeTable id={id} status={office.status} />
						</Column>
					)}
				</ColumnsContainer>
			</PageWrapper>
		</div>
	)
})
