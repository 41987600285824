import React, { useRef, useState } from 'react'
import cn from 'classnames'

import { Options } from './Options/Options'
import { Icon } from '../Icon/Icon'
import { IconButton } from '../IconButton/IconButton'

import './Dropdown.scss'

export const Dropdown = ({
	label,
	inputText = '',
	className,
	local,
	options = [],
	added = [],
	active,
	onChange,
	onClear = () => {},
	onInput = () => {},
	disabled,
}) => {
	const [focus, setFocus] = useState(false)

	const input = useRef()

	const onClick = ({ target }) => {
		if (active) return
		if (focus && target.closest('.label-input')) return
		if (!target.closest('.btn-clear')) {
			if (!focus && input.current) input.current.focus()
			setFocus(!focus)
		}
	}

	return (
		<div
			className={cn(
				'dropdown-main-container',
				{
					focus,
					filled: active,
					disabled,
				},
				className
			)}
			onClick={disabled ? () => {} : onClick}
		>
			<div className="label">
				<input
					className="label-input"
					ref={input}
					type="text"
					placeholder={label}
					disabled={disabled}
					readOnly={label === 'Категория' && true}
					value={
						!active ? inputText : options.find((item) => item.id === active)?.title || local[active]
					}
					onFocus={(e) => {
						if (active) e.target.blur()
					}}
					onChange={onInput}
					style={{ cursor: active && 'default' }}
				/>
				{!active ? (
					<Icon name="dropdown" size={48} />
				) : (
					<IconButton className="btn-clear" iconName="dropdown-close" size={48} onClick={onClear} />
				)}
			</div>
			{focus && (
				<div
					className="opt-wrapper"
					onClick={() => {
						setFocus(false)
						if (!active) {
							onClear()
						}
					}}
				></div>
			)}
			<Options options={options} added={added} onChange={onChange} />
		</div>
	)
}
