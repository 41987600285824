import { createSlice } from '@reduxjs/toolkit'

import profileApi from '../../../api/profile-api'

const initialState = {
	user: {},
}

const profile = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.user = action.payload.user
		},
	},
})

const { reducer, actions } = profile

export const { setUser } = actions

/*
	Получение информации о текущем пользователе
*/
export const getCurrentUser = () => async (dispatch) => {
	const res = await profileApi.getCurrentUser()

	if (res.success) {
		dispatch(setUser({ user: res.result }))
	}
}

export default reducer
