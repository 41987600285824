import { getAdminToken } from '../functions/localStorage'
import instance from './api'

const getTypeString = (type) => {
	switch (type) {
		case 1:
			return 'user'

		case 2:
		default:
			return 'product'
	}
}

const applicationsApi = {
	async requestApplications(data) {
		const response = await instance.post(`admin/search/quota`, data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async requestApplicationsById({ limit, offset, id, type }) {
		const typeString = getTypeString(type)

		const response = await instance.post(
			`admin/get/props/${typeString}?${typeString}_id=${id}&limit=${limit}&offset=${offset}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async approveApplication(id) {
		const response = await instance.post(
			`admin/set/approve/props?props_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async rejectApplication(id, msg) {
		const response = await instance.post(
			`admin/set/reject/props?props_id=${id}&msg=${msg}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},
}

export default applicationsApi
