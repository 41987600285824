import React from 'react'

import './ContentItemLanguageLine.scss'

export const ContentItemLanguageLine = React.memo(({ label, value }) => (
	<div className="content-item-line content-item-language-line">
		<div className="content-item-language-line__label">{label}</div>
		<div className="content-item-language-line__value">{value}</div>
	</div>
))
