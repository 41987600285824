//вкладки для панели с таблицами в ProductAdminTable

export const filterTabs = (props) => [
    {
        title: 'Заявки',
        count: props
    },
    {
        title: 'Сертификаты',
    },
    {
        title: 'Квоты',
    },
]
