import { createSlice } from '@reduxjs/toolkit'

import { wsUrl } from '../../../api/api'
import commonApi from '../../../api/common-api'

import { getAdminToken, removeAdminToken } from '../../../functions/localStorage'

import { refresh, setIsAuth } from '../auth/auth'
import { addNot } from '../global-nots/global-nots'
import { incNewCount, setNewNotification } from '../notifications/notifications'
import { getCurrentUser } from '../profile/profile'

const initialState = {
	initialized: false,
	timerId: null,
	ws: null,
	updating: false,
	device: 'desktop',
	local: {},
}

const app = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setInitialized: (state, action) => {
			state.initialized = action.payload.initialized
		},

		setTimerId: (state, action) => {
			state.timerId = action.payload.timerId
		},

		setWs: (state, action) => {
			state.ws = action.payload.ws
		},

		setUpdating: (state, action) => {
			state.updating = action.payload.updating
		},

		setDevice: (state, action) => {
			state.device = action.payload.device
		},

		setLocal: (state, action) => {
			state.local = action.payload.local
		},
	},
})

const { reducer, actions } = app

export const {
	setInitialized,
	setTimerId,
	setWs,
	setUpdating,
	setDevice,
	setLocal,
	setDropdownMenu,
} = actions

// Получение локализации
export const requestLocal = () => async (dispatch) => {
	const res = await commonApi.getLocal()

	if (res.success) dispatch(setLocal({ local: res.result }))
}

/*
	Инициализация при запуске
	Проверяет авторизацию
*/
export const initialize = () => (dispatch) => {
	const token = getAdminToken()

	if (token) {
		const timestamp = JSON.parse(atob(token.split('.')[1])).exp
		const mins = (timestamp - Math.round(Date.now() / 1000)) / 60

		if (mins > 0) {
			dispatch(getCurrentUser())
			dispatch(setIsAuth({ isAuth: true }))
			dispatch(refresh())
			dispatch(setRefreshTimeout())
		} else {
			removeAdminToken()
		}
	}

	dispatch(setInitialized({ initialized: true }))
}

/*
	Установка таймаута на обновление токена
*/
export const setRefreshTimeout = () => (dispatch) => {
	const timerId = setTimeout(() => {
		dispatch(refresh())
	}, 10 * 60 * 1000) // раз в 10 минут

	dispatch(setTimerId({ timerId }))
}

/*
	Очистка таймаута обновления токена
*/
export const clearRefreshTimeout = () => (dispatch, getState) => {
	const {
		app: { timerId },
	} = getState()

	if (timerId) clearInterval(timerId)

	dispatch(setTimerId({ timerId: null }))
}

/*
	Веб-сокет соединение
*/
export const connectWs = () => async (dispatch, getState) => {
	const ws = new WebSocket(wsUrl)
	dispatch(setWs({ ws }))
	dispatch(setUpdating({ updating: true }))

	ws.onmessage = (m) => {
		const { type, data } = JSON.parse(m.data)

		if (type === 'notif') {
			dispatch(setNewNotification({ not: data }))
			dispatch(incNewCount())
			dispatch(addNot({ not: data.msg }))
		}
	}

	ws.onerror = () => {}

	ws.onclose = () => {
		if (getState().app.updating) {
			setTimeout(() => {
				dispatch(connectWs())
			}, 5000)
		}
	}

	ws.onopen = () => {
		ws.send(`Bearer ${getAdminToken()}`)
	}
}

export default reducer
