import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'

import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { BreadCrumbs } from '../../../components/BreadCrumbs/BreadCrumbs'
import { EditCard } from '../../../components/EditCard/EditCard'
import { ColumnsContainer } from '../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { Column } from '../../../components/PageWrapper/ColumnsContainer/Column/Column'
import { Input } from '../../../components/Inputs/Input/Input'
import { TextAreaField } from '../../../components/TextAreaField/TextAreaField'
import { GroupTable } from './GroupTable/GroupTable'

import {
	setGroupInfo,
	setUsersCount,
	setUsers,
	setUsersFilters,
	updateGroup,
	createGroup,
	requestGroup,
	deleteGroup,
} from '../../../store/reducers/groups/groups'

import { getGroupInfo } from '../../../store/reducers/groups/groups-selectors'

import './GroupPage.scss'
import { onError } from '../../../store/reducers/functions'

const validate = (values) => {
	const errors = {}
	const { name, description } = values

	if (!name) {
		errors.name = 'Поле не должно быть пустым'
	}

	if (!description) {
		errors.description = 'Поле не должно быть пустым'
	}

	return errors
}

export const GroupPage = React.memo(({ id, edit, cancel, setCreate }) => {
	const dispatch = useDispatch()

	const group = useSelector(getGroupInfo)
	const history = useHistory()
	const crumbs = [
		{ link: '/groups', text: 'Группы' },
		{ link: `/groups/${id}`, text: edit ? group.name : 'Новая группа' },
	]

	useEffect(() => {
		if (!!id && edit) dispatch(requestGroup(id))
	}, [dispatch, id, edit])

	useEffect(
		() => () => {
			dispatch(setGroupInfo({ info: {} }))
			dispatch(setUsersCount({ count: 0 }))
			dispatch(setUsers({ users: [] }))
			dispatch(setUsersFilters({ pat: '' }))
		},
		[dispatch]
	)

	useEffect(
		() => () => {
			if (!edit) {
				setCreate(false)
			}
		},
		[edit, setCreate]
	)

	const savedGroupFunc = (values) => {
		if (!values.name || !values.description) {
			onError(dispatch, { code: 0, msg: 'Заполните все поля' })
		} else {
			history.goBack()
		}
	}

	const deleteThisGroup = (id) => {
		dispatch(deleteGroup(id))
		history.goBack()
	}

	if (!!id && edit && !Object.keys(group).length) return null

	return (
		<div className="group__page">
			<Formik
				initialValues={{
					name: edit ? group.name : '',
					description: edit ? group.description : '',
				}}
				validate={validate}
				onSubmit={(values) => {
					if (edit) {
						dispatch(updateGroup({ ...values, id: group.id }))
					} else {
						dispatch(createGroup(values))
					}
				}}
			>
				{({ values, errors, touched }) => {
					return (
						<Form>
							<PageWrapper>
								<div className="group__header">
									<BreadCrumbs items={crumbs} />
									<EditCard
										edit={edit}
										saveType={'submit'}
										deleteIt={'Удалить группу'}
										cancel={'Отменить'}
										save={edit ? 'Сохранить' : 'Добавить'}
										cancelFunc={cancel}
										savedFunc={() => savedGroupFunc(values)}
										deleteFunc={() => deleteThisGroup(group.id)}
										id={id}
									/>
								</div>
							</PageWrapper>
							<PageWrapper>
								<ColumnsContainer>
									<LeftColumn>
										<Column>
											<Field
												label="Название"
												name="name"
												as={Input}
												className={!!errors.name && touched.name && 'error'}
											/>
											<Field
												label="Описание"
												name="description"
												as={TextAreaField}
												error={!!errors.description && touched.description}
											/>
										</Column>
									</LeftColumn>
									<Column>{!!id && <GroupTable id={id} edit={edit} />}</Column>
								</ColumnsContainer>
							</PageWrapper>
						</Form>
					)
				}}
			</Formik>
		</div>
	)
})
