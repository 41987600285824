import React from 'react'
import cn from 'classnames'

import { IconButton } from '../../IconButton/IconButton'

import './ViewAvatarModal.scss'

export const ViewAvatarModal = React.memo(({ src, show, close }) => {
	const handleClose = ({ target }) => {
		if (!target.closest('.view-avatar-modal__container')) close()
	}

	return (
		<div
			className={cn('view-avatar-modal', { show })}
			onMouseDown={handleClose}
		>
			<IconButton
				className={cn('btn-close')}
				iconName="close-white"
				size={32}
				onClick={close}
			/>
			<div className="view-avatar-modal__container">
				<img
					src={src}
					alt="full size avatar"
					className="view-avatar-modal__image"
				/>
			</div>
		</div>
	)
})
