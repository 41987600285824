export const getGroups = ({ groups: { groups } }) =>
	groups.map((group) => ({
		prod_descr: group.description,
		...group,
	}))

export const getUserGroups = ({ groups: { userGroups } }) => userGroups

export const getGroupsCount = ({ groups: { groupsCount } }) => groupsCount

export const getUsers = ({ groups: { users } }) => users

export const getUsersCount = ({ groups: { usersCount } }) => usersCount

export const getFilters = ({ groups: { groupsFilters } }) => groupsFilters

export const getUsersFilters = ({ groups: { usersFilters } }) => usersFilters

export const getIsLoading = ({ groups: { isLoading } }) => isLoading

export const getGroupInfo = ({ groups: { groupInfo } }) => groupInfo

export const getSearchUsersResult = ({ groups: { searchUsersResult } }) => searchUsersResult
