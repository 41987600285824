import React from 'react'

import { Button } from '../../Buttons/Button/Button'

import './Confirm.scss'

export const Confirm = React.memo(
	({ text, type = 'md1', cancel, confirm, red = false, green = false }) => (
		<div className="confirm-modal">
			<div className="confirm-modal__text">{`${text}?`}</div>
			<div className="confirm-modal__buttons">
				<Button type={type} label="Отменить" onClick={cancel} white />
				<Button
					type={type}
					label="Подтвердить"
					onClick={() => {
						confirm()
						cancel()
					}}
					red={red}
					green={green}
				/>
			</div>
		</div>
	)
)
