import { logout } from './auth/auth'
import { addNot } from './global-nots/global-nots'

export const onError = (dispatch, { code, msg }) => {
	if (code === 5) {
		dispatch(logout())
	} else {
		dispatch(addNot({ not: msg, type: 'error' }))
	}
}
