import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Filters } from '../../../../components/Filters/Filters'
import { FiltersHeader } from '../../../../components/FiltersHeader/FiltersHeader'

import { setFilters } from '../../../../store/reducers/events/events'
import { getCities, getFilters } from '../../../../store/reducers/events/events-selectors'

export const EventFilters = React.memo(() => {
	const dispatch = useDispatch()

	const filteredCities = useSelector(getCities)
	const filters = useSelector(getFilters)

	const { type, is_proposal, date_mode, status, city } = filters

	// Фильтр по городам
	const handleCitiesFilter = (value) => () => {
		dispatch(setFilters({ city: city === value ? '' : value, offset: 0 }))
	}

	//Получение списка городов для фильтров
	const _cities = filteredCities.map((item) => {
		return {
			title: item,
			handler: handleCitiesFilter(item),
			active: city === item,
		}
	})

	// Фильтр "Заявки"
	const handleApplicationsFilter = (value) => () => {
		dispatch(setFilters({ is_proposal: is_proposal === value ? 0 : value, offset: 0 }))
	}

	// Фильтр "Формат"
	const handleFormatFilter = (value) => () => {
		dispatch(setFilters({ type: type === value ? 0 : value, offset: 0 }))
	}

	// Фильтр "Даты"
	const handleDatesFilter = (value) => () => {
		dispatch(setFilters({ date_mode: date_mode === value ? '' : value, offset: 0 }))
	}

	// Фильтр "Публикация"
	const handlePublicFilter = (value) => () => {
		if (status === value) dispatch(setFilters({ status: 0, offset: 0 }))
		else {
			dispatch(setFilters({ status: value, offset: 0 }))
		}
	}

	// Сбросить фильтр
	const handleResetFilter = () => {
		dispatch(
			setFilters({
				type: 0,
				is_proposal: 0,
				status: 0,
				date_mode: '',
				name: '',
				city: '',
				offset: 0
			})
		)
	}

	const FILTERS = [
		{
			title: 'Заявки',
			items: [
				{
					title: 'С заявками',
					handler: handleApplicationsFilter(1),
					active: is_proposal === 1,
				},
				{
					title: 'Без заявок',
					handler: handleApplicationsFilter(2),
					active: is_proposal === 2,
				},
			],
		},
		{
			title: 'Формат',
			items: [
				{ title: 'Онлайн', handler: handleFormatFilter(1), active: type === 1 },
				{ title: 'Офлайн', handler: handleFormatFilter(2), active: type === 2 },
			],
		},
		{
			title: 'Город',
			items: _cities,
		},
		{
			title: 'Даты',
			items: [
				{
					title: 'Прошедшие',
					handler: handleDatesFilter('after'),
					active: date_mode === 'after',
				},
				{
					title: 'Текущие',
					handler: handleDatesFilter('now'),
					active: date_mode === 'now',
				},
				{
					title: 'Предстоящие',
					handler: handleDatesFilter('before'),
					active: date_mode === 'before',
				},
			],
		},
		{
			title: 'Публикация',
			items: [
				{
					title: 'Опубликованные',
					handler: handlePublicFilter(2),
					active: status === 2,
				},
				{
					title: 'Неопубликованные',
					handler: handlePublicFilter(1),
					active: status === 1,
				},
				{
					title: 'Завершенные',
					handler: handlePublicFilter(3),
					active: status === 3,
				},
				{
					title: 'Удаленные',
					handler: handlePublicFilter(4),
					active: status === 4,
				},
			],
		},
	]
	return (
		<>
			<FiltersHeader reset={handleResetFilter} />
			<Filters filter={FILTERS} />
		</>
	)
})
