import React, { useContext } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'

import ru from 'date-fns/locale/es'

import { Input } from '../../../../../components/Inputs/Input/Input'

import EventContext from '../../../../../context/EventContext'

import { actions } from '../../actions'

import './EventStream.scss'

export const EventStream = React.memo(() => {
	const { state, localDispatch } = useContext(EventContext)

	const { setStreamLink, setStreamStartTime, setStreamEndTime } = actions

	const { streamLink, streamStartTime, streamEndTime } = state

	registerLocale('ru', ru)

	const handleStreamLinkChange = ({ target: { value } }) => localDispatch(setStreamLink(value))

	const handleStreamTimeChange = (callback) => (value) => localDispatch(callback(value))

	return (
		<div className="event-stream-block">
			<Input label="Ссылка на трансляцию" value={streamLink} onChange={handleStreamLinkChange} />
			<div className="time-block">
				<ReactDatePicker
					selected={streamStartTime}
					onChange={handleStreamTimeChange(setStreamStartTime)}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={15}
					locale="ru"
					timeCaption="Time"
					timeFormat="p"
					dateFormat="p"
					customInput={<Input label="Время начала трансляции" />}
				/>
			</div>
			<div className="time-block">
				<ReactDatePicker
					selected={streamEndTime}
					onChange={handleStreamTimeChange(setStreamEndTime)}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={15}
					locale="ru"
					timeCaption="Time"
					timeFormat="p"
					dateFormat="p"
					customInput={<Input label="Время окончания трансляции" />}
				/>
			</div>
		</div>
	)
})
