export const getCertificatesList = ({ certificates: { certificates } }) =>
	certificates

export const getCertificatesCount = ({ certificates: { count } }) => count

export const getCertificatesFilters = ({ certificates: { filters } }) => filters

export const getCertificatesType = ({ certificates: { type } }) => type

export const getIsLoading = ({ certificates: { isLoading } }) => isLoading
