import instance from './api'

import { getAdminToken } from '../functions/localStorage'

const commentsApi = {
	async requestComments(filters) {
		const response = await instance.post(`admin/search/comment`, filters, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async changeCommentStatus(targetId) {
		const response = await instance.post(
			`admin/set/approve/target?target_id=${targetId}&type=7`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async rejectComment(targetId, text) {
		const response = await instance.post(
			`admin/set/reject/target?target_id=${targetId}&type=7&msg=${text}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},
}

export default commentsApi
