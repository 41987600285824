import React from 'react'
import cn from 'classnames'

import './LightButton.scss'

export const LightButton = ({
	label = '',
	red = false,
	onClick = () => {},
}) => (
	<button
		type="button"
		className={cn('light-button', { red })}
		onClick={onClick}
	>
		<div className="light-button__text">{label}</div>
	</button>
)
