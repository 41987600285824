import { createSlice } from '@reduxjs/toolkit'

import applicationsApi from '../../../api/applications-api'

import { onError } from '../functions'

const initialState = {
	applications: [],
	count: 0,
	filters: {
		limit: 10,
		offset: 0,
	},
	type: null,
	isLoading: false,
}

const applications = createSlice({
	name: 'applications',
	initialState,
	reducers: {
		setApplications: (state, action) => {
			state.applications = action.payload.applications
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setType: (state, action) => {
			state.type = action.payload.type
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
	},
})

const { reducer, actions } = applications

export const { setApplications, setCount, setFilters, setIsLoading, setType } = actions

// Получить список заявок по типу и ID
export const requestApplicationsById = (id) => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		applications: { filters, type },
	} = getState()

	const res = await applicationsApi.requestApplicationsById({
		...filters,
		id,
		type,
	})

	if (res.success) {
		dispatch(setApplications({ applications: res.result.props }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Подтвердить заявку
export const approveApplication = (id, appId) => async (dispatch) => {
	const res = await applicationsApi.approveApplication(appId)

	if (res.success) {
		dispatch(requestApplicationsById(id))
	} else {
		onError(dispatch, res.result)
	}
}

// Отменить заявку
export const rejectApplication = (id, appId, msg) => async (dispatch) => {
	const res = await applicationsApi.rejectApplication(appId, msg)

	if (res.success) {
		dispatch(requestApplicationsById(id))
	} else {
		onError(dispatch, res.result)
	}
}

export default reducer
