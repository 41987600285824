import React from 'react'

import { Panel } from '../../Panels/Panel/Panel'
import { DragAndDrop } from '../../DragAndDrop/DragAndDrop'
import { PanelTitle } from '../../Panels/Panel/PanelTitle/PanelTitle'

import './InfoPageAddGallery.scss'

export const InfoPageAddGallery = React.memo(
	({ gallery, addPhotos, deletePhoto }) => {
		return (
			<div className="info-page-add-gallery">
				<Panel>
					<PanelTitle text="Галерея" />
					<Panel>
						<DragAndDrop
							photos={gallery}
							uploadPhotos={addPhotos}
							deletePhoto={deletePhoto}
						/>
					</Panel>
				</Panel>
			</div>
		)
	}
)
