import React from 'react'
import cn from 'classnames'

import './QuestionnaireTabsItem.scss'

export const QuestionnaireTabsItem = React.memo(
	({ label, active, onClick }) => (
		<div className={cn('questionnaire-tab-item', { active })} onClick={onClick}>
			{label}
		</div>
	)
)
