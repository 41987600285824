import React, { forwardRef } from 'react'
import cn from 'classnames'

import { LightButton } from '../../../Buttons/LightButton/LightButton'

import { url } from '../../../../api/api'

import './TableCertificatesRow.scss'

export const TableCertificatesRow = React.memo(
	forwardRef(
		(
			{ name, image, code, status, classList = [], openModal, userPage = false, showSertificate },
			ref
		) => (
			<div className={cn('table-certificates-row', ...classList)} onClick={showSertificate}>
				<div className="table-certificates-row__info">
					<div className="table-certificates-row__top">
						<div className="table-certificates-row__image">
							{image ? (
								<img src={`${url}/static/${image}`} alt="" />
							) : (
								<div className="image-plug" />
							)}
						</div>
						<div className="table-certificates-row__name">{name}</div>
					</div>
					<div className="table-certificates-row__bottom">
						{!userPage && (
							<div className="table-certificates-row__questionnaire-button" ref={ref}>
								<LightButton label="Анкета" onClick={openModal} />
							</div>
						)}
						<div className="table-certificates-row__code">
							<span>код</span>
							{code}
						</div>
					</div>
				</div>
				<div className={cn('table-certificates-row__status', { active: status })}>
					<span>{status === 1 ? 'Действует' : status === 2 ? 'Использован' : 'Отменен'}</span>
				</div>
			</div>
		)
	)
)
