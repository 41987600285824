import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ColumnsContainer } from '../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { RightColumn } from '../../components/PageWrapper/ColumnsContainer/RightColumn/RightColumn'
import { PageWrapper } from '../../components/PageWrapper/PageWrapper'
import { Panel } from '../../components/Panels/Panel/Panel'
import { QuotasTable } from './QuotasTable/QuotasTable'
import { QuotasFilters } from './QuotasFilters/QuotasFilters'

import { getQuotasFilters } from '../../store/reducers/quotas/quotas-selectors'

import { requestQuotas, setFilters, setQuotas } from '../../store/reducers/quotas/quotas'

export const QuotasPage = React.memo(() => {
	const dispatch = useDispatch()

	const filters = useSelector(getQuotasFilters)

	useEffect(() => {
		dispatch(requestQuotas())
	}, [dispatch, filters])

	useEffect(
		() => () => {
			dispatch(setQuotas({ list: [] }))
			dispatch(setFilters({ limit: 10, offset: 0, type: 0, mode: 0, group_name: '' }))
		},
		[dispatch]
	)

	return (
		<div className="quotas-page">
			<PageWrapper>
				<ColumnsContainer>
					<LeftColumn>
						<QuotasTable />
					</LeftColumn>
					<RightColumn>
						<Panel>
							<QuotasFilters />
						</Panel>
					</RightColumn>
				</ColumnsContainer>
			</PageWrapper>
		</div>
	)
})
