import React from 'react'

import './OfficeFacilities.scss'

export const OfficeFacilities = React.memo(({
	image,
	text,
	classname,
	onClick = () => {}
}) => (
	<button
		className={`office__facilities ${classname ? 'blue' : 'gray'}`}
		onClick={onClick}
	>
		<i style={classname ? { backgroundImage: `url(${image})`, filter: 'invert(1)'  } : { backgroundImage: `url(${image})`}} ></i>
		<span>{text}</span>
	</button>
))