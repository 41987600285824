import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { SearchInput } from '../../../Inputs/SearchInput/SearchInput'
import { QuotaInput } from './QuotaInput/QuotaInput'
import { TablePagination } from '../../../TablePagination/TablePagination'
import { Button } from '../../../Buttons/Button/Button'
import { CancelButton } from '../../../Buttons/CancelButton/CancelButton'

import { createQuota, requestQuotasById } from '../../../../store/reducers/quotas/quotas'
import {
	getGroups,
	getFilters,
	getGroupsCount,
} from '../../../../store/reducers/groups/groups-selectors'
import { requestGroups, setGroupsFilters } from '../../../../store/reducers/groups/groups'
import { Checkbox } from '../../../Checkbox/Checkbox'

import './QuotaContent.scss'

export const QuotaContent = React.memo(({ closeModal, type }) => {
	const [groupId, setGroupId] = useState(0)
	const [quotasType, setQuotasType] = useState(1)

	const dispatch = useDispatch()
	const match = useRouteMatch()
	const id = match.params.id

	const groups = useSelector(getGroups)
	const groupCount = useSelector(getGroupsCount)
	const { name, offset, limit } = useSelector(getFilters)

	const setCheckboxValue = (value) => () => setQuotasType(value)

	useEffect(() => {
		dispatch(requestQuotasById(id))
	}, [dispatch, name, offset, limit, id])

	useEffect(() => {
		dispatch(requestGroups())
	}, [dispatch, name, offset, limit])

	return (
		<div className="quota__wrapper">
			<div className="quota__content">
				<div className="quota__title">Добавить квоту</div>
				<SearchInput
					placeholder={'Группа'}
					value={name}
					onChange={({ target: { value } }) => dispatch(setGroupsFilters({ name: value }))}
				/>
				<div className="quota__content-box">
					{!!groups.length &&
						groups.map((group, index) => (
							<QuotaInput
								label={group.name}
								id={group.id}
								key={`group-${index}`}
								onClick={() => setGroupId(group.id)}
							/>
						))}
				</div>
				<TablePagination
					limit={limit}
					count={groupCount}
					offset={offset}
					changePage={(value) => dispatch(setGroupsFilters({ offset: value }))}
				/>
			</div>
			<div className="quota-checkbox-block">
				<Checkbox
					name="quota-type"
					checked={quotasType === 1}
					onChange={setCheckboxValue(1)}
					labelText="Одноразовая квота"
				/>
			</div>
			<div className="quota-checkbox-block">
				<Checkbox
					name="quota-type"
					checked={quotasType === 2}
					onChange={setCheckboxValue(2)}
					labelText="Месячная квота"
				/>
			</div>
			<div className="quota-checkbox-block">
				<Checkbox
					name="quota-type"
					checked={quotasType === 3}
					onChange={setCheckboxValue(3)}
					labelText="Индивидуальная квота"
				/>
			</div>

			<div className="quota__buttons">
				<CancelButton closeIt={closeModal} />
				<Button
					label={'Сохранить'}
					type={'md1'}
					onClick={() => {
						dispatch(
							createQuota(id, {
								group_id: groupId,
								product_id: id,
								all_count: 0,
								type,
								mode: quotasType,
							})
						)
						closeModal()
					}}
				/>
			</div>
		</div>
	)
})
