import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { KnowledgeBaseItem } from './KnowledgeBaseItem/KnowledgeBaseItem'
import { KnowledgeBaseList } from './KnowledgeBaseList/KnowledgeBaseList'

import {
	getArticleData,
	getArticleDataRequested,
	getFilters,
} from '../../store/reducers/knowledge-base/knowledge-base-selectors'

import {
	requestArticleById,
	requestKnowledgeBase,
	setFilters,
} from '../../store/reducers/knowledge-base/knowledge-base'

import './KnowledgeBasePage.scss'

export const KnowledgeBasePage = React.memo(() => {
	const dispatch = useDispatch()

	const match = useRouteMatch()
	const history = useHistory()

	const { offset, name, type, status } = useSelector(getFilters)
	const articleData = useSelector(getArticleData)
	const requested = useSelector(getArticleDataRequested)

	const id = match.params.id

	const [create, setCreate] = useState(id === 'create')

	const changeCreate = (state) => () => {
		if (state) {
			history.push('/knowledge-base/create')
		} else {
			history.goBack()
		}
		setCreate(state)
	}

	useEffect(() => {
		if (id && !create && id !== 'create') {
			dispatch(requestArticleById(id))
		}
	}, [dispatch, create, id])

	useEffect(() => {
		dispatch(requestKnowledgeBase())
	}, [dispatch, offset, name, type, status])

	useEffect(
		() => () => {
			dispatch(
				setFilters({
					type: 0,
					status: 0,
					offset: 0,
					name: '',
				})
			)
		},
		[dispatch]
	)

	return (
		<div className="knowledge-page">
			{id || create ? (
				<KnowledgeBaseItem
					id={id !== 'create' ? id : ''}
					edit={id && !create}
					cancel={changeCreate(false)}
					setCreate={setCreate}
					data={articleData}
					requested={requested}
					history={history}
				/>
			) : (
				<KnowledgeBaseList createNewArticle={changeCreate(true)} />
			)}
		</div>
	)
})
