import React, { useEffect } from 'react'
import cn from 'classnames'

import './SelectInputDropdown.scss'

export const SelectInputDropdown = React.memo(
	React.forwardRef(
		({ items, showDropdown, handleItemClick, handleClickOutside }, ref) => {
			useEffect(() => {
				if (showDropdown) {
					document.addEventListener('click', handleClickOutside)
				} else {
					document.removeEventListener('click', handleClickOutside)
				}
			}, [handleClickOutside, showDropdown])

			return (
				<ul
					ref={ref}
					className={cn('select-input-dropdown', {
						show: showDropdown,
						'without-scroll': items.length <= 5,
					})}
				>
					{items.map((item) => (
						<li
							className="select-input-dropdown__item"
							key={item.title}
							onClick={handleItemClick(item)}
						>
							{item.title}
						</li>
					))}
				</ul>
			)
		}
	)
)
