import React from 'react'

export const FiltersHeader = React.memo(({ reset }) => (
	<div className="filters-header">
		<div className="filters-header__title">Фильтры</div>
		<div className="filters-header__reset" onClick={reset}>
			<i></i>
			Сбросить
		</div>
	</div>
))
