import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'

import { SearchInput } from '../../../components/Inputs/SearchInput/SearchInput'

import './ModalSearch.scss'

export const ModalSearch = React.memo(
	({
		inputPlaceholder,
		handleSelect,
		searchValue,
		setSearchValue,
		selectedValue,
		setSelectedValue,
		searchResult,
		setResult,
		searchRequest,
		disabled,
	}) => {
		const dispatch = useDispatch()

		const [dropdown, setDropdown] = useState(false)

		const handleChange = ({ target: { value } }) => {
			setSelectedValue('')
			setSearchValue(value)
		}

		const handleFocus = (state) => () => {
			setDropdown(state)
		}

		useEffect(() => {
			if (searchValue.length) {
				searchRequest(searchValue)
			} else {
				dispatch(setResult({ result: [] }))
			}
		}, [dispatch, searchValue, searchRequest, setResult])

		return (
			<div className={cn('points-modal__search-wrapper', { active: dropdown })}>
				<div
					className="points-modal__overlay"
					onClick={handleFocus(false)}
				></div>
				<SearchInput
					modal
					placeholder={inputPlaceholder}
					value={selectedValue || searchValue}
					onChange={handleChange}
					onFocus={handleFocus(true)}
					disabled={disabled}
				/>
				<ul
					className={cn('search-dropdown', {
						active: searchValue.length && searchResult.length && dropdown,
					})}
				>
					{searchResult.map((item, i) => {
						return (
							<li
								key={i}
								onClick={() => {
									handleSelect(item)
									handleFocus(false)()
								}}
							>
								<span>{item.name}</span>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}
)
