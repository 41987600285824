import React from 'react'

import './ProductItemCard.scss'
import { ProductItemChars } from './ProductItemChars/ProductItemChars'
import { ProductItemCommon } from './ProductItemCommon/ProductItemCommon'

export const ProductItemCard = React.memo(
	({
		name,
		setName,
		type,
		setType,
		description,
		setDescription,
		characteristics,
		setCharacteristics,
		productImage,
		setProductImage,
		price,
		setPrice,
		city,
		setCity,
		setCurrentBalance,
		currentBalance,
		productError,
		password,
		setPassword,
		fillPassword,
		searchCity,
		country,
		setCountry,
		searchCountry,
		setCountryId,
	}) => {
		return (
			<div className="product-item__wrapper">
				<ProductItemCommon
					name={name}
					setName={setName}
					type={type}
					setType={setType}
					description={description}
					setDescription={setDescription}
					productImage={productImage}
					setProductImage={setProductImage}
					price={price}
					setPrice={setPrice}
					city={city}
					setCity={setCity}
					setCurrentBalance={setCurrentBalance}
					currentBalance={currentBalance}
					productError={productError}
					password={password}
					setPassword={setPassword}
					fillPassword={fillPassword}
					searchCity={searchCity}
					country={country}
					setCountry={setCountry}
					searchCountry={searchCountry}
					setCountryId={setCountryId}
				/>
				<ProductItemChars
					characteristics={characteristics}
					setCharacteristics={setCharacteristics}
				/>
			</div>
		)
	}
)
