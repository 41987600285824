import React from 'react'

import { AddVideoInput } from './AddVideoInput/AddVideoInput'
import { VideoPrewiev } from './VideoPrewiev/VideoPrewiev'

export const AddVideoBlock = React.memo(({ video, add }) => (
	<div className="add-video-block">
		{video ? <VideoPrewiev video={video} /> : <AddVideoInput add={add} />}
	</div>
))
