import { createSlice } from '@reduxjs/toolkit'

import commonApi from '../../../api/common-api'
import knowledgeBaseApi from '../../../api/knowledge-base-api'

import { onError } from '../functions'

const initialState = {
	isLoading: false,
	knowledgeBase: [],
	count: 0,
	filters: {
		type: 0,
		status: 0,
		limit: 10,
		offset: 0,
		name: '',
	},
	articleData: {},
	articleDataRequested: false,
}

const knowledgeBase = createSlice({
	name: 'knowledge-base',
	initialState,
	reducers: {
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setKnowledgeBase: (state, action) => {
			state.knowledgeBase = action.payload.base
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setArticleData: (state, action) => {
			state.articleData = action.payload.data
		},

		setArticleDataRequested: (state, action) => {
			state.articleDataRequested = action.payload.requested
		},
	},
})

const { reducer, actions } = knowledgeBase

export const {
	setIsLoading,
	setKnowledgeBase,
	setCount,
	setFilters,
	setArticleData,
	setArticleDataRequested,
} = actions

// Получение базы знаний
export const requestKnowledgeBase = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		knowledgeBase: { filters },
	} = getState()

	const res = await knowledgeBaseApi.requestKnowledgeBase(filters)

	if (res.success) {
		dispatch(setKnowledgeBase({ base: res.result.knows }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Получение статьи по id
export const requestArticleById = (id) => async (dispatch) => {
	const res = await knowledgeBaseApi.requestArticleById(id)

	if (res.success) {
		dispatch(setArticleData({ data: res.result }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setArticleDataRequested({ requested: true }))
}

// Создание статьи
export const createArticle = (data, gallery) => async (dispatch) => {
	const res = await knowledgeBaseApi.createArticle(data)

	if (res.success) {
		if (gallery.getAll('new_photo').length) {
			dispatch(uploadGallery(res.result.id, gallery))
		}
		dispatch(requestKnowledgeBase())
	} else {
		onError(dispatch, res.result)
	}
}

// Редактирование статьи
export const updateArticle = (id, data, gallery) => async (dispatch) => {
	const res = await knowledgeBaseApi.updateArticle(data)

	if (res.success) {
		if (gallery.getAll('new_photo').length) {
			dispatch(uploadGallery(id, gallery))
		}
		dispatch(requestKnowledgeBase())
	} else {
		onError(dispatch, res.result)
	}
}

// Загрузка галереи
export const uploadGallery = (id, data) => async (dispatch) => {
	const res = await knowledgeBaseApi.uploadGallery(id, data)

	if (!res.success) {
		onError(dispatch, res.result)
	}
}

// Опубликовать статью
export const publishArticle = (id) => async (dispatch) => {
	const res = await commonApi.approveTarget(id, 6)

	if (res.success) {
		dispatch(requestKnowledgeBase())
	} else {
		onError(dispatch, res.result)
	}
}

// Снять статью с публикации
export const unpublishArticle = (id) => async (dispatch) => {
	const res = await commonApi.rejectTarget(id, 6)

	if (res.success) {
		dispatch(requestKnowledgeBase())
	} else {
		onError(dispatch, res.result)
	}
}

// Удалить статью
export const deleteArticle = (id) => async (dispatch) => {
	const res = await commonApi.deleteTarget(id, 6)

	if (res.success) {
		dispatch(requestKnowledgeBase())
	} else {
		onError(dispatch, res.result)
	}
}

export default reducer
