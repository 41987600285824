import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { ColumnsContainer } from '../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { PageWrapper } from '../../components/PageWrapper/PageWrapper'
import { CommentsTable } from './CommentsTable/CommentsTable'
import { Comment } from './Comment/Comment'
import { ModalContainer } from '../../components/ModalContainer/ModalContainer'
import { RejectComment } from './RejectComment/RejectComment'

import { Panel } from '../../components/Panels/Panel/Panel'

import {
	getCommentId,
	getComments,
	getFilters,
} from '../../store/reducers/comments/comments-selector'

import './CommentsPage.scss'
import {
	requestComments,
	setCommentId,
	setComments,
	setFilters,
} from '../../store/reducers/comments/comments'

export const CommentsPage = React.memo(() => {
	const dispatch = useDispatch()

	const comments = useSelector(getComments)
	const commentId = useSelector(getCommentId)
	const filters = useSelector(getFilters)

	const [modal, setModal] = useState(false)

	const modalHandler = (value) => () => setModal(value)

	useEffect(() => {
		dispatch(requestComments())
	}, [dispatch, filters])

	useEffect(
		() => () => {
			dispatch(setComments({ comments: [] }))
			dispatch(setCommentId({ commentId: '' }))
			dispatch(setFilters({ limit: 10, offset: 0, pat: '' }))
		},
		[dispatch]
	)

	return (
		<div className="comments-page">
			<PageWrapper>
				<ColumnsContainer>
					<CommentsTable comments={comments} />
					<Panel classes={!commentId && 'hide'}>
						{!!commentId && (
							<Comment modalHandler={modalHandler} comments={comments} commentID={commentId} />
						)}
					</Panel>

					<ModalContainer show={modal} onClose={modalHandler(false)}>
						{!!commentId && (
							<RejectComment
								comments={comments}
								closeModal={modalHandler(false)}
								commentID={commentId}
							/>
						)}
					</ModalContainer>
				</ColumnsContainer>
			</PageWrapper>
		</div>
	)
})
