import React, { useContext, useState } from 'react'
import cn from 'classnames'

import { LoadImage } from '../../../../components/LoadImage/LoadImage'
import { ModalContainer } from '../../../../components/ModalContainer/ModalContainer'
import { PopupMap } from '../../../OfficesPage/OfficePage/OfficeLocation/LocationMap/PopupMap/PopupMap'
import { EventDescription } from './EventDescription/EventDescription'
import { EventStream } from './EventStream/EventStream'
import { EventFormat } from './EventFormat/EventFormat'
import { EventLocation } from './EventLocation/EventLocation'
import { Preview } from '../../../../components/Preview/Preview'
import { SwitchInput } from '../../../../components/Inputs/SwitchInput/SwitchInput'
import { DatePeriod } from '../../../../components/DatePeriod/DatePeriod'

import EventContext from '../../../../context/EventContext'

import { actions } from '../actions'

import './EventInfo.scss'

export const EventInfo = React.memo(({ past, deleteEvent, fillPassword }) => {
	const { state, localDispatch } = useContext(EventContext)

	const {
		setPreview,
		setImage,
		setDateStart,
		setDateEnd,
		setAddress,
		setXCoord,
		setYCoord,
		setCity,
		setIsStream,
	} = actions

	const {
		preview,
		errors,
		image,
		dateStart,
		dateEnd,
		type,
		isStream,
		address,
		xCoord,
		yCoord,
		city,
	} = state

	const [mapModal, openMapModal] = useState(false)

	const [marker, setMarker] = useState(
		xCoord && yCoord
			? { lat: xCoord, lng: yCoord }
			: {
					lat: 54.72588164300921,
					lng: 20.501959012371646,
			  }
	)

	const modalHandler = (value) => () => openMapModal(value)

	const dispatchHandler = (callback) => (value) => localDispatch(callback(value))

	const getStreamValue = (value) => localDispatch(setIsStream(value))

	return (
		<div
			className={cn('event-info-block', {
				'past-event': past,
				'delete-event': deleteEvent,
			})}
		>
			<h2>Общее</h2>
			<Preview
				image={preview}
				setImage={dispatchHandler(setPreview)}
				accept={'.jpg, .png, .jpeg, .gif'}
				error={errors.preview}
				aspect={1 / 1}
			/>
			<LoadImage
				image={image}
				setImage={dispatchHandler(setImage)}
				accept={'.jpg, .png, .jpeg, .gif'}
			/>
			<DatePeriod
				dateStart={dateStart}
				setDateStart={dispatchHandler(setDateStart)}
				dateEnd={dateEnd}
				setDateEnd={dispatchHandler(setDateEnd)}
			/>
			<EventFormat />
			{type === 2 && <EventLocation openMapModal={modalHandler(true)} />}
			<EventDescription fillPassword={fillPassword} />

			<SwitchInput
				textActive="Онлайн трансляция"
				textUnactive="Онлайн трансляция"
				value={isStream}
				onChange={({ target: { checked } }) => getStreamValue(checked)}
				textStyle={{ fontSize: '14px', fontWeight: 400 }}
				classes="event-info-block__stream-switch"
				customColor="#34a26d"
				custom
			/>

			{isStream && <EventStream />}

			<ModalContainer show={mapModal} onClose={modalHandler(false)} map>
				<PopupMap
					setOpenMap={openMapModal}
					marker={marker}
					setMarker={setMarker}
					setAddress={dispatchHandler(setAddress)}
					address={address}
					xCoord={xCoord}
					setXCoord={dispatchHandler(setXCoord)}
					yCoord={yCoord}
					setYCoord={dispatchHandler(setYCoord)}
					city={city}
					setCity={dispatchHandler(setCity)}
				/>
			</ModalContainer>
		</div>
	)
})
