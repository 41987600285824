import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import cn from 'classnames'

import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { ColumnsContainer } from '../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { RightColumn } from '../../../components/PageWrapper/ColumnsContainer/RightColumn/RightColumn'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { TableHeader } from '../../../components/TableHeader/TableHeader'
import { TableBody } from '../../../components/TableBody/TableBody'
import { TableRow } from '../../../components/TableBody/TableRows/TableRow/TableRow'
import { Filters } from '../../../components/Filters/Filters'
import { Button } from '../../../components/Buttons/Button/Button'
import { FiltersHeader } from '../../../components/FiltersHeader/FiltersHeader'

import {
	getOffices,
	getOfficesFilters,
	getOfficesCount,
	getIsLoading,
	getCities,
} from '../../../store/reducers/offices/offices-selectors'
import {
	requestOffices,
	setOfficesFilters,
	requestOfficesCity,
} from '../../../store/reducers/offices/offices'

import { changeOffset, refreshTableItems, searchItem } from '../../../functions/common'

import wifi from '../../../assets/media/svg/wifi.svg'
import coffee from '../../../assets/media/svg/coffee.svg'

import './OfficesList.scss'

export const OfficesList = React.memo(({ createOffice }) => {
	const dispatch = useDispatch()

	const offices = useSelector(getOffices)
	const filters = useSelector(getOfficesFilters)
	const count = useSelector(getOfficesCount)
	const isLoading = useSelector(getIsLoading)
	const cities = useSelector(getCities)

	const { limit, offset, is_proposal, equipment, name, city } = filters

	// фильтр по заявкам
	const handleRequestsFilter = (value) => () => {
		dispatch(
			setOfficesFilters({
				is_proposal: is_proposal === value ? 0 : value,
				offset: 0,
			})
		)
	}

	//фильтр по городам
	const handleCityFilter = (value) => () => {
		dispatch(setOfficesFilters({ city: city === value ? '' : value, offset: 0 }))
	}

	const filteredCities = cities.map((item) => {
		return {
			title: item,
			handler: handleCityFilter(item),
			active: city === item,
		}
	})

	// фильтр офисов
	const handleOfficeEquipment = (value) => () => {
		dispatch(
			setOfficesFilters({
				equipment: equipment.includes(value)
					? equipment.filter((item) => item !== value)
					: [...equipment, value],
				offset: 0,
			})
		)
	}

	const FILTER = [
		{
			title: 'Заявки',
			items: [
				{
					title: 'С заявками',
					handler: handleRequestsFilter(1),
					active: is_proposal === 1,
				},
				{
					title: 'Без заявок',
					handler: handleRequestsFilter(2),
					active: is_proposal === 2,
				},
			],
		},
		{
			title: 'Город',
			items: filteredCities,
		},
		{
			title: 'Оснащенность',
			items: [
				{
					title: 'Wi-Fi',
					icon: wifi,
					handler: handleOfficeEquipment(1),
					active: !!equipment.find((item) => item === 1),
				},
				{
					title: 'Кофемашина',
					icon: coffee,
					handler: handleOfficeEquipment(2),
					active: !!equipment.find((item) => item === 2),
				},
			],
		},
	]

	// сбросить фильтр
	const handleResetFilter = () => {
		dispatch(
			setOfficesFilters({
				limit: 10,
				offset: 0,
				is_proposal: 0,
				equipment: [],
				status: 0,
				name: '',
				city: '',
			})
		)
	}

	useEffect(() => {
		dispatch(requestOffices())
		dispatch(requestOfficesCity())
	}, [dispatch, filters])

	return (
		<div className="offices__list">
			<PageWrapper>
				<ColumnsContainer>
					<LeftColumn>
						<TableHeader
							title={'Список офисов'}
							placeholder={'Наименование'}
							searchValue={name}
							onSearchChange={searchItem(dispatch, setOfficesFilters, 'name')}
							onRefresh={refreshTableItems(dispatch, requestOffices)}
						/>
						<TableBody
							contentTitlesArray={[
								{ title: 'Наименование' },
								{ title: 'Город' },
								{ title: 'Адрес' },
								{ title: 'Режим работы' },
								{ title: 'Оснащенность' },
								{ title: 'Кол-во мест' },
								{ title: 'Заявки' },
							]}
							additionalClasses={['offices']}
							limit={limit}
							count={count}
							offset={offset}
							setOffset={changeOffset(dispatch, setOfficesFilters)}
							isLoading={isLoading}
						>
							{offices.map((row, index) => (
								<Link to={`offices/${row.id}`} key={`table-link-${index}`}>
									<div
										className={cn('table-row', {
											before: row.date_mode === 'before',
											delete: row.status === 3,
										})}
										style={{ animationDelay: `${index * 40}ms` }}
									>
										<TableRow
											row={{
												image: row.image,
												name: row.name,
												city: row.city,
												loc_addr: row.loc_addr,
												office_time: {
													time_start: row.work_start,
													time_end: row.work_end,
												},
												equipment: row.equipment,
												balance: row.balance,
												count_props: row.count_props,
											}}
											textTooltip={row.status === 1 ? 'Неопубликованный офис' : 'Удалено'}
											status={row.status}
											deleteClass={row.status === 3}
										/>
									</div>
								</Link>
							))}
						</TableBody>
					</LeftColumn>
					<RightColumn>
						<Button label={'Добавить офис'} onClick={createOffice} />
						<Panel>
							<FiltersHeader reset={handleResetFilter} />
							<Filters filter={FILTER} />
						</Panel>
					</RightColumn>
				</ColumnsContainer>
			</PageWrapper>
		</div>
	)
})
