import React, { useState } from 'react'

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'

import { PopupMap } from './PopupMap/PopupMap'
import { ModalContainer } from '../../../../../components/ModalContainer/ModalContainer'

import mapicon from '../../../../../assets/media/svg/mapicon.svg'
import './LocationMap.scss'

export const LocationMap = React.memo(
	({ city, setCity, xCoord, setXCoord, yCoord, setYCoord, setAddress, address }) => {
		const [openMap, setOpenMap] = useState(false)
		const [marker, setMarker] = useState({ lat: xCoord, lng: yCoord })
		const [libraries] = useState(['places'])

		const containerStyle = {
			width: '100%',
			height: '100%',
		}

		const { isLoaded, loadError } = useJsApiLoader({
			id: 'google-map-script',
			googleMapsApiKey: 'AIzaSyCz4vIOvF0QQjrI3IVQ8p1nJMDvF0XVFOE',
			language: 'ru',
			region: 'RU',
			libraries,
		})

		return (
			<div className="location__map">
				{isLoaded ? (
					<GoogleMap
						mapContainerStyle={containerStyle}
						center={{ lat: xCoord, lng: yCoord }}
						zoom={16}
						options={{
							disableDefaultUI: true,
						}}
					>
						<Marker
							position={{ lat: xCoord, lng: yCoord }}
							icon={{
								url: mapicon,
								scaledSize: new window.google.maps.Size(35, 55),
							}}
						/>
					</GoogleMap>
				) : (
					loadError && console.log(loadError)
				)}
				<button className="location__button" onClick={() => setOpenMap(!openMap)}>
					Показать на карте
				</button>
				<ModalContainer show={openMap} onClose={() => setOpenMap(false)}>
					<PopupMap
						setOpenMap={setOpenMap}
						marker={marker}
						setMarker={setMarker}
						setAddress={setAddress}
						address={address}
						xCoord={xCoord}
						setXCoord={setXCoord}
						yCoord={yCoord}
						setYCoord={setYCoord}
						city={city}
						setCity={setCity}
					/>
				</ModalContainer>
			</div>
		)
	}
)
