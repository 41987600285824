import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { OfficesList } from './OfficesList/OfficesList'
import { OfficePage } from './OfficePage/OfficePage'

import { setCount } from '../../store/reducers/applications/applications'
import { requestOffice, setOfficesFilters, setOffices } from '../../store/reducers/offices/offices'
import { getOfficeInfo } from '../../store/reducers/offices/offices-selectors'

import './OfficesPage.scss'

export const OfficesPage = React.memo(() => {
	const history = useHistory()
	const dispatch = useDispatch()
	const match = useRouteMatch()

	const id = match.params.id

	const office = useSelector(getOfficeInfo)

	const [create, setCreate] = useState(id === 'create')

	const changeCreate = (state) => () => {
		if (state) {
			history.push('/offices/create')
		} else {
			history.push('/offices')
		}
		setCreate(state)
	}

	useEffect(() => {
		if (id && !create && id !== 'create') dispatch(requestOffice(id))
	}, [dispatch, id, create])

	useEffect(
		() => () => {
			dispatch(
				setOfficesFilters({
					limit: 10,
					offset: 0,
					is_proposal: 0,
					equipment: [],
					status: 0,
					name: '',
					city: '',
				})
			)
			dispatch(setOffices({ offices: [] }))
		},
		[dispatch]
	)

	useEffect(
		() => () => {
			dispatch(setCount({ count: 0 }))
		},
		[dispatch, id]
	)

	return (
		<div className="offices__page">
			{id || create ? (
				<OfficePage
					id={id}
					edit={id && !create}
					cancel={changeCreate(false)}
					setCreate={setCreate}
					office={office}
				/>
			) : (
				<OfficesList createOffice={changeCreate(true)} />
			)}
		</div>
	)
})
