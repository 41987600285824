import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SocialInput } from './SocialInput/SocialInput'

import { getLinks } from '../../../store/reducers/content/content-selector'
import { setLinks } from '../../../store/reducers/content/content'

import './SocialBlock.scss'

export const SocialBlock = React.memo(() => {
	const dispatch = useDispatch()

	const { vk, fb, ig, ld, yt } = useSelector(getLinks)

	const changeValue = (link) => ({ target: { value } }) => dispatch(setLinks({ [link]: value }))

	return (
		<div className="main-block social-block-container">
			<div className="social-block-container__header">
				<h3>Мы в соцсетях</h3>
			</div>

			<div className="social-block-container__list">
				<SocialInput
					iconName="social_vk"
					iconText="VK"
					inputText={vk}
					inputChange={changeValue('vk')}
				/>
				<SocialInput
					iconName="social_fb"
					iconText="Facebook"
					inputText={fb}
					inputChange={changeValue('fb')}
				/>
				<SocialInput
					iconName="social_inst"
					iconText="Instagram"
					inputText={ig}
					inputChange={changeValue('ig')}
				/>
				<SocialInput
					iconName="social_in"
					iconText="LinkedIn"
					inputText={ld}
					inputChange={changeValue('ld')}
				/>
				<SocialInput
					iconName="social_yt"
					iconText="YouTube"
					inputText={yt}
					inputChange={changeValue('yt')}
				/>
			</div>
		</div>
	)
})
