import React, { useState } from 'react'
import { Input } from '../../../../../../components/Inputs/Input/Input'

import './ProductSearchCity.scss'

export const ProductSearchCity = React.memo(
	({
		value,
		setValue,
		productError,
		search,
		label,
		setCountryId = () => {},
		setCity = () => {},
	}) => {
		const [focus, setFocus] = useState(false)

		return (
			<div className="product-item__common-search">
				<Input
					label={label}
					value={value}
					onChange={({ target: { value } }) => setValue(value)}
					onFocus={() => setFocus(true)}
					className={productError ? '' : 'error'}
				/>
				{focus && (
					<div
						className="product-item__common-wrapper"
						onClick={() => setFocus(false)}
					></div>
				)}
				{focus && (
					<div className="city__modal">
						{search.map((item, index) => (
							<button
								className="city__modal-item"
								key={`city-modal-${index}`}
								onClick={() => {
									setValue(item.title)
									setCountryId(item.country_id)
									setCity('')
									setFocus(false)
								}}
							>
								{item.title}
							</button>
						))}
					</div>
				)}
			</div>
		)
	}
)
