import { getAdminToken } from '../functions/localStorage'

import instance from './api'

const commonApi = {
	async getCityForFilters(type) {
		/* type = 1 - офисы, 2 - события, 3 - товары */

		const response = await instance.post(
			`support/city/filter?type=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async getCountryForFilters(type) {
		/* type = 1 - офисы, 2 - события, 3 - товары */

		const response = await instance.post(
			`support/country/filter?type=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async getTableCertificate({ limit, offset, id }) {
		const response = await instance.post(
			`admin/get/certs/product?limit=${limit}&offset=${offset}&product_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async getTableProps({ limit, offset, id }) {
		const response = await instance.post(
			`admin/get/props/product?limit=${limit}&offset=${offset}&product_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	/* 
	Для запросов approve, reject, delete следующий набор type: 
	1 - офисы
	2 - событие
	3 - продукт
	4 - новости
	*/

	async approveTarget(id, type) {
		const response = await instance.post(
			`admin/set/approve/target?target_id=${id}&type=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async rejectTarget(id, type) {
		const response = await instance.post(
			`admin/set/reject/target?target_id=${id}&type=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async deleteTarget(id, type) {
		const response = await instance.post(
			`admin/delete/target?target_id=${id}&type=${type}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async requestListCities(pat, country) {
		const response = await instance.post(
			`/support/city?pat=${pat}&country_id=${country}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)
		return response.data
	},

	async searchCountry(text) {
		const response = await instance.post(
			`/support/country?pat=${text}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	// Получение списка специализаций и технологий
	async getLocal() {
		const response = await instance.get('support/translate?type=skill&lang=ru')

		return response.data
	},
}

export default commonApi
