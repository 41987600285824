import React from 'react'

import './ContentItemLine.scss'

export const ContentItemLine = React.memo(({ label, value, link }) => (
	<div className="content-item-line">
		<div className="content-item-line__label">{label}</div>
		{value ? (
			link ? (
				<>
					<a href={value} download className="content-item-line__link">
						Скачать
					</a>
				</>
			) : (
				<div className="content-item-line__value">{value}</div>
			)
		) : (
			<div className="content-item-line__value placeholder">Отсутствует</div>
		)}
	</div>
))
