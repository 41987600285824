import React from 'react'
import cn from 'classnames'
import TextareaAutosize from 'react-textarea-autosize'

import './TextAreaField.scss'

export const TextAreaField = React.memo(
	({
		label = '',
		onChange,
		onBlur,
		id,
		name = '',
		value = '',
		error,
		onClick,
		minRows = 1,
		readOnly = false,
	}) => {
		/* variables */
		const _id = id ?? `field-${Math.random().toString(36).substring(2, 11)}`
		/* / variables */

		return (
			<div
				className={cn('textarea-field', {
					full: (value !== '' && value !== null) || error,
					'has-error': error,
				})}
				onClick={onClick}
			>
				<TextareaAutosize
					minRows={minRows}
					type="text"
					id={_id}
					name={name}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					readOnly={readOnly}
				/>
				<label htmlFor={_id}>{label}</label>
			</div>
		)
	}
)
