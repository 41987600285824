import React from 'react'
import cn from 'classnames'

import './CommentTabs.scss'

export const CommentTabs = React.memo(({ name, active, setTabs }) => (
	<div onClick={setTabs} className={cn('comment-tab-block', { active })}>
		{name}
	</div>
))
