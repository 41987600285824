import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import './Tile.scss'
import { ItemOptions } from '../../ItemOptions/ItemOptions'

export const Tile = React.memo(
	({
		children,
		label,
		image,
		link,
		linkText,
		color,
		style,
		gridArea,
		small,
		access,
		colorClass = '',
		classes = '',
		deleteItem,
		check,
		toggleModal,
		hideTitle = false,
	}) => {
		const _style = {
			backgroundImage: `url(${image})`,
			...style,
		}

		return (
			<div className={`tile__wrapper ${access ? '' : 'none'} ${classes}`} style={{ gridArea }}>
				<div className={cn(`tile ${colorClass}`, { small, hideTitle })} style={_style}>
					{children}
					{color && label && (
						<div className="tile__label" style={{ backgroundColor: color }}>
							<span className="tile__sublabel">{label}</span>
						</div>
					)}
					{link && linkText && (
						<Link to={link} className="tile__link">
							<div className="tile__link-text">{linkText}</div>
						</Link>
					)}
				</div>
				<ItemOptions
					showDeleteButton={check}
					toggleModal={toggleModal}
					deleteItem={deleteItem}
					absolute
				/>
				<div className="tile__blur" style={{ backgroundImage: `url(${image})` }}></div>
			</div>
		)
	}
)
