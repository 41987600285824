export const actions = {
	setImage: (value) => ({
		type: 'image',
		payload: value,
	}),

	setDateStart: (value) => ({
		type: 'date-start',
		payload: value,
	}),

	setDateEnd: (value) => ({
		type: 'date-end',
		payload: value,
	}),

	setType: (value) => ({
		type: 'type',
		payload: value,
	}),

	setProgramms: (value) => ({
		type: 'programms',
		payload: value,
	}),

	setCity: (value) => ({
		type: 'city',
		payload: value,
	}),

	setAddress: (value) => ({
		type: 'address',
		payload: value,
	}),

	setAddressInfo: (value) => ({
		type: 'address-info',
		payload: value,
	}),

	setName: (value) => ({
		type: 'name',
		payload: value,
	}),

	setDescription: (value) => ({
		type: 'description',
		payload: value,
	}),

	setIsStream: (value) => ({
		type: 'is-stream',
		payload: value,
	}),

	setStreamLink: (value) => ({
		type: 'stream-link',
		payload: value,
	}),

	setStreamStartTime: (value) => ({
		type: 'stream-start-time',
		payload: value,
	}),

	setStreamEndTime: (value) => ({
		type: 'stream-end-time',
		payload: value,
	}),

	setXCoord: (value) => ({
		type: 'x-coord',
		payload: value,
	}),

	setYCoord: (value) => ({
		type: 'y-coord',
		payload: value,
	}),

	setErrors: (value) => ({
		type: 'errors',
		payload: value,
	}),

	setPassword: (value) => ({
		type: 'password',
		payload: value,
	}),

	setPreview: (value) => ({
		type: 'preview',
		payload: value,
	}),
}
