import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Slider } from 'antd'

import { setProductsFilters } from '../../../../store/reducers/products/products'

import 'antd/dist/antd.css'

import './FilterRange.scss'

export const FilterRange = React.memo(({ price, setPrice, maxPrice }) => {
	const dispatch = useDispatch()

	const [sliderWidth, setSliderWidth] = useState(0)
	const [marginSlider, setMarginSlider] = useState(0)
	const valueWidth = useRef()

	let marginTooltip, marginMark

	if (typeof sliderWidth === 'string') {
		const marginTip = sliderWidth.split('%')[0]
		marginTooltip = marginTip / 2

		marginMark = marginSlider.split('%')[0]
	}

	const setPriceFilterValue = () => (value) => {
		dispatch(
			setProductsFilters({
				min_price: value[0],
				max_price: value[1],
				offset: 0,
			})
		)
	}

	useEffect(() => {
		if (valueWidth.current) {
			setSliderWidth(valueWidth.current.sliderRef.childNodes[1].style.width)
			setMarginSlider(valueWidth.current.sliderRef.childNodes[3].style.left)
		}
	}, [price, setSliderWidth, setMarginSlider])

	return (
		<div className="filter_range">
			<div
				className="tooltip"
				style={{
					marginLeft: `${marginTooltip < 20 && +marginMark < 18 ? '20' : marginTooltip}%`,
					left: `${+marginMark > 60 ? 'auto' : marginSlider}`,
					right: `${+marginMark > 60 ? '-31%' : 'auto'}`,
				}}
			>
				{`${price[0]} - ${price[1]}`}
				<i />
			</div>
			<Slider
				range
				tipFormatter={null}
				value={price}
				defaultValue={[0, 0]}
				max={maxPrice}
				onChange={(value) => setPrice(value)}
				onAfterChange={setPriceFilterValue()}
				ref={valueWidth}
			/>
		</div>
	)
})
