import React, { useEffect } from 'react'
import cn from 'classnames'

import { IconButton } from '../IconButton/IconButton'

import './TablePagination.scss'

export const TablePagination = React.memo(({ limit, count, offset, changePage }) => {
	const all = Math.ceil(count / limit)

	const page = offset > 0 ? offset / limit + 1 : 1

	const prevPage = () => {
		if (page > 0) changePage(offset - limit)
	}

	const nextPage = () => {
		if (page < all) changePage(offset + limit)
	}

	if (count === 0) return null

	useEffect(() => {
		const pageContainer = document.querySelector('.page-container')
		if (pageContainer.scrollTop && !!offset) pageContainer.scrollTo(0, 0)
	}, [offset])

	return (
		<div className="table-pagination-block">
			<div className="pagination-component">
				<IconButton
					className={cn('round-icon-button', { disabled: page === 1 })}
					iconName="chevron"
					size={32}
					onClick={prevPage}
				/>
				<div className="text">
					<span>{page ? page : 0}</span>
					<span>из</span>
					<span>{all ? all : 0}</span>
				</div>
				<IconButton
					className={cn('round-icon-button', { disabled: page === all })}
					iconName="chevron"
					size={32}
					onClick={nextPage}
				/>
			</div>
		</div>
	)
})
