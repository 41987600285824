export const generateID = (prefix = '') => `${prefix}${Math.random().toString(36).substring(7)}`

export const getSocial = (link) => {
	if (link.includes('t.me')) return 'social_tg'
	if (link.includes('habr.com')) return 'social_habr'
	if (link.includes('github.com')) return 'social_github'
	if (link.includes('instagram.com')) return 'social_inst'
	if (link.includes('vk.com')) return 'social_vk'
	if (link.includes('facebook.com') || link.includes('fb.com')) return 'social_fb'
	if (link.includes('linkedin.com') || link.includes('linked.in')) return 'social_in'
	if (link.includes('behance.net')) return 'social_behance'
	if (link.includes('dribbble.com')) return 'social_dribbble'

	// if (link.includes('youtube.com') || link.includes('youtu.be')) return 'social_yt'

	return ''
}

export const getSocialTitle = (type) => {
	const switchObj = {
		telegram: 'Telegram',
		habr: 'Habr',
		github: 'GitHub',
		instagram: 'Instagram',
		vk: 'VK',
		facebook: 'Facebook',
		linkedin: 'LinkedIn',
		behance: 'Behance',
		dribble: 'Dribbble',
	}
	return switchObj[type]
}

export const getRole = (role) => {
	switch (role) {
		case 1:
			return 'Суперадмин'

		case 2:
			return 'Админ'

		case 3:
			return 'Поставщик'

		case 4:
			return 'Рекрутер'

		case 5:
			return 'Пользователь'

		default:
			return 'Нет роли'
	}
}

export const getStatusByNumber = (statusNumber) => {
	switch (statusNumber) {
		case 1:
			return 'Модерация'

		case 2:
			return 'Активный'

		case 3:
			return 'Отклонён'

		case 4:
			return 'Без анкеты'

		default:
			return 'Нет статуса'
	}
}

export const getStatusSpecs = (statusNumber) => {
	let statusOpt = { icon: '', color: '', text: '' }

	switch (statusNumber) {
		case 2:
			statusOpt.icon = 'approved'
			statusOpt.color = 'green'
			break

		case 3:
			statusOpt.icon = 'x-mark-red'
			statusOpt.color = 'red'
			break

		case 4:
			statusOpt.icon = 'x-mark'
			statusOpt.color = 'black'
			break

		case 1:
		default:
			statusOpt.icon = 'moderate'
			statusOpt.color = 'blue'
			break
	}

	statusOpt.text = getStatusByNumber(statusNumber)

	return statusOpt
}

export const getSkillName = (level) => {
	switch (level) {
		case 1:
			return 'junior'

		case 2:
			return 'middle'

		case 3:
			return 'senior'

		default:
			return ''
	}
}

export const phoneSpaces = (tel = '') =>
	tel && `${tel[0]} ${tel.substr(1, 3)} ${tel.substr(4, 3)} ${tel.substr(7, 2)} ${tel.substr(9, 2)}`

export const workType = (type) => {
	switch (type) {
		case 0:
			return 'Офис'

		case 1:
			return 'Удаленно'

		case 2:
			return 'Офис, Удаленно'

		default:
			return 'Неизвестно'
	}
}

export const getLangName = (level) => {
	switch (level) {
		case 0:
			return 'A1'

		case 1:
			return 'A2'

		case 2:
			return 'B1'

		case 3:
			return 'B2'

		case 4:
			return 'C1'

		case 5:
			return ' C2'

		default:
			return 'Неизвестно'
	}
}

// Генерирует и форматирует дату из ISO-строки
export const createDate = (iso, locale = 'ru', options = {}) => {
	if (!iso) return ''

	return new Date(iso).toLocaleString(locale, options)
}

// Генерирует и форматирует диапазон дат из ISO-строки
export const createDateRange = (from, to, locale = 'ru', options = {}) => {
	const start = new Date(from)
	const end = new Date(to)

	return +end - +start < 86400000
		? createDate(from, locale, options)
		: `${createDate(from, locale, options)} - ${createDate(to, locale, options)}`
}

// расчет для компонента AvatarPlug
export const getPlugNumber = (id) =>
	(id.charCodeAt(id.length - 1) + id.charCodeAt(id.length - 2)) % 10

export const uppercaseFirstChar = (str) => `${str[0].toUpperCase()}${str.slice(1)}`

/*
 ** Смена страницы
 */

export const changeOffset = (dispatch, callback) => (value) => dispatch(callback({ offset: value }))

/*
 ** Обновление списка в таблице
 */

export const refreshTableItems = (dispatch, callback) => () => dispatch(callback())

/*
 ** Поиск элемента в таблице
 */

export const searchItem =
	(dispatch, callback, searchValue) =>
	({ target: { value } }) =>
		dispatch(callback({ [searchValue]: value, offset: 0 }))
