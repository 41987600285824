//вкладки для панели с таблицами в OfficeTable

export const filterTabs = (props) => [
	{
			title: 'Заявки',
			count: props
	},
	{
			title: 'Сертификаты',
	},
	{
			title: 'Квоты',
	},
]
