import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import './NavLinkItem.scss'

export const NavLinkItem = React.memo(({ text, linkValue }) => (
	<NavLink to={linkValue} className={cn('nav-link')} activeClassName="nav-link--active">
		{text}
	</NavLink>
))
