import React, { useState } from 'react'

import { Panel } from '../../../components/Panels/Panel/Panel'
import { Button } from '../../Buttons/Button/Button'
import { Input } from '../../Inputs/Input/Input'
import { InfoPageTag } from './InfoPageTag/InfoPageTag'

import './InfoPageTags.scss'

export const InfoPageTags = React.memo(({ tags, setTags }) => {
	const [value, setValue] = useState()

	const addTag = () => {
		setTags((prev) => [...prev, value])
		setValue('')
	}

	const handleInputChange = ({ target: { value } }) => setValue(value)

	const handleDeleteTag = (index) => () => {
		setTags((prev) => prev.filter((item, i) => i !== index))
	}

	return (
		<div className="info-panel-tags">
			<Panel>
				<div className="info-panel-tags__title">Тэги</div>
				<div className="info-panel-tags__add">
					<Input label="Имя тэга" value={value} onChange={handleInputChange} />
					<Button type="s" label="Добавить" onClick={addTag} />
				</div>
				<div className="info-panel-tags__items">
					{tags.map((tag, i) => (
						<InfoPageTag
							key={`${tag}-${i}`}
							text={tag}
							deleteTag={handleDeleteTag(i)}
						/>
					))}
				</div>
			</Panel>
		</div>
	)
})
