import { getAdminToken } from '../functions/localStorage'
import instance from './api'

const officesApi = {
	async requestOffices(filters) {
		const response = await instance.post( //список офисов
			'/admin/search/office',
			{...filters},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async createOffice(data) {
		const response = await instance.post( // создать офис
			'/admin/create/office',
			{...data},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async updateOffice(data) { 
		const response = await instance.post( // обновить офис
			'/admin/update/office',
			{...data},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async requestOffice(id) {
		const response = await instance.post( // информация об офисе
			`/office/get?office_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	}
}

export default officesApi