import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import { TableBody } from '../../../components/TableBody/TableBody'
import { TableRow } from '../../../components/TableBody/TableRows/TableRow/TableRow'

import { getUsersList, setFilters } from '../../../store/reducers/users/users'

import {
	getCount,
	getFilters,
	getIsLoading,
	getUsers,
} from '../../../store/reducers/users/users-selectors'

import { changeOffset, getPlugNumber, getRole } from '../../../functions/common'

import { avatarPlug } from '../../../components/Avatar/avatar-functions'

import './UsersTable.scss'

export const UsersTable = () => {
	const dispatch = useDispatch()

	const users = useSelector(getUsers)
	const count = useSelector(getCount)
	const filters = useSelector(getFilters)
	const isLoading = useSelector(getIsLoading)

	const { limit, offset, sort_money: sortMoney } = filters

	const handleSort = () => {
		dispatch(setFilters({ sort_money: !sortMoney }))
	}

	useEffect(() => {
		dispatch(getUsersList())
	}, [dispatch, filters])

	return (
		<div className="users-table">
			<TableBody
				contentTitlesArray={[
					{ title: '' },
					{ title: 'ФИО' },
					{ title: 'Почта' },
					{ title: 'Телефон' },
					{ title: 'Статус анкеты' },
					{ title: 'Группы' },
					{ title: 'Интеграция' },
					{ title: 'Роль' },
					{ title: 'Баллы', handler: handleSort, withSort: true },
				]}
				additionalClasses={['users-table']}
				limit={limit}
				count={count}
				offset={offset}
				setOffset={changeOffset(dispatch, setFilters)}
				isLoading={isLoading}
			>
				{users.map((user, index) => {
					const plugNumber = getPlugNumber(user.id)

					return (
						<Link to={`/users/${user.id}`} key={`table-link-${index}`}>
							<div
								className={cn('table-row', { banned: user.isbanned })}
								style={{ animationDelay: `${index * 40}ms` }}
								key={`users-row-${index}`}
							>
								<TableRow
									row={{
										user_image: user.image,
										name: `${user.surname} ${user.name} ${user.patron}`,
										email: user.email,
										phone: user.tel,
										userStatus: user.status,
										userGroup: user.groups,
										bots: user.bots,
										role: getRole(user.role),
										users_value: user.money,
										ban: {
											isBanned: user.isbanned,
										},
									}}
									plug={avatarPlug(plugNumber)}
									plugNumber={plugNumber}
								/>
							</div>
						</Link>
					)
				})}
			</TableBody>
		</div>
	)
}
