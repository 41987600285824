import React, { useCallback, useRef, useState } from 'react'

import { SelectInputDropdown } from './SelectInputDropdown/SelectInputDropdown'

import { generateID } from '../../../functions/common'

import './SelectInput.scss'

export const SelectInput = React.memo(
	({ label, items = [], onChange, style, initialValue = null }) => {
		const [showDropdown, setShowDropdown] = useState(false)
		const [value, setValue] = useState(initialValue || '')

		const dropdownRef = useRef()

		const handleInputClick = () => {
			setShowDropdown(!showDropdown)
		}

		const handleItemClick =
			({ title, value }) =>
			() => {
				onChange(String(value))
				setValue(title)
				setShowDropdown(false)
			}

		const id = generateID('select-')

		const handleClickOutside = useCallback(({ target }) => {
			if (!dropdownRef.current.contains(target)) setShowDropdown(false)
		}, [])

		return (
			<div className="select-input" style={style} onClick={handleInputClick}>
				<input
					type="text"
					value={value}
					onChange={() => {}}
					className="select-input__text"
				/>
				{label && (
					<label htmlFor={id} className="select-input__label">
						{label}
					</label>
				)}
				<SelectInputDropdown
					ref={dropdownRef}
					items={items}
					showDropdown={showDropdown}
					handleItemClick={handleItemClick}
					handleClickOutside={handleClickOutside}
				/>
			</div>
		)
	}
)
