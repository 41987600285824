import { getAdminToken } from '../functions/localStorage'

import instance from './api'

const eventsApi = {
	async requestEvent(id) {
		const response = await instance.post(
			`event/get?event_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async updateEvent(data) {
		const response = await instance.post(`admin/update/event`, data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async createEvent(data) {
		const response = await instance.post(`admin/create/event`, data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async requestEvents(data) {
		const response = await instance.post(`admin/search/event`, data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async setEventStatus(eventId, status, msg) {
		const response = await instance.post(
			`admin/set/eventstatus?event_id=${eventId}&${status}`,
			{ msg },
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async rejectEvent(eventId) {
		const response = await instance.post(
			`admin/set/reject/event?event_id=${eventId}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async approveEvent(eventId) {
		const response = await instance.post(
			`admin/set/approve/event?event_id=${eventId}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async uploadImage(eventId, data) {
		const response = await instance.post(`event/upload/image?event_id=${eventId}`, data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async uploadVideo(eventId, link) {
		const response = await instance.post(
			`event/upload/video?event_id=${eventId}&link=${link}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async deleteMedia(eventId, type, index) {
		const response = await instance.post(
			`event/delete/media?event_id=${eventId}&type=${type}&index=${index}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},
}

export default eventsApi
