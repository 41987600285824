import { getAdminToken } from '../functions/localStorage'

import instance from './api'

const contentApi = {
	async searchNewsValue({ limit, offset, value }) {
		const response = await instance.post(
			`filling/getmain?pattern=${value}&limit=${limit}&offset=${offset}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async updateContentPage(page) {
		const response = await instance.post(`/filling/set`, page, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async requestContentPage() {
		const response = await instance.post(
			`/filling/get`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},
}

export default contentApi
