import { createSlice } from '@reduxjs/toolkit'

import commentsApi from '../../../api/comments-api'

const initialState = {
	isLoading: false,
	comments: [],
	filters: {
		limit: 10,
		offset: 0,
		pat: '',
	},
	commentId: '',
	count: 0,
	new_count: 0,
}

const comments = createSlice({
	name: 'comments',
	initialState,
	reducers: {
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},

		setComments: (state, action) => {
			state.comments = action.payload.comments
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setNewCount: (state, action) => {
			state.newCount = action.payload.newCount
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setCommentId: (state, action) => {
			state.commentId = action.payload.commentId
		},
	},
})

const { reducer, actions } = comments

export const {
	setIsLoading,
	setComments,
	setFilters,
	setCount,
	setNewCount,
	setCommentId,
} = actions

export const requestComments = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		comments: { filters },
	} = getState()

	const res = await commentsApi.requestComments(filters)

	if (res.success) {
		dispatch(setComments({ comments: res.result.comments }))
		dispatch(setCount({ count: res.result.count }))
		dispatch(setNewCount({ newCount: res.result.new_count }))
	}

	dispatch(setIsLoading({ isLoading: false }))
}

export const rejectComment = (msg) => async (dispatch, getState) => {
	const {
		comments: { commentId },
	} = getState()

	const res = await commentsApi.rejectComment(commentId, msg)

	if (res.success) {
		dispatch(setCommentId({ commentId: '' }))
		dispatch(requestComments())
	}
}

export const changeCommentStatus = () => async (dispatch, getState) => {
	const {
		comments: { commentId },
	} = getState()

	const res = await commentsApi.changeCommentStatus(commentId)

	if (res.success) {
		dispatch(requestComments())
	}
}

export default reducer
