export const getTransactions = ({ transactions: { transactions } }) => transactions

export const getTransactionsById = ({ transactions: { transactionsById } }) => transactionsById

export const getCount = ({ transactions: { count } }) => count

export const getFilters = ({ transactions: { filters } }) => filters

export const getSearchResult = ({ transactions: { searchResult } }) => searchResult

export const getIsLoading = ({ transactions: { isLoading } }) => isLoading
