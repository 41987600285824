import React from 'react'

export const DnDInputFace = React.memo(() => {
    return (
        <div className='dropzone-input-info'>
            <div className="icon-wrapper"><i></i></div>
            <p>Перетащи сюда файлы, чтобы добавить</p>  
            <p className='dropzone__footnote'>(PNG или JPEG)</p>
            <span>или</span>
            <div className='fake-button'><div className="icon-wrapper"><i></i></div><span>Загрузить</span></div>
        </div>
    )
})
