export const getEventsList = ({ events: { eventsList } }) => eventsList
export const getRequestList = ({ events: { propsList } }) => propsList
export const getCertList = ({ events: { certList } }) => certList
export const getQuotasList = ({ events: { quotasList } }) => quotasList
export const getCount = ({ events: { count } }) => count
export const getPropsCount = ({ events: { propsCount } }) => propsCount
export const getFilters = ({ events: { filters } }) => filters
export const getPageFilters = ({ events: { pageFilters } }) => pageFilters
export const getSelectedEvent = ({ events: { event } }) => event
export const getCities = ({ events: { cities } }) => cities

export const getEventPhotos = ({
	events: {
		event: { photo },
	},
}) => photo
export const getEventVideos = ({
	events: {
		event: { video },
	},
}) => video

export const getIsLoading = ({ events: { isLoading } }) => isLoading
