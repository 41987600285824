import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'

import { GlobalNot } from './GlobalNot/GlobalNot'

import { getGlobalNots } from '../../store/reducers/global-nots/global-nots-selectors'

import './GlobalNots.scss'

export const GlobalNots = React.memo(() => {
	const nots = useSelector(getGlobalNots)

	return (
		<div className={cn('global-nots', { visible: nots.length })}>
			{nots.map((not) => (
				<GlobalNot key={not.id} not={not} />
			))}
		</div>
	)
})
