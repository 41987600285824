import instance from './api'

import { getAdminToken } from '../functions/localStorage'

const authApi = {
	async regAuth({ phone, cap = '', search }) {
		const _search = search ? `&${search}` : ''

		const response = await instance.post(`core/regauth?tel=${phone}&cap=${cap}${_search}`)

		return response.data
	},

	async verif({ phone, pin }) {
		const response = await instance.post(`core/verif/admin?tel=${phone}&pin=${pin}`)

		return response.data
	},

	async verifAdmin({ phone, password, cap = '' }) {
		const response = await instance.post(`core/verif/admin?tel=${phone}&pass=${password}&cap=${cap}`)

		return response.data
	},

	async refresh() {
		const response = await instance.post(
			'core/refresh/token',
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},
}

export default authApi
