import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabsSelect } from '../../../../components/TabsSelect/TabsSelect'
import { Panel } from '../../../../components/Panels/Panel/Panel'
import { Button } from '../../../../components/Buttons/Button/Button'
import { TableItem } from './TableItem/TableItem'
import { SearchInput } from '../../../../components/Inputs/SearchInput/SearchInput'
import { ModalContainer } from '../../../../components/ModalContainer/ModalContainer'
import { GroupModal } from './GroupModal/GroupModal'

import { setUsersFilters } from '../../../../store/reducers/groups/groups'
import { getUsersCount } from '../../../../store/reducers/groups/groups-selectors'

import './GroupTable.scss'

export const GroupTable = React.memo(({ id, edit }) => {
	const dispatch = useDispatch()

	const count = useSelector(getUsersCount)

	const [tab, setTab] = useState(0)
	const [addMember, setAddMember] = useState(false)
	const [questionnaireModal, openQuestionnaireModal] = useState(false)

	const tabs = [
		{
			title: 'Участники',
			count: count,
		},
	]

	return (
		(id || edit) && (
			<div className="group__table">
				<TabsSelect tabs={tabs} tab={tab} setTab={setTab} />
				<Panel>
					<div className="group__header">
						<SearchInput
							placeholder={'Пользователь'}
							onChange={({ target: { value } }) =>
								dispatch(setUsersFilters({ pat: value, offset: 0 }))
							}
						/>
						<Button
							label="Добавить участников"
							type="md1"
							onClick={() => setAddMember(!addMember)}
						/>
					</div>
					<TableItem
						questionnaireModal={questionnaireModal}
						openQuestionnaireModal={openQuestionnaireModal}
						id={id}
					/>
				</Panel>
				<ModalContainer
					profile
					show={addMember}
					onClose={() => setAddMember(false)}
				>
					<GroupModal
						id={id}
						addMember={addMember}
						setAddMember={setAddMember}
						openQuestionnaireModal={openQuestionnaireModal}
					/>
				</ModalContainer>
			</div>
		)
	)
})
