import React from 'react'
import { useSelector } from 'react-redux'

import { ContentItemLine as Line } from '../QuestionnaireContentItem/ContentItemLine/ContentItemLine'
import { ContentItemLanguageLine as LanguageLine } from '../QuestionnaireContentItem/ContentItemLanguageLine/ContentItemLanguageLine'
import { ContentItemSocialLine as SocialLine } from '../QuestionnaireContentItem/ContentItemSocialLine/ContentItemSocialLine'
import { ContentItemText as Text } from '../QuestionnaireContentItem/ContentItemText/ContentItemText'
import { QuestionnaireContentItem as ContentItem } from '../QuestionnaireContentItem/QuestionnaireContentItem'

import { getLangName, phoneSpaces, createDate } from '../../../../functions/common'

import { getUserData } from '../../../../store/reducers/user/user-selectors'

export const DataTabContent = React.memo(() => {
	const { user, local } = useSelector(getUserData)

	const optionsDate = {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
	}
	console.log(user.first_page)

	return (
		<div>
			<ContentItem title="Личные данные">
				<Line label="Телефон" value={phoneSpaces(`${user.tel}`)} />
				<Line label="Email" value={user.first_page.email} />
				<Line label="Страна" value={local.countries[user.first_page.country]} />
				<Line label="Город" value={local.cities[user.first_page.city]} />
				<Line
					label="Дата рождения"
					value={createDate(user.first_page.birthday, 'ru', optionsDate)}
				/>
			</ContentItem>
			<ContentItem title="Опыт работы">
				<Line label="Опыт работы в IT" value={user.first_page.exp_it} />
			</ContentItem>
			<ContentItem title="Иностранные языки">
				{user.first_page.langs ? (
					user.first_page.langs.map((item) => (
						<LanguageLine
							key={item.id}
							label={local.language[item.id]}
							value={getLangName(item.skill)}
						/>
					))
				) : (
					<span className="placeholder">Языки отсутствуют</span>
				)}
			</ContentItem>
			<ContentItem title="Социальные сети">
				{user.first_page.social ? (
					user.first_page.social.map((item, index) => <SocialLine key={index} value={item} />)
				) : (
					<span className="placeholder">Соцсети отсутствуют</span>
				)}
			</ContentItem>
			<ContentItem title="Обо мне">
				{user.first_page.about !== '' ? (
					<Text text={user.first_page.about} />
				) : (
					<span className="placeholder">Описание отсутствует</span>
				)}
			</ContentItem>
		</div>
	)
})
