import { createSlice } from '@reduxjs/toolkit'

import notificationsApi from '../../../api/notifications-api'

import { onError } from '../functions'

const initialState = {
	notifications: [],
	count: 0,
	newCount: 0,
	limit: 30,
	offset: 0,
}

const notifications = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		setNotifications: (state, action) => {
			state.notifications = action.payload.nots
		},
		setNewNotification: (state, action) => {
			state.notifications = [action.payload.not, ...state.notifications]
		},
		setCount: (state, action) => {
			state.count = action.payload.count
		},
		setNewCount: (state, action) => {
			state.newCount = action.payload.new
		},
		incNewCount: (state) => {
			state.newCount = state.newCount + 1
		},
	},
})

const { reducer, actions } = notifications

export const { setNotifications, setNewNotification, setCount, setNewCount, incNewCount } = actions

/*
	Получить уведомления
*/
export const requestNotifications = () => async (dispatch, getState) => {
	const {
		notifications: { limit, offset },
	} = getState()

	const res = await notificationsApi.requestNotifications({ limit, offset })

	if (res.success) {
		dispatch(setNotifications({ nots: res.result.notifs }))
		dispatch(setCount({ count: res.result.count }))
		dispatch(setNewCount({ new: res.result.new_notif }))
	} else {
		onError(dispatch, res.result)
	}
}

/*
	Пометить уведомления как просмотренные
*/
export const checkNots = () => async (dispatch) => {
	const res = await notificationsApi.checkNots()

	if (res.success) {
		dispatch(requestNotifications())
	} else {
		onError(dispatch, res.result)
	}
}

export default reducer
