import { getAdminToken } from '../functions/localStorage'
import instance from './api'

const analiticsApi = {
	async requestAnaliticsData(filters) {
		const response = await instance.post(`admin/search/analitic`, filters, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},
}

export default analiticsApi
