import React from 'react'

import './QuestionnaireContentItem.scss'

export const QuestionnaireContentItem = React.memo(({ title, children }) => (
	<div className="questionnaire-content-item">
		<div className="questionnaire-content-item__title">{title}</div>
		{children}
	</div>
))
