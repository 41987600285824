import React from 'react'
import { useDispatch } from 'react-redux'

import { TextAreaField } from '../../../components/TextAreaField/TextAreaField'
import { LoadImage } from '../../../components/LoadImage/LoadImage'
import { InfoPageItemBlockHeader } from './InfoPageItemBlockHeader/InfoPageItemBlockHeader'
import { AddVideoBlock } from './AddVideoBlock/AddVideoBlock'

import { addNot } from '../../../store/reducers/global-nots/global-nots'

import './InfoPageItemBlock.scss'

const configs = {
	text: {
		isTextarea: true,
		title: 'Текст',
	},
	key: { isTextarea: true, title: 'Ключевой текст' },
	photo: { isTextarea: false, title: 'Изображение' },
	video: { isTextarea: false, title: 'Видео' },
}

export const InfoPageItemBlock = ({
	block,
	blocks,
	setBlocks,
	index,
	blocksLength,
}) => {
	const dispatch = useDispatch()

	const { type, content } = block
	const { isTextarea, title } = configs[type]

	const moveBack = () => {
		if (index > 0) {
			const result = [
				...blocks.slice(0, index - 1),
				blocks[index],
				blocks[index - 1],
				...blocks.slice(index + 1),
			]

			setBlocks(result)
		}
	}

	const moveForw = () => {
		if (index < blocks.length - 1) {
			const result = [
				...blocks.slice(0, index),
				blocks[index + 1],
				blocks[index],
				...blocks.slice(index + 2),
			]

			setBlocks(result)
		}
	}

	const deleteBlock = (index) => {
		setBlocks(blocks.filter((item, i) => i !== index))
	}

	const changeContent = (content, empty = false) => {
		if (content || empty) {
			const _blocks = [...blocks]
			_blocks[index] = { ..._blocks[index], content }

			setBlocks(_blocks)
		}
	}

	const addVideoBlock = (link) => () => {
		const RegExp = /^(ftp|http|https):\/\/[^ "]+$/

		if (!RegExp.test(link)) {
			dispatch(addNot({ not: 'Некорректная ссылка', type: 'error' }))
			return
		}

		changeContent(link)
	}

	const handleTextChange = ({ target: { value } }) => {
		changeContent(value, true)
	}

	const handleLoadImage = (image) => {
		changeContent(image)
	}

	return (
		<div className="info-page-card">
			<InfoPageItemBlockHeader
				index={index}
				moveBack={moveBack}
				moveForw={moveForw}
				deleteBlock={deleteBlock}
				title={title}
				length={blocksLength}
				type={type}
			/>
			{isTextarea && (
				<TextAreaField
					onChange={handleTextChange}
					value={content || ''}
					name={'info-text'}
				/>
			)}
			{type === 'video' && (
				<AddVideoBlock video={content} add={addVideoBlock} />
			)}
			{type === 'photo' && (
				<LoadImage image={content} setImage={handleLoadImage} />
			)}
		</div>
	)
}
