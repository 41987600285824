import React from 'react'
import { useSelector } from 'react-redux'

import { ContentItemSkillLine as SkillLine } from '../QuestionnaireContentItem/ContentItemSkillLine/ContentItemSkillLine'
import { QuestionnaireContentItem as ContentItem } from '../QuestionnaireContentItem/QuestionnaireContentItem'

import { getUserData } from '../../../../store/reducers/user/user-selectors'

export const SkillsTabContent = React.memo(() => {
	const { user, local } = useSelector(getUserData)

	const { specs, techs } = local
	
	const { specializations } = user.third_page
	const { skills, other } = user.fourth_page

	return (
		<div>
			<ContentItem title="Специализации">
				{!!specs.length &&
					specs.map((entry, index) => (
							<SkillLine
								key={index}
								value={specializations[entry.key]}
								label={entry.value}
							/>
						)
					)}
			</ContentItem>
			<ContentItem title="Технологии">
					{!!techs.length &&
						techs.map(entry => (
							entry.tech_skills.map((item, index) => <SkillLine
							key={index}
							value={skills[item.key]}
							label={item.value}
						/>)
						)
					)}

				{other &&
					Object.entries(other).map((entry) => {
						const [tech, level] = entry

						return (
							<SkillLine
								key={tech}
								value={level}
								label={tech}
							/>
						)
					})}
			</ContentItem>
		</div>
	)
})
