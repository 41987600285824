import React, { useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'

import { GroupsList } from './GroupsList/GroupsList'
import { GroupPage } from './GroupPage/GroupPage'

import './GroupsPage.scss'

export const GroupsPage = React.memo(() => {
	const match = useRouteMatch()
	const id = match.params.id

	const history = useHistory()

	const [create, setCreate] = useState(id === 'create')

	const changeCreate = (state) => () => {
		if (state) {
			history.push('/groups/create')
		} else {
			history.goBack()
		}
		setCreate(state)
	}

	return (
		<div className="offices__page">
			{id || create ? (
				<GroupPage
					id={id !== 'create'? id : ''}
					edit={id && !create}
					cancel={changeCreate(false)}
					setCreate={setCreate}
				/>
			) : (
				<GroupsList createNewGroup={changeCreate(true)} />
			)}
		</div>
	)
})
