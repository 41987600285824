import React from 'react'

import { url } from '../../../../api/api'
import { ItemOptions } from '../../CommonComponents/ItemOptions/ItemOptions'

import './OfficeAddress.scss'

export const OfficeAddress = React.memo(({ image, title, address, changeItemFunc }) => {
	const imageUrl = image ? `${url}/static/${image}` : ''

	return (
		<div className="offices-panel-address">
			{image && (
				<div
					className="offices-panel-address__image"
					style={{ backgroundImage: `url(${imageUrl})` }}
				></div>
			)}
			<div className="offices-panel-address__info">
				<div>
					<div className="offices-panel-address__title">{title}</div>
					<div className="offices-panel-address__address">{address}</div>
				</div>
				<ItemOptions toggleModal={changeItemFunc} />
			</div>
		</div>
	)
})
