import { createSlice } from '@reduxjs/toolkit'

import officesApi from '../../../api/offices-api'
import commonApi from '../../../api/common-api'

import { onError } from '../functions'

const initialState = {
	offices: [],
	officesCount: 0,
	officesFilters: {
		limit: 10,
		offset: 0,
		is_proposal: 0,
		equipment: [],
		status: 0,
		name: '',
		city: '',
	},
	cities: [],
	officeInfo: {},
	isLoading: false,
}

const offices = createSlice({
	name: 'offices',
	initialState,
	reducers: {
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
		setOffices: (state, action) => {
			state.offices = action.payload.offices
		},
		setOfficesCount: (state, action) => {
			state.officesCount = action.payload.officesCount
		},
		setOfficesFilters: (state, action) => {
			state.officesFilters = { ...state.officesFilters, ...action.payload }
		},
		setCitiesForFilters: (state, action) => {
			state.cities = action.payload.cities
		},
		setOffice: (state, action) => {
			state.officeInfo = action.payload.officeInfo
		},
	},
})

export const { reducer, actions } = offices

export const {
	setIsLoading,
	setOffices,
	setOfficesCount,
	setOfficesFilters,
	setCitiesForFilters,
	setOffice,
} = actions

// список офисов
export const requestOffices = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		offices: { officesFilters },
	} = getState()

	const res = await officesApi.requestOffices(officesFilters)

	if (res.success) {
		dispatch(setOffices({ offices: res.result.offices }))
		dispatch(setOfficesCount({ officesCount: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Список городов для фильтров
export const requestOfficesCity = () => async (dispatch) => {
	const res = await commonApi.getCityForFilters(1)

	if (res.success) {
		dispatch(setCitiesForFilters({ cities: res.result }))
	} else {
		onError(dispatch, res.result)
	}
}

// информация об офисе
export const requestOffice = (id) => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await officesApi.requestOffice(id)

	if (res.result) {
		dispatch(setOffice({ officeInfo: res.result }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// создание офиса
export const createOffice = (data) => async (dispatch) => {
	const res = await officesApi.createOffice(data)

	if (res.result) {
		dispatch(requestOffices())
	} else {
		onError(dispatch, res.result)
	}
}

// редактирование офиса
export const updateOffice = (data) => async (dispatch) => {
	const res = await officesApi.updateOffice(data)

	if (res.result) {
		dispatch(requestOffices())
	} else {
		onError(dispatch, res.result)
	}
}

// публикация офиса

export const approveOffice = (id) => async (dispatch) => {
	const res = await commonApi.approveTarget(id, 1)

	if (res.success) {
		dispatch(requestOffices())
	} else {
		onError(dispatch, res.result)
	}
}

// удаление публикации офиса
export const rejectOffice = (id) => async (dispatch) => {
	const res = await commonApi.rejectTarget(id, 1)

	if (res.success) {
		dispatch(requestOffices())
	} else {
		onError(dispatch, res.result)
	}
}

// Удалить офис
export const deleteOffice = (id) => async (dispatch) => {
	const res = await commonApi.deleteTarget(id, 1)

	if (res.success) {
		dispatch(requestOffices())
	} else {
		onError(dispatch, res.result)
	}
}

export default reducer
