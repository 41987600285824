import React from 'react'

import { LocationMap } from './LocationMap/LocationMap'
import { Input } from '../../../../components/Inputs/Input/Input'

import './OfficeLocation.scss'

export const OfficeLocation = React.memo(
	({
		address,
		setAddress,
		city,
		setCity,
		description,
		setDescription,
		xCoord,
		setXCoord,
		yCoord,
		setYCoord,
		officeError,
	}) => {
		return (
			<div className="office__location">
				<div className="office__title">Локация</div>
				<div className="office__location-wrapper">
					<LocationMap
						city={city}
						setCity={setCity}
						xCoord={xCoord}
						setXCoord={setXCoord}
						yCoord={yCoord}
						setYCoord={setYCoord}
						address={address}
						setAddress={setAddress}
					/>
				</div>
				<Input
					label={'Адрес'}
					value={address}
					onChange={({ target: { value } }) => setAddress(value)}
					name={'location-address'}
					className={officeError.address ? '' : 'error'}
					disabled
				/>
				<Input
					label={'Комментарий к адресу'}
					value={description}
					onChange={({ target: { value } }) => setDescription(value)}
					name={'location-comment'}
					className={officeError.description ? '' : 'error'}
				/>
			</div>
		)
	}
)
