import React from 'react'
import cn from 'classnames'

import './TableTitle.scss'

export const TableTitle = React.memo(
	({ title, onClick = () => {}, sort = false }) => (
		<div className={cn('table-title-block', { sort })} onClick={onClick}>
			<span>{title}</span>
			<i></i>
		</div>
	)
)
