import React from 'react'
import { url } from '../../../api/api'

export const PreimageGallery = React.memo(({ files, deleteFile }) => (
	<div className="preimage-gallery">
		{files.map((file, index) => {
			const bgUrl = file.preview || `${url}/static/${file}`

			return (
				<div className="preimage__wrapper" key={`image-${index}`}>
					<div className="btn-wrapper">
						<button className="preimage__delete-btn" onClick={() => deleteFile(index, file)}>
							<span></span>
						</button>
					</div>
					<div className="preimage__container">
						<div
							className="preimage"
							style={{
								backgroundImage: `url(${bgUrl})`,
							}}
							alt={'file preimage'}
						/>
					</div>
				</div>
			)
		})}
	</div>
))
