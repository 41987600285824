import instance from './api'
import { getAdminToken } from '../functions/localStorage'

const partnersApi = {
	async requestSkills(filters) {
		const response = await instance.post('admin/get/point/skill', filters, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async searchSkills(text) {
		const response = await instance.post(
			`admin/search/skills?pat=${text}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async addSkillPrice(data) {
		const response = await instance.post('admin/set/point/skill', data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async deleteSkill(id) {
		const response = await instance.post(
			`admin/delete/point/skill?skill_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async setPartnersInfo(data) {
		const response = await instance.post('admin/set/recruter/info', data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async requestPartnersInfo() {
		const response = await instance.post(
			'admin/get/recruter/info',
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},
}

export default partnersApi
