import React, { useCallback, useRef, useState } from 'react'

import { Icon } from '../../../../../../../components/Icon/Icon'
import { GroupsDropdown } from './GroupsDropdown/GroupsDropdown'

import './AddGroupButton.scss'

export const AddGroupButton = React.memo(
	({ notUserGroups, name, id, isLoading }) => {
		const [isGroupListShow, setisGroupListShow] = useState(false)

		const listRef = useRef()

		//кнопка "добавить в группу"
		const handleButtonClick = () => {
			setisGroupListShow(true)
		}

		const handleClickOutside = useCallback(
			(event) => {
				if (listRef.current && !listRef.current.contains(event.target)) {
					setisGroupListShow(false)
				}
			},
			[listRef, setisGroupListShow]
		)

		return (
			<div className="add-button-wrapper">
				<button
					className="user-groups-item add-button"
					onClick={handleButtonClick}
				>
					<Icon name="plus" size={24} />
					<div className="group-name add-text">
						<span>Добавить в группу</span>
					</div>
				</button>
				<GroupsDropdown
					ref={listRef}
					isGroupListShow={isGroupListShow}
					setisGroupListShow={setisGroupListShow}
					isLoading={isLoading}
					notUserGroups={notUserGroups}
					name={name}
					id={id}
					handleClickOutside={handleClickOutside}
				/>
			</div>
		)
	}
)
