import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { ColumnsContainer } from '../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { RightColumn } from '../../components/PageWrapper/ColumnsContainer/RightColumn/RightColumn'
import { PageWrapper } from '../../components/PageWrapper/PageWrapper'
import { Panel } from '../../components/Panels/Panel/Panel'
import { TransactionsTable } from './TransactionsTable/TransactionsTable'
import { TransactionsFilters } from './TransactionsFilters/TransactionsFilters'
import { Button } from '../../components/Buttons/Button/Button'
import { ModalContainer } from '../../components/ModalContainer/ModalContainer'
import { PointsModal } from './PointsModal/PointsModal'

import { setFilters } from '../../store/reducers/transactions/transactions'

import './TransactionsPage.scss'

export const TransactionsPage = React.memo(() => {
	const [questionnaireModal, setQuestionnaireModal] = useState(false)

	const dispatch = useDispatch()

	const handleModal = (state) => () => {
		setQuestionnaireModal(state)
	}

	useEffect(
		() => () => {
			dispatch(setFilters({ type: 0, offset: 0, pat: '' }))
		},
		[dispatch]
	)

	return (
		<div className="transactions-page">
			<PageWrapper>
				<ColumnsContainer>
					<LeftColumn>
						<TransactionsTable />
					</LeftColumn>
					<RightColumn>
						<Button label={'Изменить баллы'} onClick={handleModal(true)} />
						<Panel>
							<TransactionsFilters />
						</Panel>
					</RightColumn>
					<ModalContainer profile show={questionnaireModal} onClose={handleModal(false)}>
						<PointsModal close={handleModal(false)} />
					</ModalContainer>
				</ColumnsContainer>
			</PageWrapper>
		</div>
	)
})
