export const tabsCreator = (past, propsCount) => {
	let tabs
	past
		? (tabs = [
				{
					title: ' Фото',
				},
				{
					title: 'Видео',
				},
				{
					title: 'Сертификаты',
				},
				{
					title: 'Квоты',
				},
				{
					title: 'Комментарии',
				},
		  ])
		: (tabs = [
				{
					title: 'Заявки',
					count: propsCount,
				},
				{
					title: 'Сертификаты',
				},
				{
					title: 'Квоты',
				},
		  ])
	return tabs
}
