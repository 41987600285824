import React from 'react'

import './ShowButtonsBlock.scss'

export const ShowButtonsBlock = React.memo(
	React.forwardRef(({ text, onClick }, ref) => (
		<div className="show-buttons-block" ref={ref} onClick={onClick}>
			{text}
		</div>
	))
)
