import React from 'react'
import { Avatar } from '../../../../../../../components/Avatar/Avatar'
import { IconButton } from '../../../../../../../components/IconButton/IconButton'
import { createDate } from '../../../../../../../functions/common'

import './NotesListItem.scss'

export const NotesListItem = React.memo(({ index, msg, handleDeleteModalOpen, adminId }) => {
	const optionsDate = {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
	}

	const optionsTime = {
		hour: 'numeric',
		minute: 'numeric',
	}

	return (
		<div className="notes-list__item" style={{ animationDelay: `${index * 40}ms` }}>
			<div>
				<div className="note-info">
					<Avatar image={msg.admin_image} small />
					<span className="note-info__name">{`${msg.admin_name} ${msg.admin_surname}`}</span>
					<span className="note-info__time">
						{`${createDate(msg.time_create, 'ru', optionsDate)} в ${createDate(
							msg.time_create,
							'ru',
							optionsTime
						)}`}
					</span>
				</div>
				<div className="note-text">
					<span>{msg.msg}</span>
				</div>
			</div>
			{adminId === msg.admin_id && (
				<div className="notes-delete-btn">
					<IconButton
						className="delete-button"
						iconName="delete-black"
						size={32}
						onClick={() => handleDeleteModalOpen(msg.id)}
					/>
				</div>
			)}
		</div>
	)
})
