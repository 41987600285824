import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { ColumnsContainer } from '../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { Column } from '../../../components/PageWrapper/ColumnsContainer/Column/Column'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { ProductItemHeader } from './ProductItemHeader/ProductItemHeader'
import { ProductItemCard } from './ProductItemCard/ProductItemCard'
import { ProductAdminTable } from './ProductAdminTable/ProductAdminTable'

import { setType as setCertificatesType } from '../../../store/reducers/certificates/certificates'
import { setType as setAplicationsType } from '../../../store/reducers/applications/applications'
import { setType as setQuotasType } from '../../../store/reducers/quotas/quotas'

import {
	setProductInfo,
	createProduct,
	updateProduct,
	approveProduct,
	rejectProduct,
	deleteProduct,
	requestListCities,
	requestListCountry,
} from '../../../store/reducers/products/products'

import { getCities, getCountries } from '../../../store/reducers/products/products-selectors'

import { onError } from '../../../store/reducers/functions'

import './ProductItem.scss'

export const ProductItem = React.memo(({ id, edit, cancel, setCreate, product }) => {
	if (!!id && edit && !Object.keys(product).length) return null

	const dispatch = useDispatch()
	const history = useHistory()

	const searchCity = useSelector(getCities)
	const searchCountry = useSelector(getCountries)

	const balance = edit && !!product ? product.balance : 0
	const [productImage, setProductImage] = useState(edit && !!product ? product.image : null)
	const [name, setName] = useState(edit && !!product ? product.name : '')
	const [city, setCity] = useState(edit && !!product ? product.city : '')
	const [country, setCountry] = useState(edit && !!product ? product.country : '')
	const [type, setType] = useState(edit && !!product ? product.prod_type : 1)
	const [price, setPrice] = useState(edit && !!product ? product.price : 0)
	const [description, setDescription] = useState(edit && !!product ? product.prod_descr : '')
	const [characteristics, setCharacteristics] = useState(edit && !!product ? product.spec : [])
	const [password, setPassword] = useState(edit && !!product ? product.password : '')
	const [countryId, setCountryId] = useState(0)

	const [currentBalance, setCurrentBalance] = useState(balance)

	const [productError, setProductError] = useState({
		name: true,
		city: true,
		price: true,
		description: true,
		password: true,
		country: true,
	})

	const errorFunc = () => {
		onError(dispatch, { code: 0, msg: 'Заполните все необходимые поля' })

		setProductError({
			name: name ? true : false,
			city: city ? true : false,
			country: country ? true : false,
			price: price ? true : false,
			description: description ? true : false,
			password: password ? true : false,
		})
	}

	const handleProduct = async () => {
		const filterChars = characteristics.filter((item) => item.key && item.value)

		const data = {
			name: name,
			prod_type: type,
			prod_descr: description,
			balance: balance === currentBalance && balance > 0 ? +balance - 1 : +currentBalance,
			spec: filterChars,
			image: productImage,
			price: +price,
			loc_addr: city,
			city: city,
			country: country,
			password: password,
		}

		if (!name || !country || !price || !description || !password) {
			errorFunc()
			return
		}

		if (edit) {
			data.id = id
			dispatch(updateProduct(data))
		} else {
			dispatch(createProduct(data))
		}

		history.push('/products')
	}

	const changeStatusProduct = (id, func) => () => {
		dispatch(func(id))
		history.push('/products')
	}

	const approveCurrentProduct = async () => {
		await handleProduct()
		changeStatusProduct(id, approveProduct)()
	}

	useEffect(() => {
		dispatch(setCertificatesType({ type: 2 }))
		dispatch(setAplicationsType({ type: 2 }))
		dispatch(setQuotasType({ type: 2 }))
	}, [dispatch, id])

	useEffect(() => {
		if (country) dispatch(requestListCities(city, countryId))
	}, [dispatch, city, countryId, country])

	useEffect(() => {
		dispatch(requestListCountry(country))
	}, [dispatch, country])

	useEffect(
		() => () => {
			if (!edit) {
				setCreate(false)
			}
		},
		[edit, setCreate]
	)

	useEffect(
		() => () => {
			dispatch(setProductInfo({ productInfo: {} }))
		},
		[dispatch]
	)

	return (
		<div className="product-item__page">
			<PageWrapper>
				<ProductItemHeader
					id={product.id}
					name={name}
					edit={edit}
					cancel={cancel}
					createProduct={handleProduct}
					status={product.status}
					approveProduct={approveCurrentProduct}
					rejectProduct={changeStatusProduct(id, rejectProduct)}
					deleteProduct={changeStatusProduct(id, deleteProduct)}
				/>
			</PageWrapper>
			<PageWrapper>
				<ColumnsContainer>
					<Column>
						<Panel>
							<ProductItemCard
								name={name}
								setName={setName}
								setCurrentBalance={setCurrentBalance}
								currentBalance={currentBalance}
								type={type}
								setType={setType}
								description={description}
								setDescription={setDescription}
								characteristics={characteristics}
								setCharacteristics={setCharacteristics}
								productImage={productImage}
								setProductImage={setProductImage}
								price={price}
								setPrice={setPrice}
								city={city}
								setCity={setCity}
								country={country}
								setCountry={setCountry}
								productError={productError}
								password={password}
								setPassword={setPassword}
								fillPassword={edit && product.password}
								searchCity={searchCity}
								searchCountry={searchCountry}
								setCountryId={setCountryId}
							/>
						</Panel>
					</Column>
					<Column>{edit && <ProductAdminTable id={id} status={product.status} />}</Column>
				</ColumnsContainer>
			</PageWrapper>
		</div>
	)
})
