import React from 'react'

import './DropdownItem.scss'

export const DropdownItem = React.memo(({ handleChooseGroup, item }) => (
	<li className="add-groups-list-item">
		<button className="choose-item" onClick={handleChooseGroup}>
			{item.name}
		</button>
	</li>
))
