import React, { useContext } from 'react'

import AppContext from '../../context/AppContext'

import { Button } from '../Buttons/Button/Button'
import { Confirm } from '../ModalContainer/Confirm/Confirm'

import './EditCard.scss'

export const EditCard = React.memo(
	({
		deleteIt,
		cancel,
		disabled,
		save,
		saveType,
		edit,
		status,
		id,
		userItemHeader,
		cancelFunc = () => {},
		savedFunc = () => {},
		deleteFunc = () => {},
	}) => {
		const { setConfirmConfig } = useContext(AppContext)

		const createContent = () => (
			<Confirm
				red
				text={deleteIt}
				cancel={setConfirmConfig(false)}
				confirm={deleteFunc}
			/>
		)

		return (
			<div className="edit__card">
				{edit && (status !== 4 || !!id) && (
					<button
						className="edit__button"
						type="button"
						onClick={setConfirmConfig(true, createContent())}
					>
						<i></i>
						{deleteIt}
					</button>
				)}
				<Button label={cancel} type="md2" white onClick={cancelFunc} />
				{status !== 4 && !userItemHeader && (
					<Button
						label={save}
						nType={saveType}
						disabled={disabled}
						type="md2"
						onClick={savedFunc}
					/>
				)}
			</div>
		)
	}
)
