import React, { useEffect, useRef } from 'react'
import cn from 'classnames'

import './SwitchInput.scss'

export const SwitchInput = React.memo(
	({
		textActive,
		textUnactive,
		value,
		onChange,
		textStyle,
		classes,
		custom = false,
		customColor = '',
	}) => {
		const colorRef = useRef()

		useEffect(() => {
			if (customColor) {
				colorRef.current.style.setProperty('--color-active', customColor)
			}
		}, [colorRef, customColor])

		return (
			<div className={cn('switch-block', { custom }, classes)}>
				<label htmlFor="switch">
					<input
						className="switch-input"
						type="checkbox"
						id="switch"
						checked={value}
						onChange={onChange}
					/>
					<span className="switch-text" style={textStyle} ref={colorRef}>
						{value ? textActive : textUnactive}
					</span>
				</label>
			</div>
		)
	}
)
