import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import { ColumnsContainer } from '../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { RightColumn } from '../../../components/PageWrapper/ColumnsContainer/RightColumn/RightColumn'
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { TableBody } from '../../../components/TableBody/TableBody'
import { TableHeader } from '../../../components/TableHeader/TableHeader'
import { TableRow } from '../../../components/TableBody/TableRows/TableRow/TableRow'
import { Button } from '../../../components/Buttons/Button/Button'
import { ModalContainer } from '../../../components/ModalContainer/ModalContainer'
import { EventFilters } from './EventFilters/EventFilters'

import { requestEvents, requestEventsCity, setFilters } from '../../../store/reducers/events/events'

import {
	getCount,
	getEventsList,
	getFilters,
	getIsLoading,
} from '../../../store/reducers/events/events-selectors'
import { getDevice } from '../../../store/reducers/app/app-selectors'

import { changeOffset, refreshTableItems, searchItem } from '../../../functions/common'

export const EventsList = React.memo(({ createNewEvent }) => {
	const dispatch = useDispatch()

	const events = useSelector(getEventsList)
	const count = useSelector(getCount)
	const filters = useSelector(getFilters)
	const isLoading = useSelector(getIsLoading)
	const device = useSelector(getDevice)

	const [modal, setModal] = useState(false)

	const handleModalToggle = (value) => () => setModal(value)

	const { name, offset, limit } = filters

	useEffect(() => {
		dispatch(requestEvents())
		dispatch(requestEventsCity(2))
	}, [dispatch, filters])

	return (
		<div className="event-list">
			<PageWrapper>
				<ColumnsContainer>
					<LeftColumn>
						{(device === 'notepad' || device === 'mobile') && (
							<div className="header-mobile-block">
								<div className="filters-header__reset" onClick={handleModalToggle(true)}>
									Фильтры <i></i>
								</div>
								<Button label="Создать событие" onClick={createNewEvent} type="md1" />
							</div>
						)}
						<TableHeader
							title="Список событий"
							placeholder="Наименование"
							searchValue={name}
							onSearchChange={searchItem(dispatch, setFilters, 'name')}
							onRefresh={refreshTableItems(dispatch, requestEvents)}
						/>
						<TableBody
							contentTitlesArray={[
								{ title: 'Наименование' },
								{ title: 'Дата' },
								{ title: 'Город' },
								{ title: 'Где пройдет' },
								{ title: 'Кол-во участников' },
								{ title: 'Макс. участников' },
								{ title: 'Заявки' },
							]}
							additionalClasses={['events']}
							limit={limit}
							count={count}
							offset={offset}
							setOffset={changeOffset(dispatch, setFilters)}
							isLoading={isLoading}
						>
							{events.map((row, index) => (
								<Link to={`events/${row.id}`} key={`table-link-${index}`}>
									<div
										className={cn('table-row', {
											before: row.date_mode === 'before',
											delete: row.status === 4,
										})}
										style={{ animationDelay: `${index * 40}ms` }}
									>
										<TableRow
											row={{
												image: row.image,
												name: row.name,
												time: {
													time_start: row.time_start,
													time_end: row.time_end,
												},
												loc_addr: row.city,
												loc_desc: row.loc_addr,
												count: row.count,
												all_count: row.all_count,
												count_props: row.count_props,
											}}
											textTooltip={row.status === 1 ? 'Неопубликованное событие' : 'Удалено'}
											status={row.status}
											deleteClass={row.status === 4}
										/>
									</div>
								</Link>
							))}
						</TableBody>
					</LeftColumn>
					<RightColumn>
						<Button label="Создать событие" onClick={createNewEvent} />
						<Panel>
							<EventFilters />
						</Panel>
					</RightColumn>
					<ModalContainer show={modal} onClose={handleModalToggle(false)}>
						<EventFilters />
					</ModalContainer>
				</ColumnsContainer>
			</PageWrapper>
		</div>
	)
})
