import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { Preloader } from '../../../../../../components/Preloader/Preloader'
import { UserGroupsItem } from './UserGroupsItem/UserGroupsItem'
import { AddGroupButton } from './AddGroupButton/AddGroupButton'
import { ModalContainer } from '../../../../../../components/ModalContainer/ModalContainer'
import { YesNoModal } from '../YesNoModal/YesNoModal'

import {
	deleteUserFromGroup,
	requestGroups,
	requestUserGroup,
	setGroups,
	setGroupsCount,
	setUserGroups,
} from '../../../../../../store/reducers/groups/groups'

import {
	getGroups,
	getIsLoading,
	getUserGroups,
} from '../../../../../../store/reducers/groups/groups-selectors'

import './UserAdminGroups.scss'

export const UserAdminGroups = React.memo(({ name }) => {
	const dispatch = useDispatch()

	const match = useRouteMatch()

	const id = match.params.id

	const [isDeleteModalShow, setIsDeleteModalShow] = useState(false)
	const [group, setGroup] = useState({})

	const userGroups = useSelector(getUserGroups)
	const isLoading = useSelector(getIsLoading)
	const allGroups = useSelector(getGroups)

	//массив с группами, в которых нет юзера
	const notUserGroups = allGroups.filter(
		(allGroup) => !userGroups.map((userGroup) => userGroup.id).includes(allGroup.id)
	)

	//удаление юзера из группы
	const handleDelUserFromGroup = () => {
		dispatch(deleteUserFromGroup(group.id, id, true))
		handleDelModal(false)()
	}

	//"отмена" в модалке удаления из группы
	const handleDelModal =
		(state, group = {}) =>
		() => {
			setIsDeleteModalShow(state)

			if (!state) {
				setTimeout(() => {
					setGroup(group)
				}, 300)
			} else {
				setGroup(group)
			}
		}

	useEffect(() => {
		dispatch(requestUserGroup(id))
		dispatch(requestGroups())
	}, [dispatch, id])

	useEffect(
		() => () => {
			dispatch(setUserGroups({ userGroups: [] }))
			dispatch(setGroups({ groups: [] }))
			dispatch(setGroupsCount({ count: 0 }))
		},
		[dispatch]
	)

	return (
		<div className="user-admin-groups">
			<div className="user-groups-wrapper">
				<AddGroupButton name={name} id={id} isLoading={isLoading} notUserGroups={notUserGroups} />
				{!!userGroups.length &&
					userGroups.map((group, index) => (
						<UserGroupsItem
							key={group.id}
							userId={id}
							group={group}
							handleDelModal={handleDelModal}
							index={index}
						/>
					))}
				{isLoading && (
					<div className="preloader-container">
						<Preloader />
					</div>
				)}
			</div>
			<ModalContainer show={isDeleteModalShow} onClose={handleDelModal(false)}>
				<YesNoModal
					name={name}
					group={group}
					onClose={handleDelModal(false)}
					onClick={handleDelUserFromGroup}
					delFromGroup
				/>
			</ModalContainer>
		</div>
	)
})
