import React, { useContext } from 'react'

import EventContext from '../../../../../context/EventContext'

import { TextAreaField } from '../../../../../components/TextAreaField/TextAreaField'

import { actions } from '../../actions'

import './EventLocation.scss'

export const EventLocation = React.memo(({ openMapModal }) => {
	const handleTextChange = (callback) => ({ target: { value } }) => localDispatch(callback(value))

	const { state, localDispatch } = useContext(EventContext)

	return (
		<div className="event-location-block">
			<TextAreaField
				label="Адрес"
				value={state.address}
				onChange={handleTextChange(actions.setAddress)}
				onClick={openMapModal}
				readOnly
			/>
			<TextAreaField
				label="Комментарий к адресу"
				value={state.addressInfo}
				onChange={handleTextChange(actions.setAddressInfo)}
			/>
		</div>
	)
})
