import React from 'react'
import { Button } from '../../../../../../components/Buttons/Button/Button'

import './YesNoModal.scss'

export const YesNoModal = React.memo(
	({ name, groupObj = false, onClose, onClick, delFromGroup, group = {} }) => (
		<div className="add-group-modal">
			{groupObj && (
				<div className="add-groups-text">
					<p>
						Добавить пользователя <span>{name}</span> <br />в группу
						<span> {groupObj.name}</span> ?
					</p>
				</div>
			)}

			{delFromGroup && (
				<div className="add-groups-text">
					<p>
						Удалить пользователя <span>{name}</span> <br />
						из группы
						<span> {group.name}</span> ?
					</p>
				</div>
			)}

			{!groupObj && !delFromGroup && (
				<div className="add-groups-text">
					<p>Удалить заметку?</p>
				</div>
			)}

			<div className="buttons-block">
				<Button
					label={'Отменить'}
					onClick={onClose}
					className={'cancel-add-btn'}
					white
					type="s"
				/>
				<Button
					label={groupObj ? 'Добавить' : 'Удалить'}
					red={delFromGroup || !groupObj}
					onClick={() => {
						onClick(groupObj && groupObj.id)
					}}
					className={'add-group-btn'}
				/>
			</div>
		</div>
	)
)
