import React from 'react'

import './Preloader.scss'

export const Preloader = () => {
  return (
    <div className="preloader-component">
      <div className="preloader"></div>
    </div>
  )
}