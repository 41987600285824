export const getIsLoading = ({ news: { isLoading } }) => isLoading

export const getNews = ({ news: { news } }) => news

export const getCount = ({ news: { count } }) => count

export const getFilters = ({ news: { filters } }) => filters

export const getNewData = ({ news: { newData } }) => newData

export const getNewDataRequested = ({ news: { newDataRequested } }) => newDataRequested
