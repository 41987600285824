import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getDevice } from '../../store/reducers/app/app-selectors'

import { TextAreaField } from '../TextAreaField/TextAreaField'
import { Button } from '../Buttons/Button/Button'

import './ChoiceBlock.scss'
import { approve, reject } from '../../store/reducers/users/users'

export const ChoiceBlock = React.memo(({ user, closeModal, currentId }) => {
	if (!user) return null

	const { id, status, status_msg } = user

	if (status !== 1 || currentId !== id) return ''

	const dispatch = useDispatch()

	const device = useSelector(getDevice)

	const sm = device === 'mobile' || device === 'notepad'

	const [decline, setDecline] = useState(!!status_msg)
	const [causeValue, setCauseValue] = useState(status_msg)

	const handleCauseValue = ({ target: { value } }) => setCauseValue(value)

	const handleAccept = (f, id, msg) => () => {
		dispatch(f(id, msg))
		closeModal()
	}

	return (
		<div className="choice-block">
			{decline && (
				<div className="text-block">
					<TextAreaField
						error={status_msg !== ''}
						placeholder="Укажите причину отклонения анкеты"
						value={causeValue}
						onChange={handleCauseValue}
					/>
				</div>
			)}
			<div className="buttons-block">
				{decline ? (
					<Button type={sm ? 's' : 'lg'} label="Отмена" onClick={() => setDecline(false)} />
				) : (
					<Button type={sm ? 's' : 'lg'} red label="Отклонить" onClick={() => setDecline(true)} />
				)}
				{decline ? (
					<Button
						type={sm ? 's' : 'lg'}
						green
						label="Отправить"
						disabled={!causeValue}
						onClick={handleAccept(reject, id, causeValue)}
					/>
				) : (
					<Button
						type={sm ? 's' : 'lg'}
						green
						label="Одобрить"
						onClick={handleAccept(approve, id)}
					/>
				)}
			</div>
		</div>
	)
})
