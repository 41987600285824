import React, { useState, useEffect } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { ProductItem } from './ProductItem/ProductItem'
import { Products } from './Products/Products'

import { getProductInfo } from '../../store/reducers/products/products-selectors'
import { setCount } from '../../store/reducers/applications/applications'
import {
	requestProduct,
	setProductsFilters,
	setProducts,
} from '../../store/reducers/products/products'

import './ProductsPage.scss'

export const ProductsPage = React.memo(() => {
	const dispatch = useDispatch()
	const match = useRouteMatch()
	const history = useHistory()

	const id = match.params.id

	const product = useSelector(getProductInfo)

	const [create, setCreate] = useState(id === 'create')

	const changeCreate = (state) => () => {
		if (state) {
			history.push('/products/create')
		} else {
			history.push('/products')
		}
		setCreate(state)
	}

	useEffect(() => {
		if (id && !create && id !== 'create') dispatch(requestProduct(id))
	}, [dispatch, id, create])

	useEffect(
		() => () => {
			dispatch(
				setProductsFilters({
					prod_type: 0,
					limit: 10,
					offset: 0,
					city: '',
					country: '',
					name: '',
					is_proposal: 0,
					min_price: 0,
					max_price: 0,
				})
			)
			dispatch(setProducts({ products: [] }))
		},
		[dispatch]
	)

	useEffect(
		() => () => {
			dispatch(setCount({ count: 0 }))
		},
		[dispatch, id]
	)

	return (
		<div className="products-page">
			{id || create ? (
				<ProductItem
					id={id}
					edit={id && !create}
					cancel={changeCreate(false)}
					setCreate={setCreate}
					product={product}
				/>
			) : (
				<Products createNewProduct={changeCreate(true)} />
			)}
		</div>
	)
})
