import React from 'react'
import cn from 'classnames'

import { generateID } from '../../../functions/common'

import './Input.scss'

export const Input = React.forwardRef(
	(
		{
			type = 'text',
			name,
			className,
			label,
			placeholder,
			value,
			onChange,
			onBlur,
			onFocus,
			onKeyUp,
			onClick,
			disabled,
			error,
		},
		ref
	) => {
		const id = generateID('in-')

		return (
			<div
				className={cn('input-main-container', className, {
					'has-error': error,
				})}
			>
				<input
					name={name}
					id={id}
					type={type}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					onFocus={onFocus}
					onKeyUp={onKeyUp}
					onClick={onClick}
					disabled={disabled}
				/>
				{label && <label htmlFor={id}>{label}</label>}
			</div>
		)
	}
)
