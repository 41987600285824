import { createSlice } from '@reduxjs/toolkit'

import analiticsApi from '../../../api/analitics-api'

import { onError } from '../functions'

const initialState = {
	analiticsData: [],
	count: 0,
	filters: {
		limit: 10,
		offset: 0,
		pat: '',
	},
	isLoading: false,
}

const analitics = createSlice({
	name: 'analitics',
	initialState,
	reducers: {
		setData: (state, action) => {
			state.analiticsData = action.payload.data
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
	},
})

const { reducer, actions } = analitics

export const { setData, setCount, setFilters, setIsLoading } = actions

// Получить список заявок по типу и ID
export const requestAnaliticsData = (id) => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		analitics: { filters },
	} = getState()

	const res = await analiticsApi.requestAnaliticsData({
		...filters,
	})

	if (res.success) {
		dispatch(setData({ data: res.result.referal_analitic }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

export default reducer
