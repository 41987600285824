import React from 'react'
import { url } from '../../api/api'

import './BotIcon.scss'

export const BotIcon = ({ icon, title = '', link = '' }) => (
	<div className="bot-icon-container">
		<a href={link} className="bot-icon-container__link">
			<i
				className="bot-icon-container__icon"
				style={{ backgroundImage: `url(${url}/static/${icon})` }}
			/>

			{title}
		</a>
	</div>
)
