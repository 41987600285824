import React from 'react'
import { Link } from 'react-router-dom'

import './BreadCrumbs.scss'

export const BreadCrumbs = React.memo(({ items = [] }) => (
	<div className="bread-crumbs">
		{items.map((item, index) => (
			<div key={`bread-crumbs-${index}`}>
				{index < items.length - 1 ? (
					<>
						<Link to={item.link} className="bread-crumbs__link">
							{item.text}
						</Link>
						<span className="bread-crumbs__separator">/</span>
					</>
				) : (
					<span>{item.text}</span>
				)}
			</div>
		))}
	</div>
))
