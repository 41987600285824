import React, { useState } from 'react'

import { LightButton } from '../../../../Buttons/LightButton/LightButton'
import { Input } from '../../../../Inputs/Input/Input'

export const AddVideoInput = React.memo(({ add }) => {
	const [link, setLink] = useState('')

	return (
		<div className="add-video-input">
			<Input
				label={'Ссылка на видео'}
				value={link}
				onChange={({ target: { value } }) => setLink(value)}
			/>
			<LightButton blue label="Добавить видео" onClick={add(link)} />
		</div>
	)
})
