import { createSlice } from '@reduxjs/toolkit'
import { onError } from '../functions'

import certificateApi from '../../../api/certificate-api'

const initialState = {
	certificates: [],
	count: 0,
	filters: {
		limit: 10,
		offset: 0,
	},
	type: null,
	status: '',
	isLoading: false,
}

const certificates = createSlice({
	name: 'certificates',
	initialState,
	reducers: {
		setCertificates: (state, action) => {
			state.certificates = action.payload.certs
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setType: (state, action) => {
			state.type = action.payload.type
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
	},
})

const { reducer, actions } = certificates

export const { setCertificates, setCount, setFilters, setIsLoading, setType } =
	actions

// Получить список сертификатов
export const requestCertificates = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		certificates: { filters },
	} = getState()

	const res = await certificateApi.requestCertificates({ ...filters })

	if (res.success) {
		dispatch(setCertificates({ certs: res.result.certs }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Получить список сертификатов по типу
export const requestCertificatesById = (id) => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		certificates: { filters, type },
	} = getState()

	const res = await certificateApi.requestCertificatesById({
		...filters,
		id,
		type,
	})

	if (res.success) {
		dispatch(setCertificates({ certs: res.result.certs }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

//Подтвердить сертификат
export const approveCertificate = (id) => async (dispatch) => {
	const res = await certificateApi.approveCertificate(id)

	if (!res.success) {
		onError(dispatch, res.result)
	}
}

// Отменить сертификат
export const rejectCertificate = (id, message) => async (dispatch) => {
	const res = await certificateApi.rejectCertificate(id, message)

	if (!res.success) {
		onError(dispatch, res.result)
	}
}

export default reducer
