import React from 'react'

import { IconButton } from '../../../../../../../components/IconButton/IconButton'

import './UserGroupsItem.scss'

export const UserGroupsItem = React.memo(({ group, handleDelModal, index }) => {
	return (
		<div
			className="user-groups-item"
			style={{ animationDelay: `${index * 40}ms` }}
		>
			<div className="group-name">
				<span>{group.name}</span>
			</div>
			<div className="delete-button">
				<IconButton
					className="btn-clear"
					iconName="delete-black"
					size={24}
					onClick={handleDelModal(true, group)}
				/>
			</div>
		</div>
	)
})
