import { createSlice } from '@reduxjs/toolkit'
import { onError } from '../functions'

import quotasApi from '../../../api/quotas-api'

const initialState = {
	quotasList: [],
	count: 0,
	filters: {
		limit: 10,
		offset: 0,
		type: 0,
		is_updated: 0,
		group_name: '',
	},
	type: null,
	isLoading: false,
}

const quotas = createSlice({
	name: 'quotas',
	initialState,
	reducers: {
		setQuotas: (state, action) => {
			state.quotasList = action.payload.list
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setType: (state, action) => {
			state.type = action.payload.type
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
	},
})

const { reducer, actions } = quotas

export const { setQuotas, setCount, setFilters, setIsLoading, setType } = actions

// Получить список квот
export const requestQuotas = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		quotas: { filters },
	} = getState()

	const res = await quotasApi.requestQuotas(filters)

	if (res.success) {
		dispatch(setQuotas({ list: res.result.quotas }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Получить список квот по типу и ID
export const requestQuotasById = (id) => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		quotas: {
			filters: { offset, limit },
			type,
		},
	} = getState()

	const res = await quotasApi.requestQuotasById({ limit, offset, id, type })

	if (res.success) {
		dispatch(setQuotas({ list: res.result.quotas }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Создать квоту
export const createQuota = (eventId, data) => async (dispatch, getState) => {
	const {
		quotas: { type },
	} = getState()
	const res = await quotasApi.createQuota(data)

	if (res.success) {
		dispatch(requestQuotasById(eventId, type))
	} else {
		onError(dispatch, res.result)
	}
}

// Обновить квоту
export const updateQuota = (eventId, data) => async (dispatch, getState) => {
	const {
		quotas: { type },
	} = getState()

	const res = await quotasApi.updateQuota(data)

	if (res.success) {
		dispatch(requestQuotasById(eventId, type))
	} else {
		onError(dispatch, res.result)
	}
}

// Удалить квоту
export const deleteQuota = (eventId, id) => async (dispatch, getState) => {
	const {
		quotas: { type },
	} = getState()

	const res = await quotasApi.deleteQuota(id)

	if (res.success) {
		dispatch(requestQuotasById(eventId, type))
	} else {
		onError(dispatch, res.result)
	}
}

export default reducer
