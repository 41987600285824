import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { avatarPlug } from '../../../components/Avatar/avatar-functions'

import { TableBody } from '../../../components/TableBody/TableBody'
import { TableRow } from '../../../components/TableBody/TableRows/TableRow/TableRow'
import { TableHeader } from '../../../components/TableHeader/TableHeader'
import { getPlugNumber, refreshTableItems, createDate } from '../../../functions/common'

import {
	requestComments,
	setCommentId,
	setFilters,
	changeCommentStatus,
} from '../../../store/reducers/comments/comments'

import {
	getCount,
	getFilters,
	getIsLoading,
} from '../../../store/reducers/comments/comments-selector'

import './CommentsTable.scss'

export const CommentsTable = React.memo(({ comments = [] }) => {
	const dispatch = useDispatch()

	const count = useSelector(getCount)
	const { offset, limit, pat } = useSelector(getFilters)
	const isLoading = useSelector(getIsLoading)

	const changeOffset = (value) => {
		dispatch(setFilters({ offset: value }))
		dispatch(setCommentId({ commentId: '' }))
	}

	const selectComment = (commentId) => async () => {
		await dispatch(changeCommentStatus({ commentId }))
		dispatch(setCommentId({ commentId }))
	}

	const optionsDate = {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
	}

	const optionsTime = {
		hour: 'numeric',
		minute: 'numeric',
	}

	return (
		<div className="comments-table">
			<TableHeader
				title="Новые комментарии"
				placeholder="Ф.И.О"
				searchValue={pat}
				onSearchChange={({ target: { value } }) => {
					dispatch(setFilters({ pat: value, offset: 0 }))
					dispatch(setCommentId({ commentId: '' }))
				}}
				onRefresh={refreshTableItems(dispatch, requestComments)}
			/>

			<TableBody
				contentTitlesArray={[
					{ title: 'Событие' },
					{ title: '' },
					{ title: 'Пользователь' },
					{ title: 'Дата' },
					{ title: 'Время' },
					{ title: 'Статус' },
				]}
				additionalClasses={['comments']}
				offset={offset}
				limit={limit}
				count={count}
				setOffset={changeOffset}
				isLoading={isLoading}
			>
				{comments.map((comment, index) => {
					const plugNumber = getPlugNumber(comment.from_id)

					return (
						<div
							className="table-row"
							style={{ animationDelay: `${index * 40}ms` }}
							key={`table-block-${comment.id}`}
							onClick={selectComment(comment.id)}
						>
							<TableRow
								row={{
									eventName: comment.target_name,
									user_image: comment.from_image,
									name: comment.from_name,
									date: createDate(comment.time_create, 'ru', optionsDate),
									postTime: createDate(comment.time_create, 'ru', optionsTime),
									commentStatus: comment.status,
								}}
								status={comment.status}
								key={`table-row-item-${index}`}
								plug={avatarPlug(plugNumber)}
								plugNumber={plugNumber}
							/>
						</div>
					)
				})}
			</TableBody>
		</div>
	)
})
