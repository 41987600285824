import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { useState } from 'react'
import { Input } from '../../../../../components/Inputs/Input/Input'
import { LightButton } from '../../../../../components/Buttons/LightButton/LightButton'
import { VideoItem } from './VideoItem/VideoItem'
import { getEventVideos } from '../../../../../store/reducers/events/events-selectors'

import {
	deleteMedia,
	uploadVideo,
} from '../../../../../store/reducers/events/events'

import './AddVideo.scss'

export const AddVideo = React.memo(() => {
	const dispatch = useDispatch()

	const match = useRouteMatch()
	const id = match.params.id

	const videos = useSelector(getEventVideos)

	const [link, setLink] = useState('')
	const [videoArr, setVideoArr] = useState(videos)

	const addVideo = (videoLink) => {
		setVideoArr([...videoArr, videoLink])
		dispatch(uploadVideo(id, videoLink))
		setLink('')
	}

	const deleteFile = (videoId) => () => {
		setVideoArr(videoArr.filter((link, index) => videoId !== index))
		dispatch(deleteMedia(id, 1, videoId))
	}

	return (
		<div className="add-video__wrapper">
			<div className="add-video-input">
				<Input
					label={'Ссылка на видео'}
					value={link}
					onChange={({ target: { value } }) => setLink(value)}
				/>
				<LightButton
					blue
					label="Добавить видео"
					onClick={() => addVideo(link)}
				/>
			</div>
			<div className="video-prewiew__wrapper">
				<div className="video-prewiew">
					{videoArr.map((item, i) => {
						return <VideoItem key={i} item={item} deleteFile={deleteFile(i)} />
					})}
				</div>
			</div>
		</div>
	)
})
