import React from 'react'

import { Button } from '../../../components/Buttons/Button/Button'
import { DeleteReason } from '../../../components/DeleteReason/DeleteReason'

import closeIcon from '../../../assets/media/svg/status-icons/close-white.svg'

import './Comment.scss'

export const Comment = React.memo(({ modalHandler, comments = [], commentID }) => {
	const comment = comments.find((item) => item.id === commentID)

	const { status, msg, status_msg } = comment

	return (
		<div className="comment">
			<div className="comment__header">
				<p className="title">Комментарий</p>
			</div>
			<div className="comment__body">
				{!!comment && (
					<>
						<p className="text">{msg}</p>

						{status !== 3 ? (
							<Button
								icon={closeIcon}
								red
								label={'Удалить'}
								type={'ss'}
								onClick={modalHandler(true)}
							/>
						) : (
							<DeleteReason reason={status_msg} />
						)}
					</>
				)}
			</div>
		</div>
	)
})
