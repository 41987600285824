import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalContainer } from '../../../../../../components/ModalContainer/ModalContainer'
import { TableBody } from '../../../../../../components/TableBody/TableBody'
import { TableRow } from '../../../../../../components/TableBody/TableRows/TableRow/TableRow'
import { UserAdminTransactionsHeader } from './UserAdminTransactionsHeader/UserAdminTransactionsHeader'

import { PointsOperations } from './PointsOerations/PointsOperations'

import {
	requestUserTransactions,
	setFilters,
	setCount,
	setTransactionsById,
} from '../../../../../../store/reducers/transactions/transactions'

import {
	getCount,
	getFilters,
	getIsLoading,
	getTransactionsById,
} from '../../../../../../store/reducers/transactions/transactions-selectors'

import { changeOffset } from '../../../../../../functions/common'

import './UserAdminTransactions.scss'

export const UserAdminTransactions = React.memo(({ id, name }) => {
	const [questionnaireModal, setQuestionnaireModal] = useState(false)

	const dispatch = useDispatch()

	const transactions = useSelector(getTransactionsById)
	const count = useSelector(getCount)
	const isLoading = useSelector(getIsLoading)

	const { offset, limit } = useSelector(getFilters)

	useEffect(() => {
		dispatch(
			setFilters({
				limit: 18,
			})
		)
		dispatch(requestUserTransactions(id))
	}, [dispatch, offset, limit, id])

	useEffect(
		() => () => {
			dispatch(
				setFilters({
					offset: 0,
					limit: 20,
				})
			)
			dispatch(setCount({ count: 0 }))
			dispatch(setTransactionsById({ transactionsById: [] }))
		},
		[dispatch]
	)
	return (
		<div className="user-admin-transactions">
			<UserAdminTransactionsHeader
				userMoney={transactions.money}
				setQuestionnaireModal={setQuestionnaireModal}
			/>
			<TableBody
				contentTitlesArray={['Дата', 'Операция', 'Баллы']}
				offset={offset}
				limit={limit}
				count={count}
				setOffset={changeOffset(dispatch, setFilters)}
				isLoading={isLoading}
			>
				{transactions.trans?.map((transaction, index) => {
					return (
						<div
							className="table-row"
							style={{ animationDelay: `${index * 40}ms` }}
							key={`table-block-${transaction.id}`}
						>
							<TableRow
								row={{
									trans_time: transaction.trans_time,
									msg: transaction.msg,
									value: transaction.value,
								}}
								key={`table-row-${index}`}
							/>
						</div>
					)
				})}
			</TableBody>
			<ModalContainer show={questionnaireModal} onClose={() => setQuestionnaireModal(false)}>
				<PointsOperations close={() => setQuestionnaireModal(false)} name={name} userId={id} />
			</ModalContainer>
		</div>
	)
})
