import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'

import { updateQuota } from '../../../../store/reducers/quotas/quotas'

import { DeleteButton } from '../../../Buttons/DeleteButton/DeleteButton'

import './TableQuotaRow.scss'

export const TableQuotaRow = React.memo(
	({ name, numbers, icon, mode, onClick, id, thunkId, status }) => {
		const dispatch = useDispatch()

		const [value, setValue] = useState(numbers)

		useEffect(() => {
			setValue(numbers)
		}, [id, numbers])

		return (
			<div className={cn('quota__row', { mode })}>
				<div className="quota__row-name">{name}</div>
				<div className="quota__row-input-block">
					{status < 3 || status > 4 ? (
						<input
							className="quota__row-num"
							id="quota-value"
							type="number"
							value={value}
							onFocus={() => !value && setValue('')}
							onBlur={() => (!value ? setValue(0) : value)}
							onChange={({ target: { value } }) => {
								setValue(value)
								dispatch(updateQuota(thunkId, { id, all_count: Number(value) }))
							}}
						/>
					) : (
						<div className="quota__row-div-block">{value}</div>
					)}

					{mode === 2 && <label htmlFor="quota-value" className="quota__row-num-label" />}
				</div>
				{(status < 3 || status > 4) && <DeleteButton icon={icon} onClick={onClick} />}
			</div>
		)
	}
)
