import React from 'react'

import './CheckBox.scss'

export const CheckBox = React.memo(({ index, item, handler, active }) => {
	return (
		<div className="checkbox-item">
			<input
				type="checkbox"
				id={`chekbox-${index}`}
				onChange={handler(item[0])}
				checked={active(item[0])}
			/>
			<label htmlFor={`chekbox-${index}`}>
				<span>{item[1]}</span>
			</label>
		</div>
	)
})
