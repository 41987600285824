export const getIsLoading = ({ knowledgeBase: { isLoading } }) => isLoading

export const getKnowledgeBase = ({ knowledgeBase: { knowledgeBase } }) => knowledgeBase

export const getCount = ({ knowledgeBase: { count } }) => count

export const getFilters = ({ knowledgeBase: { filters } }) => filters

export const getArticleData = ({ knowledgeBase: { articleData } }) => articleData

export const getArticleDataRequested = ({ knowledgeBase: { articleDataRequested } }) => articleDataRequested
