import React from 'react'
import cn from 'classnames'

import { Icon } from '../Icon/Icon'

import './IconButton.scss'

export const IconButton = ({ className, iconName, size = 24, onClick }) => (
	<button
		className={cn('button-icon-main', className)}
		style={{ height: size, width: size }}
		onClick={onClick}
		type="button"
	>
		<Icon name={iconName} size={size <= 24 ? size : 24} />
	</button>
)
