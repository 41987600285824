import React, { useContext } from 'react'

import { Button } from '../../../../../components/Buttons/Button/Button'

import EventContext from '../../../../../context/EventContext'

import { actions } from '../../actions'

import './EventFormat.scss'

export const EventFormat = React.memo(() => {
	const { state, localDispatch } = useContext(EventContext)

	const dispatchHandler = (value) => () => localDispatch(actions.setType(value))

	return (
		<div className="event-format-block">
			<p>Формат</p>
			<div className="event-format-block__buttons">
				<Button
					label={'Онлайн'}
					type="md1"
					gray={state.type !== 1}
					blue={state.type === 1}
					onClick={dispatchHandler(1)}
				/>
				<Button
					label={'Оффлайн'}
					type="md1"
					gray={state.type !== 2}
					blue={state.type === 2}
					onClick={dispatchHandler(2)}
				/>
			</div>
		</div>
	)
})
