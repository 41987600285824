import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { PageWrapper } from '../../components/PageWrapper/PageWrapper'
import { MainValues } from './MainValues/MainValues'
import { ColumnsContainer } from '../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { RightColumn } from '../../components/PageWrapper/ColumnsContainer/RightColumn/RightColumn'
import { PartnersTable } from './PartnersTable/PartnersTable'
import { Button } from '../../components/Buttons/Button/Button'
import { ModalContainer } from '../../components/ModalContainer/ModalContainer'
import { PartnersModal } from './PartnersModal/PartnersModal'

import {
	requestPartnersInfo,
	requestSkills,
	setFilters,
} from '../../store/reducers/partners/partners'

import { getFilters } from '../../store/reducers/partners/partners-selectors'

export const PartnersPage = React.memo(() => {
	const dispatch = useDispatch()

	const [showModal, setShowModal] = useState(false)
	const [showModalContent, setShowModalContent] = useState(false)
	const [id, setId] = useState(null)

	const filters = useSelector(getFilters)
	const { pat, offset } = filters

	const handleModal =
		(state, id = null) =>
		() => {
			setShowModal(state)
			setId(id)

			if (state) {
				setShowModalContent(state)
			} else {
				setTimeout(() => {
					setShowModalContent(state)
				}, 300)
			}
		}

	useEffect(() => {
		dispatch(requestSkills())
	}, [dispatch, pat, offset])

	useEffect(() => {
		dispatch(requestPartnersInfo())
	}, [dispatch])

	useEffect(
		() => () => {
			dispatch(setFilters({ offset: 0, pat: '' }))
		},
		[dispatch]
	)

	return (
		<div className="partners-page">
			<PageWrapper>
				<MainValues />
				<ColumnsContainer>
					<LeftColumn>
						<PartnersTable handleModal={handleModal} />
					</LeftColumn>
					<RightColumn>
						<Button label="Добавить" onClick={handleModal(true)} />
					</RightColumn>
				</ColumnsContainer>
			</PageWrapper>
			<ModalContainer show={showModal} onClose={handleModal(false)}>
				{showModalContent && <PartnersModal id={id} close={handleModal(false)} />}
			</ModalContainer>
		</div>
	)
})
