import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from '../../../components/Buttons/Button/Button'
import { ColumnsContainer } from '../../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { RightColumn } from '../../../components/PageWrapper/ColumnsContainer/RightColumn/RightColumn'
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { Filters } from '../../../components/Filters/Filters'
import { ProductsTable } from './ProductsTable/ProductsTable'
import { FiltersHeader } from '../../../components/FiltersHeader/FiltersHeader'

import {
	requestProducts,
	setProductsFilters,
	requestEventsCity,
	requestProductCountry,
} from '../../../store/reducers/products/products'
import {
	getProducts,
	getProductsCount,
	productsFilters,
	getCitiesForFilters,
	getCountriesForFilters,
	getIsLoading,
	getMaxPrice,
} from '../../../store/reducers/products/products-selectors'

import './Products.scss'

export const Products = React.memo(({ createNewProduct }) => {
	const dispatch = useDispatch()

	const products = useSelector(getProducts)
	const count = useSelector(getProductsCount)
	const filters = useSelector(productsFilters)
	const filteredCities = useSelector(getCitiesForFilters)
	const filteredCountries = useSelector(getCountriesForFilters)
	const isLoading = useSelector(getIsLoading)
	const maxPrice = useSelector(getMaxPrice)

	const [price, setPrice] = useState([0, 0])

	const { offset, limit, prod_type, city, country, name, is_proposal } = filters

	// фильтр "Заявки"
	const handleRequestsFilter = (value) => () => {
		dispatch(setProductsFilters({ is_proposal: is_proposal === value ? 0 : value, offset: 0 }))
	}

	// фильтр "Город"

	const handleCityFilter = (value) => () => {
		dispatch(setProductsFilters({ city: city === value ? '' : value, offset: 0 }))
	}

	const cities = filteredCities
		.filter((item) => item)
		.map((item) => ({
			title: item,
			handler: handleCityFilter(item),
			active: city === item,
		}))

	// фильтр "Страна"

	const handleCountryFilter = (value) => () => {
		dispatch(setProductsFilters({ country: country === value ? '' : value, offset: 0 }))
	}

	const countries = filteredCountries.map((item) => ({
		title: item,
		handler: handleCountryFilter(item),
		active: country === item,
	}))

	// фильтр "Вид"
	const handleViewFilter = (value) => () => {
		dispatch(setProductsFilters({ prod_type: prod_type === value ? 0 : value, offset: 0 }))
	}

	// сбросить фильтр
	const handleResetFilter = () => {
		dispatch(
			setProductsFilters({
				prod_type: 0,
				limit: 10,
				offset: 0,
				city: '',
				country: '',
				name: '',
				is_proposal: 0,
				min_price: 0,
				max_price: 0,
			})
		)
		setPrice([0, maxPrice])
	}

	const filtersItem = [
		{
			title: 'Заявки',
			items: [
				{
					title: 'С заявками',
					handler: handleRequestsFilter(1),
					active: is_proposal === 1,
				},
				{
					title: 'Без заявок',
					handler: handleRequestsFilter(2),
					active: is_proposal === 2,
				},
			],
		},
		{
			title: 'Страна',
			items: countries,
		},
		{
			title: 'Город',
			items: cities,
		},
		{
			title: 'Вид',
			items: [
				{
					title: 'Услуги',
					handler: handleViewFilter(2),
					active: prod_type === 2,
				},
				{
					title: 'Товары',
					handler: handleViewFilter(1),
					active: prod_type === 1,
				},
			],
		},
		{
			title: 'Стоимость',
			items: [''],
			range: true,
		},
	]

	useEffect(() => {
		dispatch(requestProducts())
		dispatch(requestEventsCity())
		dispatch(requestProductCountry())
	}, [dispatch, filters])

	useEffect(() => {
		setPrice([0, maxPrice])
	}, [maxPrice])

	return (
		<div className="products">
			<PageWrapper>
				<ColumnsContainer>
					<LeftColumn>
						<ProductsTable
							productsList={products}
							limit={limit}
							count={count}
							offset={offset}
							name={name}
							isLoading={isLoading}
						/>
					</LeftColumn>
					<RightColumn>
						<Button label="Добавить товар или услугу" onClick={createNewProduct} />
						<Panel>
							<FiltersHeader reset={handleResetFilter} />
							<Filters filter={filtersItem} price={price} setPrice={setPrice} maxPrice={maxPrice} />
						</Panel>
					</RightColumn>
				</ColumnsContainer>
			</PageWrapper>
		</div>
	)
})
