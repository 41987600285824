import React from 'react'
import cn from 'classnames'

import './Options.scss'

export const Options = ({ options = [], added = [], onChange }) => {
	return (
		<div className="dropdown-options">
			{options.map((item, index) => (
				<div
					key={index}
					className={cn('option', {
						active: added.find((addedItem) => item.id === addedItem.id),
					})}
					onClick={() => onChange(item)}
				>
					<span>{item.name}</span>
				</div>
			))}
		</div>
	)
}
