import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MainNewsBlock } from './MainNewsBlock/MainNewsBlock'
import { ActualInfoBlock } from './ActualInfoBlock/ActualInfoBlock'
import { NewsBlock } from './NewsBlock/NewsBlock'
import { ProductsBlock } from './ProductsBlock/ProductsBlock'
import { OfficeBlock } from './OfficeBlock/OfficeBlock'
import { SocialBlock } from './SocialBlock/SocialBlock'
import { Button } from '../../components/Buttons/Button/Button'
import { Preloader } from '../../components/Preloader/Preloader'

import { requestContentPage, updateContentPage } from '../../store/reducers/content/content'
import { setOfficesFilters } from '../../store/reducers/offices/offices'
import { setProductsFilters } from '../../store/reducers/products/products'
import { setFilters as setNewsFilters } from '../../store/reducers/news/news'
import { setFilters as setEventsFilters } from '../../store/reducers/events/events'

import { getContentIsLoading } from '../../store/reducers/content/content-selector'

import './ContentPage.scss'

export const ContentPage = React.memo(() => {
	const dispatch = useDispatch()

	const isLoading = useSelector(getContentIsLoading)

	const updateMainPage = () => dispatch(updateContentPage())

	useEffect(() => {
		dispatch(requestContentPage())
	}, [dispatch])

	useEffect(
		() => () => {
			dispatch(setOfficesFilters({ name: '' }))
			dispatch(setProductsFilters({ name: '' }))
			dispatch(setNewsFilters({ name: '' }))
			dispatch(setEventsFilters({ name: '' }))
		},
		[dispatch]
	)

	return isLoading ? (
		<div className="preloader-container">
			<Preloader />
		</div>
	) : (
		<div className="content-page-wrapper">
			<MainNewsBlock />
			<ActualInfoBlock />
			<NewsBlock />
			<ProductsBlock />
			<OfficeBlock />
			<SocialBlock />
			<div className="save-button-block">
				<div className="save-button">
					<Button label="Сохранить" type="md1" onClick={updateMainPage} />
				</div>
			</div>
		</div>
	)
})
