import React from 'react'
import cn from 'classnames'

import './HeaderButton.scss'

export const HeaderButton = React.memo(
	({ onClick, prev = false, next = false, del = false, disabled = false }) => (
		<button
			className={cn('header-button', {
				prev,
				next,
				del,
				disabled,
			})}
			onClick={onClick}
		>
			<i></i>
		</button>
	)
)
