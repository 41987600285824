import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Filters } from '../../../components/Filters/Filters'
import { FiltersHeader } from '../../../components/FiltersHeader/FiltersHeader'
import { setFilters } from '../../../store/reducers/quotas/quotas'

import { getQuotasFilters } from '../../../store/reducers/quotas/quotas-selectors'

export const QuotasFilters = React.memo(() => {
	const dispatch = useDispatch()

	const { mode, type } = useSelector(getQuotasFilters)

	// Фильтр "Вид"
	const handleViewFilter = (value) => () => {
		dispatch(setFilters({ mode: mode === value ? 0 : value, offset: 0 }))
	}

	// Фильтр "Тип"
	const handleTypeFilter = (value) => () => {
		dispatch(setFilters({ type: type === value ? 0 : value, offset: 0 }))
	}

	// Сбросить фильтр
	const handleReset = () => {
		dispatch(setFilters({ mode: 0, type: 0, offset: 0 }))
	}

	const FILTER = [
		{
			title: 'Вид',
			items: [
				{ title: 'Одноразовая', handler: handleViewFilter(1), active: mode === 1 },
				{ title: 'Месячная', handler: handleViewFilter(2), active: mode === 2 },
				{ title: 'Индивидуальная', handler: handleViewFilter(3), active: mode === 3 },
			],
		},
		{
			title: 'Тип',
			items: [
				{ title: 'Товар', handler: handleTypeFilter(3), active: type === 3 },
				{ title: 'Офис', handler: handleTypeFilter(1), active: type === 1 },
				{ title: 'Событие', handler: handleTypeFilter(2), active: type === 2 },
			],
		},
	]

	return (
		<div className="quotas-filters">
			<FiltersHeader reset={handleReset} />
			<Filters filter={FILTER} />
		</div>
	)
})
