import React from 'react'
import { IconButton } from '../../../../../../../components/IconButton/IconButton'
import { TextAreaField } from '../../../../../../../components/TextAreaField/TextAreaField'

import './NotesAdminInput.scss'

export const NotesAdminInput = React.memo(({ setComment, comment, handleCommentSend }) => {
	return (
		<div className="notes-message">
			<div className="notes-message__input">
				<TextAreaField
					id=""
					value={comment}
					onChange={({ target: { value } }) => setComment(value)}
					label="Заметка"
				/>
			</div>
			<div className="notes-message__send-btn">
				<IconButton
					className="send-button"
					iconName="checkmark-white"
					size={32}
					onClick={handleCommentSend}
				/>
			</div>
		</div>
	)
})
