import React from 'react'

import './QuotaInput.scss'

export const QuotaInput = React.memo(({ label, id, onClick }) => {
	return (
		<div className="quota__input-wrapper">
			<input type="radio" className="quota__input" name="quota" id={id} />
			<label htmlFor={id} className="quota__label" onClick={onClick}>
				{label}
			</label>
		</div>
	)
})
