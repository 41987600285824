import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'

import { delNot } from '../../../store/reducers/global-nots/global-nots'

import './GlobalNot.scss'

export const GlobalNot = React.memo(({ not = {} }) => {
	const dispatch = useDispatch()

	const { id, text, type } = not

	const notRef = useRef()

	useEffect(() => {
		const timer = setTimeout(() => {
			notRef.current.classList.remove('visible')
		}, 5000)

		return () => {
			clearTimeout(timer)
		}
	}, [])

	useEffect(() => {
		const timer = setTimeout(() => {
			dispatch(delNot({ id }))
		}, 5150)

		return () => {
			clearTimeout(timer)
		}
	}, [dispatch, id])

	return (
		<div ref={notRef} className={cn('global-not visible', { [`${type}`]: type })}>
			{text}
		</div>
	)
})
