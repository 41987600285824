import React from 'react'
import { Page, Text, View, Image } from '@react-pdf/renderer'

import { workType } from '../../../../functions/common'

export const JobPage = ({ styles, user, pagesLogo }) => {
	const {
		user: {
			first_page: { surname, name, city },
			second_page: {
				comp,
				post,
				motivation,
				work_type,
				month_cost,
				hour_cost,
				cities,
				work_open,
				countries,
			},
		},
		local: { cities: localCities, countries: localCountries },
	} = user

	const {
		page,
		pageHeader,
		section,
		pageWrapper,
		title,
		row,
		rowTitle,
		pageName,
		pagesLogo: pagesLogoStyles,
	} = styles

	return (
		<Page style={page}>
			<View style={pageHeader}>
				<Text style={pageName}>
					Резюме {surname} {name}
				</Text>
				<Image style={pagesLogoStyles} source={pagesLogo}></Image>
			</View>
			<View style={pageWrapper}>
				{/* Работа */}
				<View style={section}>
					<Text style={title}>Работа</Text>
					<View style={row}>
						<Text style={rowTitle}>Организация</Text>
						<Text>{comp ? comp : 'не указано'}</Text>
					</View>
					<View style={row}>
						<Text style={rowTitle}>Должность</Text>
						<Text>{post ? post : 'не указано'}</Text>
					</View>
					<View style={row}>
						<Text style={rowTitle}>Предложения о работе</Text>
						<Text>{work_open ? 'Принимает' : 'Отклоняет'}</Text>
					</View>
					<View style={row}>
						<Text style={rowTitle}>Мотивация</Text>
						<Text>{motivation ? motivation : 'не указано'}</Text>
					</View>
					<View style={row}>
						<Text style={rowTitle}>Город</Text>
						<Text>{localCities[city] ? localCities[city] : 'не указано'}</Text>
					</View>
					<View style={row}>
						<Text style={rowTitle}>Желаемый режим занятости</Text>
						<Text>{workType(work_type) ? workType(work_type) : 'не указано'}</Text>
					</View>
				</View>

				{/* Вознаграждение */}
				<View style={section}>
					<Text style={title}>Вознаграждение</Text>
					<View style={row}>
						<Text style={rowTitle}>Ожидаемая ЗП</Text>
						<Text>{month_cost ? month_cost : 'не указано'}</Text>
					</View>
					<View style={row}>
						<Text style={rowTitle}>Стоимость часа</Text>
						<Text>{hour_cost ? hour_cost : 'не указано'}</Text>
					</View>
				</View>

				{/* Релокация */}
				<View style={section}>
					<Text style={title}>Релокация</Text>
					<View style={row}>
						<Text style={rowTitle}>Страны, в которые готов переехать</Text>
						<Text>{countries ? countries.map((item) => localCountries[item]).join(', ') : ''}</Text>
					</View>
					<View style={row}>
						<Text style={rowTitle}>Города, в которые готов переехать</Text>
						<Text>{cities ? cities.map((item) => localCities[item]).join(', ') : ''}</Text>
					</View>
				</View>
			</View>
		</Page>
	)
}
