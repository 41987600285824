import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TableBody } from '../../../components/TableBody/TableBody'
import { TableRow } from '../../../components/TableBody/TableRows/TableRow/TableRow'
import { TableHeader } from '../../../components/TableHeader/TableHeader'

import {
	getCount,
	getQuotas,
	getQuotasFilters,
	getQutasIsLoading,
} from '../../../store/reducers/quotas/quotas-selectors'

import { requestQuotas, setFilters } from '../../../store/reducers/quotas/quotas'

import { changeOffset, refreshTableItems, searchItem } from '../../../functions/common'

import './QuotasTable.scss'

export const QuotasTable = React.memo(() => {
	const dispatch = useDispatch()

	const quotas = useSelector(getQuotas)
	const count = useSelector(getCount)
	const { offset, limit, group_name } = useSelector(getQuotasFilters)
	const isLoading = useSelector(getQutasIsLoading)

	return (
		<div className="products-table">
			<TableHeader
				title={'Список товаров и услуг'}
				placeholder="Название группы"
				searchValue={group_name}
				onSearchChange={searchItem(dispatch, setFilters, 'group_name')}
				onRefresh={refreshTableItems(dispatch, requestQuotas)}
			/>
			<TableBody
				contentTitlesArray={[
					{ title: 'Группа' },
					{ title: 'Описание' },
					{ title: 'Вид' },
					{ title: 'Количество' },
					{ title: 'Тип' },
					{ title: '' },
					{ title: 'Наименование' },
				]}
				linkItem={`quotas`}
				additionalClasses={['quotas-table']}
				offset={offset}
				limit={limit}
				count={count}
				setOffset={changeOffset(dispatch, setFilters)}
				isLoading={isLoading}
			>
				{quotas.map((quota, index) => (
					<div
						className="table-row"
						style={{ animationDelay: `${index * 40}ms` }}
						key={`table-block-${quota.id}`}
					>
						<TableRow
							row={{
								group_name: quota.group_name,
								group_desc: quota.group_desc,
								mode: quota.mode,
								all_count: quota.all_count,
								type: quota.type,
								image: quota.image,
								name: quota.name,
							}}
							key={`table-row-${index}`}
						/>
					</div>
				))}
			</TableBody>
		</div>
	)
})
