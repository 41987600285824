import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'
import ru from 'date-fns/locale/ru'

import { BreadCrumbs } from '../../../components/BreadCrumbs/BreadCrumbs'
import { EditCard } from '../../../components/EditCard/EditCard'
import { Input } from '../../../components/Inputs/Input/Input'
import { Preview } from '../../../components/Preview/Preview'
import { LoadImage } from '../../../components/LoadImage/LoadImage'
import { PageWrapper } from '../../../components/PageWrapper/PageWrapper'
import { Panel } from '../../../components/Panels/Panel/Panel'
import { InfoPageAddGallery } from '../../../components/InfoPage/InfoPageAddGallery/InfoPageAddGallery'
import { InfoPageAddBlocks } from '../../../components/InfoPage/InfoPageAddBlocks/InfoPageAddBlocks'
import { InfoPageItemBlock } from '../../../components/InfoPage/InfoPageItemBlock/InfoPageItemBlock'
import { InfoPageTypesBtns } from '../../../components/InfoPage/InfoPageTypesBtns/InfoPageTypesBtns'
import { Preloader } from '../../../components/Preloader/Preloader'
import { PublishButton } from '../../../components/Buttons/PublishButton/PublishButton'
import { InfoPageTags } from '../../../components/InfoPage/InfoPageTags/InfoPageTags'
import { PanelTitle } from '../../../components/Panels/Panel/PanelTitle/PanelTitle'

import { onError } from '../../../store/reducers/functions'

import {
	createArticle,
	publishArticle,
	unpublishArticle,
	deleteArticle,
	setArticleData,
	setArticleDataRequested,
	updateArticle,
} from '../../../store/reducers/knowledge-base/knowledge-base'

import { url } from '../../../api/api'

import './KnowledgeBaseItem.scss'

export const KnowledgeBaseItem = React.memo(
	({ id, edit, cancel, setCreate, data, requested, history }) => {
		if (!Object.keys(data).length && edit) {
			if (requested) {
				history.push('/knowledge-base')
			} else {
				return <Preloader />
			}
		}

		const dispatch = useDispatch()

		const [preview, setPreview] = useState(
			data?.prev_image ? `${url}/static/${data.prev_image}` : ''
		)

		const [image, setImage] = useState(data?.image || '')
		const [tags, setTags] = useState(data?.tags || [])
		const [blocks, setBlocks] = useState(data?.blocks || [])
		const [articleName, setArticleName] = useState(data?.name || '')
		const [articleDescription, setArticleDescription] = useState(data?.description || '')
		const [time, setTime] = useState(data?.time_create ? new Date(data.time_create) : new Date())
		const [articleType, setArticleType] = useState(data?.type || 1)

		const [articleGallery, setArticleGallery] = useState([])
		const [delFromGallery, setDelFromGallery] = useState([])

		const [errors, setErrors] = useState({})

		const gallery = data?.gallery || []

		const crumbs = [
			{ link: '/knowledge-base', text: 'База знаний' },
			{ text: edit ? articleName : 'Создать статью' },
		]

		const published = data?.status === 2

		const addBlock = (type, content) => {
			setBlocks([...blocks, { type, content }])
		}

		// Сохранение статьи
		const handleSave = async () => {
			const formData = new FormData()

			if (!articleName || !articleDescription || !preview) {
				if (!articleName || !articleDescription) {
					onError(dispatch, {
						code: 0,
						msg: 'Не заполнено имя или описание статьи',
					})
					setErrors((errors) => ({
						...errors,
						name: !articleName.length,
						description: !articleDescription.length,
					}))
				}

				if (!preview) {
					onError(dispatch, {
						code: 0,
						msg: 'Установите миниатюру',
					})
					setErrors((errors) => ({
						...errors,
						preview: true,
					}))
				}

				return false
			}

			articleGallery.forEach((item) => {
				const [name, value] = item
				formData.append(name, value)
			})

			const data = {
				name: articleName,
				prev_image: preview,
				image,
				description: articleDescription,
				time_create: time,
				type: articleType,
				tags,
				blocks,
				gallery,
				deleted: delFromGallery,
			}

			if (edit) {
				data.id = id
				dispatch(updateArticle(id, data, formData))
			} else {
				dispatch(createArticle(data, formData))
			}

			history.push('/knowledge-base')
		}

		// Добавление фото в галерею
		const handleAddGalleryPhotos = (photos) => {
			setArticleGallery((g) => [...g, ...photos])
		}

		// Удаление фото из галереи
		const handleDeleteGalleryPhotos = (index, file) => {
			if (typeof file === 'string') {
				setDelFromGallery((d) => [...d, index])
			} else {
				setArticleGallery((g) => g.filter((item) => item[1].localId !== file.localId))
			}
		}

		// Публикация, снятие с публикацииб удаление
		const changeArticleStatus = (id, callback) => () => {
			dispatch(callback(id))
			history.push('/knowledge-base')
		}

		const publish = async () => {
			await handleSave()
			changeArticleStatus(id, publishArticle)()
		}

		// отключение режима создания новости при размонтировании
		useEffect(
			() => () => {
				if (!edit) {
					setCreate(false)
				}
			},
			[edit, setCreate]
		)

		// Отчистка стейта при размонтировании
		useEffect(
			() => () => {
				dispatch(setArticleData({ data: {} }))
				dispatch(setArticleDataRequested({ requested: false }))
			},
			[dispatch]
		)

		return (
			<div className="knowledge-base-item">
				<div className="knowledge-base-item__header">
					<BreadCrumbs items={crumbs} />
					<div className="button-block">
						<EditCard
							id={id}
							edit={edit && data?.status !== 3}
							deleteIt={'Удалить статью'}
							cancel={'Отменить'}
							save={'Сохранить'}
							savedFunc={handleSave}
							cancelFunc={cancel}
							deleteFunc={changeArticleStatus(id, deleteArticle)}
						/>
						<PublishButton
							show={edit && data?.status !== 3}
							isPublished={published}
							unpublishFunc={changeArticleStatus(id, unpublishArticle)}
							publishFunc={publish}
						/>
					</div>
				</div>
				<PageWrapper>
					<Panel>
						<div className="knowledge-base-item__types-block">
							<PanelTitle text="Общее" />
							<InfoPageTypesBtns knowledgeBase type={articleType} setType={setArticleType} />
						</div>
						<Preview
							image={preview}
							setImage={setPreview}
							accept={'.jpg, .png, .jpeg, .gif'}
							error={errors.preview}
							aspect={4 / 4}
						/>
						<LoadImage image={image} setImage={setImage} accept={'.jpg, .png, .jpeg, .gif'} />
						<Input
							label={'Название'}
							value={articleName}
							name={'info-name'}
							onChange={({ target: { value } }) => setArticleName(value)}
							className={errors.name && 'error'}
						/>
						<Input
							label={'Краткое описание'}
							value={articleDescription}
							name={'info-description'}
							onChange={({ target: { value } }) => setArticleDescription(value)}
							className={errors.description && 'error'}
						/>
						<DatePicker
							dateFormat="dd.MM.yyyy"
							selected={time}
							onChange={setTime}
							showMonthDropdown
							showYearDropdown
							peekNextMonth
							dropdownMode="select"
							locale={ru}
							customInput={<Input label="Дата" placeholder="" />}
						/>
					</Panel>

					<InfoPageTags tags={tags} setTags={setTags} />

					{blocks?.map((item, index) => (
						<Panel key={index}>
							<InfoPageItemBlock
								block={item}
								index={index}
								blocks={blocks}
								setBlocks={setBlocks}
								blocksLength={blocks.length}
							/>
						</Panel>
					))}

					<InfoPageAddBlocks addBlock={addBlock} />

					<InfoPageAddGallery
						gallery={gallery}
						addPhotos={handleAddGalleryPhotos}
						deletePhoto={handleDeleteGalleryPhotos}
					/>
				</PageWrapper>
			</div>
		)
	}
)
