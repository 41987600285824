import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SearchInput } from '../../Inputs/SearchInput/SearchInput'
import { Button } from '../../Buttons/Button/Button'
import { TableQuotaHeader } from '../../TableHeader/TableQuotaHeader/TableQuotaHeader'
import { TableBody } from '../../TableBody/TableBody'
import { TableQuotaRow } from '../../TableBody/TableRows/TableQuotaRow/TableQuotaRow'
import { ModalContainer } from '../../ModalContainer/ModalContainer'
import { QuotaContent } from './QuotaContent/QuotaContent'

import {
	deleteQuota,
	setFilters,
	setCount,
	setQuotas,
	requestQuotasById,
} from '../../../store/reducers/quotas/quotas'

import {
	getQuotasFilters,
	getCount,
	getQutasIsLoading,
	getQuotas,
} from '../../../store/reducers/quotas/quotas-selectors'
import { changeOffset } from '../../../functions/common'

import deleteIt from '../../../assets/media/svg/delete-black.svg'

import './Quotas.scss'

export const Quotas = React.memo(({ id, status, type }) => {
	const dispatch = useDispatch()

	const { limit, offset, group_name } = useSelector(getQuotasFilters)
	const count = useSelector(getCount)

	const [showModal, setShowModal] = useState(false)

	const quotasList = useSelector(getQuotas)
	const filters = useSelector(getQuotasFilters)
	const isLoading = useSelector(getQutasIsLoading)

	useEffect(
		() => () => {
			dispatch(setQuotas({ list: [] }))
			dispatch(
				setFilters({
					limit: 10,
					offset: 0,
					type: 0,
					is_updated: 0,
					group_name: '',
				})
			)
			dispatch(setCount({ count: 0 }))
		},
		[dispatch]
	)

	useEffect(() => {
		dispatch(requestQuotasById(id))
	}, [dispatch, id, filters])

	return (
		<div className="quotas">
			<div className="quotas__header">
				<SearchInput
					placeholder={'Группа'}
					value={group_name}
					onChange={({ target: { value } }) =>
						dispatch(setFilters({ group_name: value, offset: 0 }))
					}
				/>
				{(status < 3 || status > 4) && (
					<Button label="Добавить квоту" type="md1" gray onClick={() => setShowModal(true)} />
				)}
			</div>
			<TableBody
				limit={limit}
				count={count}
				offset={offset}
				setOffset={changeOffset(dispatch, setFilters)}
				isLoading={isLoading}
			>
				<TableQuotaHeader />
				{!!quotasList.length ? (
					quotasList.map((item, index) => (
						<TableQuotaRow
							name={item.name}
							numbers={item.all_count}
							icon={deleteIt}
							mode={item.mode}
							key={`quotas-${index}`}
							onClick={() => dispatch(deleteQuota(id, item.id))}
							id={item.id}
							thunkId={id}
							status={status}
						/>
					))
				) : (
					<span>Список групп пуст</span>
				)}
			</TableBody>
			{showModal && (
				<ModalContainer profile={showModal} show={showModal} onClose={() => setShowModal(false)}>
					<QuotaContent closeModal={() => setShowModal(false)} type={type} />
				</ModalContainer>
			)}
		</div>
	)
})
