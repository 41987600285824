import { createSlice } from '@reduxjs/toolkit'
import { onError } from '../functions'

import eventsApi from '../../../api/events-api'
import commonApi from '../../../api/common-api'

const initialState = {
	eventsList: [],
	count: 0,
	filters: {
		limit: 10,
		offset: 0,
		data_mode: '',
		type: 0,
		status: 0,
		is_proposal: 0,
		name: '',
		city: '',
	},
	event: null,
	cities: [],
	isLoading: false,
}

const events = createSlice({
	name: 'events',
	initialState,
	reducers: {
		setEventsList: (state, action) => {
			state.eventsList = action.payload.eventsList
		},

		setCount: (state, action) => {
			state.count = action.payload.count
		},

		setFilters: (state, action) => {
			state.filters = { ...state.filters, ...action.payload }
		},

		setEvent: (state, action) => {
			state.event = action.payload.event
		},

		setCityForFilters: (state, action) => {
			state.cities = action.payload.cities
		},

		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
	},
})

const { reducer, actions } = events

export const {
	setEventsList,
	setFilters,
	setCount,
	setEvent,
	setCityForFilters,
	setIsLoading,
} = actions

// Список городов для фильтров
export const requestEventsCity = (type) => async (dispatch) => {
	const res = await commonApi.getCityForFilters(type)

	if (res.success) {
		dispatch(setCityForFilters({ cities: res.result }))
	}
}

// Получить список событий
export const requestEvents = () => async (dispatch, getState) => {
	dispatch(setIsLoading({ isLoading: true }))

	const {
		events: { filters },
	} = getState()

	const res = await eventsApi.requestEvents({ ...filters })

	if (res.success) {
		dispatch(setEventsList({ eventsList: res.result.events }))
		dispatch(setCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// Получить событие
export const requestEvent = (id) => async (dispatch) => {
	const res = await eventsApi.requestEvent(id)

	if (res.success) {
		dispatch(setEvent({ event: res.result }))
	} else {
		onError(dispatch, res.result)
	}
}

export const createEvent = (data) => async (dispatch) => {
	const res = await eventsApi.createEvent(data)

	if (res.success) {
		dispatch(requestEvents())
	} else {
		onError(dispatch, res.result)
	}
}

// Обновить информацию о событии
export const updateEvent = (data) => async (dispatch) => {
	const res = await eventsApi.updateEvent(data)

	if (res.success) {
		dispatch(requestEvents())
	} else {
		onError(dispatch, res.result)
	}
}

// Отменить публикацию события
export const rejectEvent = (eventId) => async (dispatch) => {
	const res = await commonApi.rejectTarget(eventId, 2)

	if (res.success) {
		dispatch(requestEvents())
	} else {
		onError(dispatch, res.result)
	}
}

// Разрешить публикацию события
export const approveEvent = (eventId) => async (dispatch) => {
	const res = await commonApi.approveTarget(eventId, 2)

	if (res.success) {
		dispatch(requestEvents())
	} else {
		onError(dispatch, res.result)
	}
}

// Удалить публикацию события
export const deleteEvent = (eventId) => async (dispatch) => {
	const res = await commonApi.deleteTarget(eventId, 2)

	if (res.success) {
		dispatch(requestEvents())
	} else {
		onError(dispatch, res.result)
	}
}

export const uploadImage = (eventId, data) => async (dispatch) => {
	const res = await eventsApi.uploadImage(eventId, data)

	if (res.success) {
		dispatch(requestEvent(eventId))
	} else {
		onError(dispatch, res.result)
	}
}

export const uploadVideo = (eventId, link) => async (dispatch) => {
	const res = await eventsApi.uploadVideo(eventId, link)

	if (res.success) {
		dispatch(requestEvent(eventId))
	} else {
		onError(dispatch, res.result)
	}
}

export const deleteMedia = (eventId, type, index) => async (dispatch) => {
	const res = await eventsApi.deleteMedia(eventId, type, index)

	if (res.success) {
		dispatch(requestEvent(eventId))
	} else {
		onError(dispatch, res.result)
	}
}

export default reducer
