import React from 'react'

import { ColumnsContainer } from '../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { PageWrapper } from '../../components/PageWrapper/PageWrapper'
import { AnaliticsTable } from './AnaliticsTable/AnaliticsTable'

import './AnaliticsPage.scss'

export const AnaliticsPage = React.memo(() => (
	<div className="analitics-page">
		<PageWrapper>
			<ColumnsContainer>
				<AnaliticsTable />
			</ColumnsContainer>
		</PageWrapper>
	</div>
))
