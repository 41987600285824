import React from 'react'
import { useDispatch } from 'react-redux'
import { Field, Formik, Form } from 'formik'

import { Input } from '../../../../../../../components/Inputs/Input/Input'
import { Button } from '../../../../../../../components/Buttons/Button/Button'
import { TextAreaField } from '../../../../../../../components/TextAreaField/TextAreaField'

import { createTransaction } from '../../../../../../../store/reducers/transactions/transactions'

export const PointsOperations = React.memo(({ close, name, userId }) => {
	const dispatch = useDispatch()

	return (
		<Formik
			initialValues={{ points: '', msg: '' }}
			onSubmit={(values, { setValues }) => {
				const { points, msg } = values

				dispatch(
					createTransaction(
						{ to_id: userId, is_user: true, value: points, msg },
						true
					)
				)

				setValues({ points: '', msg: '' })

				close()
			}}
		>
			{({ values }) => {
				const { points, msg } = values

				return (
					<div className="points-modal">
						<div className="points-modal__title">
							<span>Изменить баллы пользователя </span>
							<span>{name}?</span>
						</div>
						<Form>
							<Field
								name="points"
								type="number"
								label={'Баллы(+/-)'}
								as={Input}
							/>
							<Field name="msg" label={'Текст сообщения'} as={TextAreaField} />
							<div className="points-modal__buttons">
								<Button label={'Отменить'} type="md1" onClick={close} white />
								<Button
									nType="submit"
									label={'Подтвердить'}
									type="md2"
									disabled={!points || !msg}
								/>
							</div>
						</Form>
					</div>
				)
			}}
		</Formik>
	)
})
