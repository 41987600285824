import { createSlice } from '@reduxjs/toolkit'

import {
	removeAdminToken,
	setAdminToken,
	setUserToken,
} from '../../../functions/localStorage'

import {
	setRefreshTimeout,
	clearRefreshTimeout,
	setUpdating,
	setWs,
} from '../app/app'
import { setUser } from '../profile/profile'

import authApi from '../../../api/auth-api'

const initialState = {
	isAuth: false,
	nextStep: false,
	phone: '',
}

const auth = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setIsAuth: (state, action) => {
			state.isAuth = action.payload.isAuth
		},
		setPhone: (state, action) => {
			state.phone = action.payload.phone
		},
	},
})

const { reducer, actions } = auth

export const { setIsAuth, setPhone } = actions

/*
	Авторизация админа
*/
export const regAuth = (params) => async (dispatch) => {
	const res = await authApi.verifAdmin(params)

	if (res.success) {
		const { jwt_user, jwt_admin, user } = res.result

		dispatch(setUser({ user }))
		setAdminToken(jwt_admin)
		setUserToken(jwt_user)
		dispatch(setIsAuth({ isAuth: true }))
		dispatch(setRefreshTimeout())

		dispatch(setPhone({ phone: '' }))
	}
}

/*
	Обновление токена
*/
export const refresh = () => async (dispatch) => {
	const res = await authApi.refresh()

	if (res.success) {
		setAdminToken(res.result)

		// Обновить таймаут
		dispatch(clearRefreshTimeout())
		dispatch(setRefreshTimeout())
	} else {
		dispatch(logout())
	}
}

/*
	Выход из приложения
*/
export const logout = () => (dispatch, getState) => {
	const {
		app: { ws },
	} = getState()

	dispatch(setIsAuth({ isAuth: false }))
	dispatch(clearRefreshTimeout())
	dispatch(setUser({ user: {} }))
	removeAdminToken()

	setUpdating({ updating: false })
	ws.close()
	setWs({ ws: null })
}

export default reducer
