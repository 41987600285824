import React from 'react'
import { useSelector } from 'react-redux'

import { Avatar } from '../../../../../../components/Avatar/Avatar'
import { Button } from '../../../../../../components/Buttons/Button/Button'

import { getDevice } from '../../../../../../store/reducers/app/app-selectors'

import './UserBanModal.scss'

export const UserBanModal = React.memo(
	({ name, onClose, onClick, photo, isBanned }) => {
		const device = useSelector(getDevice)

		const sm = device === 'mobile' || device === 'notepad'

		return (
			<div className="user-ban-modal">
				<div className="user-ban-info">
					<Avatar image={photo} />
					<div className="user-ban-text">
						<p>
							{isBanned ? 'Разблокировать' : 'Заблокировать'} пользователя
							<br /> <span>{name}</span>?
						</p>
					</div>
				</div>
				<div className="buttons-block">
					<Button
						label="Отменить"
						onClick={onClose}
						className="cancel-ban-btn"
						white
						type="s"
					/>
					<Button
						type={sm ? 's' : 'lg'}
						label={isBanned ? 'Разблокировать' : 'Заблокировать'}
						red={!isBanned}
						onClick={onClick}
						className="user-ban-btn"
					/>
				</div>
			</div>
		)
	}
)
