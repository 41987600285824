import React from 'react'
import cn from 'classnames'

import { LoadImage } from '../../../../../components/LoadImage/LoadImage'
import { Input } from '../../../../../components/Inputs/Input/Input'

import './ProductItemCommon.scss'
import { TextAreaField } from '../../../../../components/TextAreaField/TextAreaField'
import { ProductSearchCity } from './ProductSearchCity/ProductSearchCity'

export const ProductItemCommon = React.memo(
	({
		name,
		setName,
		type,
		setType,
		description,
		setDescription,
		productImage,
		setProductImage,
		price,
		setPrice,
		city,
		setCity,
		setCurrentBalance,
		currentBalance,
		productError,
		password,
		setPassword,
		fillPassword,
		searchCity,
		country,
		setCountry,
		searchCountry,
		setCountryId,
	}) => (
		<>
			<div className="product-item__title">
				<h2>Общее</h2>
			</div>
			<div className="product-item__common">
				<div className="product-item__common-image">
					<LoadImage
						image={productImage}
						setImage={setProductImage}
						accept={'.png'}
						market
					/>
				</div>
				<div className="product-item__common-description">
					<div className="product-item__common-box">
						<Input
							label={'Наименование'}
							value={name}
							onChange={({ target: { value } }) => setName(value)}
							className={productError.name ? '' : 'error'}
						/>
						<div className="common-description__checkbox">
							<button
								className={cn('checkbox-item', { active: type === 1 })}
								onClick={() => setType(1)}
							>
								Товар
							</button>
							<button
								className={cn('checkbox-item', { active: type === 2 })}
								onClick={() => setType(2)}
							>
								Услуга
							</button>
						</div>
					</div>
					<div className="product-item__common-box">
						<Input
							label={'Пароль'}
							value={password}
							onChange={({ target: { value } }) => setPassword(value)}
							className={productError.password ? '' : 'error'}
							disabled={!!fillPassword}
						/>

						<Input
							label={'Стоимость'}
							value={price}
							onFocus={() => !price && setPrice('')}
							onBlur={() => (!price ? setPrice(0) : price)}
							type={'number'}
							onChange={({ target: { value } }) => setPrice(value)}
							className={productError.price ? '' : 'error'}
						/>
						<Input
							label={'Остаток'}
							type={'number'}
							value={currentBalance}
							onFocus={() => !currentBalance && setCurrentBalance('')}
							onBlur={() =>
								!currentBalance ? setCurrentBalance(0) : currentBalance
							}
							onChange={({ target: { value } }) => setCurrentBalance(value)}
						/>
					</div>
					<div className="product-item__common-item">
						<ProductSearchCity
							search={searchCountry}
							value={country}
							setValue={setCountry}
							productError={productError.country}
							label={'Страна'}
							setCountryId={setCountryId}
							setCity={setCity}
						/>
						<ProductSearchCity
							search={searchCity}
							value={city}
							setValue={setCity}
							productError={productError.city}
							label={'Город'}
						/>
					</div>
				</div>
				<TextAreaField
					label="Описание"
					value={description}
					onChange={({ target: { value } }) => setDescription(value)}
					error={productError.description ? '' : 'error'}
				/>
			</div>
		</>
	)
)
