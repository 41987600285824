import { createSlice } from '@reduxjs/toolkit'

import groupsApi from '../../../api/groups-api'
import { onError } from '../functions'

const initialState = {
	groups: [],
	userGroups: [],
	users: [],
	groupsCount: 0,
	usersCount: 0,
	groupsFilters: {
		name: '',
		limit: 10,
		offset: 0,
	},
	usersFilters: {
		limit: 10,
		offset: 0,
		pat: '',
	},
	groupInfo: {},
	searchUsersResult: [],
	isLoading: false,
}

const groups = createSlice({
	name: 'groups',
	initialState,
	reducers: {
		setGroups: (state, action) => {
			state.groups = action.payload.groups
		},
		setUserGroups: (state, action) => {
			state.userGroups = action.payload.userGroups
		},
		setGroupsCount: (state, action) => {
			state.groupsCount = action.payload.count
		},
		setGroupsFilters: (state, action) => {
			state.groupsFilters = { ...state.groupsFilters, ...action.payload }
		},
		setIsLoading: (state, action) => {
			state.isLoading = action.payload.isLoading
		},
		setUsers: (state, action) => {
			state.users = action.payload.users
		},
		setUsersCount: (state, action) => {
			state.usersCount = action.payload.count
		},
		setUsersFilters: (state, action) => {
			state.usersFilters = { ...state.usersFilters, ...action.payload }
		},
		setGroupInfo: (state, action) => {
			state.groupInfo = action.payload.info
		},
		setSearchUsersResult: (state, action) => {
			state.searchUsersResult = action.payload.result
		},
	},
})

export const { reducer, actions } = groups

export const {
	setGroups,
	setGroupsCount,
	setGroupsFilters,
	setIsLoading,
	setUsers,
	setUsersCount,
	setUsersFilters,
	setGroupInfo,
	setSearchUsersResult,
	setUserGroups,
} = actions

// получение списка групп
export const requestGroups = () => async (dispatch, getState) => {
	const {
		groups: { groupsFilters },
	} = getState()

	dispatch(setIsLoading({ isLoading: true }))

	const res = await groupsApi.requestGroups(groupsFilters)

	if (res.success) {
		dispatch(setGroups({ groups: res.result.groups }))
		dispatch(setGroupsCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// получение списка групп в которых состоит пользователь
export const requestUserGroup = (user_id) => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await groupsApi.requestUserGroup(user_id)

	if (res.success) {
		dispatch(setUserGroups({ userGroups: res.result }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// получение списка участников группы
export const requestUsersInGroup = (group_id) => async (dispatch, getState) => {
	const {
		groups: { usersFilters },
	} = getState()

	dispatch(setIsLoading({ isLoading: true }))

	const res = await groupsApi.requestUsersInGroup({
		group_id,
		is_expelled: true,
		...usersFilters,
	})

	if (res.success) {
		dispatch(setUsers({ users: res.result.users }))
		dispatch(setUsersCount({ count: res.result.count }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// получение информации о группе
export const requestGroup = (id) => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await groupsApi.requestGroup(id)

	if (res.success) {
		dispatch(setGroupInfo({ info: res.result }))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

// создание новой группы
export const createGroup = (data) => async (dispatch) => {
	const res = await groupsApi.createGroup(data)

	if (res.success) {
		dispatch(setGroupInfo({ info: res.result }))
		dispatch(requestGroups())
	} else {
		onError(dispatch, res.result)
	}
}

// сохранение изменений в группе
export const updateGroup = (data) => async (dispatch) => {
	const res = await groupsApi.updateGroup(data)

	if (res.success) {
		dispatch(requestGroup(data.id))
	} else {
		onError(dispatch, res.result)
	}
}

// удаление группы

export const deleteGroup = (id) => async (dispatch) => {
	const res = await groupsApi.deleteGroup(id)

	if (res.success) {
		dispatch(requestGroups())
	} else {
		onError(dispatch, res.result)
	}
}

// поиск пользователей для добавления
export const searchUsers = (id, value) => async (dispatch) => {
	const res = await groupsApi.requestUsersInGroup({
		limit: 10,
		offset: 0,
		pat: value,
		group_id: id,
		is_expelled: false,
	})

	if (res.success) {
		dispatch(setSearchUsersResult({ result: res.result.users }))
	}
}

// добавление пользователей в группу

export const addUsersToGroup = (data) => async (dispatch) => {
	const res = await groupsApi.addUsersToGroup(data)
	const userId = data.users[0]

	if (res.success) {
		dispatch(requestUsersInGroup(data.group_id))
		dispatch(requestUserGroup(userId))
	} else {
		onError(dispatch, res.result)
	}
}

// удаление пользователя из группы
export const deleteUserFromGroup = (idGroup, idUser, userGroups) => async (dispatch) => {
	dispatch(setIsLoading({ isLoading: true }))

	const res = await groupsApi.deleteUserFromGroup(idGroup, idUser)

	if (res.success) {
		userGroups ? dispatch(requestUserGroup(idUser)) : dispatch(requestUsersInGroup(idGroup))
	} else {
		onError(dispatch, res.result)
	}

	dispatch(setIsLoading({ isLoading: false }))
}

export default reducer
