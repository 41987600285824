import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	profile: {
		surname: '',
		name: '',
		patronymic: '',
		birthday: '',
		email: '',
		expWork: '',
		expIT: '',
		country: -1,
		city: -1,
		language: [],
		social: [],
		resumeLink: '',
		// resumeFile: "",
		gitLink: '',
		about: '',
	},
	work: {
		wokrState: -1,
		organization: '',
		workOpen: -1,
		motivation: '',
		workType: -1,
		country: [],
		city: [],
		monthCost: '',
		hourCost: '',
	},
	specs: {
		list: [],
	},
	techs: {
		list: [],
		custom: [],
	},
}

const userForm = createSlice({
	name: 'userForm',
	initialState,
	reducers: {
		setProfile: (state, action) => {
			state.profile = { ...state.profile, ...action.payload }
		},
		setWork: (state, action) => {
			state.work = { ...state.work, ...action.payload }
		},
		setSpecsList: (state, action) => {
			state.specs.list = action.payload
		},
		setTechsList: (state, action) => {
			state.techs.list = action.payload
		},
		setTechsCustom: (state, action) => {
			state.techs.custom = action.payload
		},
	},
})

const { reducer, actions } = userForm

export const {
	setProfile,
	setWork,
	setSpecsList,
	setTechsList,
	setTechsCustom,
} = actions

export default reducer
