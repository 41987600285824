import React from 'react'
import cn from 'classnames'

import './TabsSelect.scss'

export const TabsSelect = React.memo(({ tabs, tab, setTab }) => (
	<div className="tabs-select-wrapper">
		{tabs.map((item, i) => (
			<button
				className={cn('tab-selector', { active: tab === i })}
				onClick={() => setTab(i)}
				key={`tab-${i}`}
				type="button"
			>
				<span>{item.title}</span>
				{item.count > 0 && (
					<div className="tab-bagel">
						<span className="tab-count">{item.count}</span>
					</div>
				)}
			</button>
		))}
	</div>
))
