import React from 'react'

import { HeaderButton } from './HeaderButton/HeaderButton'

import './BlockHeaderButtons.scss'

export const BlockHeaderButtons = React.memo(
	({ index, moveBack, moveForw, deleteBlock, length }) => {
		const handleDelete = () => deleteBlock(index)

		return (
			<div className="info-page-card-btns">
				<HeaderButton onClick={moveBack} prev disabled={index === 0} />
				<HeaderButton onClick={moveForw} next disabled={index === length - 1} />
				<HeaderButton onClick={handleDelete} del />
			</div>
		)
	}
)
