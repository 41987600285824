import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import cn from 'classnames'

import { PageWrapper } from '../../components/PageWrapper/PageWrapper'
import { ColumnsContainer } from '../../components/PageWrapper/ColumnsContainer/ColumnsContainer'
import { LeftColumn } from '../../components/PageWrapper/ColumnsContainer/LeftColumn/LeftColumn'
import { RightColumn } from '../../components/PageWrapper/ColumnsContainer/RightColumn/RightColumn'
import { UsersTable } from './UsersTable/UsersTable'
import { FormsTable } from './FormsTable/FormsTable'
import { TableHeader } from '../../components/TableHeader/TableHeader'
import { UsersFilters } from './UsersFilters/UsersFilters'
import { UsersCount } from './UsersCount/UsersCount'
import { UserItem } from './UsersTable/UserItem/UserItem'
import { ModalContainer } from '../../components/ModalContainer/ModalContainer'

import { setCount } from '../../store/reducers/applications/applications'
import { setFilters, setUsers } from '../../store/reducers/users/users'
import { getUsersList } from '../../store/reducers/users/users'

import { getCount, getFilters } from '../../store/reducers/users/users-selectors'
import { getDevice } from '../../store/reducers/app/app-selectors'

import './UsersPage.scss'
import { refreshTableItems, searchItem } from '../../functions/common'

export const UsersPage = () => {
	const dispatch = useDispatch()

	const match = useRouteMatch()

	const id = match.params.id

	const count = useSelector(getCount)
	const filters = useSelector(getFilters)
	const { name } = filters

	const device = useSelector(getDevice)

	const [tab, setTab] = useState(0)
	const [modal, setModal] = useState(false)

	const handleModalToggle = (modalState) => () => setModal(modalState)

	const changeTab = (i) => {
		setTab(i)
		dispatch(
			setFilters({
				offset: 0,
				name: '',
				role: 0,
				status: 0,
				sort: '',
				skills: [],
				specializations: [],
				group_id: '',
				is_group: 0,
				is_banned: 0,
			})
		)
	}

	const tabs = [
		{
			title: 'Пользователи',
		},
		{
			title: 'Анкеты',
		},
	]

	useEffect(() => {
		dispatch(getUsersList())
	}, [filters, dispatch])

	useEffect(
		() => () => {
			dispatch(
				setFilters({
					offset: 0,
					name: '',
					role: 0,
					status: 0,
					sort: '',
					skills: [],
					specializations: [],
					group_id: '',
					is_group: 0,
					is_work_open: 0,
					is_banned: 0,
				})
			)
			dispatch(setUsers({ users: [] }))
		},
		[dispatch]
	)

	useEffect(
		() => () => {
			dispatch(setCount({ count: 0 }))
		},
		[dispatch, id]
	)

	return (
		<div className={cn('users-page', { 'user-item-page': id })}>
			<PageWrapper>
				{id ? (
					<UserItem id={id} />
				) : (
					<ColumnsContainer>
						<LeftColumn>
							{(device === 'notepad' || device === 'mobile') && (
								<div className="filters-header__reset" onClick={handleModalToggle(true)}>
									Фильтры <i></i>
								</div>
							)}
							<TableHeader
								tabs={tabs}
								tab={tab}
								setTab={changeTab}
								title="Список пользователей"
								placeholder="Фамилия, Имя"
								stanalone={tab === '0'}
								searchValue={name}
								onSearchChange={searchItem(dispatch, setFilters, 'name')}
								onRefresh={refreshTableItems(dispatch, getUsersList)}
							/>
							{tab === 1 ? <FormsTable /> : <UsersTable />}

							{!!count && <UsersCount count={count} />}
						</LeftColumn>
						<RightColumn>
							<UsersFilters />
						</RightColumn>
						<ModalContainer show={modal} onClose={handleModalToggle(false)}>
							<UsersFilters />
						</ModalContainer>
					</ColumnsContainer>
				)}
			</PageWrapper>
		</div>
	)
}
