import instance from './api'

import { getAdminToken } from '../functions/localStorage'

const newsApi = {
	async requestNews(params) {
		const response = await instance.post('admin/search/news', params, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async requestNewById(id) {
		const response = await instance.post(
			`news/get?news_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async createNew(data) {
		const response = await instance.post('admin/create/news', data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async updateNew(data) {
		const response = await instance.post('admin/update/news', data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
			},
		})

		return response.data
	},

	async uploadGallery(id, data) {
		const response = await instance.post(`news/upload/image?news_id=${id}`, data, {
			headers: {
				Authorization: `Bearer ${getAdminToken()}`,
				'Content-Type': 'multipart/form-data',
			},
		})

		return response.data
	},
}

export default newsApi
