import React from 'react'
import { Icon } from '../../../../components/Icon/Icon'
import { Input } from '../../../../components/Inputs/Input/Input'

export const SocialInput = React.memo(
	({ iconName, iconText, inputText, inputChange }) => (
		<div className="social-block-input">
			<div className="social-block-input__image">
				<Icon name={iconName} />
				<p>{iconText}</p>
			</div>
			<div className="social-block-input__value">
				<Input label="Текст" value={inputText} onChange={inputChange} />
			</div>
		</div>
	)
)
