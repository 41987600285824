import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { url } from '../../../api/api'
import cn from 'classnames'

import { Icon } from '../../Icon/Icon'

import {
	checkNots,
	requestNotifications,
} from '../../../store/reducers/notifications/notifications'

import { getNewCount, getNots } from '../../../store/reducers/notifications/notifications-selectors'

import './Notifications.scss'

const ListItem = ({ text, image, success, checked, type }) => (
	<div className={cn('list-item', { checked })}>
		{image && <img src={`${url}/static/${image}`} alt="Фото события" />}
		{image && success && type === 'props' && (
			<span className="status">{success ? <Icon name="approved" /> : <Icon name="denied" />}</span>
		)}
		<span className="text">{text}</span>
	</div>
)

export const Notifications = () => {
	const dispatch = useDispatch()

	const [hover, setHover] = useState(false)

	const notifications = useSelector(getNots)
	const newCount = useSelector(getNewCount)

	const handleHover = (state) => () => {
		setHover(state)

		if (state) {
			setTimeout(() => {
				if (newCount) {
					dispatch(checkNots())
				}
			}, 3000)
		}
	}

	useEffect(() => {
		dispatch(requestNotifications())
	}, [dispatch])

	return (
		<div
			className={cn('notif-container', { hover })}
			onMouseEnter={handleHover(true)}
			onMouseLeave={handleHover(false)}
		>
			<div className="notif-button">
				<Icon name="notifications" />
				{!!newCount && (
					<div className="badge">
						<span>{newCount}</span>
					</div>
				)}
			</div>
			<div className="drop-card">
				<div className="scroll-wrapper">
					<div className="list-container">
						{notifications.map((not) => (
							<ListItem
								key={not.id}
								image={not.image}
								text={not.msg}
								checked={not.сhecked}
								type={not.type}
								success={not.confirmation}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}
