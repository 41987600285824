import React from 'react'
import { PanelTitle } from '../../../../Panels/Panel/PanelTitle/PanelTitle'

import './BlockHeaderTitle.scss'

export const BlockHeaderTitle = React.memo(({ title, type }) => (
	<div className="block-header-title">
		<div
			className={`block-header-title__icon block-header-title__icon--${type}`}
		>
			<i></i>
		</div>
		<PanelTitle text={title} />
	</div>
))
