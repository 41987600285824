import React, { useContext } from 'react'
import { Button } from '../Button/Button'

import AppContext from '../../../context/AppContext'

import send from '../../../assets/media/svg/send.svg'

import { Confirm } from '../../ModalContainer/Confirm/Confirm'

import './PublishButton.scss'

export const PublishButton = React.memo(
	({ show, publishFunc, unpublishFunc, isPublished }) => {
		const { setConfirmConfig } = useContext(AppContext)

		const createContent = () => (
			<Confirm
				red
				text="Снять с публикации"
				cancel={setConfirmConfig(false)}
				confirm={unpublishFunc}
			/>
		)

		return (
			<div className="publish-button">
				{show && (
					<div className="button-block">
						{isPublished ? (
							<Button
								label="Снять с публикации"
								type="md2"
								red
								icon={send}
								onClick={setConfirmConfig(true, createContent())}
							/>
						) : (
							<Button
								label="Публиковать"
								type="md2"
								icon={send}
								onClick={publishFunc}
							/>
						)}
					</div>
				)}
			</div>
		)
	}
)
