import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import app from './reducers/app/app'
import auth from './reducers/auth/auth'
import profile from './reducers/profile/profile'
import userForm from './reducers/user-form/user-form'
import users from './reducers/users/users'
import user from './reducers/user/user'
import events from './reducers/events/events'
import transactions from './reducers/transactions/transactions'
import quotas from './reducers/quotas/quotas'
import certificates from './reducers/certificates/certificates'
import groups from './reducers/groups/groups'
import applications from './reducers/applications/applications'
import notifications from './reducers/notifications/notifications'
import globalNots from './reducers/global-nots/global-nots'
import products from './reducers/products/products'
import news from './reducers/news/news'
import offices from './reducers/offices/offices'
import content from './reducers/content/content'
import partners from './reducers/partners/partners'
import knowledgeBase from './reducers/knowledge-base/knowledge-base'
import comments from './reducers/comments/comments'
import analitics from './reducers/analitics/analitics'

const reducers = {
	app,
	auth,
	profile,
	userForm,
	users,
	user,
	events,
	transactions,
	quotas,
	certificates,
	groups,
	applications,
	notifications,
	globalNots,
	products,
	news,
	offices,
	content,
	partners,
	knowledgeBase,
	comments,
	analitics,
}

const middleware = getDefaultMiddleware({
	immutableCheck: false,
	serializableCheck: false,
	thunk: true,
})

const store = configureStore({ reducer: reducers, middleware })

window.store = store

export default store
