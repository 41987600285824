import React, { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import PhoneInput from 'react-phone-input-2'
import ReCAPTCHA from 'react-google-recaptcha'
import 'react-phone-input-2/lib/style.css'

import { RegistForm } from './RegistForm/RegistForm'
import { FormHeader } from './RegistForm/FormHeader/FormHeader'
import { FormGroup } from './RegistForm/FormGroup/FormGroup'
import { Button } from '../../components/Buttons/Button/Button'
import { Input } from '../../components/Inputs/Input/Input'

import { regAuth } from '../../store/reducers/auth/auth'

import './EnterPage.scss'

import { getDevice } from '../../store/reducers/app/app-selectors'

export const EnterPage = () => {
	const dispatch = useDispatch()

	const captcha = useRef()

	const device = useSelector(getDevice)

	const onChangeCap = (setValues) => (cap) => {
		setValues((values) => ({ ...values, cap }))
	}

	return (
		<div className="regist-page">
			<div className="form-container">
				<Formik
					initialValues={{ phone: '', password: '' }}
					onSubmit={async (values, { setSubmitting }) => {
						setSubmitting(true)

						const { phone, password, cap } = values

						const success = dispatch(regAuth({ phone, password, cap }))
						setSubmitting(false)

						if (!success) {
							captcha.current.reset()
						}
					}}
				>
					{({ values, isSubmitting, setValues }) => {
						let disableButtonReason

						const { phone, password, cap } = values
						disableButtonReason = !(phone && cap && password)

						return (
							<Form>
								<RegistForm>
									<FormHeader text="Вход в панель администратора" />
									<FormGroup>
										<Field label="Телефон" name="phone">
											{({ field: { name }, form: { setFieldTouched, setFieldValue } }) => (
												<PhoneInput
													country={'ru'}
													containerClass="phone-input-container"
													inputClass="phone-input"
													buttonClass="phone-input-button"
													dropdownClass="phone-input-dropdown"
													preferredCountries={['ru']}
													onBlur={() => {
														setFieldTouched(name, true)
													}}
													onChange={(value) => {
														setFieldValue(name, value)
													}}
												/>
											)}
										</Field>
										<Field label="Пароль" name="password" type="password" as={Input} />
									</FormGroup>
									<ReCAPTCHA
										ref={captcha}
										size={device === 'mobile' ? 'compact' : 'normal'}
										sitekey="6LfXV_QoAAAAAC3MowiQRJU4w3NqU-e8BiFm__mY"
										onChange={onChangeCap(setValues)}
									/>
									<Button
										nType="submit"
										label="Войти"
										type="md2"
										onClick={() => {}}
										disabled={disableButtonReason}
										loading={isSubmitting}
									/>
								</RegistForm>
							</Form>
						)
					}}
				</Formik>
			</div>
		</div>
	)
}
