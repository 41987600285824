import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ModalSearch } from '../../../../../components/ModalContainer/ModalSearch/ModalSearch'
import { TableBody } from '../../../../../components/TableBody/TableBody'
import { TableRequestRow } from '../../../../../components/TableBody/TableRows/TableRequestRow/TableRequestRow'
import { Button } from '../../../../../components/Buttons/Button/Button'
import { CancelButton } from '../../../../../components/Buttons/CancelButton/CancelButton'

import icon from '../../../../../assets/media/svg/delete-black.svg'

import {
	searchUsers,
	setSearchUsersResult,
	addUsersToGroup,
} from '../../../../../store/reducers/groups/groups'
import { getUserInfo } from '../../../../../store/reducers/user/user'

import { getSearchUsersResult } from '../../../../../store/reducers/groups/groups-selectors'

import './GroupModal.scss'

export const GroupModal = React.memo(({ id, addMember, setAddMember, openQuestionnaireModal }) => {
	const dispatch = useDispatch()

	const [searchValue, setSearchValue] = useState('')
	const [selectedValue, setSelectedValue] = useState('')
	const [newMember, setNewMember] = useState([])
	const [newMemberId, setNewMemberId] = useState({ group_id: '', users: [] })
	const [userId, setUserId] = useState(null)

	const searchResult = useSelector(getSearchUsersResult)

	const handleSelect = (item) => {
		if (id !== 'create') setNewMemberId({ group_id: id, users: [...newMemberId.users, item.id] })

		setNewMember([...newMember, item])
		dispatch(setSearchUsersResult({ result: [] }))
		setSelectedValue('')
		setSearchValue('')
	}

	const selectUserQuestionnaire = (id) => {
		openQuestionnaireModal(true)
		setUserId(id)
	}

	const deleteMember = (id, group) => {
		setNewMember(newMember.filter((member) => member.id !== id))
		setNewMemberId({
			group_id: group,
			users: newMemberId.users.filter((member) => member !== id),
		})
	}

	const deleteMembersInfo = () => {
		setNewMemberId({ group_id: '', users: [] })
		setNewMember([])
		setSelectedValue('')
		setSearchValue('')
		setAddMember(false)
	}

	useEffect(() => {
		if (!addMember) {
			setNewMember([])
			setNewMemberId({ group_id: '', users: [] })
		}
	}, [addMember])

	const searchRequest = useCallback((value) => dispatch(searchUsers(id, value)), [dispatch, id])

	useEffect(() => {
		if (userId) {
			dispatch(getUserInfo(userId))
			setTimeout(() => {
				setUserId(null)
			}, 300)
		}
	}, [dispatch, userId])

	return (
		<div className="group__modal">
			<div className="group__modal-header">Добавить пользователей</div>
			<ModalSearch
				inputPlaceholder="Пользователь/Группа"
				handleSelect={handleSelect}
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				selectedValue={selectedValue}
				setSelectedValue={setSelectedValue}
				searchResult={searchResult.filter(
					(item) => !newMember.find((member) => member.id === item.id)
				)}
				setResult={setSearchUsersResult}
				searchRequest={searchRequest}
			/>
			<TableBody custom>
				{newMember.map((item, index) => (
					<div key={`modal-${index}`} className="group__modal-box">
						<TableRequestRow
							name={item.name}
							image={item.image}
							phone={item.phone}
							classList={['group__modal-item']}
							openModal={() => selectUserQuestionnaire(item.id)}
							icon={icon}
							deleteIt
							removeItem={() => deleteMember(item.id, id)}
						/>
					</div>
				))}
			</TableBody>
			<div className="group__modal-buttons">
				<CancelButton closeIt={() => deleteMembersInfo()} />
				<Button
					nType="button"
					type="md1"
					label="Сохранить"
					onClick={() => {
						dispatch(setSearchUsersResult({ result: [] }))
						dispatch(addUsersToGroup(newMemberId))
						deleteMembersInfo()
					}}
				/>
			</div>
		</div>
	)
})
