import { getAdminToken } from '../functions/localStorage'
import instance from './api'

const groupsApi = {
	async requestUserGroup(id) {
		const response = await instance.post(
			`admin/get/group/user?user_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async requestGroups(filters) {
		const response = await instance.post(
			'admin/search/group',
			{ ...filters },
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async createGroup(data) {
		const response = await instance.post(
			'admin/create/group',
			{ ...data },
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async updateGroup(data) {
		const response = await instance.post(
			'admin/update/group',
			{ ...data }, {
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async addUsersToGroup(data) {
		const response = await instance.post(
			`admin/group/adduser`,
			{ ...data },
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async deleteUserFromGroup(idGroup, idUser) {
		const response = await instance.post(
			`admin/group/deluser?group_id=${idGroup}&user_id=${idUser}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async requestGroupsByUser(id) {
		const response = await instance.post(
			`admin/get/group/user?user_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async requestUsersInGroup(filters) {
		const response = await instance.post(
			'admin/search/user/group',
			{ ...filters },
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},

	async requestGroup(id) {
		const response = await instance.post(
			`admin/get/group?group_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	},
	
	async deleteGroup(id) {
		const response = await instance.post(
			`admin/delete/group?group_id=${id}`,
			{},
			{
				headers: {
					Authorization: `Bearer ${getAdminToken()}`,
				},
			}
		)

		return response.data
	}
}

export default groupsApi
