import React, { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'

import Cropper from 'react-easy-crop'

import { Button } from '../Buttons/Button/Button'
import { ModalContainer } from '../ModalContainer/ModalContainer'

import { addNot } from '../../store/reducers/global-nots/global-nots'

import getCroppedImg from './preview-functions'

import './Preview.scss'

export const Preview = React.memo(({ image, setImage, accept, error, aspect }) => {
	const dispatch = useDispatch()

	const [previewImage, setPreviewImage] = useState(null)
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

	const [showModal, handleShowModal] = useState(false)

	const previewElem = useRef()

	const loadImage = (e) => {
		const file = e.currentTarget.files[0]

		if (file.size / 1024 / 1024 > 1.5) {
			dispatch(addNot({ not: 'Cлишком большой размер фотографии', type: 'error' }))
		} else {
			const reader = new FileReader()

			reader.onload = async () => {
				setPreviewImage(reader.result)
				await handleShowModal(true)
			}

			if (file) {
				reader.readAsDataURL(file)
			}
		}
	}

	const resetInputValue = (e) => (e.target.value = '')

	//Получение координат для обреза загруженного файла
	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	//Обрезка изображения и отправка его на сервер - загрузка аватара на сервер
	const uploadCroppedImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(previewImage, croppedAreaPixels)

			setImage(croppedImage)

			handleShowModal(false)
		} catch (e) {
			console.log(e)
		}
	}, [setImage, croppedAreaPixels, previewImage])

	const onClose = () => {
		handleShowModal(false)
		setTimeout(() => {
			setPreviewImage(null)
		}, 1000)
	}

	return (
		<>
			<div
				className={cn('preview', { isLoaded: image, error })}
				ref={previewElem}
				style={{ backgroundImage: `url(${image})` }}
			>
				<label htmlFor="preview-input" className="preview__label">
					<span>Миниатюра</span>
				</label>
				<input
					id="preview-input"
					className="preview__input"
					type="file"
					accept={accept}
					onChange={loadImage}
					onClick={resetInputValue}
				/>
			</div>

			<ModalContainer show={showModal} onClose={onClose}>
				<Cropper
					image={previewImage}
					crop={crop}
					zoom={zoom}
					aspect={aspect}
					style={{
						containerStyle: {
							width: '100%',
							height: 500,
							position: 'relative',
						},
					}}
					onCropChange={setCrop}
					onCropComplete={onCropComplete}
					onZoomChange={setZoom}
				/>

				<Button label="Применить" onClick={uploadCroppedImage} className={'save-preview'} />
			</ModalContainer>
		</>
	)
})
