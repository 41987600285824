import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { BreadCrumbs } from '../../../../../components/BreadCrumbs/BreadCrumbs'
import { EditCard } from '../../../../../components/EditCard/EditCard'
import { ModalContainer } from '../../../../../components/ModalContainer/ModalContainer'
import { UserBanModal } from './UserBanModal/UserBanModal'
import { BackToList } from '../../../../../components/Buttons/BackToList/BackToList'
import { ShowButtonsBlock } from '../../../../../components/Buttons/ShowButtonsBlock/ShowButtonsBlock'
import { ButtonsPanelBlock } from '../../../../../components/Buttons/ShowButtonsBlock/ButtonsPanelBlock/ButtonsPanelBlock'

import { ban } from '../../../../../store/reducers/users/users'

import { getDevice } from '../../../../../store/reducers/app/app-selectors'

import './UserItemHeader.scss'

export const UserItemHeader = React.memo(
	React.forwardRef(
		({ id, name, isBanned, photo, showButtons, clickOutside, setShowButtons }, ref) => {
			const [isBanModalShow, setIsBanModalShow] = useState(false)

			const history = useHistory()

			const dispatch = useDispatch()

			const device = useSelector(getDevice)

			const crumbs = [
				{ link: '/users', text: 'Пользователи' },
				{ link: `/users/${id}`, text: `${name}` },
			]

			const handleBanUser = () => {
				setIsBanModalShow(false)
				dispatch(ban(id, !isBanned))
			}

			const handleGoBack = () => {
				history.push('/users')
			}

			const handleItemClick = () => setShowButtons(true)

			useEffect(() => {
				if (showButtons) {
					document.addEventListener('click', clickOutside)
				} else {
					document.removeEventListener('click', clickOutside)
				}
			}, [clickOutside, showButtons, setShowButtons])

			return (
				<>
					<div className="user-item-header">
						{device === 'mobile' || device === 'notepad' ? (
							<>
								<BackToList text="К пользователям" onClick={handleGoBack} />
								<ShowButtonsBlock
									text="Управление пользователем"
									ref={ref}
									onClick={handleItemClick}
								/>
								<ButtonsPanelBlock show={showButtons}>
									<div className="is-user-banned">
										<label htmlFor="isBanned">
											<input
												className="is-banned-input"
												type="checkbox"
												id="isBanned"
												checked={isBanned}
												onChange={() => setIsBanModalShow(!isBanModalShow)}
											/>
											<span className="is-banned-label">Заблокированный</span>
										</label>
									</div>
									<EditCard
										userItemHeader
										saveType={'submit'}
										cancel={'Назад'}
										cancelFunc={handleGoBack}
									/>
								</ButtonsPanelBlock>
							</>
						) : (
							<>
								<BreadCrumbs items={crumbs} />
								<div className="header-buttons">
									<div className="is-user-banned">
										<label htmlFor="isBanned">
											<input
												className="is-banned-input"
												type="checkbox"
												id="isBanned"
												checked={isBanned}
												onChange={() => setIsBanModalShow(!isBanModalShow)}
											/>
											<span className="is-banned-label">Заблокированный</span>
										</label>
									</div>
									<EditCard
										userItemHeader
										saveType={'submit'}
										cancel={'Назад'}
										cancelFunc={() => {
											history.push('/users')
										}}
									/>
								</div>
							</>
						)}
					</div>
					<ModalContainer show={isBanModalShow} onClose={() => setIsBanModalShow(false)}>
						<UserBanModal
							name={name}
							photo={photo}
							onClose={() => setIsBanModalShow(false)}
							onClick={handleBanUser}
							isBanned={isBanned}
							id={id}
						/>
					</ModalContainer>
				</>
			)
		}
	)
)
